import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';

/* export const WrapperCalculate = styled.section`
    display: flex;
    align-items: center;
    height: 434px;
    background: rgba(42, 34, 57, 1);
    margin-top: 51px;

    div {
        height: 229px;
        width: 100%;
        background: rgba(255, 86, 32, 1);
    }

    h2 {
        display: none;
    }

    ${breakpoint('xl')} {
        height: 200px;
        margin-top: 86px;
        margin-bottom: 8px;
        background: transparent;
        div {
            background: rgba(255, 86, 32, 1);
            height: 100%;
        }
    }
`;
 */

export const WrapperCalculate = styled.div`
      margin: auto;
      max-width: 320px;
      .slick-dots {        
        text-align: center;
        width: auto;         
        right: 0;
        left: 0;
        top: 290px;
        margin: 0 auto;
        li {
            width: 15px;
            height: 15px;
            &.slick-active {
                button {
                    &:before {
                        font-size: 12px;
                        line-height: 14px;
                        width: 11px;
                        height: 11px;
                        color: transparent;
                        box-shadow: 0px 0px 6px 1.5px
                            ${(props) => props.theme.colors.alerts.yellow};
                        border: 2px solid ${(props) => props.theme.colors.alerts.yellow};
                        top: -2px;
                    }
                }
            }
            button {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                &:before {
                    font-size: 10px;
                    line-height: 12px;
                    color: transparent;
                    border: 2px solid ${(props) => props.theme.colors.disabled.gray};
                    border-radius: 50%;
                    width: 7px;
                    height: 7px;
                    opacity: 1;
                }
            }
        }
    }

    @media screen and (max-width: 704px) {
        .slick-dots {
          top: 550px; 
          left: 0;
        }
      }

      ${breakpoint('md')({
    maxWidth: '704px'

})}
    
        ${breakpoint('lg')({
    maxWidth: '1056px'
})}
`;

export const ContainerImage = styled.div`
    img {
        height: 200px;
        display: flex;        
        margin: auto;
        position: relative;       
        left: 20px;
        top: -113px;


        ${breakpoint('md')({
    display: 'block',
    position: 'relative',
    left: '20px',
    top: '-60px',
    height: '200px'
})}

        ${breakpoint('lg')({
    position: 'relative',
    top: '-87px',
    left: '30px',
    height: '240px'
})}
        }
`;

export const ContainerImageReverse = styled.div`

    padding-right: 60px;

    img {
        height: 200px;
        display: flex;
        margin: auto;
        position: relative;
        left: 20px;
        top: -113px;


        ${breakpoint('md')({
    display: 'block',
    position: 'relative',
    left: '20px',
    top: '-60px',
    height: '200px'
})}

        ${breakpoint('lg')({
    position: 'relative',
    top: '-87px',
    left: '30px',
    height: '240px'
})}
        }
`;

export const ContainerText = styled.div`
    padding-top: 20px;

    b {
        color: ${(props) => props.theme.colors.accents.base.yellow};
    }

    p {
        font-family: ${(props) => props.theme.fonts.mon_bold};
        text-align: center;
        padding: 0 20px 20px;
        font-size: 20px;
        line-height: 24px;
        margin: auto;
        position: relative;
        top: -131px;

        ${(props) =>
        breakpoint('md')({
            fontSize: '14px',
            lineHeight: '16.8px',
            width: '230px',
            textAlign: 'end',
            padding: '0',
            top: '0'
        })}
        ${breakpoint('lg')({
            alignSelf: 'center',
            fontSize: '24px',
            lineHeight: '28px',
            width: '431px',
            textAlign: 'left'
        })};
    }

    ${breakpoint('md')({
            paddingTop: '0',
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center'
        })}

    ${breakpoint('lg')({
            alignSelf: 'center',
            paddingLeft: '60px',
            textAlign: 'left'
        })}
`;

export const ContainerSlider = styled.div`
    width: 100%;
    background: linear-gradient(180.26deg, #0df96c -31.53%, #004e2d 99.84%);
    border-radius: 16px;
    padding-bottom: 30px;
    margin: 150px auto 0;
    height: 268px;


    ${breakpoint('md')({
        marginTop: '100px',
        height: '140px',
        background: 'linear-gradient(274.3deg, #004E2D 42.64%, #00D455 100.44%)',
        padding: '0',
        display: 'flex!important'
    })}

    ${breakpoint('lg')({
        height: '140px'
    })}

    a {
        margin: auto;
        padding-top: 20px;

        button {
            margin: auto;
            height: 48px;
            width: 205px;
            font-size: 16px;
            line-height: 20px;
            position: relative;
            top: -120px;

            ${breakpoint('md')({
                height: '46px',
                width: '160px',
                position: 'relative',
                top: '0'
            })}

            ${breakpoint('lg')({
                height: '32px',
                width: '194px',
                fontSize: '14px',
                lineHeight: '16px'
            })}
        }
    }

    a {
        ${breakpoint('md')({
            paddingTop: '0'
        })}
    }
`;

export const ContainerResponsive = styled.div`
    @media screen and (max-width: 704px) {
        display: block!important;
    }
`;

export const ArrowCustom = styled.div`
    display: flex;
    cursor: pointer;
    position: absolute;
    top: 60%;
    bottom: 0;
    align-items: center;A
    z-index: 4;
    height: 60px;
    svg {
        path {
            transition: all 0.3s ease-in-out;
        }
    }
    &:hover {
        svg {
            // path {
            //     fill: ${(props) => props.theme.colors.principal.greenBase};
            // }
        }
    }
    &.prev {
        left: -100px;
    }
    &.next {
        right: -100px;
        transform: rotate(180deg);
    }
`;