import React from 'react';
import { TitleStepContainer, NumberStep } from './styled';

interface IProps {
    number: string;
    text: string;
    className?: 'titleStepBillonario'
}

export const TitleStep = (props:IProps) => {

    const {number, text, className='' } = props

    return (
        <TitleStepContainer
            className={className}
        >
            <NumberStep>{number}</NumberStep>
            <h3>{text}</h3>
        </TitleStepContainer>
    );
};
