import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';
interface IProps {
    state: boolean;
}
export const HeaderMessageContainer = styled.div<IProps>`
    display: ${(props: IProps) => (props.state ? 'none !important' : 'block')};
    width: 100%;
    max-height: 56px;
    top: 0;
    left: 0;
    background-color: ${(props) => props.theme.colors.accents.neon.blue};
    z-index: 7;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: fixed;

    // Desktop
    ${breakpoint('md')`
        height: 40px;
        padding: 14px ;
        display: flex;
        justify-content: center;
    `}
`;
export const ContentImg = styled.div`
    padding: 22px 17px 19px 0px;
    ${breakpoint('md')`
        padding: 0px;
        margin-right: 10px;

    `}
`;
export const ImageLogo = styled.img`
    color: ${(props) => props.theme.colors.text.gray};
`;
export const ContentText = styled.div`
    width: 262px;
    max-height: 36px;
    display: flex;
    text-align: center;
    ${breakpoint('md')`
        width:auto;
    `}
`;
export const Text = styled.p`
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 14px;
    line-height: 17px;
    a {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        text-decoration: underline;
        margin-left: 6px;
        ${breakpoint('md')`
            margin-left: 40px;
        `}
    }
`;
