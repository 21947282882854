import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const FooterPrincipal = styled.footer`
    /* background-color: #1f192b; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 3;

    &.marginSummary {
        margin-bottom: 102px;
    }

    &.marginSummaryBi {
        .copyright {
            height: 120px;
            margin-bottom: 125px;
        }
    }

    &.marginBilleton {

        .copyright {
            height: 110px;
            margin-bottom: 115px;
        }
    }

    &.marginShoppingCart {
        .copyright {
            height: 130px;
            margin-bottom: 335px;
        }
    }

    ${breakpoint('md')`
        &.marginSummary {
            margin-bottom: unset;
        }

        &.marginSummaryBi {
            .copyright {
                height: 50px;
                margin-bottom: 0;
            }
        }

        &.marginBilleton {

            .copyright {
                height: 50px;
                margin-bottom: 0;
            }
        }

        &.marginShoppingCart {
            .copyright {
                height: 50px;
                margin-bottom: 0;
            }
        }
    `}
`;

export const FooterContainer = styled.div`
    width: 100%;
    background: ${(props) => props.theme.colors.principal.darkGreen};
    color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding: 32px 40px 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 310px;

    ${breakpoint('md')`
        justify-content: center;
        align-items: baseline;
        padding: 50px 40px 50px;
        overflow: hidden;
        flex-wrap: wrap;
        flex-direction: row;
    `}
    ${breakpoint('md')`
        padding: 50px 40px 20px;
    `}
`;

export const ContainerColumnUs = styled.div`
    position: relative;
    &:after {
        content: '';
        background: #fff;
        height: 120%;
        width: 1px;
        position: absolute;
        right: 0;
        top: 0;
        margin: auto 0;
    }

    ${breakpoint('md')`
        display: flex;
        width: 48%;
        flex-direction: column;
        padding: 0px 20px 0px 25px;
        order: 1;
    `}
    ${breakpoint('lg')`
        width: 33.333%;
        max-width: 280px;
        &:after {
            height: 285px;
        }
    `}
    ${breakpoint('xxl')`
        width: 25%;
        &:after {
            height: 265px;
        }
    `}
`;

export const ContainerColumnHelp = styled.div`
    position: relative;
    &:after {
        content: '';
        background: #fff;
        height: 100%;
        width: 1px;
        position: absolute;
        right: 0;
        top: 0;
        margin: auto 0;
    }

    ${breakpoint('md')`
        display: flex;
        width: 48%;
        flex-direction: column;
        padding: 5px 20px 0px 25px;
        order: 3;
    `}
    ${breakpoint('lg')`
        width: 33.333%;
        padding: 0 20px 0px 25px;
        order: 2;
        max-width: 275px;
        &:after {
            height: 285px;
        }
    `}
    ${breakpoint('xxl')`
        width: 20%;
        min-width: 280px;
        &:after {
            height: 265px;
        }
    `}
`;

export const ContainerColumnContact = styled.div`
    position: relative;
    &:after {
        background: #fff;
        height: 100%;
        width: 1px;
        position: absolute;
        right: 0;
        top: 0;
        margin: auto 0;
    }

    ${breakpoint('md')`
        display: flex;
        width: 48%;
        flex-direction: column;
        padding: 0px 20px 0px 25px;
        order: 2;
    `}
    ${breakpoint('lg')`
        width: 33.333%;
        order: 3;
    `}
    ${breakpoint('xxl')`
        width: 33%;
        &:after {
            content: '';
            height: 265px;
        }
    `}
`;

export const ContainerColumnFollowUs = styled.div`
    width: 80%;
    display: flex;
    height: unset;
    position: relative;
    flex-wrap: wrap;

    ${breakpoint('md')`
        width: 48%;
        flex-direction: column;
        height: 95%;   
        order: 4; 
        padding: 0 30px;
    `}
    ${breakpoint('lg')`
        width: 100%;
        margin-top: 41px;
        padding: 12px 5% 0;
        flex-direction: row;
        align-content: flex-start;
        height: auto;  
        &:before {
            content: '';
            background: #fff;
            height: 1px;
            width: 90%;
            position: absolute;
            right: 0;
            left: 0;
            top: 0;
            margin: 0 auto;
        }
    `}
    ${breakpoint('xxl')`
        width: 20%;
        margin-top: 0;
        padding: 0 30px;
        &:before {
            content: none;
        }
    `}
`;

export const TitleColumns = styled.p`
    display: none;

    ${breakpoint('md')`
        font-family: ${(props) => props.theme.fonts.mon_bold};
        display: block;
        font-size: 16px;
        color: #fff;
        margin: 20px 0px;
        width: 100%;
    `}

    ${breakpoint('xxl')`
        margin: 0px 0px 20px 0px;
    `}
`;

export const ContainerTextColumns = styled.div`
    margin-bottom: 20px;
`;

export const TextColumns = styled.p`
    font-family: ${(props) => props.theme.fonts.lf_regular};
    display: block;
    font-size: 14px;
    color: #fff;
    margin-bottom: 14px;
    line-height: 17px;
    padding-left: 18px;
    a {
        padding-left: 0;
    }
  &.link{
    cursor: pointer;
    text-align: center;
    padding-left: 0;

    ${breakpoint('md')`
       text-align: left;
           padding-left: 18px;

    `}
  }
`;

export const ContainerSocialNetwork = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: baseline;
    height: 50px;
    border-bottom: 1px solid #fff;

    ${breakpoint('md')`
        margin: 0px 18px;
        width: 80%;
    `}
    ${breakpoint('lg')`
        border-bottom: none;
        width: 200px;
        gap: 32px;
        margin-left: 24px;
    `}
    ${breakpoint('xxl')`
        width: 100%;
        margin: 0px 18px 12px;
        border-bottom: 1px solid #fff;
    `}
`;

export const SocialNetwork = styled.img`
    display: block;
`;

export const FooterMenu = styled.div`
    padding: 30px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const ContainerTitles = styled.div`
    margin-bottom: 14px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    ${breakpoint('md')`
            margin-bottom: 24px;
    `}
`;

export const TitleMenu = styled.label`
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-size: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &.iconClassDown::after {
        font-family: 'icomoon';
        content: '\\e912';
        margin-left: 8px;
        font-weight: bold;
        font-size: 12px;
    }

    &.iconClassUp::after {
        font-family: 'icomoon';
        content: '\\e90b';
        margin-left: 8px;
        font-weight: bold;
        font-size: 12px;
    }
`;

export const ContainerSubtitleMenu = styled.ul`
    margin-top: 15px;
    overflow: hidden;
    height: 0;
    transition: all 0.5s ease;
    &.dropOpen {
        height: auto;
    }
`;

export const TitleMenuInput = styled.input`
    display: none;
    /* &:checked ~ ${ContainerSubtitleMenu} {
        height: auto;
    }

    &:checked + ${TitleMenu}::after {
        content: '\\e912';
    } */
`;

export const SubtitleMenu = styled.h3`
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 14px;
    color: #fff;
    margin-bottom: 15px;
    text-align: center;
    line-height: 17px;
`;

export const LinkContact = styled.a`
    &.textBold {
        font-weight: 700;
    }
    &.propsSubtitleMenu {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 14px;
        color: #fff;
        margin-bottom: 15px;
        text-align: center;
        line-height: 17px;
    }
`;

export const ItemSubtitle = styled.li`
    a {
        text-decoration: none;
        text-align: center;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        display: block;
        font-size: 14px;
        color: #fff;
        margin-bottom: 14px;
        line-height: 17px;
        transition: all 0.5s ease-in-out;

        &:hover {
            font-weight: bold;
        }

        ${breakpoint('md')`
            padding-left: 18px;
            text-align: left;
        `}
    }
`;

export const LinkSub = styled.a`
    text-decoration: none;
    font-family: ${(props) => props.theme.fonts.lf_regular};
    display: block;
    font-size: 14px;
    color: #fff;
    margin-bottom: 14px;
    line-height: 17px;
    transition: all 0.5s ease-in-out;

    &:hover {
        font-weight: bold;
    }

    ${breakpoint('md')`
        padding-left: 18px;
    `}
`;

export const FooterLogos = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 345px;
    a {
        img {
            width: 100%;
        }
    }

    ${breakpoint('md')`
        flex-direction: column;
        justify-content: unset;
        padding: 15px 0px 0px 0px;
        margin-left: 18px;
        max-width: unset;
        `}
    ${breakpoint('lg')`
        flex-direction: row;
        flex-grow: 1;
        justify-content: space-around;
        padding-top: 0;
        border-left: 1px solid #fff;
    `}

    ${breakpoint('xxl')`
        border-left: none;
        justify-content: flex-start;
        max-width: 200px;
    `}
`;

export const ContainerLine = styled.div`
    display: flex;
    position: relative;
    width: 40%;
    ${breakpoint('xs')`
        width: unset;
    `}
`;

export const LineLogo = styled.span`
    display: inline-block;
    background: #fff;
    height: 46px;
    width: 1px;
    position: absolute;
    right: -10%;

    ${breakpoint('xs')`
        right: -30%;
    `}
`;

export const CorporateLogo = styled.img`
    display: block;
    max-width: 126px;
    margin-bottom: 20px;

    &.sizeLogo {
        width: 108px;
    }

    &.marginTopLogo {
        margin-top: 10px;

        ${breakpoint('md')`
            margin-bottom: 0px;
        `}
    }

    ${breakpoint('md')`
        margin-bottom: 10px;
    `}
`;

export const FooterCopyright = styled.div`
    width: 100%;
    background: ${(props) => props.theme.colors.principal.greenBase};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    font-size: 14px;

    ${breakpoint('md')`
        height: 50px;
        padding-bottom: unset;
    `}
`;

export const TextCopyright = styled.p`
    background: ${(props) => props.theme.colors.principal.greenBase};
    color: #fff;
    font-family: ${(props) => props.theme.fonts.lf_regular};
    width: 250px;
    text-align: center;
    line-height: 16.8px;

    a {
        font-family: ${(props) => props.theme.fonts.lf_bold};
    }

    &.paddingSummary {
        padding-bottom: 27px;
    }

    ${breakpoint('md')`
        width: unset;
        
        &.paddingSummary {
        padding-bottom: unset;

        b {
            text-decoration: underline;
        }
    }
    `}
`;
