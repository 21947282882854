import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const CardExampleGameContainer = styled.div`
    width: 260px;
    height: auto;
    background: ${(props) => props.theme.colors.backgrounds.gray500};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 16px rgba(13, 249, 108, 0.5);
    border-radius: 16px;
    padding: 15px 20px 20px 20px;
    margin: 0 auto;
    margin-bottom: 10px;
    margin-top: 10px;

    ${breakpoint('md')`
        margin: 0;
    `}
`;

export const CardExampleGameHeader = styled.div`
    width: 100%;
    display: flex;
    box-sizing: border-box;
`;

export const HitsContainer = styled.div`
    width: 50%;
    height: 58px;
    padding-right: 5px;
    display: flex;
   
    flex-direction: column;
    align-items: center;
    padding-right: 14px;
    border-right: 1px solid ${(props) => props.theme.colors.backgrounds.gray400};

    & > span {
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 32px;
        margin-bottom: 8px;
        color: ${(props) => props.theme.colors.accents.base.yellow};
        position: relative;

        &:after {
            width: 53px;
            height: 53px;
            position: absolute;
            top: -10px;
            left: -15px;
            content: ' ';
            background: #0df96c;
            opacity: 0.15;
            filter: blur(10px);
            border-radius: 50%;
            transform: matrix(1, 0, 0, -1, 0, 0);
        }
    }

    & > p {
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 15px;
    }
`;

export const HitsContent = styled.div`
    width: 100%;
    height: 51px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > p {
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 120%;
        display: flex;
        justify-content: center;
        align-items: center;

        & > span {
            font-family: ${(props) => props.theme.fonts.mon_bold};
            font-style: normal;
            font-weight: bold;
            font-size: 40px;
            width: 46px;
            margin: auto;
            text-align: center;
            line-height: 40px;
            color: ${(props) => props.theme.colors.accents.base.yellow};
            position: relative;

            &:after {
                width: 53px;
                height: 53px;
                position: absolute;
                top: -5px;
                left: 0px;
                content: ' ';
                background: #0df96c;
                opacity: 0.15;
                filter: blur(10px);
                border-radius: 50%;
                transform: matrix(1, 0, 0, -1, 0, 0);
            }
        }
    }
`;

export const ExplanationContainer = styled.div<{ betValue: boolean }>`
    width: 100%;
    height: 100%;
    padding-left: 14px;
    display: flex;
    align-items: flex-start;
    ${(props) => props.betValue && 'align-items: center;'} 
    flex-direction: column;
    justify-content: center;
    & > span {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        color: ${props => props.theme.colors.accents.base.yellow};
        margin-bottom: 2px;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        ${(props) => props.betValue && 'font-size: 21px;'} 
        line-height: 120%;
    }

    & > p {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 120%;
        ${(props) => props.betValue && 'text-align: center;'} 
    }
`;

export const CardExampleGameBody = styled.div<{ nameGame: boolean }>`
    width: 100%;
    display: flex;
    padding-right: 5px;
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 5px;
    position: relative;

    ${(props) => !props.nameGame && `min-height: 144px;margin-top: 13px;`};

    &:after {
        position: absolute;
        content: ' ';
        left: 0;
        bottom: 0;
        width: 204px;
        height: 1px;
        background: ${(props) => props.theme.colors.backgrounds.gray400};
    }
`;

export const RowBalls = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Ball = styled.span`
    width: 45px;
    height: 45px;
    background: ${(props) => props.theme.colors.text.white};
    color: ${(props) => props.theme.colors.backgrounds.gray500};
    border-radius: 50%;
    outline: none;
    border: none;
    margin: 4px 2px;
    text-align: center;
    font-size: 28px;
    font-family: ${(props) => props.theme.fonts.lf_bold}, ${(props) => props.theme.fonts.icons};
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${(props) => props.theme.colors.disabled.gray};
    box-shadow: 0px 0px 9px #00d455;
    position: relative;

    &:after {
        content: ' ';
        width: 16px;
        height: 16px;
        background: #00d455;
        position: absolute;
        top: -8px;
        right: 0px;
        border-radius: 50%;
    }
    &:before {
        position: absolute;
        top: -6px;
        right: 3px;
        border-radius: 50%;
        content: '\\e943';
        color: #fff;
        z-index: 2;
        font-family: ${(props) => props.theme.fonts.icons};
        font-size: 10px;
    }
`;

export const AuxiliarText = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    align-self: flex-end;

    & > p {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 120%;
        color: #ffe000;
    }
`;

export const CardExampleGameFooter = styled.div<{ betValue: boolean }>`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    max-width: 250px;
    max-height: 128px;
    overflow: auto;
    padding-right: 10px;

    &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-track {
        background: ${(props) => props.theme.colors.backgrounds.gray400};
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.colors.disabled.gray};
    }

    ${breakpoint('md')`
        max-height:none;
        overflow: auto;
    `}

    & > ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 14px;
    }

    & > ul > li > p {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 120%;
        text-align: center;

        & > b {
            font-weight: bold;
            color: #ffe000;
            margin-left: 6px;
            ${(props) => props.betValue && 'font-size: 30px;'}
            ${(props) => props.betValue && 'color:' + props.theme.colors.accents.base.yellow + ';'}
            ${(props) => props.betValue && 'font-family:' + props.theme.fonts.lf_bold + ';'}
            ${(props) => props.betValue && 'text-shadow: 0px 0px 18px rgba(255, 224, 0, 0.6);'}
        }
    }
`;
