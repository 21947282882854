import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export default createGlobalStyle`
    ${reset}
    *{
        color: ${(props) => props.theme.colors.text.gray};
        font-family: ${(props) => props.theme.fonts.mon_regular};
        box-sizing: border-box;
        padding: 0;
        margin: 0;
    }

    .none{
        display: none;
    }

    html, body {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        scroll-behavior: smooth;
    }

`;
