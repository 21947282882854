import { breakpoint, colors } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const ContainerCard = styled.div`
    position: relative;
    height: 213px;
    width: 212px;
    background-color: #1d1828;
    box-shadow: 0px 0px 16px rgba(13, 249, 108, 0.5);
    border-radius: 16px;
    margin: 0px auto 63px;
    display: flex;
    flex-direction: column;

    ${breakpoint('md')`
       margin: 0px 12px 63px;
    `}
`;

export const ContainerStep = styled.div`
    height: 66px;
    width: 66px;
    background-color: #1d1828;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.colors.accents.base.yellow};
    color: ${(props) => props.theme.colors.accents.base.yellow};
    font-size: 40px;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 73px;
    top: -35px;
`;

export const ContainerIcon = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 50px;
    min-height: 24px;

    div {
        width: 58px;
        height: 1px;
        background-color: ${(props) => props.theme.colors.backgrounds.gray400};
    }

    img {
        margin: 0 15px;
        width: 24px;
    }
`;

export const ContainerText = styled.div`
    p:first-child {
        margin: 21px 14px 0px;
        text-align: center;
        b {
            display: inline-block;
            font-family: ${(props) => props.theme.fonts.lf_bold};
            font-size: 20px;
            color: ${(props) => props.theme.colors.text.gray};
            line-height: 24px;
            margin-bottom: 9px;
        }
    }

    p:not(:first-child) {
        margin: 0 8px;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 14px;
        color: ${(props) => props.theme.colors.disabled.gray};
        text-align: center;
        line-height: 16.8px;
    }
`;
