import React from 'react';
import { Breadcrumbs, ButtonGoBack, Layout, SEO } from '@componentsShared';
import { ContainerHeader, ContainerMainText, Title, WrapperFrequentQuestions } from './styled';
import { graphql, useStaticQuery } from 'gatsby';
import { InternalQuestionBanner, WrapperQuestionResponse } from '@components';
import { colorText } from '@utils/colorText';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Viewport from 'src/shared/hooks/viewport';

const FrequentQuestions = () => {
    const viewport = Viewport();
    const DataFrequentQuestions = useStaticQuery(graphql`
        query DataFrequentQuestions {
            allContentfulPaginaInternaFooter(filter: { page: { eq: "PreguntasFrecuentes" } }) {
                nodes {
                    title
                    pageIcon {
                        file {
                            url
                        }
                        title
                        description
                    }
                    imageText {
                        mediaFile {
                            file {
                                url
                            }
                            description
                        }
                        title
                        text {
                            raw
                        }
                    }
                    metaData {
                        title
                        descripcion
                        keyWords
                    }
                }
            }
        }
    `);

    const {
        allContentfulPaginaInternaFooter: {
            nodes: [{ imageText, pageIcon, title, metaData }]
        }
    } = DataFrequentQuestions;

    let crumbs = [
        {
            crumbLabel: 'Inicio',
            pathname: '/'
        },
        {
            crumbLabel: 'Preguntas frecuentes',
            pathname: 'preguntas-frecuentes/'
        }
    ];

    return (
        <Layout>
            <SEO
                title={metaData.title}
                description={metaData.descripcion}
                keyWords={metaData.keyWords}
                slug="/preguntas-frecuentes/"
            />
            <WrapperFrequentQuestions>
                {viewport.desktop ? <Breadcrumbs crumbs={crumbs} /> : <ButtonGoBack />}
                <ContainerHeader>
                    <img src={pageIcon.file.url} alt={pageIcon.description}/>
                    <Title dangerouslySetInnerHTML={{ __html: colorText(title) }} />
                </ContainerHeader>
                <ContainerMainText>
                    <img src={imageText[0].mediaFile.file.url} alt={imageText[0].mediaFile.description} />
                    {documentToReactComponents(JSON.parse(imageText[0].text.raw))}
                </ContainerMainText>
                <WrapperQuestionResponse />
                <InternalQuestionBanner />
            </WrapperFrequentQuestions>
        </Layout>
    );
};

export default FrequentQuestions;
