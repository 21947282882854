import React from 'react'
import { Spinner } from '@componentsShared';
import { BoxRedirect, Title, BoxTexts } from './styled';

interface Iprops {
    titulo: string;
    primaryText: string
    secundaryText?: string;
    strongText?: string;
}

export const PageToLoading = (props: Iprops) => {

    const { titulo, primaryText, secundaryText, strongText } = props

    return (
        <BoxRedirect>
            <Spinner />
            <Title>
                { titulo }
            </Title>
            <BoxTexts>
                { secundaryText && <p>{ secundaryText }</p> }
                { <p>{ primaryText } { strongText && <span>{ strongText }</span> }</p> }
            </BoxTexts>
        </BoxRedirect>
    )
}
