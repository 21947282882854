import { lotteriesServices } from "@services/lotteries";
import { saveCookieByHours } from "@utils/handlerCookies";
import siteUrl from "@utils/siteUrl";
import moment from "moment";
import { IBets, ILot } from "src/shared/components/molecules/cartBodyDetail/types";

export type Lottery = {
    __typename?: string;
    abreviatura: string;
    desLoteria: string;
    horaCierre: string | number;
    ideLoteria: number;
    numCifras: number;
    sorteo: string;
    disponible?: boolean;
    numeroFavorito?: string | undefined
    horaInicioConteo?: string;
};

export type Bet = {
    betNumbers: string;
    lottery: Lottery;
    value?: number;
    editBet: boolean;
    sigla: string;
    idBetCart: string;
};

export const editBilleton = async (bet: IBets) => {
    
    const actual: string = moment().format('DD/MM/YYYY');

    let lottery = {} as Lottery;
    await lotteriesServices
        .getAwardsPLanBilleton(actual, bet.sigla)
        .then((data) => {
            const lotteries: Lottery[] = parsedLotteries(data.data.getGameInfoBilleton.loterias);
            lottery = lotteries.find(lot => {
                return lot.ideLoteria == bet.loteries[0].loterieId;
            }) || {} as Lottery;
        })
        .catch((err) => {
            console.log('error', err);
        });
    
    const dataBilleton: Bet = {
        betNumbers: bet.betNumbers[0].numbers.join(''),
        editBet: true,
        sigla: bet.sigla,
        value: bet.total,
        lottery: lottery,
        idBetCart: bet.id
    };
    saveCookieByHours('betBilleton', dataBilleton, 0.18);
    window.location.href = siteUrl + '/juegos/billeton/';
};

const parsedLotteries = (lotteries: Lottery[]): Lottery[] => {
    const newLotteriesList: Lottery[] = lotteries.map((lott) => {
        lott.sorteo = lott.sorteo.toString();
        lott.abreviatura = lott.abreviatura.toLowerCase();

        lott.disponible = true;

        return lott;
    });
    return newLotteriesList;
};

export const updateLotteryBilleton = (lotteries: Lottery[], lotterySelect?: Lottery) => {
    console.log('lista: ', lotteries, "select: ", lotterySelect);
    return lotteries.find(lot => {
        return lot.ideLoteria = lotterySelect?.ideLoteria || 0;
    }) || {} as Lottery;
}