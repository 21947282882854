import { colorText } from '@utils/colorText';
import React from 'react';
import { Tap, Taps } from './styled';
import { TPropsContainerTaps } from './types';

export const ContainerTaps = (props: TPropsContainerTaps) => {
    const { sections, tapOpen, setTapOpen } = props;
    return (
        <Taps>
            {sections.length > 1 &&
                sections.map((secciones) => (
                    <Tap
                        state={tapOpen.title === secciones.title}
                        onClick={() => {
                            setTapOpen({
                                title: secciones.title,
                                sections: secciones.secciones
                            });
                        }}
                        dangerouslySetInnerHTML={{
                            __html: colorText(secciones.title)
                        }}
                    />
                ))}
        </Taps>
    );
};
