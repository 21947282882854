import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';
import registerBackground from 'src/shared/assets/images/registerBackgroundStepTwo.png';

export const RegisterBox = styled.div``;

export const ContainerRegisterTop = styled.div`
    ${breakpoint('md')`
        position: absolute;
        width: 100%;
        z-index: 1;
        padding: 6px 54px 0 54px;

        div{
            margin: 28px auto;
        }

     
    `}
`;

export const RegisterTitle = styled.h1`
    padding-top: 15px;
    text-align: center;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-size: 20px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.accents.base.yellow};

    ${breakpoint('md')`
       padding: 15px;
       border-bottom: 0.5px solid #3E3A47;
    `}
`;

export const RegisterForm = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 1024px;
    background: ${(props) => props.theme.colors.backgrounds.gray450};
    border-radius: 16px;
    position: relative;
    margin: 0 auto 20px;
    border: 0.5px solid #c5c3c9;

    ${breakpoint('md')`
       background-image: url(${registerBackground});
       background-repeat: no-repeat; 
       background-size: cover;
       border: none;
    `}
`;

export const RegisterBody = styled.div`
    width: 100%;
    padding: 0 14px 30px 14px;
    ${breakpoint('md')`
        padding: 0 53px;
    `}
`;

export const TitleContainer = styled.div`
    padding: 18px 0;
    border-bottom: 0.5px solid #3e3a47;

    ${breakpoint('md')`
        padding: 80px 0; 

        div{
            position: relative;
            top: 8rem;
        }

        p{
            padding: 0;
        }
    `}
`;

export const AuxText = styled.p`
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-size: 16px;
    font-weight: 19.2px;
    color: ${(props) => props.theme.colors.accents.base.yellow};
    position: relative;
    top: 8rem;
    padding: 0 5px;

    ${breakpoint('md')`
        padding: 0;
    `}
`;

export const MainText = styled.p`
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 14px;
    line-height: 18px;
    color: #c5c3c9;
    margin-top: 14px;
    padding-left: 0px;

    ${breakpoint('md')`
        
        padding-left: 0;
        margin-top: 53px;
    `}
`;

export const FormRegister = styled.form`
    padding: 25px 0;
    div {
        padding-bottom: 12px;
    }

    ${breakpoint('md')`
        padding: 28px 0;
    `}

    .tol {
        position: absolute;
        left: 18rem;

        p {
            top: 10px;
            span {
                color: ${(props) => props.theme.colors.principal.greenNeon};
            }
        }
    }
`;

export const InputContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;

    div {
        label {
            font-size: 14px;
            line-height: 24px;
            font-family: ${(props) => props.theme.fonts.lf_bold};
        }

        padding: 0;
        padding-bottom: 12px;
    }

    ${breakpoint('md')`
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 58px;

        input {
            max-width: 430px;
        }
    `}
`;

export const CustomCheck = styled.input`
    height: 16px;
    width: 16px;
    border: 1px solid #c5c3c9;
    appearance: none;
    border-radius: 4px;
    position: relative;
    top: 5px;
    &:before {
        content: none;
    }
    &:checked {
        border: none;
        &:before {
            font-family: 'icomoon';
            content: '\\e944';
            color: ${(props) => props.theme.colors.principal.greenBase};
            font-size: 16px;
        }
    }
`;
export const FormButtons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
        font-size: 16px;
        width: 175px;
        margin-bottom: 42px;
    }

    .redirect {
        text-decoration: none;
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-size: 16px;
        line-height: 21.6px;
    }

    ${breakpoint('md')`
        flex-direction: row-reverse;
        justify-content: center;
        align-items: baseline;
        margin: 0;

        button{
            font-size: 14px;
            width: 138px;
            heigth: 32px;
            margin-bottom: 42px;
            margin-left: 56px;
        }

        .redirect {
            font-size: 14px;
            line-height: 21.6px;
        }
    `}
`;

export const ContainerPassword = styled.div`
    display: grid;
    grid-template-columns: 1fr;

    div {
        label {
            font-size: 14px;
            line-height: 24px;
            font-family: ${(props) => props.theme.fonts.lf_bold};
        }

        padding: 0;
        padding-bottom: 12px;
    }
    ${breakpoint('md')`
        
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 58px;
      
    `}
`;


export const ContainerReCaptcha = styled.div`
    display: flex;
    justify-content: center;

`