import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

type IProps = {
    heightAuto?: boolean;
    legal?: boolean;
};

export const ContainerCard = styled.div<IProps>`
    background: ${(props) => props.theme.colors.backgrounds.gray500};
    border: 1px solid rgba(197, 195, 201, 0.5);
    border-radius: 16px;
    max-height: ${(props: IProps) => (props.heightAuto ? '95px' : '80px')};
    width: 100%;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    margin-top: ${(props: IProps) => (props.legal ? '10px' : '0')};

    & > div:nth-child(1) {
        height: ${(props: IProps) => (props.heightAuto ? '80px' : '62px')};
    }

    &.open {
        max-height: 2000px;
    }

    ${breakpoint('md')<IProps>`
        margin-top: ${(props: IProps) => (props.legal ? '16px' : '0')};
        max-height: 51px;

        & > div:nth-child(1) {
            height: 51px;
        }
    `};

    hr {
        border: none;
        background: rgba(197, 195, 201, 0.5);
        height: 1px;
        margin: 0 25px;
    }
`;

export const HeaderCard = styled.div`
    align-items: center;
    display: flex;
    height: 62px;
    justify-content: space-between;
    margin: 0 26px 0 21px;

    h3 {
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-size: 14px;
        line-height: 16px;
        margin-right: 20px;
        padding-bottom: 3px;

        ${breakpoint('xxs')`
        margin-top: 20px;
        `};

        ${breakpoint('md')`
        margin-top: 0;
        `};

        span {
            color: ${(props) => props.theme.colors.accents.base.yellow};
        }
    }

    button span {
        font-size: 13px;
    }

    ${breakpoint('md')`
        height: 51px;
        margin-right: 38px;
        margin-left: 24px;
    `};

    ${breakpoint('md')`
        margin-right: 31px;
    `};
`;

export const BoxContent = styled.div`
    height: auto;
    margin: 15px 20px 25px 20px;
    overflow: hidden;
    white-space: pre-line;

    p {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 14px;
        line-height: 16px;

        a {
            color: ${(props) => props.theme.colors.principal.darkGreen};
        }

        b {
            font-family: ${(props) => props.theme.fonts.lf_bold};
        }
    }

    ol {
        margin: 12px 0;
    }

    ol li {
        margin-left: 40px;
        list-style: decimal;
    }

    &.content-scrollable {
        margin: 15px 20px 25px 20px;
        overflow: hidden;
        white-space: pre-line;
        height: 219px;
        overflow-y: scroll;

        p {
            margin-right: 15px;
        }

        ::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        ::-webkit-scrollbar-thumb {
            background: #c5c3c9;
        }
        ::-webkit-scrollbar:vertical {
            width: 2px;
        }
        ::-webkit-scrollbar-track {
            background: #3e3a47;
        }
    }

    ${breakpoint('md')`
        margin-right: 26px;
        margin-left: 26px;

        &.content-scrollable {
            height: auto;
            margin: 24px 30px 25px 30px;
            overflow-y: hidden;

            p {
                margin-right: 0;
            }
        }
    `};

    ${breakpoint('xl')`
        p {
            font-size: 16px;
            line-height: 19px;
        }

        margin-top: 25px;
    `};
`;

export const Title = styled.h3``;
