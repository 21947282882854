import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const ContainerResolutions = styled.div`
    padding-top: 12px;

    ${breakpoint('md')`
      padding: 20px 5px 0 5px;
    `}
    ${breakpoint('lg')`
      padding: 24px 24px 0 24px;
    `}
`;

export const Box = styled.div`
    padding-top: 6px;
    width: 100%;
    height: 43px;
    background: ${(props) => props.theme.colors.backgrounds.gray400};
    border-radius: 12px;
    &:hover {
        background: linear-gradient(263.33deg, #0df96c 8.85%, #009f5c 98.8%);
        &:active {
            background: #009f5c;
        }
    }
    ${breakpoint('s')`
         width: 269.06px;
    `}
`;
export const Title = styled.p`
    font-size: 14px;
    text-align: center;
    font-family: ${(props) => props.theme.fonts.lf_bold};
    color: ${(props) => props.theme.colors.text.gray};
`;

export const Text = styled.p`
    font-size: 12px;
    text-align: center;
    padding-top: 3px;
    font-family: ${(props) => props.theme.fonts.lf_regular};
    color: ${(props) => props.theme.colors.text.gray};
`;

export const Pdf = styled.div`
    text-decoration: none;
`;
