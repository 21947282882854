import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const ErrorLoginContainer = styled.div`
    width: 100%;
    background: ${(props) => props.theme.colors.accents.neon.orange};
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 8px 23px 13px 25px;

    ${breakpoint('md')`
        padding: 7px 53px 7px 53px;
    `}
    & > span {
        font-size: 33px;

        ${breakpoint('md')`
            margin-right: 20px;
            font-size: 32px;
        `}
    }
`;

export const ContentErrorLogin = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: ${(props) => props.theme.colors.text.white};
    p {
        width: 200px;
        text-align: center;
        color: ${(props) => props.theme.colors.text.white};
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;

        ${breakpoint('md')`
            width: 271px;
        `}

        span {
            font-family: ${(props) => props.theme.fonts.lf_bold};
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 24px;
            ${breakpoint('md')`
                line-height: 18px;
            `}
        }
    }
    a {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        color: ${(props) => props.theme.colors.text.white};
        border-bottom: 1px solid ${(props) => props.theme.colors.text.white};
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        width: 129px;
        text-decoration: none;
    }
`;
