import styled from 'styled-components';
import breakpoint from "styled-components-breakpoint";

export const WrapperCartBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
`;

export const CartBodyLotteries = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow-y: scroll;
    padding: 10px 10px 10px 10px;
    flex-grow: 1;
    &::-webkit-scrollbar {
        width: 3px;
        background: rgba(197, 195, 201, 1);
    }
    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background: rgba(62, 58, 71, 1);
    }

    ${breakpoint('lg')`
        margin: 20px 28px 0 20px;
        max-height: 85%;
        padding: 10px 25px 125px 15px;
    `}
`;

export const CartLotteryBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-between;
    width: 100%;
    background: #fff;
    border-radius: 12px;
    margin-bottom: 8px;
    min-height: 230px;
    height: auto;
    overflow: hidden;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.15);
    ${breakpoint('lg')`
        height: auto;
    `}
`;

export const ContainerDeleteCart = styled.div`
    flex-direction: row-reverse;
    display: flex;
    position: absolute;
    right: 18px;
    z-index: 1;
    padding-top: 12px;
    button{
        margin-right: 0;
    }
`;

export const CartLotteryBoxTop = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    padding: 0 20px 0;
    position: relative;

    .ellipsis {
        align-self: flex-end;
        filter: invert(98%) sepia(1%) saturate(2666%) hue-rotate(193deg) brightness(82%) contrast(89%);
        margin-left: 6px;
        width: 17px;
    }

    ${breakpoint('lg')`
         padding: 20px 20px 0;
    `}
`;

export const CartLotteryBoxInfo = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    ${breakpoint('lg')`
        flex-direction: row;
        height: auto;
    `}
`;

export const CartLotteryBoxInfoLogo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    border-bottom: 1px solid rgba(197, 195, 201, .8);
    &:after {
        content: '';
        position: absolute;
        inset: 0;
        margin: auto;
        height: 80%;
        width: 1px;
        background: rgba(197, 195, 201, .8);
    }
    img {
        width: 115px;
        height: 52px;
        object-fit: contain;
        object-position: center;
    }
    h2 {
        width: 48%;
        font-size: 16px;
        line-height: 19,2px;
        color: ${props => props.theme.colors.principal.darkGreen};
        font-family: ${props => props.theme.fonts.mon_bold};
        padding-left: 30px;
        white-space: nowrap;
    }
    ${breakpoint('lg')`
        width: 200px;
        justify-content: space-between;
        &:after {
            content: none;
        }
        img {
            height: 100px;
            width: 90%;
        }
        h2 {
            padding-left: 55px;
        }
    `}
`;

export const CartLotteryBoxInfoLotts = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    &:before {
        position: absolute;
        left: 0;
        height: 80%;
        margin: auto 0;
        width: 1px;
        background: rgba(197, 195, 201, .8);
    }
    h2 {
        width: 100%;
        font-size: 16px;
        line-height: 19,2px;
        color: ${props => props.theme.colors.principal.darkGreen};
        font-family: ${props => props.theme.fonts.mon_bold};
        margin-top: 8px;
        padding-left: 5px;
    }
    ${breakpoint('lg')`
        padding: 0 20px;
        &:before {
            content: '';
        }
    `}
`;

export const ContentSliderLotts = styled.div`
    display: flex;
    height: 60px;
    width: 100%;
    padding-top: 5px;
    .slick-slider {
        .slick-list {
            padding: 0;
            .slick-track {
                margin: 0;
                width: 100% !important;
                .slick-slide {
                    width: 50px !important;
                }
            }
        }
    } 
    ${breakpoint('lg')`
        .slick-slider {
            .slick-list {
                .slick-track {
                    .slick-slide {
                        width: 50px !important;
                    }
                }
            }
        }
    `}
`; 

export const ContentElements = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
    row-gap: 20px;
    ${breakpoint('md')`
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, 100px);
    `}
`;

export const BoxLott = styled.div`
    display: flex;
    width: 45px !important;
    height: 45px;
    overflow: hidden;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    margin: 6px 10px 0 5px;
    background: rgba(196, 196, 196, 1);
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`; 

export const CartLotteryBoxNumbers = styled.div`
    display: flex;
    justify-content: start;
    align-content: center;
    align-items: center;
    width: 100%;
    border-top: 1px solid rgba(197, 195, 201, .8);
    padding: 15px 0 15px;
    height: 60px;
    margin-top: 4px;
    padding-bottom: 0;
    ${breakpoint('lg')`
        height: 72px;
        margin-top: 0;
    `}
`;

export const BoxNumbers = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: max-content;
    border-right: 1px solid rgba(197, 195, 201, .8);
    padding: 0 12px;
    &:last-child {
        border-right: none;
    }
    p {
        font-size: 12px;
        margin-bottom: 8px;
        color: ${props => props.theme.colors.backgrounds.gray500};
        font-family: ${props => props.theme.fonts.mon_bold};
        white-space: nowrap;
    }
    span {
        color: ${props => props.theme.colors.principal.darkGreen};
        font-family: ${props => props.theme.fonts.lf_bold};
        font-size: 16px;
        letter-spacing: 3px;
    }
`;

export const CartLotteryBoxTotal = styled.div`
    background: ${props => props.theme.colors.backgrounds.gray450};
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 30px;
    height: 45px;
    p {
        font-family: ${props => props.theme.fonts.lf_bold};
        color: ${props => props.theme.colors.text.gray};
        font-size: 14px;
        line-height: 16px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        span {
            font-size: 16px;
            line-height: 16px;
            color: ${props => props.theme.colors.accents.base.yellow};
            margin-left: 17px;
        }
    }
    ${breakpoint('lg')`
        justify-content: flex-end;
        height: 44px;
        padding: 5px 40px;
        p {
            width: initial;
            font-size: 12px;
        }
    `}
`;

export const CartBodyBottom = styled.div`
    width: 100%;
    position: unset;
    display: flex;
    flex-direction: column;
    z-index: 2;
    flex-shrink: 0;
    ${breakpoint('lg')`
        position: absolute;
        bottom: 0;
    `}
`;

export const CartBodyBottomTotal = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 170px;
    background: ${props => props.theme.colors.backgrounds.gray400};
    border-radius: 16px 16px  0 0;
    padding: 0 20px;
    position: relative;
    &:after {
        position: absolute;
        inset: 0;
        margin: auto;
        height: 80px;
        width: 1px;
        background: rgba(197, 195, 201, .8);
        
    }
    ${breakpoint('lg')`
        height: 113px;
        &:after {
            content: '';
        }
    `}
`;

export const CartBodyBottomTotalLeft = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    ${breakpoint('lg')`
        width: 48%;
    `}
`;

export const RowTotal = styled.div`
    width: 200px;
    display: flex;
    justify-content: flex-end;
    &:nth-child(1) {
        p, span {
            color: #fff;
        }
    }
    &:nth-child(2) {
        p, span {
            color: ${props => props.theme.colors.disabled.gray};
        }
    }
    &:nth-child(3) {
        p, span {
            color: ${props => props.theme.colors.accents.base.yellow};
        }
    }
    p {
        font-size: 12px;
        margin-top: 10px;
        font-family: ${props => props.theme.fonts.mon_bold};
        display: flex;
        align-items: center;
        span {
            width: 103px;
            display: flex;
            justify-content: flex-end;
            font-size: 16px;
            font-family: ${props => props.theme.fonts.lf_bold};
        }
    }
`;

export const CartBodyBottomTotalRight = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    button {
        width: 204px;
        margin-bottom: 15px;
        margin-top: 15px;
    }
    a {
        text-decoration: underline;
        font-family: Montserrat-Bold;
        font-size: 14px;
        margin-bottom: 15px;
        margin-left: 20px;
        margin-right: 20px;
        text-align: center;
    }
    ${breakpoint('lg')`
        width: 48%;
        button {
            width: initial;
        }
        flex-direction: column;
    `}
`;

export const CartBodyBottomPayments = styled.div`
    width: 100%;
    height: 58px;
    background: rgba(42, 34, 57, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    h2 {
        font-size: 16px;
        line-height: 24px;
        font-family: ${props => props.theme.fonts.lf_regular};
        color: ${props => props.theme.colors.text.gray};
    }
`;

export const LogosYourPayments = styled.div`
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    max-width: 360px;
    width: 90%;
    margin: 0 auto;
    a {
        display: flex;
        align-items: center;
    }
    img {
        object-fit: contain;
    }
    ${breakpoint('md')`
        padding-left: 84px;
    `}
`;