import React, { MouseEvent, useContext, useEffect, useState } from 'react';
import { ButtonIcon, LogoGana, UserData } from '@componentsShared';
import { ImagesLogo } from '../../atoms/logo/styled';

import {
    ContainerLogo,
    ContentLogos,
    ContentMenu,
    ContentSectionTwo,
    ItemMenu,
    ItemMenuBold,
    LogosImg,
    StyledMenu,
    StyledSubMenu,
    ContentItem,
    ContainerGeneral,
    LineLogo,
    ContainerLineLogo
} from './styled';
import Viewport from 'src/shared/hooks/viewport';
import { AppContextUser } from 'src/shared/context/user';
import { carServices } from '@services/car';
import { Redirect } from '../../atoms/redirect';
import siteUrl from '@utils/siteUrl';

interface SubTitle {
    name: string;
    url: string | null;
}
interface Title {
    subtitle: SubTitle[] | null;
    name: string;
    url: string;
}
interface File {
    url: string;
    fileName: string;
}
interface LogosHeader {
    url: string;
    name: string;
    image: {
        file: File;
    };
}
interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    dataPrimary: Title[];
    dataSecundary: Title[];
    logosHeader: LogosHeader[];
}

export const Menu = ({ open, setOpen, dataPrimary, dataSecundary, logosHeader }: Props) => {
    const [openLine, setOpenLine] = useState(false);
    const [pathName, setPathName] = useState('');
    const viewport = Viewport();

    const generarItem = (item: Title, inScroll: boolean, index: number) => {
        const [openSub, setOpenSub] = useState(false);
        if (item.subtitle === null) {
            return (
                <ContentItem key={index}>
                    <ItemMenu
                        key={index + 'itemMenu'}
                        href={siteUrl + item.url}
                        className={pathName === '/' + item.url.replace('/', '') ? 'active' : ''}
                    >
                        {item.name}
                    </ItemMenu>
                </ContentItem>
            );
        } else {
            const subMenu = item.subtitle?.map((subitem: SubTitle, key) => {
                return (
                    <ContentItem key={subitem.name + key}>
                        <ItemMenu href={subitem.url !== null ? siteUrl + subitem.url : undefined}>
                            {subitem.name}
                        </ItemMenu>
                    </ContentItem>
                );
            });
            const str = item.name.split('GANA');
            let randomId = Math.random() * (1 - 99) + 1;
            return (
                <ContentItem key={index + 'contentItem' + randomId}>
                    <ItemMenu
                        className={pathName === item.url ? 'active' : ''}
                        key={index + 'item' + randomId}
                        href={siteUrl + item.url}
                    >
                        {str[0]}{' '}
                        {item.name.endsWith('GANA') ? (
                            <ItemMenuBold open={false}>GANA</ItemMenuBold>
                        ) : null}
                        <ButtonIcon
                            header={true}
                            icon={
                                (inScroll ? openSub && !openLine : openLine)
                                    ? 'angle-small-up'
                                    : 'angle-small-down'
                            }
                            colorDefault={'#0DF96C'}
                            onClick={() => {
                                if (!inScroll) {
                                    setOpenSub(!openSub);
                                    setOpenLine(!openLine);
                                }
                                if (inScroll) {
                                    if (openLine) {
                                        setOpenSub(true);
                                        setOpenLine(!openLine);
                                    } else {
                                        setOpenSub(!openSub);
                                    }
                                }
                            }}
                            title={!openSub ? 'Plegar' : 'Deplegar'}
                            size="small"
                        />
                    </ItemMenu>
                    <StyledSubMenu
                        open={inScroll ? openSub && !openLine : openLine}
                        key={'subMenu' + randomId}
                    >
                        {subMenu}
                    </StyledSubMenu>
                </ContentItem>
            );
        }
    };

    useEffect(() => {
        setPathName(window.location.pathname);
    }, []);

    const closeModalClickOut = (e: MouseEvent) => {
        if (e.clientX > 260) {
            setOpen(false);
        }
    };
    return (
        <ContainerGeneral
            open={open || viewport.desktop || viewport.tablet}
            onClick={(e) => closeModalClickOut(e)}
        >
            <StyledMenu open={open}>
                <ContainerLogo>
                    <LogoGana type="Primary" />
                </ContainerLogo>
                {viewport.mobile && <UserData />}
                <ContentMenu open={openLine || viewport.desktop || viewport.tablet} scroll={false}>
                    {dataPrimary.map((item, index) => {
                        return generarItem(item, true, index);
                    })}
                </ContentMenu>

                <ContentSectionTwo>
                    {/*          {dataSecundary.map((item, index) => {
                        return generarItem(item, false, index);
                    })} */}
                </ContentSectionTwo>

                <ContentLogos>
                    {logosHeader.map((item, index) => {
                        if (index === logosHeader.length - 1) {
                            return (
                                <LogosImg
                                    logoOki={item.name === 'Logo Oki'}
                                    src={item.image.file.url}
                                    alt={item.image.file.fileName}
                                    onClick={() => {
                                        window.open(item.url, '_blank');
                                    }}
                                    key={'LogosImg1' + index}
                                />
                            );
                        } else {
                            return (
                                <ContainerLineLogo key={'LogosImg2' + index}>
                                    <LogosImg
                                        logoOki={item.name === 'Logo Oki'}
                                        src={item.image.file.url}
                                        alt={item.image.file.fileName}
                                        onClick={() => {
                                            window.open(item.url, '_blank');
                                        }}
                                    />
                                    <LineLogo />
                                </ContainerLineLogo>
                            );
                        }
                    })}
                </ContentLogos>
            </StyledMenu>
        </ContainerGeneral>
    );
};
