import styled from 'styled-components';
import { hexToRGB } from '@utils/hexToRgba';
import { breakpoint } from 'src/shared/styles/variables';

type IProps = {
    open?: boolean;
    navbarOpen?: boolean;
    state?: boolean;
};

export const ModalContainer = styled.div<IProps>`
    position: fixed;
    visibility: hidden;
    opacity: 1;
    height: 100vh;
    width: 100%;
    z-index: 1001;
    background: ${(props) =>
        props.open ? `${hexToRGB(props.theme.colors.backgrounds.gray450, 0.7)}` : `${'none;'} `};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;

    ${(props) =>
        props.open &&
        `
            visibility:visible;
        
        `}
`;

/* Mobile */

export const FlexContainer = styled.div<IProps>`
    border-radius: 10px 0 0 0;
    &.menuNav {
        display: flex;
        flex-direction: column;
        background: #f8f8f8;
        height: 100vh;
        width: 97%;
        left: 0;
        position: absolute;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        transition: all 0.3s ease-in-out;
        overflow-x: hidden;
        top: 0;
    }

    &.showMenu {
        -webkit-transform: ${(props) => props.open && 'translateX(4%)'};
        transition: all 0.3s ease-in-out;
        transform: ${(props) => props.open && 'translateX(4%)'};
    }
`;

/* Desktop */

export const FlexDesktopContainer = styled.div<IProps>`
    border-radius: 10px 0 0 0;

    &.menuNav {
        display: flex;
        flex-direction: column;
        background: #f8f8f8;
        height: 100vh;
        width: 50%;
        left: 0;
        position: relative;
        -webkit-transform: translateX(200%);
        transform: translateX(200%);
        transition: all 0.5s ease-in-out;
        overflow-x: hidden;
    }

    &.showMenu {
        -webkit-transform: ${(props) => props.open && 'translateX(50%)'};
        transition: all 0.3s ease-in-out;
        transform: ${(props) => props.open && 'translateX(50%)'};
    }
`;

/* Components */

export const MainContainer = styled.div<IProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 84px);
    position: relative;
    .loading {
        & > div {
           border: 4px solid ${props  => props.theme.colors.principal.darkGreen};
           border-left-color: ${props  => props.theme.colors.disabled.gray};
           width: 50px;
           height: 50px;
        }
    }
    ${breakpoint('md')`
        height: calc(100% - 100px);
    `}
`;
