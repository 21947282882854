import React from 'react';
import { CartHeaderContainer, CartModalTitle, TextContainer } from './styled';
import { TCartHeader } from './types';

const days = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];

const months = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre'
];

export const CartHeader = ({ handleClose, bets }: TCartHeader) => {
    const date = new Date();
    const dateToday = `${days[date.getDay()]}, ${date.getDate()} de ${months[date.getMonth()]}`;

    return (
        <>
            <CartHeaderContainer>
                <span className="icon-shopping-cart"></span>
                <CartModalTitle>
                    <TextContainer>
                        <p>Carrito de compras</p>
                        <p>
                            <b>{bets ? bets : 0} apuestas</b> en tu carrito
                        </p>
                        <span>{dateToday}</span>
                    </TextContainer>
                    <span onClick={handleClose} className="icon-cross"></span>
                </CartModalTitle>
            </CartHeaderContainer>
        </>
    );
};
