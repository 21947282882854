import { BILLONARIO_SIGLA_3C } from "@constants/billonarioConstants";
import { lotteriesServices } from "@services/lotteries";
import siteUrl from "@utils/siteUrl";
import { setDataStorage } from "@utils/transferData";
import moment from "moment";
import { IBets, ILot } from "src/shared/components/molecules/cartBodyDetail/types";

export type Lottery = {
    abreviatura: string;
    desLoteria: string;
    horaCierre: string;
    ideLoteria: number;
    numCifras: number;
    sorteo: string;
};

export type TNumbers = {
    number: string;
    numberOne: string;
    numberTwo: string;
    numberThree: string;
    numberFour: string;
    state?: string;
    isAnimate?: boolean;
};

export type Butt = {
    idButt: number;
    name: string;
    numbers: TNumbers[];
    numbersFull: string[]
    isCollapsed: boolean;
    isEditLottery: boolean;
    isRef: boolean;
    sigla: string;
    lotteries: Lottery[];
    idBetCart: string;
}

export type DataGame = {
    betValue: number;
    totalValue: number;
    butts: Butt[];
};

export const editBillonario = async (bet: IBets) => {
    const numbersConfirmation: string[] = []
    const numbers = bet.betNumbers.map((lines, index: number) => {
        const number: TNumbers = {
            number: lines.numbers.join(''),
            numberFour: '',
            numberOne: '',
            numberThree: '',
            numberTwo: ''
        };
        if (bet.sigla === BILLONARIO_SIGLA_3C) {
            number.numberOne = getNumber(lines.numbers[0]);
            number.numberTwo = getNumber(lines.numbers[1]);
            number.numberThree = getNumber(lines.numbers[2]);
        } else {
            number.numberOne = getNumber(lines.numbers[0]);
            number.numberTwo = getNumber(lines.numbers[1]);
            number.numberThree = getNumber(lines.numbers[2]);
            number.numberFour = getNumber(lines.numbers[3]);
        }
        numbersConfirmation.push(lines.numbers.join(''));
        return number;
    });

    const actual: string = moment().format('DD/MM/YYYY');

    const lotteries: Lottery[] = await lotteriesServices
        .getLotteries(actual, bet.sigla)
        .then((data) => {
            let list: Lottery[] = [] as Lottery[];
            bet.loteries.map((lot: ILot, index: number) => {
                const dataLoterries: Lottery[] = data.data.getGameInfo.loterias;
                const lottery: Lottery = (dataLoterries.find((lottery: Lottery) => {
                    return lottery.ideLoteria == lot.loterieId;
                })) || {} as Lottery;
                list.push(lottery);
            });
            return list;
        })
        .catch((err) => {
            console.log(err);
        }) || [] as Lottery[];

    const butts: Butt[] = [
        {
            idButt: bet.numero_colilla,
            isCollapsed: false,
            isEditLottery: true,
            isRef: false,
            lotteries: lotteries,
            name: bet.nombre_colilla,
            numbers: numbers,
            numbersFull: numbersConfirmation,
            sigla: bet.sigla,
            idBetCart: bet.id
        }
    ];

    const dataGame: DataGame = {
        butts: butts,
    } as DataGame;

    setDataStorage(dataGame, 'buttsBillonario');
    window.location.href = siteUrl + '/juegos/billonario/';
}

const getNumber = (num: number) => {
    if (!num) {
        return '0';
    }
    return num.toString();
};