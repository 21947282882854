import React, { useEffect, useState } from 'react'
import Viewport from 'src/shared/hooks/viewport';
import { setCookie, getCookie } from '@utils/handlerCookies'
import {Button} from '@componentsShared'

import { BoxLabelCookies, BoxText, /* Button, */ ImgCookie } from './styled';
import cookie from './Cookies.svg';

export const LabelCookies = () => {

    const viewport = Viewport();

    const [show, setShow] = useState(true)

    useEffect(() => {
        if( getCookie('acceptedCookies') ) {
            setShow(false);
        }
    }, [])

    const closeLabel = ():any => {
        setCookie('acceptedCookies', 'True', 365);
        setShow(false);
    }

    return (
        <>
            {
                viewport.mobile
                &&
                (
                    <BoxLabelCookies show={show}>
                        <BoxText>
                            <p>Este sitio usa cookies para optimizar tu navegación.<br/><a href='https://www.gana.com.co/' target='_blank'>Leer más</a></p>
                        </BoxText>
                        <Button
                            onClick={ closeLabel }
                            text='Aceptar'
                            typeButton='white'
                        />
                    </BoxLabelCookies>
                )
            }

            {
                !viewport.mobile
                &&
                (
                    <BoxLabelCookies show={show}>
                        <ImgCookie src={cookie} alt='Imagen de galletas' />
                        <BoxText>
                            <p>Este sitio usa cookies para optimizar tu navegación. <br/><a href='https://www.gana.com.co/' target='_blank'>Leer más</a></p>
                        </BoxText>
                        <Button
                            onClick={ closeLabel }
                            text='Aceptar'
                            typeButton='white'
                        />
                    </BoxLabelCookies>
                )
            }
        </>
    )
}
