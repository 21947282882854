import { gql } from '@apollo/client';
import client from 'src/shared/config/apollo-client-gana';

export const queriesDocumentsData = {
    getDocumentos: gql`
        query {
            getDocumentos {
                nombre
                abreviatura
                regex
                __typename
            }
        }
    `
};

export const getDocumentsServices = {
    getDocumentos: () => {
        return client.query({
            query: queriesDocumentsData.getDocumentos
        });
    }
};
