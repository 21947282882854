import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';
import registerBackgroundStepTwo from 'src/shared/assets/images/registerBackgroundStepTwo.png';

export const RegisterBox = styled.div`
    ${breakpoint('md')`
        padding: 0px 15px;

    `}
`;

export const ContainerRegisterTop = styled.div`
    ${breakpoint('md')`
        position: absolute;
        width: 100%;
        z-index: 1;
        padding: 6px 54px 0 54px;

        div{
            margin: 28px auto;
        }

    `}
`;

export const RegisterTitle = styled.h1`
    padding-top: 15px;
    text-align: center;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-size: 20px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.accents.base.yellow};

    ${breakpoint('md')`
       padding: 15px;
       border-bottom: 0.5px solid #3E3A47;
    `}
`;

export const RegisterForm = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 1024px;
    background: ${(props) => props.theme.colors.backgrounds.gray450};
    border-radius: 16px;
    position: relative;
    margin: 0px auto 20px;
    border: 0.5px solid #c5c3c9;

    ${breakpoint('md')`
        background-image: url(${registerBackgroundStepTwo});
        background-repeat: no-repeat; 
        background-size: cover;
        border: none;
    `}
`;

export const RegisterBody = styled.div`
    width: 100%;
    padding: 0 14px 0px 14px;

    ${breakpoint('md')`
        padding: 0 53px;
        border-radius: 16px;
        border: 0.5px solid #3e3a47;
    `}
`;

export const ContainerInputs = styled.div`
    width: 100%;
    margin-top: 16px;
    padding-bottom: 0px !important;
    position: relative;

    & > .errorCountry {
        left: 5px;
        bottom: 18px;
    }

    ${breakpoint('md')`
        & > .errorCountry {
            left: 3px;
        }
    `}
`;

export const TitleContainer = styled.div`
    padding: 13px 0;
    border-bottom: 0.5px solid #3e3a47;

    ${breakpoint('md')`
        padding: 80px 0; 

        div{
            position: relative;
            top: 8rem;
        }
    `}
`;

export const AuxText = styled.p`
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-size: 16px;
    font-weight: 19.2px;
    color: ${(props) => props.theme.colors.accents.base.yellow};
    position: relative;
    top: 8rem;
    padding: 0 5px;
`;

export const MainText = styled.p`
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 14px;
    line-height: 18px;
    color: #c5c3c9;
    margin-top: 14px;
    padding-left: 6px;

    ${breakpoint('md')`
        margin-top: 53px;
    `}
`;

export const DocumentContainerStepTwo = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-bottom: 0px;
    position: relative;

    & > .errorCity {
        bottom: 18px;
        right: 113px;
    }

    ${breakpoint('md')`
        flex-direction: row;
        padding: 0px;

        & > .errorCity {
            right: 245px;
            bottom: 20px;
        }
    `}
    ${breakpoint('lg')`
        flex-direction: row;
        justify-content: space-between;
        padding: 0px;
    `}
`;

export const SelectContainer = styled.div`
    width: 100%;
    min-width: 308px;
    div {
        padding-left: 0;
    }

    ${breakpoint('md')`
        padding-top: 12px;
        width: 40%;
        margin-right: 50px;
    `}

    ${breakpoint('lg')`
        padding-top: 12px;
        margin-right: 0px;
        width: 100%;
        max-width: 418px;
    `}
`;

export const TextLabel = styled.label`
    font-family: ${(props) => props.theme.fonts.lf_bold};
    font-size: 14px;
    font-weight: 24px;
    line-height: 17px;

    ${breakpoint('md')`
        position: absolute;
        line-height: unset;
    `}
`;

export const FormRegister = styled.form`
    padding: 25px 0;

    ${breakpoint('md')`
        padding: 12px 0;
    `}
`;

export const FormButtons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 34px;
    padding-top: 32px;

    ${breakpoint('sm')`
        padding-bottom: 32px;
        padding-top: 10px;

    `}

    button {
        width: 175px;
        font-family: ${(props) => props.theme.fonts.mon_bold};
        height: 48px;
        font-size: 16px;
        margin-bottom: 41px;
        border-radius: 16px;
        padding: 13px 24px;

        ${breakpoint('sm')`
            margin-bottom: 0px;
            font-size: 14px;
            width: 138px;
            height: 32px;
            border-radius: 12px;
            padding: 8px 32px;
        `}
    }

    .redirect {
        text-decoration: none;
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-size: 14px;
        line-height: 21.6px;
    }

    ${breakpoint('sm')`
        flex-direction: row-reverse;
        justify-content: center;
        align-items: baseline;

        button{
            margin-left: 56px;
        }
    `}
`;

export const ContainerLoading = styled.div`
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;

    .loading {
        inset: auto;
        width: auto;
    }
`;

export const ContentError = styled.div`
    position: absolute;
    bottom: 20px;
`;