import { hexToRGB } from '@utils/hexToRgba';
import theme from 'src/shared/styles/theme';
import { breakpoint, fonts } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    visibility: visible;
    opacity: 1;
    height: 100vh;
    width: 100vw;
    z-index: 1001;
    background: ${(props) => hexToRGB(props.theme.colors.backgrounds.gray450, 0.7)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const ModalBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${(props) => props.theme.colors.text.white};
    width: 290px;
    height: auto;
    left: 479px;
    top: 162px;
    border-radius: 16px;
    padding: 20px;

    & > .logo {
        width: 170px;
    }

    button:first-child {
        height: min-content;
        margin-right: 0;
        align-self: flex-end;
    }

    button:first-child span {
        color: black;
        font-size: 25px;
    }

    button:last-child {
        margin-top: 25px;
        margin-bottom: 10px;
    }

    ${breakpoint('md')`
        width: 400px;

        button:last-child {
            margin-top: 35px;
        }

        & > .logo {
            width: 190px;
        }
    `}
`;

export const MainText = styled.span`
    color: black;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    margin-top: 20px;
    text-align: center;
    line-height: 18px;

    ${breakpoint('md')`
        font-size: 20px;
        line-height: 24px;
        margin-top: 30px;
    `}
`;

export const SecondaryText = styled.span`
    color: black;
    font-family: ${(props) => props.theme.fonts.mon_medium};
    margin-top: 20px;
    text-align: center;
    line-height: 18px;
    font-size: 14px;

    ${breakpoint('md')`
        font-size: 16px;
        line-height: 20px;
    `}
`;