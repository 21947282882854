import { ErrorSpan } from '@componentsShared';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { CheckboxCustom, Container, LabelCustom } from './styled';

interface IProps {
    name: string;
    label: string;
    registerCustom?: any;
    required?: string;
    disabled?: boolean;
}

export const Checkbox = (props: IProps) => {
    const { name, required, disabled, label } = props;

    const { register, formState } = useFormContext();

    return (
        <Container>
            <CheckboxCustom
                disabled={disabled}
                className={formState.errors[name]?.message && 'error'}
                type="checkbox"
                id={'#' + name}
                {...register(name, { required: false })}
            />
            <LabelCustom htmlFor={'#' + name}>{label}</LabelCustom>
            {formState.errors[name]?.message && (
                <ErrorSpan text={formState.errors[name]?.message}></ErrorSpan>
            )}
        </Container>
    );
};
