import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    ${breakpoint('md')`
        padding-right: 0;
        padding-left: 0;
        margin-right: 24px;
        margin-left: 24px;
    `}

    ${breakpoint('lg')`
        margin-right: 14px;
        margin-left: 14px;
    `}
`;

export const TextareaCustomLabel = styled.label`
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
    color: ${(props) => props.theme.colors.text.gray};
    display: flex;
    align-items: center;

    /* &::after {
        font-family: 'icomoon' !important;
        font-size: 20px;
        margin-left: 8px;
        content: '\\e9ad';
    } */
`;

export const TextAreaCustom = styled.textarea`
    padding: 16px;
    padding-right: 30px;
    width: 100%;
    min-height: 117px;
    background: ${(props) => props.theme.colors.backgrounds.gray400};
    font-size: 16px;
    line-height: 24px;
    font-family: ${(props) => props.theme.fonts.lf_regular};
    border-radius: 16px;
    outline: none;
    border: none;
    resize: none;
    /* overflow: scroll;   */

    &:focus {
        border: 1px solid ${(props) => props.theme.colors.principal.greenNeon};
    }

    &.error {
        border: 1px solid ${(props) => props.theme.colors.accents.dark.red};
    }

    /* &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background:${(props) => props.theme.colors.text.gray}; 
        border-radius: 32px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.colors.principal.greenNeon}; 
        border-radius: 60px; 
    } */
`;
