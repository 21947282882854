import React, { useEffect, useState, useRef } from 'react';
import { IncentiveBetContainer, IncentiveBetImagen, IncentiveBetMsg } from './styles';
import { cfImagenesService } from '@servicesCF/imagenes';
import { IProps } from './types';

declare global {
    var times: { [key: string]: any };
}

const regPorcentaje = /[0-9]{1,}[0-9,. ]+(%){1}/g;

export const IncentiveBanner = (props: IProps) => {
    const divRef = useRef<any>(null);
    const { sigla, message, small } = props;
    let isMounted = true;

    const [parseMensaje, setParseMensaje] = useState<string>('');

    const imagenesIncentivos = cfImagenesService.getImagenesIncentivos()[0]?.images || [];
    const icono = imagenesIncentivos.find(imagen => (imagen.title?.includes('_icono')));
    const imgDefault = imagenesIncentivos.find(imagen => (imagen.title?.includes('_default')));
    const imgJuego = imagenesIncentivos.find(imagen => (imagen.title?.includes('_' + (sigla||'').trim().toUpperCase())));
    const imagenIncentivo = imgJuego?.file?.url ? imgJuego : imgDefault;

    useEffect(() => {
        let mensajeFinal = message || '';
        const porcentajes = mensajeFinal?.match(regPorcentaje) || [];
        porcentajes.forEach((porcentaje) => {
            const regex = new RegExp(`(<span>)*(${porcentaje}){1}(<\\span>)*`, 'g');
            mensajeFinal = mensajeFinal.replace(regex, `<span>${porcentaje}</span>`);
        });
        setParseMensaje(mensajeFinal);
    }, [message])


    useEffect(() => {
        if (divRef.current) {
            const observer = new MutationObserver(() => {
                if (isMounted) {
                    const divWidth = divRef.current.offsetWidth;
                    console.log(divWidth);
                    //divRef.current.style.fontSize = `${divWidth}px`;
                }
            });
            observer.observe(divRef.current, { attributes: true });
            return () => {
                isMounted = false;
                observer.disconnect();
            }
        }
    }, [divRef.current]);


    return (
        <>
            {
                parseMensaje &&
                <IncentiveBetContainer
                    ref={divRef}
                    className={`incentive--banner ${small ? 'small' : ''}`}
                    small={small}>
                    <img className='incentive--icono' src={icono?.file?.url} alt={icono?.description} />
                    {
                        imagenIncentivo?.file?.url &&
                        <IncentiveBetImagen
                            small={small}
                            className='incentive--container-imagen'
                            bgImage={imagenIncentivo?.file?.url}>
                            <div className='incentive--imagen'>
                                <img
                                    src={imagenIncentivo?.file?.url}
                                    alt={imagenIncentivo?.description}
                                />
                            </div>
                        </IncentiveBetImagen>
                    }
                    <IncentiveBetMsg
                        small={small}
                        className='incentive--mensaje'
                        dangerouslySetInnerHTML={{ __html: parseMensaje }} />
                </IncentiveBetContainer>
            }
        </>
    );
};
