import styled from 'styled-components';
import { hexToRGB } from '@utils/hexToRgba';
import { breakpoint } from 'src/shared/styles/variables';
import { IPropsTransaction } from './types';

export const Container = styled.div`
    margin-top: 73px;
`;
export const TransactionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 17px 56px 17px;
    gap: 12px;
    ${breakpoint('lg')`
        flex-direction: row; 
        margin-top:65px;
        align-items: flex-start;
        gap: 15px;
    `}
`;
export const MessageTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    button {
        padding: 8px 32px;
        border-radius: 12px;
        margin-top: 28px;
        background: ${(props) => hexToRGB(props.theme.colors.principal.greenBase)};
    }
`;

export const Circle = styled.div<IPropsTransaction>`
    background: ${(props) => props.theme.colors.backgrounds.gray500};
    width: 55px;
    height: 55px;
    border-radius: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 18px
        ${(props) =>
            props.state
                ? hexToRGB(props.theme.colors.principal.greenNeon, 0.5)
                : hexToRGB(props.theme.colors.alerts.red, 0.5)};
`;
export const CircleFill = styled.div<IPropsTransaction>`
    width: 43px;
    height: 43px;
    border: 2px solid
        ${(props) =>
            props.state
                ? hexToRGB(props.theme.colors.principal.greenNeon)
                : hexToRGB(props.theme.colors.alerts.red)};
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    align-items: center;
    border-radius: 100%;
    box-shadow: 0px 0px 5px
        ${(props) =>
            props.state
                ? hexToRGB(props.theme.colors.principal.greenNeon)
                : hexToRGB(props.theme.colors.alerts.red)};
    img {
        margin: 0 auto;
    }
`;

export const NameState = styled.p<IPropsTransaction>`
    max-width: ${(props) => (props.state ? '' : '238px')};
    margin-top: 20px;
    font-size: 16px;
    line-height: 19px;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    text-transform: lowercase;
    ::first-letter {
        text-transform: uppercase;
    }
    span {
        color: ${(props) =>
            props.state ? props.theme.colors.principal.greenBase : props.theme.colors.alerts.red};
    }
    ${breakpoint('lg')<IPropsTransaction>`
      max-width: ${(props) => (props.state ? '' : '503px')};
      margin-bottom: ${(props) => (props.state ? '' : '43px')};
      margin-top: ${(props) => (props.state ? '' : '40px')};
      font-size: ${(props) => (props.state ? '' : '20px')};
   `}
`;

export const TextEmail = styled.div`
    font-size: 14px;
    line-height: 18px;
    font-family: ${(props) => props.theme.fonts.lf_light};
    margin-top: 9px;
    max-width: 217px;
    text-align: center;
    p {
        margin-bottom: 15px;
    }
    ${breakpoint('lg')`
        max-width: 409px;
    `}
`;
export const TransactionDetail = styled.div<IPropsTransaction>`
    background: ${(props) => props.theme.colors.backgrounds.gray450};
    min-width: 326px;
    border: 0.5px solid
        ${(props) =>
            props.state ? props.theme.colors.principal.greenBase : props.theme.colors.alerts.red};
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 0px 17px;
    width: 100%;
    ${breakpoint('lg')`
        min-height:314px;
        min-width:504px;
        max-width:504px;
        max-height: 314px;
     `}
`;
export const OrderDetail = styled.div`
        width: 100%;
        min-height: 314px;
        background: ${(props) => props.theme.colors.backgrounds.gray450};
        border: 0.5px solid ${(props) => props.theme.colors.disabled.gray};
        box-sizing: border-box;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 17px 13px 17px;
        div:nth-child(2) {
            margin-top: 16px;
        }
        ${breakpoint('lg')`
            min-height:314px;
            min-width:504px;
            max-width:504px;
    `}
`;
export const BoxSpinner = styled.div<IPropsTransaction>`
    margin: auto !important;
`;

export const TitleDetails = styled.div<IPropsTransaction>`
    background: ${(props) => props.theme.colors.backgrounds.gray400};
    border-radius: 12px;
    margin-top: 17px;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 16px;
    text-align: center;
    color: ${(props) =>
        props.state ? props.theme.colors.accents.base.yellow : props.theme.colors.disabled.gray};
    padding: 12px 0px;
    width: 100%;
    min-width: 295px;
    max-height: 40px;
`;

export const ContentDateState = styled.div<IPropsTransaction>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 11px;
    margin-top: 15px;
    width: 100%;
    border-bottom: 0.5px solid ${(props) => props.theme.colors.disabled.gray};
    padding-bottom: 15px;
    span {
        font-size: 14px;
        line-height: 24px;
        font-family: ${(props) => props.theme.fonts.lf_bold};
        padding: 4px 39.6px;
        border-radius: 16px;
        background: ${(props) =>
            props.state ? props.theme.colors.principal.greenBase : props.theme.colors.alerts.red};
    }
    p {
        font-size: 12px;
        line-height: 14px;
        color: ${(props) => props.theme.colors.text.gray};
        font-family: ${(props) => props.theme.fonts.lf_light};
        ::first-letter {
            text-transform: uppercase;
        }
    }
    ${breakpoint('lg')<IPropsTransaction>`
        flex-direction: row;
        justify-content: space-between;
        span {
            font-size: 12px;
            line-height: 20px;
            font-family: ${(props) => props.theme.fonts.lf_regular};
            color: ${(props) =>
                props.state ? props.theme.colors.backgrounds.gray500 : props.theme.colors.text.white};
            padding: 6px 10px;
            border-radius: 16px;
        }
    `}
`;
export const TransactionInformation = styled.div`
    margin-top: 13px;
    width: 100%;
    div:nth-child(odd) {
        background: ${(props) => props.theme.colors.backgrounds.gray500};
        width: 100%;
        height: 75px;
    }

    ${breakpoint('lg')`
        div:nth-child(odd) {
            height:32px;
        }
        div:nth-last-child(2){
            margin-bottom:19px;
        }  
    `}
`;
export const Text = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    line-height: 24px;
    border-radius: 12px;
    margin-bottom: 20px;
    font-size: 14px;

    font-family: ${(fonts) => fonts.theme.fonts.lf_semiBold};
    h3 {
        font-family: ${(fonts) => fonts.theme.fonts.lf_light};
    }

    ${breakpoint('lg')`
        flex-direction: row;
        margin-bottom: 9px;
        label{
            text-align: right;
            width: 50%;
        }
        h3{
            width: 50%;
            text-align:left; 
            margin-left: 77px;
        }
        
    `}
`;

export const ContainerGame = styled.div<IPropsTransaction>`
    margin-top: 19px;
    background: ${(props) => props.theme.colors.backgrounds.gray400};
    width: 100%;
    min-width: 300px;
    min-height: 204px;
    border-radius: 12px;
    padding: 0px 14px;
    button {
        margin-top: 15px;
        padding: 13px 32.5px;
        margin: 0 auto;
    }
    button::after {
        margin-left: 8px;
        font-size: 18px;
        content: '\\e971';
        font-family: 'icomoon' !important;
    }

    ${breakpoint('lg')`
    min-width: 471px;
    min-height: 191px;
    position:relative;
    margin-top: 0px;
    margin-bottom: 32px;
    button{
        padding: 8px 32px;
        position:absolute;
        bottom: -15px;
        left: 50%;
        transform: translate(-50%);
    }
    `}
`;
export const InfoGame = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 12px;
    position: relative;
    img {
        width: 100%;
        max-height: 40px;
        /* min-width: 267px; */
    }
    label {
        font-family: ${(fonts) => fonts.theme.fonts.lf_bold};
        margin-top: 11px;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
    }

    .icon-info {
        font-size: 20px;
        color: ${(props) => props.theme.colors.principal.greenBase};
        margin-left: 60px;
        position: absolute;
        right: 0;
        margin-right: 10px;
    }
    .__react_component_tooltip {
        max-width: 240px;
        min-width: 240px;
        font-size: 14px;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        line-height: 24px;
        ::after {
            left: 217px !important;
            margin-left: -10px !important;
        }
    }
    ${breakpoint('lg')`
        margin-top: 17px;
        .icon-info{
            font-size:18px;
        }
        label{
            margin-top: 15px;
            font-size: 14px;
            margin-bottom: 6px;
        }
    
        `}
`;

export const TransactionNumber = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 0.5px solid ${(props) => props.theme.colors.disabled.gray};
    padding-bottom: 12px;
    margin-bottom: 15px;
    span {
        background: ${(props) => props.theme.colors.backgrounds.gray500};
        font-size: 13px;
        line-height: 22px;
        min-height: 27px;
        min-width: 200px;
        font-family: ${(fonts) => fonts.theme.fonts.lf_light};
        border-radius: 12px;
        padding: 2.5px 34.93px;
        margin: 0 auto;
    }
    ${breakpoint('lg')`
        border-bottom: none;
        span{
            padding: 9px 70.43px;
            line-height: 14px; 
            min-width: 271px;
            min-height: 32px;
        }
    `}
`;
export const ContentTitle = styled.div`
    width: 100%;
    min-width: 295px;
    border-bottom: 0.5px solid ${(props) => props.theme.colors.disabled.gray};
    padding-bottom: 16px;
    ${breakpoint('lg')`
    padding-bottom:0px;
    border-bottom: none;
    `}
`;
export const ContainerImage = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    min-width: 267px;
    min-height: 44px;
    background: ${(props) => props.theme.colors.text.white};
    border-radius: 12px;
    ${breakpoint('lg')`
        min-height: 56px;

    `}
`;
export const TotalValue = styled.section<IPropsTransaction>`
    display: flex;
    flex-direction: column;
    h2 {
        font-family: ${(fonts) => fonts.theme.fonts.lf_bold};
        color: ${(props) =>
            props.state
                ? props.theme.colors.principal.greenBase
                : props.theme.colors.disabled.gray};
        font-size: 16px;
        margin-top: 3px;
    }
    ${breakpoint('lg')`
        flex-direction: row;
        width:100%;
            justify-content:end; 
            align-items: end;
            gap: 10px;
            h2{
                font-size: 20px;
                margin-right:29px;
                margin-top: 0px;
            }
            border-top: 0.5px solid ${(props) => props.theme.colors.disabled.gray};
            padding-top:10px;
    `}
`;
export const NameBuyer = styled.h3`
    text-transform: lowercase;
    ::first-letter {
        text-transform: uppercase;
    }
`;

export const TitleNotDetail = styled.span`
    font-family: ${(props) => props.theme.fonts.lf_medium};
    margin-top: 25%;
`;
