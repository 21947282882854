import React, { useContext, useEffect, useState } from 'react';
import { BoxTooltipIniSesion, ButtonClose, BoxText, ImgUser } from './styled';
import user from './user-add.svg'
import cerrar from './cerrar.svg'
import { AppContextUser } from '../../../context/user';

export const TooltipIniSesion = () => {
    const { dataUser: { isLogged } } = useContext(AppContextUser)

    const [show, setShow] = useState(true);

    useEffect(() => {
        if ( sessionStorage.getItem('showTooltipIniSesion') ) {
            setShow(false)
        }
    }, [])

    const closeTooltip = ():any => {
        sessionStorage.setItem('showTooltipIniSesion', 'false');
        setShow(false);
    }

    if (isLogged) {
        return null
    }

    return (
        <BoxTooltipIniSesion show={show}>
            <ImgUser src={ user } alt="Icono de login" />
            <BoxText>
                <p>Mejora tu experiencia de juego</p>
                <p>Ingresa con tus datos para que participes por bonos y ¡muchas ofertas!.</p>
            </BoxText>
            <ButtonClose onClick={ closeTooltip }><img src={ cerrar } alt="Icono de cerrar sesion" /></ButtonClose>
        </BoxTooltipIniSesion>
    )
}
