import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

interface TState {
    state: boolean;
    duracion?: number
}

export const ContainerToast = styled.div<TState>`
    position: fixed;
    top: 90px;
    left: 0;
    opacity: 1;
    width: 100vw;
    z-index: 998;
    display: flex;
    justify-content: flex-end;

    ${(props) => !props.state && `top:115px;`};
    animation: fadeInOpacity 5s linear;

    ${(props) => props.duracion && `animation: fadeInOpacity ${props.duracion}s linear;`};

    @keyframes fadeInOpacity {
   
        0%{ opacity: 0 }
        25% { opacity: 1 }
        80% { opacity: 1 }
        85% { opacity: 0.8 }
        100% { opacity: 0 }
    }

    &.pqrs {
        top: 0;
    }

    ${breakpoint('md')`
        justify-content: center;

        &.pqrs {
            top: 90px;
        }
    `}
`;

export const TextToast = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: row;
    padding: 17px;
    border-bottom-left-radius: 16px;
    justify-content: center;
    align-items: center;
    max-width: 350px;
    span {
        margin-right: 14px;
        font-size: 20px;
    }
    p {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
    }

    &.pqrs {
        width: 100%;
        border-bottom-right-radius: 16px;
        max-width: initial;
    }

    ${breakpoint('md')`
        max-width: 500px;
        margin-top: 20px;
        width: 50%;
        border-radius: 30px;
        padding: 15px 35px;
        text-align: center;

        &.pqrs {
            width: 50%;
            border-bottom-right-radius: 30px;
            max-width: 500px;
        }
    `}

    &.info {
        background: ${(props) => props.theme.colors.accents.neon.blue};
    }
    &.warning {
        background: ${(props) => props.theme.colors.accents.neon.yellow};
    }
    &.danger {
        background: ${(props) => props.theme.colors.accents.neon.orange};
    }
`;
