import { gql } from '@apollo/client';
import client from '../../config/apollo-client-gana';

export type TLotterySend = {
    ideLoteria: string;
};

export type IRegisterPQRS = {
    tipo_identificacion: string;
    numero_identificacion: string;
    nombre: string;
    apellido: string;
    correo: string;
    telefono: string;
    mensaje: string;
    pqrs_type_id: string;
    latitud: number;
    longitud: number;
}

export const queriesGeneralData = {
    getPaises: gql`
        query {
            getPaises {
                name
                id
                departments {
                    name
                    id
                    cities {
                        name
                        code
                        __typename
                    }
                    __typename
                }
                __typename
            }
        }
    `,
    generateNumber: gql`
        query generarNumeroAleatorio($objTope: objNumAleatorio!) {
            generarNumeroAleatorio(objTope: $objTope) {
                numero
            }
        }
    `,
    getPaisesNationality: gql`
        query {
            getPaisesNationality {
                name
                id
            }
        }
    `,
    getRandomNumber: gql`
        query ($input: RQGetRandomArrayNumbers!) {
            getRandomArrayNumbers(input: $input) {
                message
                status
                data
            }
        }
    `,
    getHash: gql`
        query {
            getHashNew {
                checksum,
                contrato,
                version
            }
        }
    `,
    registerPQRS: gql`
        mutation storePQRS($input: PQRSInput!) { 
            storePQRS(input:$input)
        }
    `
};

export const generalDataServices = {
    getPaises: () => {
        return client.query({
            query: queriesGeneralData.getPaises
        });
    },
    generateNumber: (date: string, lotteries: TLotterySend[], cifra: number) => {
        return client.query({
            query: queriesGeneralData.generateNumber,
            variables: {
                objTope: {
                    date,
                    lotteries: lotteries,
                    cifra
                }
            }
        });
    },
    getPaisesNationality: () => {
        return client.query({
            query: queriesGeneralData.getPaisesNationality
        });
    },
    getRandomNumber: (cifra: number, cantidad: number, numeros: [] | string[]) => {
        return client.query({
            query: queriesGeneralData.getRandomNumber,
            variables: {
                input: {
                    cifra,
                    cantidad,
                    numeros
                }
            }
        });
    },
    getHash: () => {
        return client.query({
            query: queriesGeneralData.getHash
        });
    },
    registerPQRS: (input: IRegisterPQRS) => {
        return client.mutate({
            mutation: queriesGeneralData.registerPQRS,
            variables: {
                input
            }
        })
    }
};
