import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Viewport from '../../../hooks/viewport';
import { IContentfulData, IModalHash, IProps } from './types';
import {
    FooterCopyright,
    FooterMenu,
    FooterContainer,
    FooterPrincipal,
    TextCopyright,
    SocialNetwork,
    ContainerSocialNetwork,
    FooterLogos,
    CorporateLogo,
    ContainerColumnFollowUs,
    TitleColumns,
    TitleMenu,
    ContainerSubtitleMenu,
    ContainerTitles,
    SubtitleMenu,
    ContainerColumnContact,
    TextColumns,
    ContainerColumnHelp,
    ContainerColumnUs,
    ContainerTextColumns,
    LinkSub,
    LineLogo,
    LinkContact,
    ItemSubtitle,
    ContainerLine
} from './styled';
import { Redirect } from '../../atoms/redirect';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { generalDataServices } from '@services/generalData';
import ModalHash from '../modalHash';

export const Footer = ({ showSummary }: IProps) => {
    const dataFooter: IContentfulData = useStaticQuery(graphql`
        query QueryFooter {
            allContentfulGeneral {
                nodes {
                    iva
                    logoGanaPrimary {
                        file {
                            url
                        }
                        title
                    }
                    logoGanaSecundary {
                        file {
                            url
                        }
                        title
                    }
                    pqrs
                    address
                    nationalCustomerServiceLine
                    fixedCustomerServiceLine
                    email
                    emailCourtNotifications
                }
            }
            allContentfulFooter {
                nodes {
                    socialNetworks {
                        image {
                            file {
                                url
                            }
                            title
                        }
                        url
                    }
                    corporateLogos {
                        image {
                            title
                            file {
                                url
                            }
                        }
                        url
                    }
                    copyright
                    copyrightRich {
                        raw
                    }
                    titles {
                        subtitle {
                            name
                            url
                        }
                        name
                    }
                }
            }
        }
    `);

    const [checker, setChecker] = useState({});

    const [open, setOpen] = useState(false);
    const [isModalHash, setIsModalHash] = useState(false);
    const [infoModalHash, setInfoModalHash] = useState<IModalHash>({
        checksum: '',
        contrato: '',
        version: '',
        __typename: ''
    });

    /* destructuring y nombre----------------------------- */
    const viewport = Viewport();
    const {
        allContentfulGeneral: {
            nodes: [
                {
                    address,
                    email,
                    emailCourtNotifications,
                    fixedCustomerServiceLine,
                    iva,
                    logoGanaPrimary,
                    logoGanaSecondary,
                    nationalCustomerServiceLine,
                    pqrs
                }
            ]
        },
        allContentfulFooter: {
            nodes: [{ socialNetworks, titles, corporateLogos, copyright, copyrightRich }]
        }
    } = dataFooter;

    const handlerChangeOpen = (item: object) => {
        setChecker(item);
        if (item === checker) {
            setChecker({});
        }
        setOpen(false);
    };

    const showModalHash = async () => {
        await generalDataServices
            .getHash()
            .then((data) => {
                setIsModalHash(true);
                setInfoModalHash(data.data.getHashNew)
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <>
            {isModalHash && (
                <ModalHash
                    modalState={isModalHash}
                    setModalState={() => setIsModalHash(!isModalHash)}
                    infoModal={infoModalHash}
                />
            )}
            <FooterPrincipal
                className={
                    showSummary === true
                        ? 'marginSummary'
                        : showSummary === 'billonario'
                        ? 'marginSummaryBi'
                        : showSummary === 'billeton'
                        ? 'marginBilleton'
                        : showSummary === 'shoppingCart'
                        ? 'marginShoppingCart'
                        : ''
                }
            >
                <FooterContainer>
                    <ContainerColumnFollowUs>
                        <TitleColumns>Síguenos</TitleColumns>
                        <ContainerSocialNetwork>
                            {socialNetworks.map((socialNetwork, index) => {
                                const { url, image } = socialNetwork;
                                return (
                                    <a
                                        href={url}
                                        target="_blank"
                                        key={'footerSocialNetWork ' + index}
                                    >
                                        <SocialNetwork src={image.file.url} alt={image.title} />
                                    </a>
                                );
                            })}
                        </ContainerSocialNetwork>
                        {!viewport.mobile && (
                            <FooterLogos>
                                {corporateLogos.map((corporateLogo, index) => {
                                    return (
                                        <a
                                            href={corporateLogo.url}
                                            target="_blank"
                                            key={'footerCorporateLogo ' + index}
                                        >
                                            <CorporateLogo
                                                src={corporateLogo.image.file.url}
                                                alt={corporateLogo.image.title}
                                            />
                                        </a>
                                    );
                                })}
                            </FooterLogos>
                        )}
                    </ContainerColumnFollowUs>

                    {!viewport.mobile && (
                        <>
                            <ContainerColumnContact>
                                <TitleColumns>Contáctanos</TitleColumns>
                                <TextColumns className={'link'} onClick={() => showModalHash()}>
                                    Hash
                                </TextColumns>
                                <LinkSub href={pqrs}>
                                    Contáctanos
                                </LinkSub>
                                <TextColumns>{address}</TextColumns>
                                <TextColumns>
                                    Linea de servicio al cliente <br />{' '}
                                    {nationalCustomerServiceLine} - {fixedCustomerServiceLine}
                                </TextColumns>
                                <TextColumns>
                                    Correo electrónico <br />{' '}
                                    <LinkSub href={`mailto:${email}`}>{email}</LinkSub>
                                </TextColumns>
                                <TextColumns>
                                    Correo notificaciones judiciales{' '}
                                    <LinkSub href={`mailto:${emailCourtNotifications}`}>
                                        {emailCourtNotifications}
                                    </LinkSub>
                                </TextColumns>
                            </ContainerColumnContact>

                            <ContainerColumnHelp>
                                {titles.map((title, index) => {
                                    if (title.name !== 'Nosotros') {
                                        return (
                                            <div key={'ColumnHelp ' + index}>
                                                <TitleColumns>{title.name}</TitleColumns>
                                                <ContainerTextColumns>
                                                    <ul>
                                                        {title.subtitle.map((subtitle, index) => {
                                                            return (
                                                                <ItemSubtitle
                                                                    key={
                                                                        'subTitleMenuColumnHelp ' +
                                                                        index
                                                                    }
                                                                >
                                                                    <Redirect
                                                                        url={subtitle.url}
                                                                        text={subtitle.name}
                                                                    />
                                                                </ItemSubtitle>
                                                            );
                                                        })}
                                                    </ul>
                                                </ContainerTextColumns>
                                            </div>
                                        );
                                    }
                                })}
                            </ContainerColumnHelp>

                            <ContainerColumnUs>
                                {titles.map((title, index) => {
                                    if (title.name === 'Nosotros') {
                                        return (
                                            <div key={'ColumnUs ' + index}>
                                                <TitleColumns>{title.name}</TitleColumns>
                                                <ul>
                                                    {title.subtitle.map((subtitle, index) => {
                                                        return (
                                                            <ItemSubtitle
                                                                key={
                                                                    'subTitleMenuColumnUs ' + index
                                                                }
                                                            >
                                                                <Redirect
                                                                    url={subtitle.url}
                                                                    text={subtitle.name}
                                                                />
                                                            </ItemSubtitle>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        );
                                    }
                                })}
                            </ContainerColumnUs>
                        </>
                    )}

                    {viewport.mobile && (
                        <FooterMenu>
                            {titles.map((title, index) => {
                                return (
                                    <ContainerTitles key={'containerFooterMenu' + index}>
                                        <TitleMenu
                                            onClick={() => handlerChangeOpen(title)}
                                            className={
                                                title.name === 'Puntos de venta'
                                                    ? ''
                                                    : title.name === 'Puntos GANA'
                                                    ? ''
                                                    : title === checker
                                                    ? 'iconClassDown'
                                                    : 'iconClassUp'
                                            }
                                        >
                                            {title.name}{' '}
                                        </TitleMenu>
                                        <ContainerSubtitleMenu
                                            className={
                                                title.name === 'Puntos de venta'
                                                    ? 'dropOpen'
                                                    : title.name === 'Puntos GANA'
                                                    ? 'dropOpen'
                                                    : open
                                                    ? ''
                                                    : title === checker
                                                    ? 'dropOpen'
                                                    : ''
                                            }
                                        >
                                            {title.subtitle.map((subtitle, index) => {
                                                return (
                                                    <ItemSubtitle key={'subTitleMenus ' + index}>
                                                        <Redirect
                                                            url={subtitle.url}
                                                            text={subtitle.name}
                                                        />
                                                    </ItemSubtitle>
                                                );
                                            })}
                                        </ContainerSubtitleMenu>
                                    </ContainerTitles>
                                );
                            })}
                            <ContainerTitles>
                                <TitleMenu
                                    onClick={() => {
                                        setOpen(!open);
                                        setChecker({});
                                    }}
                                    className={open ? 'iconClassDown' : 'iconClassUp'}
                                >
                                    Contáctanos
                                </TitleMenu>
                                <ContainerSubtitleMenu className={open ? 'dropOpen' : ''}>
                                    <TextColumns className={'link'} onClick={() => showModalHash()}>
                                        Hash
                                    </TextColumns>
                                    <LinkSub href={pqrs}>
                                       <SubtitleMenu>Contáctanos</SubtitleMenu>
                                    </LinkSub>
                                    <SubtitleMenu>{address}</SubtitleMenu>
                                    <SubtitleMenu>
                                        Linea de servicio al cliente <br />{' '}
                                        <LinkContact
                                            className="textBold"
                                            href={'tel:' + nationalCustomerServiceLine}
                                        >
                                            {nationalCustomerServiceLine}
                                        </LinkContact>{' '}
                                        -{' '}
                                        <LinkContact
                                            className="textBold"
                                            href={'tel:' + fixedCustomerServiceLine}
                                        >
                                            {fixedCustomerServiceLine}
                                        </LinkContact>
                                    </SubtitleMenu>
                                    <SubtitleMenu>
                                        Correo eléctronico <br />
                                        <LinkContact className="textBold" href={'mailto:' + email}>
                                            {email}
                                        </LinkContact>
                                    </SubtitleMenu>
                                    <SubtitleMenu>
                                        Correo notificaciones judiciales <br />
                                        <LinkContact
                                            className="textBold"
                                            href={'mailto:' + emailCourtNotifications}
                                        >
                                            {emailCourtNotifications}
                                        </LinkContact>
                                    </SubtitleMenu>
                                </ContainerSubtitleMenu>
                            </ContainerTitles>
                        </FooterMenu>
                    )}
                    {viewport.mobile && (
                        <FooterLogos>
                            {corporateLogos.map((corporateLogo, index) => {
                                if (index === 0) {
                                    return (
                                        <ContainerLine key={'footerCorporateLogo ' + index}>
                                            <a href={corporateLogo.url} target="_blank">
                                                <CorporateLogo
                                                    className="sizeLogo"
                                                    src={corporateLogo.image.file.url}
                                                    alt={corporateLogo.image.title}
                                                />
                                            </a>
                                            <LineLogo></LineLogo>
                                        </ContainerLine>
                                    );
                                } else if (index === 2) {
                                    return (
                                        <a
                                            href={corporateLogo.url}
                                            target="_blank"
                                            key={'footerCorporateLogo ' + index}
                                        >
                                            <CorporateLogo
                                                className="marginTopLogo"
                                                src={corporateLogo.image.file.url}
                                                alt={corporateLogo.image.title}
                                            />
                                        </a>
                                    );
                                } else {
                                    return (
                                        <a
                                            href={corporateLogo.url}
                                            target="_blank"
                                            key={'footerCorporateLogo ' + index}
                                        >
                                            <CorporateLogo
                                                src={corporateLogo.image.file.url}
                                                alt={corporateLogo.image.title}
                                            />
                                        </a>
                                    );
                                }
                            })}
                        </FooterLogos>
                    )}
                </FooterContainer>
                <FooterCopyright className="copyright">
                    <TextCopyright className={showSummary ? 'paddingSummary' : ''}>
                        {/*  {copyright} */}
                        {documentToReactComponents(JSON.parse(copyrightRich.raw))}
                    </TextCopyright>
                </FooterCopyright>
            </FooterPrincipal>
        </>
    );
};
