import { breakpoint } from "src/shared/styles/variables";
import styled from "styled-components";

type IProps = {
    active?: boolean
}

export const ContainerTermsConditions = styled.section`
   margin-top: 13px;
   width: 100%;
   height: 100%;
   text-align: center;
 
   & > div:nth-child(1) {
       margin-left: 10px;
   }
 
   hr {
       border: none;
       border-top: 1px solid rgba(197, 195, 201, 0.5);
       margin: 20px 33px 15px 33px;
   }
 
   ${breakpoint('md')`
       display: flex;
       flex-direction: column;
       margin-top: 28px;
 
       & > ul {
           margin-left: 70px;
       }
 
       hr {
           margin-left: 45px;
           margin-right: 23px;
       }
   `};
`;
 
export const Icon = styled.img`
   align-self: center;
   margin-top: 32px;
   width: 25px;
`;
 
export const Title = styled.h1`
   font-family: ${(props) => props.theme.fonts.mon_bold};
   font-size: 18px;
   margin-top: 45px;
 
   span {
       color: ${(props) => props.theme.colors.accents.base.yellow};
   }
 
   ${breakpoint('md')`
       font-size: 28px;
       margin-top: 15px;
   `};
`;
 
export const Header = styled.div`
   margin: auto;
   width: 80%;
 
   .slider-style {
       .slick-track {
           align-items: center;
           display: flex;
       }
 
       .slick-list {
           margin: 0 auto;
           width: 90%;
       }
   }
 
   .slider-style:after {
       content: '';
       background: #0DF96C;
       height: 3px;
       width: 100px;
       position: absolute;
       bottom: -12px;
       left: calc(50% - 50px);
   };
 
   ${breakpoint('md')`
       display: flex;
       justify-content: space-between;
        width: 95%;
   `};
 
   ${breakpoint('xl')`
        width: 85%;
   `};
`;
 
export const Arrow = styled.div`
   cursor: pointer;
   position: absolute;
   bottom: 10px;
   z-index: 1;
 
   button span {
       font-size: 12px;
   }
 
   &.left {
       left: 0;
   }
  
   &.right {
       right: 0;
   }
`;
 
export const TabButton = styled.button<IProps>`
   background: transparent;
   border: none;
   font-family: ${(props) => props.theme.fonts.mon_bold};
   font-size: 14px;
 
   span {
       color: ${(props) => props.theme.colors.accents.base.yellow};
       max-width: 150px;
       margin: 0 auto;
   }
 
   h2 {
       line-height: 16px;
       margin: auto;
       width: 183px;
   }
 
   ${breakpoint('md')`
       height: 65px;
       position: relative;
       width: 180px;
       padding-bottom: 3px;
  
       h2 {
           color: #56525D;
           transition: all 0.2s ease-in-out;
           margin: 0 auto;
           max-width: 180px;
       }
  
       span {
           color: #56525D;
           transition: all 0.2s ease-in-out;
       }
  
       ${(props: IProps) =>
           props.active ?
           `
           h2 {
               color: #F8F8F8;
           }
  
           span {
               color: #FFE000;
           }
  
           &:after {
               content: ' ';
               background: #0DF96C;
               height: 3px;
               width: 100px;
               position: absolute;
               bottom: 6px;
               left: calc(50% - 50px);
           }
           `
           :
           `
           &:hover {
               h2, span {
                   color: #C5C3C9;
               }
           }
           `
       };
   `};
  
   ${breakpoint('xl')`
       cursor: pointer;
       height: 45px;
       width: 180;
      
       h2 {
           margin: 0;
           width: auto;
           max-width: 180;
       }
 
       ${(props: IProps) =>
           ` 
           &:after {
               bottom: -4px;
           }
           `
       };
   `};
`;
 
export const ContentInfo = styled.div`
   font-family: ${(props) => props.theme.fonts.lf_regular};
   height: 450px;
   overflow-y: auto;
   word-wrap: break-word;
   text-align: start;
   font-size: 14px;
   padding-right: 12px;
   margin: 37px 30px 35px 34px;
 
   a {
       color: ${(props) => props.theme.colors.principal.greenBase};
   }
   h1 {
       font-size: 22px;
       margin: 15px 0px 15px 0px;
       font-weight: bold;
       color: ${(props) => props.theme.colors.accents.base.yellow};
   }
   h3 {
       margin: 15px 0px 15px 0px;
       font-weight: bold;
       color: ${(props) => props.theme.colors.accents.base.yellow};
   }
   h5 {
       margin: 15px 0px 15px 0px;
       font-weight: bold;
       color: ${(props) => props.theme.colors.accents.base.yellow};
   }
   p {
       margin: 10px 0px 10px 0px;
       line-height: 20px;
   }
   p:first-child {
       margin: 0px 0px 10px 0px;
   }
 
   ul {
       margin-top: 18px;
       margin-bottom: 27px;
   }
   ol {
       margin-top: 27px;
       margin-bottom: 27px;
   }
   ul li {
       margin-left: 40px;
       list-style: decimal;
   }
   ol li {
       margin-left: 40px;
       list-style: disc;
   }
   p b {
       font-weight: bold;
   }
   ::-webkit-scrollbar {
       -webkit-appearance: none;
   }
   ::-webkit-scrollbar:vertical {
       width: 2px;
   }
   ::-webkit-scrollbar-thumb {
       background: ${(props) => props.theme.colors.disabled.gray};
   }
   ::-webkit-scrollbar-track {
       background: ${(props) => props.theme.colors.backgrounds.gray400};
   }
 
   ${breakpoint('md')`
       font-size: 16px;
       margin: 45px 20px 35px 45px;
       padding: 0px 22px 22px 0px;
 
       ::-webkit-scrollbar-thumb {
           background: ${(props) => props.theme.colors.backgrounds.gray450};
       }
       ::-webkit-scrollbar-track {
           background: ${(props) => props.theme.colors.disabled.gray};
       }
   `};
`;
 
export const BoxContent = styled.div`
 
   ${breakpoint('md')`
       margin: 0 auto;
       width: 90%;
 
   `};
`;
