import { ButtonIcon, Redirect } from '@componentsShared';
import React, { useContext, useEffect, useState } from 'react';
import Viewport from 'src/shared/hooks/viewport';
import iconUser from 'src/shared/assets/images/iconUser.svg';
import iconUserBlue from 'src/shared/assets/images/iconUserBlue.svg';
import { colors } from 'src/shared/styles/variables';
import {
    ButtonHeader,
    ButtonList,
    ContainerLoginData,
    ContainerDataUser,
    ContainerUser,
    IconUser,
    TextWelcome,
    TitleWelcome,
    ContainerLoading,
    UserName
} from './styled';

import { TooltipIniSesion } from '@componentsShared';
import { getData, setData } from '@utils/transferData';
import { loginServices } from '@services/login';
import { AppContextUser, initialUserData } from 'src/shared/context/user';
import { useFetchDataUser } from '@utils/useFetchDataUser';
import { Loading } from '../../atoms/loading';
import siteUrl from '@utils/siteUrl';

export const UserData = () => {
    const [isLog, setIsLog] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { dataUser, setDataUser } = useContext(AppContextUser);
    const viewport = Viewport();
    const { nombres, saldo, puntos, isLogged } = dataUser;

    const initialState = {
        token: '',
        user: '',
        type: '',
        isLogged: false
    };

    const { fetchData } = useFetchDataUser();

    const getUserData = () => {
        let userData = getData('userData');

        if (userData !== null) {
            setIsLog(userData.isLogged);
            fetchData(userData.user, userData.token, userData.type);
        }
    };

    const logout = async () => {
        await loginServices
            .logout(dataUser.token)
            .then()
            .catch((err) => console.log(err));

        setDataUser(initialUserData);
        setData(initialState, 'userData');
        window.location.reload();
    };

    const goToHistory = () => {
        window.location.href = siteUrl + '/historial-de-compras';
    }

    useEffect(() => {
        getUserData();
        setTimeout(() => {
            nombres === '' && isLogged && logout();
        }, 10000);
    }, []);
    return isLogged ? (
        <ContainerLoginData>
            {!nombres && isLogged ? (
                <ContainerLoading>
                    <Loading />
                </ContainerLoading>
            ) : (
                <>
                    <ContainerUser className={isOpen ? 'show' : 'hide'} loginUser={isLog}>
                        <IconUser className={isOpen ? 'show' : 'hide'} loginUser={isLog}>
                            <img
                                src={iconUserBlue}
                                alt="icon-user-blue"
                                title="Icono usuario"
                            ></img>
                        </IconUser>
                        <ContainerDataUser loginUser={isLog}>
                            <UserName>
                                <TitleWelcome>{nombres.toLowerCase()}</TitleWelcome>
                                {!viewport.mobile && (
                                    <ButtonIcon
                                        onClick={() => setIsOpen(!isOpen)}
                                        title="drop"
                                        icon={!isOpen ? 'angle-small-down' : 'angle-small-up'}
                                        colorDefault={colors.principal.greenNeon}
                                    ></ButtonIcon>
                                )}
                            </UserName>
                            <TextWelcome loginUser={true}>
                                Saldo OKI: <b> {saldo}</b>{' '}
                            </TextWelcome>
                            <TextWelcome className="desklog" loginUser={false}>
                                Puntos gana: <b> {puntos} puntos </b>
                            </TextWelcome>
                        </ContainerDataUser>
                    </ContainerUser>
                </>
            )}

            {viewport.mobile && (
                <ButtonList>
                    {/** <ButtonHeader
                        loginUser={false}
                        onClick={() => {
                            console.log('ir a mi perfil');
                        }}
                    >
                        Ir a mi perfil
                    </ButtonHeader> */}
                    <ButtonHeader loginUser={true} onClick={goToHistory}>
                        Historial de compras
                    </ButtonHeader>
                    < br />
                    <ButtonHeader loginUser={true} onClick={logout}>
                        Cerrar sesión
                    </ButtonHeader>
                </ButtonList>
            )}
            {!viewport.mobile && (
                <ButtonList className={isOpen ? 'show' : 'hide'}>
                    <TextWelcome>
                        Puntos gana: <b> {puntos} </b> <span> puntos </span>
                    </TextWelcome>
                    {/** <ButtonHeader
                        loginUser={false}
                        onClick={() => {
                            console.log('ir a mi perfil');
                        }}
                    >
                        Ir a mi perfil
                    </ButtonHeader> */}
                    <ButtonHeader loginUser={true}  onClick={goToHistory}>
                        Historial de compras
                    </ButtonHeader>
                    <ButtonHeader loginUser={true} onClick={logout}>
                        Cerrar sesión
                    </ButtonHeader>
                </ButtonList>
            )}
        </ContainerLoginData>
    ) : (
        <>
            {viewport.mobile && (
                <ContainerLoginData>
                    <TitleWelcome>Bienvenido</TitleWelcome>
                    <ContainerUser loginUser={isLog}>
                        <IconUser loginUser={isLog}>
                            <img src={iconUser} alt="icon-user" title="Icono usuario"></img>
                        </IconUser>
                        <ContainerDataUser loginUser={isLog}>
                            <TextWelcome loginUser={isLog}>
                                Inicia sesión para mejorar tu experiencia en Gana
                            </TextWelcome>
                        </ContainerDataUser>
                    </ContainerUser>
                    <ButtonList>
                        <ButtonHeader loginUser={true} className={'margin'}>
                            <Redirect url="registro" text="Regístrate" />
                        </ButtonHeader>
                        <ButtonHeader loginUser={false}>
                            <Redirect url="iniciar-sesion" text="Iniciar sesión" />
                        </ButtonHeader>
                    </ButtonList>{' '}
                </ContainerLoginData>
            )}

            {!viewport.mobile && (
                <ContainerLoginData className="login">
                    <ButtonList className="desklog">
                        <TooltipIniSesion />
                        <ButtonHeader loginUser={false}>
                            <Redirect url="iniciar-sesion" text="Iniciar sesión" />
                        </ButtonHeader>
                        <ButtonHeader className="newAcount" loginUser={true}>
                            <Redirect url="registro" text="Regístrate" />
                        </ButtonHeader>
                    </ButtonList>
                </ContainerLoginData>
            )}
        </>
    );
};
