import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';
import arrow from 'src/shared/assets/images/arrow-green.png';

export const ContainerDatePicker = styled.div`
    position: relative;
    width: 100%;

    .calendar {
        background: ${(props) => props.theme.colors.backgrounds.gray400};
        border: none;
        border-radius: 16px;
        color: ${(props) => props.theme.colors.text.gray};
        height: 56px;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 16px;
        outline: none;
        padding: 0 17px;
        width: 100%;
    }

    .react-datepicker__tab-loop {
        min-width: 327px;
        max-width: 327px;

        .react-datepicker-popper {
            padding-top: 7px;
            max-height: 325px;
            min-width: 327px;
            max-width: 327px;

            .react-datepicker {
                background: ${(props) => props.theme.colors.backgrounds.gray400};
                border: none;
                border-radius: 16px;
                max-height: 313px;
                min-width: 327px;
                max-width: 327px;
                overflow: hidden;

                .react-datepicker__navigation {
                    background-image: url(${arrow});
                    background-repeat: no-repeat;
                    background-size: contain;
                    height: 24px;
                    width: 24px;
                    margin-top: 21px;
                    transition: all 0.1s ease-in-out;
                    
                    span {
                        display: none;
                    }

                    &:hover {
                        filter: brightness(115%);
                    }
                }

                .react-datepicker__navigation--next {
                    margin-right: 22px;
                }
                
                .react-datepicker__navigation--previous {
                    margin-left: 22px;

                    transform: rotate(180deg);
                }

                .react-datepicker__triangle {
                    display: none;
                }

                .react-datepicker__month-container {
                    margin-top: 0;
                    width: 100%;

                    .react-datepicker__header {
                        background: ${(props) => props.theme.colors.backgrounds.gray400};
                        border: none;
                        border-top-right-radius: 16px;
                        border-top-left-radius: 16px;
                        height: 85px;
                        position: relative;

                        &:after {
                            content: '';
                            background: ${(props) => props.theme.colors.backgrounds.gray500};
                            height: 1px;
                            width: 279px;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            margin: 0 24px;
                            opacity: .5;
                        }

                        .react-datepicker__current-month {
                            color: ${(props) => props.theme.colors.text.gray};
                            font-family: ${(props) => props.theme.fonts.lf_bold};
                            font-size: 14px;
                            margin-top: 20px;
                            text-transform: capitalize;
                        }

                        .react-datepicker__day-names {
                            margin-top: 10px;

                            .react-datepicker__day-name {
                                color: ${(props) => props.theme.colors.disabled.gray};
                                font-family: ${(props) => props.theme.fonts.lf_bold};
                                font-size: 14px;
                                text-transform: capitalize;
                                width: 37px;

                                ${breakpoint('md')`
                                    width: 36px;
                                `};
                            }

                            & > div:nth-child(3) {
                                margin-top: 0;
                            }

                            ${breakpoint('md')`
                                & > div:nth-child(2) {
                                    margin-right: 2.656px;
                                }
                            `};
                        }
                    }
                    
                    .react-datepicker__month {
                        & > div:last-child {
                            margin-top: 25px;
                        }

                        .react-datepicker__week {
                            margin-top: 0;

                            .react-datepicker__day {
                                border-radius: 50%;
                                color: ${(props) => props.theme.colors.text.gray};
                                font-family: ${(props) => props.theme.fonts.lf_semiBold};
                                font-size: 14px;
                                height: 35px;
                                width: 37px;
                                padding: 4px 2px 0 1px;
                                transition: all 0.1s ease-in-out;

                                &:hover {
                                    background: ${(props) => props.theme.colors.backgrounds.gray600};;
                                }

                                ${breakpoint('md')`
                                    height: 35px;
                                    width: 36px;
                                    padding: 4px 1px 0 1px;
                                `};
                            }

                            .react-datepicker__day--keyboard-selected {
                                background: none;
                            }

                            .react-datepicker__day--disabled {
                                color: ${(props) => props.theme.colors.backgrounds.gray450};

                                &:hover {
                                    background: none;
                                }
                            }
                            
                            .react-datepicker__day--selected {
                                background: ${(props) => props.theme.colors.principal.greenBase};

                                &:hover {
                                    background: ${(props) => props.theme.colors.principal.greenBase};
                                }
                            }

                            & > div:nth-child(3) {
                                margin-top: 0;
                            }

                            ${breakpoint('md')`
                                & > div:nth-child(2) {
                                    margin-right: 2.656px;
                                }
                            `};
                        }

                        ${breakpoint('md')`
                            margin: 6.4px;

                            & > div:nth-child(2) {
                                margin-right: 0;
                            }
                        `};
                    }
                }
            }
        }
    }

    & > span {
        color: ${(props) => props.theme.colors.principal.greenNeon};
        font-size: 20px;
        position: absolute;
        right: 18px;
        top: 17px;
    }
`;
