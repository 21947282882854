import React, { useContext, useEffect, useState } from 'react';
import Viewport from 'src/shared/hooks/viewport';
import { carServices } from '@services/car';
import { CartHeader, EmptyCart, CartBodyDetail, Loading } from '@componentsShared';
import { IProps, IDataBets } from './types';
import { FlexContainer, FlexDesktopContainer, MainContainer, ModalContainer } from './styled';
import { AppContextUser } from 'src/shared/context/user';
import { ModalDeleteBet } from '@componentsShared';
import { getCart } from '@utils/carrito';
import { IBets } from '../../molecules/cartBodyDetail/types';
import { ModalEditBet } from '../../molecules/modalEditBet';

export const CartModal = ({ open, handleClose }: IProps) => {
    const { dataUser } = useContext(AppContextUser);
    const viewport = Viewport();

    const [betsCart, setBetsCart] = useState<IDataBets>();
    const [isEmpty, setIsEmpty] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [dataDelete, setDataDelete] = useState({
        openModal: false,
        idBet: ''
    });
    const [dataEdit, setDataEdit] = useState({
        openModalEdit: false,
        bet: {} as IBets,
        logo: {
            file: {
                url: '',
                fileName: ''
            }
        },
        idCart: 0
    });
    const fetchDataCart = async () => {
        if (dataUser.tipoDocumento && dataUser.usuario && dataUser.token) {
            const success = (data: any) => {
                setBetsCart(data.data.getCart);
                if (data.data.getCart.bets.length > 0) {
                    setIsEmpty(false);
                }
            };
            const error = (err: any) => {
                console.log(err);
            };
            getCart(dataUser.token, dataUser.tipoDocumento, dataUser.usuario, success, error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        setIsLoading(true);
        if (dataUser.isLogged) {
            fetchDataCart();
        }
    }, [dataUser]);

    const displayModalDelete = () => {
        setDataDelete({
            ...dataDelete,
            openModal: !dataDelete.openModal,
            idBet: ''
        });
    };

    const displayModalEdit = () => {
        setDataEdit({
            ...dataEdit,
            openModalEdit: !dataEdit.openModalEdit,
            bet: {} as IBets,
            logo: {
                file: {
                    url: '',
                    fileName: ''
                }
            },
            idCart: 0
        });
    };

    const closeModalClickOut = (e: MouseEvent) => {
        if (viewport.mobile) {
            if ((e.clientX < window.innerWidth * 0.03) && !dataDelete.openModal && !dataEdit.openModalEdit) {
                handleClose();
            }
        } else if ((e.clientX < window.innerWidth / 2) && !dataDelete.openModal && !dataEdit.openModalEdit) {
            handleClose();
        }
    };

    const [height, setHeight] = useState<string>('100vh');


    useEffect(() => {
        const isClosedModal = sessionStorage.getItem('closeModalLocation') == 'true';
        if(!viewport.desktop) {
            let h = 0;
            if(window?.innerHeight) {
                h = window.innerHeight - (isClosedModal ? 46 : 0);
                setHeight(h + 'px');
            } else if(document?.documentElement?.clientHeight) {
                h = document.documentElement.clientHeight - (isClosedModal ? 46 : 0);
                setHeight(h + 'px');
            }
        }
    });

    return (
        <>
            <ModalContainer open={open} onClick={(e) => closeModalClickOut(e)}>
                <ModalDeleteBet
                    modalState={dataDelete.openModal}
                    setModalState={displayModalDelete}
                    idCarrito={Number(dataDelete.idBet)}
                    fetchDataCart={fetchDataCart}
                />
                <ModalEditBet
                    modalState={dataEdit.openModalEdit}
                    setModalState={displayModalEdit}
                    bet={dataEdit.bet}
                    logo={dataEdit.logo}
                    idCart={dataEdit.idCart}
                />
                {viewport.mobile && (
                    <FlexContainer open={open} className={`menuNav ${open ? ' showMenu' : ''}`} style={{height: height}}>
                        <CartHeader bets={betsCart?.bets.length} handleClose={handleClose} />
                        <MainContainer>
                            {isLoading ? (
                                <Loading />
                            ) : (
                                <>
                                    {isEmpty ? (
                                        <EmptyCart />
                                    ) : (
                                        <CartBodyDetail
                                            dataBets={betsCart}
                                            fetchDataCart={fetchDataCart}
                                            setDataDelete={setDataDelete}
                                            setDataEdit={setDataEdit}
                                        />
                                    )}
                                </>
                            )}
                        </MainContainer>
                    </FlexContainer>
                )}

                {!viewport.mobile && (
                    <FlexDesktopContainer
                        open={open}
                        className={`menuNav ${open ? ' showMenu' : ''}`}
                    >
                        <CartHeader bets={betsCart?.bets.length} handleClose={handleClose} />
                        <MainContainer>
                            {isLoading ? (
                                <Loading />
                            ) : (
                                <>
                                    {isEmpty ? (
                                        <EmptyCart />
                                    ) : (
                                        <CartBodyDetail
                                            dataBets={betsCart}
                                            fetchDataCart={fetchDataCart}
                                            setDataDelete={setDataDelete}
                                            setDataEdit={setDataEdit}
                                        />
                                    )}
                                </>
                            )}
                        </MainContainer>
                    </FlexDesktopContainer>
                )}
            </ModalContainer>
        </>
    );
};
