import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
    uri: process.env.BACK_GANA,
});

const authLink = setContext((_, { headers }) => {
    const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhMSI6IkRhdGEgMSIsImRhdGEyIjoiRGF0YSAyIiwiZGF0YTMiOiJEYXRhIDMiLCJkYXRhNCI6IkRhdGEgNCIsImlhdCI6MTU2Mjg4MDM0MX0.O6csaznGfJLEzogn8c_vrdJc3ZO_KOBWneWFw2aer0i86om4fDbHmSfHw5P5RaqbdBO3QPQMV3dnGnHus37Fuw'
    return {
        headers: {
            ...headers,
            authorization: `Bearer ${token}`,
        }
    }
});

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    mutation: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    }
}

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions
});

export default client;