import { loginServices } from '@services/login';
import { getData, setData } from '@utils/transferData';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { AppContextUser, initialUserData } from 'src/shared/context/user';
import { ModalGana } from '../modalGana';

export const ValidateLogin = () => {
    const [modalState, setModalState] = useState(false);
    let dataUserLocal = getData('userData');
    const { dataUser, setDataUser } = useContext(AppContextUser);

    const userData = {
        token: '',
        type: '',
        user: '',
        isLogged: false
    };

    const logout = async () => {
        await loginServices
            .logout(dataUserLocal.token)
            .then((data) => {})
            .catch((err) => console.log(err));
        setDataUser(initialUserData);
        setData(userData, 'userData');
    };

    const handleOnIdle = () => {
        if (dataUserLocal.isLogged === true) {
            setModalState(true);
            setDataUser(initialUserData);
            setData(userData, 'userData');
            logout();
        }
    };

    useIdleTimer({
        timeout: 1000 * 60 * 10,
        // timeout: 5000,
        onIdle: handleOnIdle
    });

    useEffect(() => {
        let data = getData('userData');
        if (data) {
            data.token = null;
            setDataUser(data);
        }
    }, []);

    const dataModalSuccess = useStaticQuery(graphql`
        query ModalExpire {
            allContentfulModal(filter: { name: { eq: "Sesion expiro" } }) {
                edges {
                    node {
                        icon {
                            file {
                                url
                            }
                        }
                        title
                        text
                        AditionalText
                    }
                }
            }
        }
    `);
    const {
        allContentfulModal: { edges }
    } = dataModalSuccess;
    const { node: modal } = edges[0];

    return (
        <div>
            <ModalGana
                visibility={modalState}
                setVisibility={() => {
                    setModalState(!modalState);
                    window.location.href = '/';
                }}
                options={{
                    logo: 'secundary',
                    icon: modal.icon.file.url,
                    title: modal.title,
                    text: modal.text,
                    boldText: modal.AditionalText,
                    primaryButtonText: 'Iniciar Sesión',
                    primaryButton: () => {
                        setModalState(!modalState);
                        window.location.href = '/iniciar-sesion/';
                    },
                    dimensions: {
                        height: { desktop: '440px' },
                        width: { desktop: '435px' }
                    }
                }}
            />
        </div>
    );
};
