import { systemServices } from '@services/system';

export const BILLONARIO_CONFISYS_VALOR_3C = 'BILLONARIO_VALOR_3C';
export const BILLONARIO_CONFISYS_VALOR_4C = 'BILLONARIO_VALOR_4C';
export const BILLONARIO_STORAGE = 'BILLONARIO_DATA';
export const BILLONARIO_SIGLA_3C = 'DG3';
export const BILLONARIO_SIGLA_4C = 'BN4';
export const BILLONARIO_URL_CONFIRMATION = '/juegos/billonario/confirmacion-apuesta/';

export const useValoresBillonario = () => {
    return new Promise<{
        BILLONARIO_VALOR_3C: number;
        BILLONARIO_VALOR_4C: number;
    }>(async (resolve) => {
        const configuration = {
            valor3c: 3000,
            valor4c: 3000
        };
        const {
            data: { getConfisys }
        } = await systemServices.getConfisys([
            BILLONARIO_CONFISYS_VALOR_3C,
            BILLONARIO_CONFISYS_VALOR_4C
        ]);

        getConfisys.forEach((confisys) => {
            if (confisys.nombre === BILLONARIO_CONFISYS_VALOR_3C) {
                configuration.valor3c = Number(confisys.valorStr || '3000');
            } else if (confisys.nombre === BILLONARIO_CONFISYS_VALOR_4C) {
                configuration.valor4c = Number(confisys.valorStr || '3000');
            }
        });

        resolve({
            BILLONARIO_VALOR_3C: configuration.valor3c,
            BILLONARIO_VALOR_4C: configuration.valor4c
        });
    });
};