import { gql } from "@apollo/client";
import client from '../../config/apollo-client-gana';

export const queries = {
    getIncentivosWebGana: gql`
        query ($sigla: String, $valorApuesta: Int) {
            getIncentivosWebGana(sigla: $sigla, valorApuesta: $valorApuesta) {
                status
                message
                data {
                    parMensaje
                    topeMaximoTransaccion
                    topeMinimoTransaccion
                    juegos
                }
            }
        }
    `
};

export const incentivosSevices = {
    getIncentivosWebGana: (sigla: String, valorApuesta: Number) => {
        return client.query({
            query: queries.getIncentivosWebGana,
            variables: { sigla: sigla, valorApuesta: valorApuesta }
        }) 
    }
};