import { gql } from '@apollo/client';
import client from '../../config/apollo-client-gana';
import { getGameInfoRequest, getGameInfoResponse } from '@utils/encrypt/getGameInfo';
import { getGameInfoBilletonResponse } from '@utils/encrypt/getGameInfoBilleton';

export interface TypeLottery {
    abreviatura: string;
    desLoteria: string;
    horaCierre: string;
    ideLoteria: number;
    numCifras: number;
    sorteo: string;
}

interface ILottery {
    ideLoteria: string;
}

interface INumbers {
    number: string;
}

export const queries = {
    getGameInfo: gql`
        query ($result: LoteriasSearchInput!) {
            getGameInfo(filter: $result) {
                vlrMaximoColilla
                vlrMinimoColilla
                loterias {
                    abreviatura
                    desLoteria
                    horaCierre
                    ideLoteria
                    numCifras
                    sorteo
                    numeroFavorito
                    __typename
                }
                planPremios {
                    valorApuesta
                    premioNeto
                    premioBruto
                    iva
                    puntosAGanar
                    __typename
                }
                planPremiosBilleton {
                    lotteryId
                    premiosBilleton {
                        valorApuesta
                        premioNeto
                        premioBruto
                        iva
                        puntosAGanar
                        __typename
                    }
                }
                __typename
            }
            getIncentivesnoToken {
                id
                incentivo_tres
                incentivo_cuatro
                fecha_incremento
                incremento_tres
                incremento_cuatro
                cantidad_utv
                valor_uvt
                __typename
            }
        }
    `,
    getAwardsPlanBilleton: gql`
        query ($fecha: String, $sigla: String) {
            getGameInfoBilleton (fecha: $fecha, sigla: $sigla) {
                denominacion
                loterias {
                    abreviatura
                    desLoteria
                    horaCierre
                    horaInicioConteo
                    ideLoteria
                    numCifras
                    numeroFavorito
                    sorteo
                }
                valorApuesta {
                    iva
                    premioBruto
                    puntosAGanar
                    valorApuesta
                }
            }
        }
    `,
    getAvailableNumber: gql`
        query ($fecha: String, $sigla: String, $loteria: Int, $numero: String, $valorApuesta: Int) {
            getNumeroDisponible (fecha: $fecha, sigla: $sigla, loteria: $loteria, numero: $numero, valorApuesta: $valorApuesta)
        }
    `,
    getPlanPremios: gql`
        query ($result: LoteriasSearchInput!) {
            getGameInfo(filter: $result) {
                planPremios {
                    valorApuesta
                    premioNeto
                    premioBruto
                    iva
                    puntosAGanar
                    __typename
                }
                __typename
            }
        }
    `,
    getGameInfoVlr: gql`
        query ($result: LoteriasSearchInput!) {
            getGameInfo(filter: $result) {
                vlrMaximoColilla
                vlrMinimoColilla
            }
        }
    `,
    validateTopes: gql`
        query validarTopeResponsabilidad($objTope: objValidateTope!) {
            validarTopeResponsabilidad(objTope: $objTope) {
                data {
                    loteria
                    disponible
                    numero
                }
            }
        }
    `,
    getGameIncentives: gql`
        query {
            getIncentivesnoToken {
                id
                incentivo_tres
                incentivo_cuatro
                fecha_incremento
                incremento_tres
                incremento_cuatro
                cantidad_utv
                valor_uvt
                __typename
            }
        }
    `,
    getBaloto: gql`
        query {
            getBaloto {
                fecha
                numeros
                sorteo
                acumuladoProximoSorteo
                fechaProximoSorteo
                ultimoSorteo
                __typename
            }
        }
    `,
    getLotteriesBilleton: gql`
        query ($result: LoteriasSearchInput!) {
            getGameInfo(filter: $result) {
                loterias {
                    abreviatura
                    desLoteria
                    horaCierre
                    ideLoteria
                    numCifras
                    sorteo
                    horaInicioConteo
                    numeroFavorito
                    premiosBilleton {
                        valorApuesta
                        premioNeto
                        premioBruto
                        iva
                        puntosAGanar
                        disponible
                        __typename
                    }
                    __typename
                }
                __typename
            }
            getIncentivesnoToken {
                id
                incentivo_tres
                incentivo_cuatro
                fecha_incremento
                incremento_tres
                incremento_cuatro
                cantidad_utv
                valor_uvt
                __typename
            }
        }
    `,
    getResultLoterries: gql`
        query ($result: ResultadoSearchInput) {
            getResultadosTope(filter: $result) {
                message
                status
                data {
                    desLoteria
                    fecSorteo
                    ideClaseLoteria
                    ideLoteria
                    ideProducto
                    numero
                    numeroFavorito
                    serie
                    sigla
                    siglaLoteria
                    sorteo
                    desSigno
                    desSignoFavorito
                    __typename
                }
                __typename
            }
        }
    `
};

export const lotteriesServices = {
    getLotteries: async (date: string, siglas: string) => {
        const response = await client.query({
            query: queries.getGameInfo,
            variables: {
                result: { fechaSorteo: date, sigla: siglas }
            }
        });

        response.data.getGameInfo = getGameInfoResponse(response.data.getGameInfo);
        return response;
    },

    getAwardsPLanBilleton: async (fecha: string, sigla: string) => {
        const response = await client.query({
            query: queries.getAwardsPlanBilleton,
            variables: {
                fecha,
                sigla
            }
        });
        response.data.getGameInfoBilleton = getGameInfoBilletonResponse(response.data.getGameInfoBilleton);
        return response;
    },

    getAvailableNumber: (
        fecha: string,
        sigla: string,
        loteria: number,
        numero: string,
        valorApuesta: number
    ) => {
        return client.query({
            query: queries.getAvailableNumber,
            variables: {
                fecha,
                sigla,
                loteria,
                numero,
                valorApuesta
            }
        })
    },

    getIncentives: () => {
        return client.query({
            query: queries.getGameIncentives
        });
    },

    getBaloto: () => {
        return client.query({
            query: queries.getBaloto
        });
    },

    getPlanPremios: async (date: string, siglas: string, bets: object[]) => {
        const request = getGameInfoRequest({ fechaSorteo: date, sigla: siglas, bets: bets });
        const response = await client.query({
            query: queries.getPlanPremios,
            variables: {
                result: request
            }
        });
        response.data.getGameInfo = getGameInfoResponse(response.data.getGameInfo);
        return response;
    },

    getGameInfoVlr: async (date: string, siglas: string) => {
        const response = await client.query({
            query: queries.getGameInfoVlr,
            variables: {
                result: { fechaSorteo: date, sigla: siglas }
            }
        });

        response.data.getGameInfo = getGameInfoResponse(response.data.getGameInfo);
        return response;
    },

    validateTopes: (date: string, lotteries: ILottery[], numbers: INumbers[]) => {
        return client.query({
            query: queries.validateTopes,
            variables: {
                objTope: {
                    date: date,
                    lotteries: lotteries,
                    numbers: numbers
                }
            }
        });
    },
    getLotteriesBilleton: async (date: string, siglas: string)=>{
        const response = await client.query({
            query: queries.getLotteriesBilleton,
            variables: {
                result: {
                    fechaSorteo:date,
                    sigla: siglas
                }
            }
        });

        response.data.getGameInfo = getGameInfoResponse(response.data.getGameInfo);
        return response;
    },
    getResultLotteries: (date: string) => {
        return client.query({
            query: queries.getResultLoterries,
            variables: {
                result: { fecha: date }
            }
        });
    }
};
