import React, { useContext, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Button, Carousel, ButtonIcon, Redirect } from '@componentsShared';
import Viewport from 'src/shared/hooks/viewport';
import logoVisa from 'src/shared/assets/images/logo-visa.png';
import logoMastercard from 'src/shared/assets/images/logo-mastercard.png';
import ellipsis from 'src/shared/assets/images/ellipsis-solid.svg';
import logoOki from 'src/shared/assets/images/logo-oki.png';
import { converterNumber } from '@utils/decimalNumberConverter';
import { IPropsCartBody, IBets, INumbers, ILogo, ILot } from './types';
import { AppContextUser } from 'src/shared/context/user';
import {
    WrapperCartBody,
    CartBodyLotteries,
    CartLotteryBox,
    CartLotteryBoxTop,
    CartLotteryBoxInfo,
    CartLotteryBoxInfoLogo,
    CartLotteryBoxInfoLotts,
    CartLotteryBoxNumbers,
    BoxNumbers,
    CartLotteryBoxTotal,
    CartBodyBottom,
    CartBodyBottomTotal,
    CartBodyBottomPayments,
    LogosYourPayments,
    CartBodyBottomTotalLeft,
    CartBodyBottomTotalRight,
    RowTotal,
    ContentSliderLotts,
    BoxLott,
    ContainerDeleteCart,
    ContentElements
} from './styled';
import siteUrl from '@utils/siteUrl';
import { editBet } from '@utils/EditCarts';
import { ElementBox } from '../elementBox';

export const CartBodyDetail = (props: IPropsCartBody) => {
    const viewport = Viewport();
    const {
        setDataDelete,
        dataBets,
        dataBets: { bets },
        setDataEdit
    } = props;

    const logoLotteriesCart = useStaticQuery(graphql`
        query LogosLotteriesCart {
            allContentfulLoterias {
                nodes {
                    abreviatura
                    nombre
                    imagen {
                        file {
                            url
                            fileName
                        }
                    }
                }
            }
            allContentfulConfiguracionJuego {
                edges {
                    node {
                        abbreviation
                        logo {
                            file {
                                url
                            }
                            title
                        }
                    }
                }
            }
        }
    `);

    const {
        allContentfulConfiguracionJuego: { edges: arrayLogosGame },
        allContentfulLoterias: { nodes: logosLotteries }
    } = logoLotteriesCart;

    const arrayImagesLotteries: ILogo[] = logosLotteries;
    const filterImageLottery = (arr: ILogo[], param: string) => {
        return arr.find((lot) => lot.abreviatura === param);
    };

    const arrayLogoGame = arrayLogosGame;
    const filteredImgGame = (sigla: string) => {
        return arrayLogoGame?.find((itemBet: any) => itemBet.node.abbreviation === sigla);
    };

    const customSettings = {
        dots: false,
        infinite: false,
        autoplay: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1
    };
    const { dataUser } = useContext(AppContextUser);

    return (
        <WrapperCartBody>
            <CartBodyLotteries>
                {bets.map((bet: IBets, index: number) => {
                    const logoGame = filteredImgGame(bet.sigla);
                    const {
                        node: { logo }
                    } = logoGame;
                    return (
                        <CartLotteryBox key={index}>
                            <CartLotteryBoxTop>
                                <ContainerDeleteCart>
                                    <ButtonIcon
                                        icon="trash"
                                        title="Eliminar"
                                        colorDefault="#C5C3C9"
                                        onClick={() => {
                                            setDataDelete({
                                                openModal: true,
                                                idBet: bet.id
                                            });
                                        }}
                                    />
                                    <ButtonIcon
                                        title="Editar Apuesta"
                                        icon="edit"
                                        colorDefault="#C5C3C9"
                                        onClick={() => {
                                            setDataEdit({
                                                openModalEdit: true,
                                                bet: bet,
                                                logo: logo,
                                                idCart: dataBets?.id || 0
                                            });
                                        }}
                                    />
                                </ContainerDeleteCart>
                                <CartLotteryBoxInfo>
                                    <CartLotteryBoxInfoLogo>
                                        <img src={logo.file.url} alt={logo.title} />
                                        {viewport.mobile && <h2>{bet.nombre_colilla}</h2>}
                                    </CartLotteryBoxInfoLogo>
                                    <CartLotteryBoxInfoLotts>
                                        {!viewport.mobile && <h2>{bet.nombre_colilla}</h2>}

                                        <ContentSliderLotts>
                                            {!viewport.mobile && (
                                                <Carousel customSettings={customSettings}>
                                                    {bet.loteries.map((lot: ILot) => {
                                                        const abreviatura = lot.sigla.toLowerCase();
                                                        const logo: ILogo | undefined =
                                                            filterImageLottery(
                                                                arrayImagesLotteries,
                                                                abreviatura
                                                            );

                                                        return (
                                                            <BoxLott key={lot.loterieId}>
                                                                {logo ? (
                                                                    <img
                                                                        src={logo.imagen.file.url}
                                                                        alt={lot.desLoteria}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src="https://c8.alamy.com/compes/kd3wf9/icono-de-rueda-de-loteria-kd3wf9.jpg"
                                                                        alt="logo default"
                                                                    />
                                                                )}
                                                            </BoxLott>
                                                        );
                                                    })}
                                                </Carousel>
                                            )}
                                            {viewport.mobile && (
                                                <>
                                                    {bet.loteries.map((lot: ILot, index) => {
                                                        const abreviatura = lot.sigla.toLowerCase();
                                                        const logo: ILogo | undefined =
                                                            filterImageLottery(
                                                                arrayImagesLotteries,
                                                                abreviatura
                                                            );

                                                        if (viewport.mobile) {
                                                            if (index > 4) {
                                                                return;
                                                            }

                                                            if (index === 4) {
                                                                return (
                                                                    <img
                                                                        src={ellipsis}
                                                                        alt="ellipsis"
                                                                        className="ellipsis"
                                                                    />
                                                                );
                                                            }
                                                        }

                                                        return (
                                                            <BoxLott key={lot.loterieId}>
                                                                {logo ? (
                                                                    <img
                                                                        src={logo.imagen.file.url}
                                                                        alt={lot.desLoteria}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src="https://c8.alamy.com/compes/kd3wf9/icono-de-rueda-de-loteria-kd3wf9.jpg"
                                                                        alt="logo default"
                                                                    />
                                                                )}
                                                            </BoxLott>
                                                        );
                                                    })}
                                                </>
                                            )}
                                        </ContentSliderLotts>
                                        {bet.elementoschance?.length > 0 && (
                                            <ContentElements>
                                                {bet.elementoschance?.map((item) => {
                                                    return (
                                                        <ElementBox
                                                            dataElement={item}
                                                            elementActive={true}
                                                            evento={bet.eventochance.desEvento}
                                                        />
                                                    );
                                                })}
                                            </ContentElements>
                                        )}
                                    </CartLotteryBoxInfoLotts>
                                </CartLotteryBoxInfo>

                                <CartLotteryBoxNumbers>
                                    {bet.betNumbers.map((num: INumbers, index) => {
                                        if (viewport.mobile) {
                                            if (index > 3) {
                                                return;
                                            }

                                            if (index === 3) {
                                                return (
                                                    <img
                                                        src={ellipsis}
                                                        alt="ellipsis"
                                                        className="ellipsis"
                                                    />
                                                );
                                            }
                                        }

                                        return (
                                            <BoxNumbers key={index}>
                                                <p>Número {index + 1}</p>
                                                <span>{num.numbers.join('')}</span>
                                            </BoxNumbers>
                                        );
                                    })}
                                </CartLotteryBoxNumbers>
                            </CartLotteryBoxTop>

                            <CartLotteryBoxTotal>
                                <p>
                                    Total<span>$ {converterNumber((bet.total ? bet.total : 0) + 
                                                    (bet.vlrTotalAdicional || 0) + 
                                                    (bet.ivaAdicional || 0))}
                                        </span>
                                </p>
                            </CartLotteryBoxTotal>
                        </CartLotteryBox>
                    );
                })}
            </CartBodyLotteries>

            <CartBodyBottom>
                <CartBodyBottomTotal>
                    <CartBodyBottomTotalLeft>
                        <RowTotal>
                            <p>
                                Subtotal{' '}
                                <span>
                                    $ {dataBets && converterNumber(dataBets.total - dataBets.iva)}
                                </span>
                            </p>
                        </RowTotal>
                        <RowTotal>
                            <p>
                                IVA <span>$ {dataBets && converterNumber(dataBets.iva)}</span>
                            </p>
                        </RowTotal>
                        <RowTotal>
                            <p>
                                Total a pagar{' '}
                                <span>$ {dataBets ? converterNumber(dataBets.total) : 0}</span>
                            </p>
                        </RowTotal>
                    </CartBodyBottomTotalLeft>
                    <CartBodyBottomTotalRight>
                        <Button
                            onClick={() => {
                                window.location.href = siteUrl + '/carrito';
                            }}
                            text="Quiero pagar"
                        />
                        <Redirect url="juegos/" text="Agregar otra apuesta" />
                    </CartBodyBottomTotalRight>
                </CartBodyBottomTotal>

                {!viewport.mobile && (
                    <CartBodyBottomPayments>
                        <h2>Medios de pago</h2>
                        <LogosYourPayments>
                            <img src={logoOki} alt="logo oki" />
                            <img src={logoVisa} alt="logo visa" />
                            <img src={logoMastercard} alt="logo mastercard" />
                        </LogosYourPayments>
                    </CartBodyBottomPayments>
                )}
            </CartBodyBottom>
        </WrapperCartBody>
    );
};
