import styled from 'styled-components';
interface Props {
    open: boolean;
}

export const StyledBurger = styled.button<Props>`
    position: absolute;
    top: 5%;
    left: 21px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
    margin-top: 12px;
    &:focus {
        outline: none;
    }
    span {
        display: ${({ open }) => (!open ? 'none' : 'flex')};
        margin-bottom: 2vh;
    }
`;

export const MenuBurger = styled.img<Props>`
     display: ${({ open }) => (open ? 'none' : 'flex')};
    
`