import styled, { css } from 'styled-components';
import EllipseBottom from 'src/shared/assets/images/EllipseBottomMedium.png';
import { breakpoint } from 'src/shared/styles/variables';

export const WrapperCartDetail = styled.section`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 17px;
    width: 100%;
    padding: 13px 17px 13px;
    max-width: 1240px;
    margin: 0 auto;

    ${breakpoint('md')`
        padding: 30px 50px 40px;
    `}
    ${breakpoint('xxl')`
        padding: 30px 0px 40px;
    `}
`;

export const ContentCart = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 16px;
    position: relative;
    max-width: 805px;

    ${breakpoint('md')`
        background: none;
        justify-content: space-between;
        width: 65%;
    `}

    ${breakpoint('lg')`
        max-width: 805px;
    `}

    ${breakpoint('xxlMax')`
        max-width: 813px;
    `}
`;
export const CartTitle = styled.div<{ state: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 16px;
    margin-top: 22px;
    h1 {
        font-size: 20px;
        line-height: 24px;
        font-family: ${(props) => props.theme.fonts.mon_bold};
        color: ${(props) => props.theme.colors.text.gray};
        span {
            color: ${(props) => props.theme.colors.accents.base.yellow};
        }
    }

    ${breakpoint('md')`
        background: ${(props: any) => props.state && props.theme.colors.backgrounds.gray400};
        margin-bottom: ${(props: any) => props.state && '21px'}; 
        border-radius: 16px;
        height: 40px;
    `}
`;

export const ContainerGoBack = styled.div`
    width: 100%;
    margin-bottom: 17px;
`;

export const ContainerEmptyCart = styled.div`
    width: 100%;
    height: 100%;
    & > div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        p {
            color: ${(props) => props.theme.colors.accents.neon.blue};
        }

        .cartText {
            width: 283px;
        }
    }

    ${CartTitle} {
        margin-bottom: 105px;
    }
`;

export const TitleDate = styled.h3`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: ${(props) => props.theme.fonts.lf_bold};
    color: ${(props) => props.theme.colors.text.gray};
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 41px;
`;

export const ContentDetail = styled.div<{ stateBet: string }>`
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    width: 100%;
    background: ${(props) => props.theme.colors.backgrounds.gray450};
    border: 0.5px solid ${(props) => props.theme.colors.principal.greenNeon};
    box-sizing: border-box;
    border-radius: 16px;
    position: relative;
    max-width: 805px;
    margin-bottom: 54px;
    z-index: 3;
    /* opacity: .3; */
    /* height: 561px; */

    &:after {
        content: '';
        display: flex;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: url(${EllipseBottom});
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.8;
        z-index: -1;
    }

    .alert {
        opacity: 0.3;
    }

    ${(props) =>
        props.stateBet === 'alert'
            ? css`
                  border: 0.5px solid ${props.theme.colors.accents.neon.orange};
                  /* opacity: 0.3; */
              `
            : props.stateBet === 'danger' &&
              css`
                  border: 0.5px solid ${props.theme.colors.alerts.red};
                  /* opacity: 0.3; */
              `};

    ${breakpoint('md')`
        background: ${(props) => props.theme.colors.backgrounds.gray500};
    `}
`;

export const ContentDetailLogo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #fff;
    border-radius: 12px;
    position: absolute;
    left: 0;
    right: 0;
    top: -28px;
    height: 56px;
    max-width: 747;
    width: 90%;
    margin: 0 auto;
    padding: 6px 10px;
    img {
        width: 120px;
        object-fit: contain;
    }
`;

export const ContentDetailTop = styled.div`
    width: 100%;
    justify-content: space-between;
    display: flex;
    padding-bottom: 15px;
    margin: 48px 17px 0px 17px;
    border-bottom: 0.5px solid rgba(197, 195, 201, 0.7);

    ${breakpoint('md')`
        margin: 48px 35px 0px 35px;
    `}
`;

export const ContentDetailTopTitle = styled.h2`
    width: 100%;
    display: flex;
    font-size: 16px;
    line-height: 19.2px;
    color: ${(props) => props.theme.colors.accents.base.yellow};
    font-family: ${(props) => props.theme.fonts.mon_bold};
`;

export const ContentDetailTopIcons = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    span {
        color: ${(props) => props.theme.colors.disabled.gray};
        width: 16px;
        height: 16px;
        font-size: 17px;
        transition: all 0.5s ease-in-out;
        cursor: pointer;
        &:last-child {
            margin-left: 15px;
        }
        &:hover {
            color: ${(props) => props.theme.colors.principal.greenBase};
        }
    }
`;

export const ContentDetailMediumLotteries = styled.div<{ isShowMore: boolean; cantToShow: number }>`
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    width: 100%;

    ${breakpoint('md')`
        max-width: 605px;
        justify-content: center;
    `}

    & > div:only-child {
        margin: 0 auto;
    }

    &:nth-child(-n) {
        justify-content: center;
    }

    ${(props) =>
        !props.isShowMore &&
        `
        &>div:nth-child(n+${props.cantToShow}){
            display:none;
        }    
        `};

    & > button {
        margin-top: 16px;
        max-width: 120px;
        padding: 0px;
        color: ${(props) => props.theme.colors.text.white} !important;
        & > span {
            color: ${(props) => props.theme.colors.principal.greenBase};
        }
    }
`;

export const ContainerDetailLotteries = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 24px 17px 0px 17px;

    ${breakpoint('md')`
        padding: 24px 0px 0px 0px;
        &.eventoChance {
            flex-direction: row;
        }
    `}

    & > button {
        margin-top: 16px;
        max-width: 120px;
        padding: 0px;
        color: ${(props) => props.theme.colors.text.white} !important;
        & > span {
            color: ${(props) => props.theme.colors.principal.greenBase};
        }
    }
`;

export const ContainerDetailNumbers = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 0.1px solid ${(props) => props.theme.colors.disabled.gray};
    border-top: 0.1px solid ${(props) => props.theme.colors.disabled.gray};
    margin: 20px 15px;
    width: 100%;

    ${breakpoint('md')`
        margin: 24px 35px 0px 35px;
        border-bottom: none;
        &.elementos {
            flex-direction: row;
        }
    `}

    & > button {
        max-width: 120px;
        padding: 0px;
        margin-bottom: 11px;
        color: ${(props) => props.theme.colors.text.white} !important;

        & > span {
            color: ${(props) => props.theme.colors.principal.greenBase};
        }
    }
`;

export const ContentDetailNumbers = styled.div<{ isShowMore: boolean; cantToShow: number }>`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    padding: 10px 0px;

    ${breakpoint('md')`
        max-width: 605px;
        justify-content: center;
        align-items: center;
    `}

    ${(props) =>
        `
        & > div:nth-child(${props.cantToShow}n) {
            border-right: none;
        }`}

    & > div:last-child {
        border-right: none;
    }

    & > div:only-child {
        border-right: none;
        margin: 0 auto;
    }

    &:nth-child(-n) {
        justify-content: center;
    }

    ${(props) =>
        !props.isShowMore &&
        `
        &>div:nth-child(n+${props.cantToShow + 1}){
            display:none;
        }    
        `};
`;

export const ContentDetailElements = styled.div`
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    margin: 20px 0px;
    column-gap: 10px;
    ${breakpoint('md')`
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fill, 100px);
    `}
`;

export const ContentDetailNumbersBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-right: 0.5px solid rgba(197, 195, 201, 0.7);
    margin: 11px 0px;
    padding: 5px 17px;
    width: 98px;

    ${breakpoint('md')`
        padding: 5px 10px;
        width: 85px;
    `}

    p {
        font-family: ${(props) => props.theme.fonts.mon_bold};
        color: ${(props) => props.theme.colors.text.gray};
        font-size: 12px;
        line-height: 32px;
    }
    span {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        color: ${(props) => props.theme.colors.accents.base.yellow};
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 4px;
    }
`;

export const ContentDetailIncentive = styled.div`
    width: 100%;
    display: flex;
    padding-bottom: 16px;
    justify-content: center;

    .incentivos--carrito {
        padding-top: 2rem;
        width: 90%;
    }
`;

export const ContentDetailBottom = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 5px;

    ${breakpoint('md')`
        flex-direction: row;
        border-top: 0.1px solid ${(props) => props.theme.colors.principal.greenNeon};
        height: 62px;

        &.alertText {
            border-top: 0.1px solid ${(props) => props.theme.colors.alerts.orange} !important;
            opacity: 1 !important;
        }
    
        &.dangerText {
            border-top: 0.1px solid ${(props) => props.theme.colors.alerts.red} !important;        
            opacity: 1 !important;
        }
    `}
`;

export const ContentDetailBottomLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;

    ${breakpoint('md')`
        padding: 0px 20px;
        
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            top: 0;
            margin: auto 0;
            right: 0;
            background: ${(props) => props.theme.colors.principal.greenNeon}; 
            width: 1px;
            height: 40px; 
        } 
    `}

    span {
        font-size: 20px;
        color: ${(props) => props.theme.colors.principal.greenNeon};
    }
    p {
        color: ${(props) => props.theme.colors.principal.greenNeon};
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 12px;
        line-height: 20px;
        margin-left: 20px;
    }

    &.alertText {
        p,
        span {
            color: ${(props) => props.theme.colors.alerts.orange} !important;
            opacity: 1 !important;
        }
        ${breakpoint('md')`
            &:after {
                background: ${(props) => props.theme.colors.alerts.orange} !important; 
            } 
        `}
    }

    &.dangerText {
        p,
        span {
            color: ${(props) => props.theme.colors.alerts.red} !important;
            opacity: 1 !important;
        }

        ${breakpoint('md')`
            &:after {
                background: ${(props) => props.theme.colors.alerts.red} !important; 
            } 
        `}
    }
`;

export const ContentDetailBottomRight = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 40%; */
    width: 100%;
    margin-top: 18px;
    margin-bottom: 5px;
    padding: 21px 31px;
    border-radius: 0px 0px 12px 12px;
    background: ${(props) => props.theme.colors.backgrounds.gray500};

    p {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        color: ${(props) => props.theme.colors.text.gray};
        font-size: 12px;
        line-height: 16px;
    }
    span {
        margin-left: 20px;
        color: ${(props) => props.theme.colors.accents.base.yellow};
        font-family: ${(props) => props.theme.fonts.lf_bold};
        font-size: 16px;
        line-height: 16px;
    }

    ${breakpoint('md')`
        background: none;
        margin: 0;
    `}
`;

export const AlertTextMoreNumbers = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00a3ff;
    border-radius: 16px;
    margin: 0 auto;
    margin-top: 24px;
    padding: 14px 14px;

    & > span {
        font-size: 22px;
        margin-right: 20px;
    }

    & > p {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        text-align: center;

        & > b {
            font-weight: bold;
        }

        ${breakpoint('md')`
            font-size: 14px;
        `}
    }

    ${breakpoint('md')`
        width: 65%;
        max-height: 56px;
        padding: 4px 55px;
    `}
`;

export const WrapperRedirect = styled.div`
    width: 100%;
    height: 100%;

    & > div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
`;

export const ContainerDetailEvent = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    & > span {
        font-size: 16px;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        text-transform: lowercase;
        color: ${(props) => props.theme.colors.accents.base.yellow};
    }
    & > span::first-letter {
        text-transform: uppercase;
    }

    & > p {
        font-size: 14px;
        font-family: ${(props) => props.theme.fonts.lf_regular};
    }

    ${breakpoint('md')`
        margin: 0 -150px 0 250px;
    `}
`;
