import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { graphql, useStaticQuery } from 'gatsby';
import { Carousel } from '@componentsShared';
import { lotteriesServices } from '@services/lotteries';
import Viewport from 'src/shared/hooks/viewport';
import { IArrow, ILogo, IPropResult } from './types';
import {
    ArrowCustom,
    BoxLotteryImg,
    BoxLotteryName,
    BoxLotteryNumber,
    CircleNumber,
    IsAstro,
    ResultBottom,
    ResultBottomBox,
    ResultTopTitle,
    ResultTopViewAll,
    WrapperResults,
    NotResults
} from './styled';

const SampleArrowCustom = (props: IArrow) => {
    const { onClick, side } = props;
    return (
        <ArrowCustom onClick={onClick} className={side}>
            <span className="icon-angle-left"></span>
        </ArrowCustom>
    );
};

export const ResultLotteries = () => {
    const viewport = Viewport();
    moment.locale('es');

    const [resultLott, setResultLott] = useState([]);
    const [dateTitle, setDateTitle] = useState('');

    const logoLotteriesResult = useStaticQuery(graphql`
        query LogosLotteriesResult {
            allContentfulLoterias {
                nodes {
                    abreviatura
                    nombre
                    imagen {
                        file {
                            url
                            fileName
                        }
                        description
                    }
                }
            }
        }
    `);

    const arrayImagesCms: ILogo[] = logoLotteriesResult.allContentfulLoterias.nodes;

    const customSettings = {
        centerMode: viewport.mobile ? true : true,
        centerPadding: '60px',
        dots: false,
        infinite: viewport.mobile ? false : resultLott.length > 4 ? true : false,
        autoplay: viewport.mobile ? false : true,
        speed: 500,
        slidesToShow: viewport.mobile ? 1 : 4,
        slidesToScroll: 1,
        nextArrow: viewport.mobile ? false : <SampleArrowCustom side="next" />,
        prevArrow: viewport.mobile ? false : <SampleArrowCustom side="prev" />,
        initialSlide: 0,
        draggable: true,
        swipeToSlide: true,
        variableWidth: true
    };

    const fetchGetResultLotteries = async () => {
        let now = moment();
        let actual: string = now.format('DD-MM-YYYY');
        await lotteriesServices
            .getResultLotteries(actual) // '18-08-2021'
            .then((data) => {
                const {
                    data: { getResultadosTope }
                } = data;

                if (getResultadosTope.data !== null) {
                    setResultLott(getResultadosTope.data);
                    setDateTitle(getResultadosTope.data[0].fecSorteo);
                }
            })
            .catch((err) => console.log('Error fetchGetResultLoterries', err));
    };

    useEffect(() => {
        fetchGetResultLotteries();
    }, []);

    const validateDate = dateTitle !== '' ? dateTitle : moment().format('DD-MM-YYYY');
    const dateTitleFormt = moment(validateDate, 'DD-MM-YYYY').format('MMMM D,  YYYY');

    const filterImageLottery = (arr: ILogo[], param: string) => {
        return arr.find((lot) => lot.abreviatura === param);
    };

    return (
        <WrapperResults>
            <ResultTopTitle>
                <h2>Resultados loterías y sorteos</h2>
                <span>{dateTitleFormt}</span>
            </ResultTopTitle>

            {/* <ResultTopViewAll>
                <a href="/">
                    Ver todos los resultados
                    <span
                        className={
                            viewport.mobile ? 'icon-arrow-small-right' : 'icon-angle-small-right'
                        }
                    ></span>
                </a>
            </ResultTopViewAll> */}

            <ResultBottom>
                {resultLott ? (
                    <Carousel customSettings={customSettings}>
                        {resultLott.map((data: IPropResult) => {
                            const numbersArray = data.numero;
                            const abreviatura = data.siglaLoteria.toLowerCase();

                            const logo: ILogo | undefined = filterImageLottery(
                                arrayImagesCms,
                                abreviatura
                            );
                            return (
                                <ResultBottomBox
                                    key={data.ideLoteria}
                                    className={
                                        data.desLoteria == 'Astro Sol' ||
                                        data.desLoteria == 'Astro Luna'
                                            ? 'astro'
                                            : ''
                                    }
                                >
                                    {logo ? (
                                        <BoxLotteryImg
                                            src={logo.imagen.file.url}
                                            alt={logo.imagen.description}
                                        />
                                    ) : (
                                        <BoxLotteryImg
                                            src="https://c8.alamy.com/compes/kd3wf9/icono-de-rueda-de-loteria-kd3wf9.jpg"
                                            alt="logo default"
                                        />
                                    )}
                                    <BoxLotteryName>{data.desLoteria}</BoxLotteryName>
                                    <BoxLotteryNumber>
                                        <CircleNumber>{numbersArray[0]}</CircleNumber>
                                        <CircleNumber>{numbersArray[1]}</CircleNumber>
                                        <CircleNumber>{numbersArray[2]}</CircleNumber>
                                        {numbersArray[3] && (
                                            <CircleNumber>{numbersArray[3]}</CircleNumber>
                                        )}
                                    </BoxLotteryNumber>

                                    {data.desLoteria == 'Astro Sol' ||
                                    data.desLoteria == 'Astro Luna' ? (
                                        <IsAstro>{data.desSigno.toLowerCase()}</IsAstro>
                                    ) : (
                                        ''
                                    )}
                                </ResultBottomBox>
                            );
                        })}
                    </Carousel>
                ) : (
                    <NotResults>No se encontraron resultados</NotResults>
                )}
            </ResultBottom>
        </WrapperResults>
    );
};
