import React, { useState } from 'react';
import {
    ModalDenyLocation,
    ModalGana,
    ModalNotLocation
} from '@componentsShared';
import { graphql, useStaticQuery } from 'gatsby';
import { setDataStorage } from '@utils/transferData';
import { UserLocation, getCurrentLocation, hasLocation } from '@utils/location';

interface IProps {
    modalState: boolean;
    setModalState: () => void;
    setFlag?: () => void;
    showModalNotLocation?: boolean;
    updateLocation?: (location: UserLocation) => void;
    user: string;
    cartId: number;
}

export const ModalLocation = (props: IProps) => {
    const { modalState, setModalState, setFlag, updateLocation, user, cartId } = props;
    const [cityState, setCityState] = useState(true);
    const [notLocation, setNotLocation] = useState(true);
    const [denyLocation, setDenyLocation] = useState(false);

    const success = async (location: UserLocation) => {
        if (updateLocation && hasLocation(location)) {
            updateLocation(location);
        } else {
            setNotLocation(true);
            setCityState(false);
        }
    };

    const error = (err: any) => {
        setDataStorage(false, 'isLocated');
        setDataStorage(true, 'isDeny');
        setDenyLocation(true);
    };

    const handleLoc = () => {
        getCurrentLocation(user, cartId, updateLocation).then(success).catch(error);
    };

    const dataLogo = useStaticQuery(graphql`
        query modalAlowLocation {
            allContentfulModal(filter: { name: { eq: "Importante permite tu ubicacion" } }) {
                edges {
                    node {
                        icon {
                            file {
                                url
                            }
                            description
                        }
                        title
                        text
                        AditionalText
                    }
                }
            }
        }
    `);
    const {
        allContentfulModal: { edges }
    } = dataLogo;
    const { node } = edges[0];

    const showModal = () => {
        setNotLocation(!notLocation);
    };

    const showDeny = () => {
        setDenyLocation(!denyLocation);
    };

    const optionsModal = {
        logo: 'terciary',
        icon: node.icon.file.url,
        description: node.icon.description,
        title: node.title,
        text: node.text,
        boldText: node.AditionalText,
        primaryButtonText: 'Permitir',
        primaryButton: () => {
            handleLoc();
            setDataStorage(true, 'isLocated');
            sessionStorage.setItem('closeModalLocation', 'false');
            setModalState();
        },

        dimensions: {
            height: { mobile: '488px', desktop: '455px' },
            width: { desktop: '408px' }
        },
        paddingBox: { mobile: '35px 27px 35px' },
        bold: true,
        size: { desktop: '16px' },
        aditionalFunction: () => {
            sessionStorage.setItem('closeModalLocation', 'true');
        }
    };

    return (
        <>
            <ModalGana
                visibility={modalState}
                setVisibility={setModalState}
                options={optionsModal}
            />

            {notLocation && !cityState && (
                <ModalNotLocation modalState={notLocation} setModalState={showModal} />
            )}

            {denyLocation && (
                <ModalDenyLocation
                    modalState={denyLocation}
                    setModalState={() => {
                        showDeny();
                        setFlag && setFlag();
                    }}
                />
            )}
        </>
    );
};
