import { ErrorSpan } from '@componentsShared';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Container,
    SelectContainer,
    LabelText,
    IconArrow,
    SelectContainerList,
    SelectContainerOptions,
    SelectOption,
    DescriptionField,
    ContentError
} from './styled';

export interface IOption {
    value: string | number;
    text: string;
}

interface IProps {
    options: IOption[];
    placeholder?: string;
    name: string;
    required: string;
    label: string;
    defaultSelection?: IOption;
    disabled?: boolean;
}

export const Select = (props: IProps) => {
    const {
        options,
        placeholder,
        name,
        required,
        label,
        defaultSelection,
        disabled = false
    } = props;

    let optionEmpty: IOption = {
        value: 0,
        text: placeholder ?? ''
    };

    const { register, formState, getValues, setValue, clearErrors } = useFormContext();

    const [isOpen, setIsOpen] = useState(false);
    const [selection, setSelection] = useState<IOption>(optionEmpty);

    useEffect(() => {
        if (defaultSelection !== undefined) {
            setSelection(defaultSelection);
            setValue(name, defaultSelection.value);
        } else {
            setTimeout(() => {
                options.forEach((element) => {
                    if (element.value === getValues(name)) {
                        setSelection(element);
                        setValue(name, element.value);
                    }
                });
            }, 500);
        }
    }, []);

    return (
        <Container error={formState.errors[name]}>
            <DescriptionField>{label}</DescriptionField>
            <SelectContainer 
                disabled={disabled} 
                onClick={() => !disabled && setIsOpen(!isOpen)}
                selectItem={getValues(name) ? true : false}
            >
                <LabelText>{(selection.text === '' || getValues(name) === '') ? placeholder : selection.text}</LabelText>
                <IconArrow open={isOpen}> </IconArrow>
            </SelectContainer>
            <input type="hidden" id={'#' + name} {...register(name, { required: required })} />
            {formState.errors[name]?.message && (
                <ContentError>
                    <ErrorSpan text={formState.errors[name]?.message}></ErrorSpan>
                </ContentError>
            )}

            <SelectContainerList error={formState.errors[name]} state={isOpen}>
                <SelectContainerOptions
                    role="listbox"
                    activeScroll={
                        options.length > 3 ? true : placeholder && options.length > 2 ? true : false
                    }
                >
                    {/* {placeholder && (
                        <SelectOption
                            type="button"
                            onClick={() => {}}
                            key="a1"
                        >
                            {placeholder}
                        </SelectOption>
                    )} */}

                    {options.map((option, key) => (
                        <SelectOption
                            type="button"
                            onClick={() => {
                                clearErrors(name);
                                setValue(name, option.value);
                                setIsOpen(false);
                                setSelection(option);
                            }}
                            key={key}
                        >
                            {option.text}
                        </SelectOption>
                    ))}
                </SelectContainerOptions>
            </SelectContainerList>
        </Container>
    );
};
