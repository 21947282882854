import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {
    BannerContainer,
    BannerText,
    ContainerDetails,
    ContainerImage,
    IconImage,
    SectionTitle
} from './styled';
import Viewport from 'src/shared/hooks/viewport';

export type PropsContentBanner = {
    auxDataBanner: any;
};

export const AuxBanner = ({ auxDataBanner }: PropsContentBanner) => {
    const viewport = Viewport();
    const {
        allContentfulBanner: {
            nodes: [{ mobileImage, desktopImage, icon, adText, mobileText, game }]
        }
    } = auxDataBanner;

    return (
        <>
            <BannerContainer>
                {viewport.mobile && (
                    <>
                        <ContainerImage>
                            <img
                                className={`mobile  ${game === 'Resultados' ? 'results' : ''}`}
                                src={mobileImage.file.url}
                                alt={game}
                            />
                        </ContainerImage>
                        <ContainerDetails>
                            <IconImage
                                src={icon.file.url}
                                alt={icon.file.title}
                                className={game === 'Resultados' ? 'results' : ''}
                            />
                            <SectionTitle className={game === 'Resultados' ? 'results' : ''}>
                                {game}
                            </SectionTitle>
                            <BannerText className={game === 'Juegos' ? 'games' : ''}>
                                {documentToReactComponents(JSON.parse(mobileText.raw))}
                            </BannerText>
                        </ContainerDetails>
                    </>
                )}

                {!viewport.mobile && (
                    <>
                        <ContainerImage>
                            <img
                                className={`desktop  ${game === 'Resultados' ? 'results' : ''}`}
                                src={desktopImage.file.url}
                                alt={game}
                            />
                        </ContainerImage>

                        <IconImage src={icon.file.url} alt={icon.file.title} />
                        <SectionTitle>{game}</SectionTitle>

                        <BannerText>{documentToReactComponents(JSON.parse(adText.raw))}</BannerText>
                    </>
                )}
            </BannerContainer>
        </>
    );
};
