import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { hexToRGB } from 'src/shared/utils/hexToRgba';

export const ContentLotteries = styled.section`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 16px;
    background: rgba(62, 58, 71, 1);
    padding: 25px 25px;
    position: relative;
    max-width: 816px;

    ${breakpoint('xs')`
        padding: 25px 10px 29px;
    `}

    ${breakpoint('md')`
        background: none;
        justify-content: space-between;
        padding: 5px 25px 0px;
    `}
`;

export const LotteriesTop = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    width: 100%;
    h3 {
        font-size: 15px;
        line-height: 22.4px;
        text-align: center;
        width: 100%;
        font-family: ${(props) => props.theme.fonts.lf_bold};
        span {
            color: #ffe000;
        }
    }
    p {
        line-height: 19.2px;
        font-size: 12px;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        color: #f8f8f8;
        text-align: center;
        margin-top: 10px;
    }

    ${breakpoint('md')`
        margin-left: 13px;
        width: 100%;
        align-items: flex-start;
        h3 {
            text-align: left;
            font-size: 14px;
        }
        p {
            text-align: left;
            width: 80%;
        }
    `}
    ${breakpoint('lg')`
        width: 48%;
        align-items: flex-start;
        h3 {
            text-align: left;
            font-size: 14px;
        }
        p {
            text-align: left;
            width: 80%;
        }
    `}
`;

export const LotteriesSearch = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 320px;
    width: 100%;
    padding: 5px 0;
    margin: 12px auto;

    ${breakpoint('md')`
        width: 100%;
        margin: 0px 0 12px;
    `}

    ${breakpoint('lg')`
        width: 50%;
        margin: 0px 0 12px;
    `}
`;

export const FormSearch = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    input {
        width: 300px;
        border: 1px solid #0df96c;
        padding: 0 10px 0 45px;
        width: 100%;
        height: 56px;
        position: relative;
        background: #3e3a47;
        font-size: 16px;
        line-height: 24px;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        border-radius: 16px;
        outline: none;
        color: #c5c3c9;
    }
    span {
        left: 18px;
        color: ${(props) => props.theme.colors.principal.greenNeon};
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        font-size: 18px;
    }
`;

export const LotteriesBottom = styled.div`
    display: grid;
    /* grid-template-columns: repeat(2, 1fr);
    justify-items: center; */
    grid-template-columns: repeat(3,90px);
    justify-content: center;
    justify-items: center;
    margin-top: 10px;
    gap: 12px;
    width: 100%;
    background: rgba(62, 58, 71, 1);
    position: relative;
    z-index: 5;

    ${breakpoint('xs')`
         grid-template-columns: repeat(auto-fill,90px);
    `}

    ${breakpoint('md')`
        grid-template-columns: repeat(3, 1fr);
        margin: 30px auto 0;
        gap: 8px;
        max-width: 600px;
        background: #1D1828;
    `}


    ${breakpoint('lg')`
        grid-template-columns: repeat(6, 1fr);
    `}
`;

export const NotFoundLotteries = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    color: ${(props) => props.theme.colors.alerts.yellow};
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 12px;
    line-height: 20px;
`;

export const BoxRandomIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    height: 77%;
    border: 1px solid ${(props) => props.theme.colors.principal.greenBase};
    border-radius: 16px;
`;

export const IconRandom = styled.span`
    color: ${(props) => props.theme.colors.principal.greenBase};
    font-size: 32px;
`;

export const BoxRandomTitle = styled.h3`
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-family: ${(props) => props.theme.fonts.lf_bold};
    color: #f8f8f8;
    font-size: 12px;

    text-transform: capitalize;
    height: 22%;
    width: 100%;
    margin-top: 4px;
`;

export const BoxRandom = styled.div`
    display: flex;
    flex-direction: column;
    width: 98%;
    max-width: 90px;
    height: 117px;
    border: 1px solid ${(props) => props.theme.colors.principal.greenBase};
    border-radius: 16px;
    align-content: flex-start;
    justify-content: space-around;
    padding: 8px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:active {
        background: ${(props) => props.theme.colors.principal.greenBase};
        ${BoxRandomIcon} {
            border: 1px solid rgba(62, 58, 71, 1);
        }
        ${IconRandom} {
            color: rgba(62, 58, 71, 1);
        }
        ${BoxRandomTitle} {
            color: rgba(62, 58, 71, 1);
        }
    }
    ${breakpoint('md')`
        height: 120px;
    `}
`;

export const ContentBtns = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 40px 0 20px;
    padding: 5px 10px;
    width: 100%;
    & > button {
        font-size: 16px;
        margin-right: 0;
    }
    ${breakpoint('md')`
        // padding: 5px 73px;
        padding: 5px 0;
        margin: 5px 0 20px;
        justify-content: flex-start;
        
    `}
`;

export const BtnAccept = styled.button`
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-decoration: none;
    padding: 12px 24px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    font-size: 18px;
    text-transform: capitalize;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    color: #f8f8f8;
    background: linear-gradient(90deg, rgba(0, 159, 92, 1) 0%, rgba(13, 249, 108, 1) 100%);
    &:hover {
        background: linear-gradient(90deg, rgba(0, 159, 92, 1) 0%, rgba(13, 249, 108, 1) 100%);
        box-shadow: 0 0 6px #0df96c;
    }
    &:active {
        background: #009f5c;
    }
`;

export const BtnCancel = styled.button`
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-decoration: none;
    padding: 10px 22px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    font-size: 18px;
    text-transform: capitalize;
    border-radius: 16px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    color: #f8f8f8;
    background: none;
    border: 1px solid ${(props) => props.theme.colors.principal.greenNeon};
    margin-right: 25px;
    &:hover {
        border: 1px solid transparent;
        background: ${(props) => props.theme.colors.principal.greenNeon};
        box-shadow: 0px 0px 4px ${(props) => props.theme.colors.principal.greenNeon},
            0px 0px 10px ${(props) => hexToRGB(props.theme.colors.principal.greenNeon)};
    }
    &:active {
        background: ${(props) => props.theme.colors.principal.darkGreen};
    }

    a {
        text-decoration: none;
    }
`;

export const BoxRandomNumber = styled.div`
    width: 100%;
    max-width: 270px;
    display: flex;
    visibility: hidden;
    flex-direction: column;
    padding: 20px 0 5px;
    transition: all 0.8s ease;
    position: relative;
    margin-top: -170px;
    position: relative;
    z-index: 3;
    &.open {
        visibility: visible;
        margin-top: 30px;
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        background: #fff;
        height: 1px;
        width: 100%;
    }
    h2 {
        color: ${(props) => props.theme.colors.accents.base.yellow};
        font-family: ${(props) => props.theme.fonts.lf_bold};
        font-size: 14px;
        line-height: 32px;
        margin: 0;
    }
    p {
        color: #fff;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 14px;
        margin: 0;
    }
    ${breakpoint('md')`
        margin: -250px 50px 0;
        &.open {
           margin: 40px 50px 0;
        }
        p {
            line-height: 18px;
        }
    `}
`;

export const NumberRandom = styled.div`
    border: 1px solid #1d1828;
    border-radius: 30px;
    max-width: 180px;
    width: 100%;
    margin: 30px auto 0;
    display: flex;
    overflow: hidden;
    button {
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #1d1828;
        border: none;
        span {
            font-size: 14px;
            color: ${(props) => props.theme.colors.principal.greenNeon};
            font-weight: 800;
        }
        &[disabled] {
            span {
                color: #77847c;
            }
        }
    }
    h3 {
        width: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        font-size: 28px;
        font-family: ${(props) => props.theme.fonts.lf_bold};
    }
    ${breakpoint('md')`
        background: #1D1828;
        border: 1px solid rgba(62,58,71,1);
        margin: 30px 0 25px;
        button {
            background: rgba(62,58,71,1);
        }
    `}
`;
