import React from 'react';
import { SelectDate } from '@componentsShared';
import { useFormContext } from 'react-hook-form';
import { DateContainer } from './styled';
import { TMonth, TNameDate } from './types';

const months: TMonth[] = [
    { value: '1', text: 'En.' },
    { value: '2', text: 'Feb.' },
    { value: '3', text: 'Mar.' },
    { value: '4', text: 'Abr.' },
    { value: '5', text: 'May.' },
    { value: '6', text: 'Jun.' },
    { value: '7', text: 'Jul.' },
    { value: '8', text: 'Ag.' },
    { value: '9', text: 'Sept.' },
    { value: '10', text: 'Oct.' },
    { value: '11', text: 'Nov.' },
    { value: '12', text: 'Dic.' }
];

export const DatePicker = ({
    nameDay,
    nameMonth,
    nameYear,
    startDate,
    isInputExpedition
}: TNameDate) => {
    const { register, getValues } = useFormContext();

    const handleDate = () => {
        let day = getValues(nameDay);
        let year = getValues(nameYear);
        let month = getValues(nameMonth);

        if (day !== '' && year !== '' && month !== '') {
            return false;
        } else {
            return true;
        }
    };

    const rangeYear = () => {
        const max = isInputExpedition ? new Date().getFullYear() : new Date().getFullYear() - 18;
        const value = startDate ? startDate : 102;

        const min = max - value;
        const allYears = [];

        for (let i = max; i >= min; i--) {
            allYears.push({ value: i, text: i });
        }
        if (isInputExpedition) {
            allYears.pop();
        }
        return allYears;
    };

    const years: any = rangeYear();

    const rangeMonthExpedition = () => {
        let eYear = getValues(nameYear);
        let monthsExpedition;
        if (eYear === new Date().getFullYear()) {
            monthsExpedition = months.slice(0, new Date().getMonth() + 1);
        } else {
            monthsExpedition = months;
        }
        return monthsExpedition;
    };

    const eMonths: any = rangeMonthExpedition();

    const rangeDay = () => {
        let year = getValues(nameYear);
        let month = getValues(nameMonth);
        const allDays = [];

        if (year === new Date().getFullYear() - 18 && month == new Date().getMonth() + 1) {
            for (let i = 1; i <= new Date().getDate(); i++) {
                allDays.push({ value: i, text: i });
            }
            return allDays;
        } else {
            for (let i = 1; i <= 31; i++) {
                allDays.push({ value: i, text: i });
            }
        }

        return allDays;
    };

    const days: any = rangeDay();

    const rangeDayExpedition = () => {
        let eYear = getValues(nameYear);
        let eMonth = getValues(nameMonth);
        let daysExpedition;

        if (eYear === new Date().getFullYear() && eMonth == new Date().getMonth() + 1) {
            const arrDays = [];
            for (let i = 1; i <= new Date().getDate(); i++) {
                arrDays.push({ value: i, text: i });
            }
            return arrDays;
        } else {
            daysExpedition = days;
        }
        return daysExpedition;
    };

    const eDays: any = rangeDayExpedition();

    return (
        <DateContainer>
            <input type="hidden" {...register('datePicker', { required: handleDate() })} />
            <SelectDate
                label=""
                name={nameYear}
                options={years}
                placeholder="Año"
                required="fecha requerida"
            />
            <SelectDate
                label=""
                name={nameMonth}
                options={isInputExpedition ? eMonths : months}
                placeholder="Mes"
            />
            <SelectDate
                label=""
                name={nameDay}
                options={isInputExpedition ? eDays : days}
                placeholder="Día"
            />
        </DateContainer>
    );
};
