import { BC_SIGLA_3_CIFRAS, BC_SIGLA_4_CIFRAS, BC_STORAGE_DATA_GAME } from '@constants/bonoConstants';
import { lotteriesServices } from '@services/lotteries';
import { systemServices } from '@services/system';
import { setSessionEncrypt } from '@utils/security';
import siteUrl from '@utils/siteUrl';
import moment from 'moment';
import { IBets } from 'src/shared/components/molecules/cartBodyDetail/types';
import { IButt, IDataGame, ILottery } from 'src/shared/context/bonoContext';

export type Bet = {
    number: string;
    numberOne: string;
    numberTwo: string;
    numberThree: string;
    numberFour: string;
    collapse: boolean;
    errorDerecho: string;
};

export type Lottery = {
    ideLoteria: number;
    desLoteria: string;
    abreviatura: string;
    horaCierre: string;
    numCifras: number;
    sorteo: string;
    numeroFavorito?: string;
    disponible?: boolean;
};

export type Errors = {
    stepOne: string;
    stepTwo: string;
    stepThree: {
        numbers: string;
        bets: string;
        modalities: string;
    };
};

export type Butt = {
    name: string;
    date: string;
    lotteries: Lottery[];
    lotterySelect?: Lottery;
    errors: Errors;
    valueNeto: number;
    valueTotal: number;
    bet: Bet;
    collapse: boolean;
    isStored?: boolean;
    id: number;
    saveChanges?: boolean;
    sigla: string;
    cifra: number;
    isValid?: boolean;
    idBetCart: string;
};

export type DataGame = {
    betValue: number;
    valueTotal?: number;
    valueToWin3C?: number;
    valueToWin4C?: number;
    totalPoints: number;
    points: number;
    betIva: number;
    butts: Butt[];
};

export const editBono = async (bet: IBets) => {
    let betBono: Bet = {
        collapse: false,
        errorDerecho: '',
        number: bet.betNumbers[0].numbers.join(''),
        numberOne: '',
        numberTwo: '',
        numberThree: '',
        numberFour: ''
    };

    if (bet.sigla === BC_SIGLA_4_CIFRAS) {
        betBono = {
            collapse: false,
            errorDerecho: '',
            number: bet.betNumbers[0].numbers.join(''),
            numberOne: getNumber(bet.betNumbers[0].numbers[0]),
            numberTwo: getNumber(bet.betNumbers[0].numbers[1]),
            numberThree: getNumber(bet.betNumbers[0].numbers[2]),
            numberFour: getNumber(bet.betNumbers[0].numbers[3])
        };
    } else {
        betBono = {
            collapse: false,
            errorDerecho: '',
            number: bet.betNumbers[0].numbers.join(''),
            numberOne: getNumber(bet.betNumbers[0].numbers[0]),
            numberTwo: getNumber(bet.betNumbers[0].numbers[1]),
            numberThree: getNumber(bet.betNumbers[0].numbers[2]),
            numberFour: ''
        };
    }

    let betValue = 0;
    await systemServices
        .getConfisys(['VALOR_COLILLA_BONO'])
        .then((data: any) => {
            betValue = isNaN(parseInt(data.data.getConfisys[0].valorStr))
                ? 3000
                : parseInt(data.data.getConfisys[0].valorStr);
        })
        .catch((err) => {
            console.log(err);
        });

    const dateSelected = moment.utc(bet.date_sorteo, 'YYYY-MM-DD');
    const dateLotteries = dateSelected.format('DD-MM-YYYY');

    const butt: Butt = {
        bet: betBono,
        cifra: bet.sigla === BC_SIGLA_3_CIFRAS? 3 : 4,
        collapse: false,
        date: dateLotteries,
        errors: {
            stepOne: '',
            stepTwo: '',
            stepThree: {
                bets: '',
                modalities: '',
                numbers: ''
            }
        },
        id: bet.numero_colilla || 1,
        lotteries: [] as Lottery[],
        name: bet.nombre_colilla || 'Apuesta 1',
        sigla: bet.sigla,
        valueNeto: 0,
        valueTotal: betValue,
        isStored: false,
        isValid: true,
        lotterySelect: {} as Lottery,
        saveChanges: false,
        idBetCart: bet.id
    };

    const dataGame: DataGame = {
        betIva: 0,
        betValue: 0,
        butts: [butt],
        points: 0,
        totalPoints: 0,
        valueTotal: 0,
        valueToWin3C: 0,
        valueToWin4C: 0 
    }

    await lotteriesServices.getLotteries(dateLotteries, bet.sigla)
    .then((data) => {
        const planPremios = data.data.getGameInfo.planPremios;
        if(planPremios) {
            dataGame.betIva = planPremios[0].iva;
            dataGame.points = planPremios[0].puntosAGanar;
            dataGame.totalPoints = dataGame.points * dataGame.butts.length;
        }

        const lotteries: Lottery[] = parsedLotteries(data.data.getGameInfo.loterias);
        let lottery = lotteries.find(lot => {
            return (lot.ideLoteria == bet.loteries[0].loterieId);
        }) || {} as Lottery;

        dataGame.butts[0].lotterySelect = lottery;

        dataGame.betValue = betValue;
        dataGame.valueTotal = betValue * dataGame.butts.length;

        if(bet.cifra === 3) {
            dataGame.valueToWin3C = planPremios[0].premioBruto;
        } else {
            dataGame.valueToWin4C = planPremios[0].premioBruto;
        }
    })
    .catch((err) => {
        console.log(err);
    });

    setSessionEncrypt(dataGame, BC_STORAGE_DATA_GAME);
    window.location.href = siteUrl + '/juegos/bono/';
};

const getNumber = (num: number) => {
    if (!num) {
        return '0';
    }
    return num.toString();
};

const parsedLotteries = (lotteries: Lottery[]): Lottery[] => {
    const newLotteriesList: Lottery[] = lotteries.map((lott) => {
        lott.sorteo = lott.sorteo.toString();
        lott.abreviatura = lott.abreviatura.toLowerCase();
        lott.disponible = true;

        return lott;
    });
    return newLotteriesList;
};
