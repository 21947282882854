import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const BoxLotteryImg = styled.div`
    display: flex;
    width: 100%;
    margin: 0 auto;
    height: 77%;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 0px 9px rgba(0, 212, 85, 0.25);
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: center;
        background: #f8f8f8;
    }
    
    &.activeTimer{
        box-shadow:none;
        border-radius: 16px;
    }

    &.activeTimer{
        box-shadow:none;
        border-radius: 16px;
    }
`;

export const BoxLotteryTitle = styled.h3`
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-family: ${(props) => props.theme.fonts.lf_bold};
    color: #f8f8f8;
    font-size: 12px;
    line-height: 12px;
    text-transform: capitalize;
    height: 23%;
    width: 100%;
    margin-top: 6px;
`;

export const ButtonDelete = styled.div`
    width: 100%;
    position: absolute;
    top: -22px;
    display: flex;
    justify-content: center;
    z-index: 3;

    button {
        width: 25px;
        height: 25px;
        background: ${(props) => props.theme.colors.principal.greenBase};
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            font-size: 18px;
        }
    }
`;

export const BoxHoverTimeClose = styled.div`
    width: 100%;
    max-height: 94px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 8px 0px;
    border-bottom: 1px solid ${(props) => props.theme.colors.backgrounds.gray600};
    p,
    span {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        color: ${(props) => props.theme.colors.accents.base.yellow};
    }

    p {
        font-size: 12px;
    }
    span {
        font-size: 14px;
        font-family: ${(props) => props.theme.fonts.lf_bold};
    }
`;

export const BoxHoverFavorite = styled.div`
    width: 100%;
    max-height: 94px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 8px 0px;

    p {
        font-size: 12px;
    }
    span {
        font-size: 14px;
        letter-spacing: 1px;
    }
`;

export const BoxHover = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    padding: 10px 5px;
    background: ${(props) => props.theme.colors.backgrounds.gray450};
    border-radius: 16px;
    font-family: ${(props) => props.theme.fonts.lf_bold};
    h4 {
        width: 100%;
        height: 40px;
        padding: 5px 0px;
        text-align: center;
        border-bottom: 1px solid ${(props) => props.theme.colors.text.gray};
        font-size: 11px;
        text-transform: capitalize;
    }
    &.close {
        display: none !important;
    }
    &.open {
        display: flex;
    }
`;

export const LotteryTimerAct = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 12px;
    font-family: ${(props) => props.theme.fonts.lf_bold};
    p {
        color: ${(props) => props.theme.colors.accents.base.yellow};
        font-size: 14px;
    }
    span {
        font-size: 14px;
        color: ${(props) => props.theme.colors.accents.base.yellow};
        margin-top: 5px;
    }
`;

export const BoxLottery = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 98%;
    min-width: 90px;
    max-width: 90px;
    height: 117px;
    border: 1px solid ${(props) => props.theme.colors.principal.greenBase};
    border-radius: 16px;
    align-content: flex-start;
    justify-content: space-around;
    padding: 8px 8px 6px;
    transition: all 0.3s ease-in-out;
    position: relative;
    cursor: pointer;
    background: #272231;
    box-shadow: 0 0 9px rgba(0, 212, 85, 0.25);

    &.lotteryActive {
        background: ${(props) => props.theme.colors.principal.greenBase};
        &.activeTimer {
            box-shadow: none;
            border: none;
            ${LotteryTimerAct} {
                background: none;
            }
        }
        ${BoxLotteryImg} {
            background: ${(props) => props.theme.colors.principal.darkGreen};
            img {
                opacity: 0;
            }
        }
        ${BoxLotteryTitle} {
            color: rgba(62, 58, 71, 1);
        }
    }
    &.activeTimer {
        border: 1px solid ${(props) => props.theme.colors.accents.base.yellow};
        box-shadow: 1px 0 12px ${(props) => props.theme.colors.accents.base.yellow};
    }

    &.deleteCard {
        margin-top: 22px;
    }

    ${breakpoint('md')`
        height: 120px;
        &:hover{
            ${BoxHover}{
                display: flex !important;
            }
        }
    `}

    &.lotteryAlert {
        box-shadow: 0px 0px 9px #ff7a00 !important;
        border-color: #ff7a00 !important;
        opacity: 1 !important;
    }

    &.alert {
        opacity: 0.3;
    }

    &.lotteryNoActive {
        box-shadow: none;
        border-color: ${(props) => props.theme.colors.text.white};
    }

    &.up {
        z-index: 4;
    }


    &.disabled {
        opacity: .3;
        cursor: auto;

        &:hover{
            ${BoxHover}{
                display: none !important;
            }
        }
    }

    &.lotteryCart {
        cursor: auto;

        &:hover{
            ${BoxHover}{
                display: none !important;
            }
        }
    }

`;

export const ContentFavoritesNumber = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    h2 {
        font-size: 12px;
        line-height: 12px;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        margin-bottom: 5px;
    }
    h3 {
        font-size: 14px;
        font-family: ${(props) => props.theme.fonts.lf_bold};
        letter-spacing: 1px;
        margin-top: 5px;
        text-align: center;
    }
`;
