import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import Viewport from 'src/shared/hooks/viewport';

export const PreviewYoutube = (props: {
    url: string | null;
    height?: { mobile: string; desktop: string };
    width?: { mobile: string; desktop: string };
    playerVars?: object
}) => {
    const {
        url,
        height = { desktop: '360', mobile: '295' },
        width = { desktop: '360', mobile: '360' },
        playerVars
    } = props;

    const [idVideo, setIdVideo] = useState('');

    const viewport = Viewport();

    const [optionsVideo, setOptionsVideo] = useState<object>({
        width: '100%',
        height: '365'
    });

    useEffect(() => {
        setOptionsVideo({
            ...optionsVideo,
            width: viewport.mobile ? width.mobile : width.desktop,
            height: viewport.mobile ? height.mobile : height.desktop
        });
    }, [viewport]);

    useEffect(() => {
        if (url) {
            if (url.includes('youtube.com')) {
                let id = url.split('=')[1].split('&')[0];
                setIdVideo(id);
            } else if (url.includes('youtu.be')) {
                let id = url.split('/')[3].split('?')[0];
                setIdVideo(id);
            }
        }

        if(playerVars){
           setOptionsVideo({...optionsVideo, playerVars: playerVars})
        }
    }, []);

    return <>{idVideo && <YouTube videoId={idVideo} opts={optionsVideo} />}</>;
};
