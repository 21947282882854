import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const ContentLogo = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 0;
    width: 100%;
`;

export const ContentBack = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const LinkBack = styled.a`
    display: flex;
    align-items: center;
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 12px;
    line-height: 12px;
    color: #fff;
    text-decoration: none;
    span {
        margin-right: 3px;
        color: ${(props) => props.theme.colors.principal.greenBase};
        font-size: 24px;
    }
`;

export const ImagesLogo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 232px;
    margin: 25px auto 30px;
    background: #fff;
    padding: 20px 10px;
    border-radius: 12px;

    img {
        width: 167px;
        height: 60px;

        ${breakpoint('sm')`
            height: 55px;
        `}
        ${breakpoint('md')`
            height: 60px;
        `}
    }

    ${breakpoint('sm')`
        width: 100%;
        background: #fff;
        padding: 12px 0;
        border-radius: 12px;
        margin: 25px 0 20px;
    `}

    ${breakpoint('md')`
        max-width: 816px;
        background: #fff;
        padding: 12px 0;
        border-radius: 12px;
        margin: 25px 0 20px;
    `}

    ${breakpoint('lg')`
        max-width: 816px;
        background: #fff;
        padding: 12px 0;
        border-radius: 12px;
        margin: 25px 0 30px;
        img {
            width: 239px;
            height: 86px;
        }
    `}

    ${breakpoint('lg')`
        margin: 25px 0 30px;
    `}
`;
