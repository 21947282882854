import { graphql, useStaticQuery } from 'gatsby';


export const cfConfigurationService = {

    getChatbotUrl:  (): string => {
        const {
            contentfulConfiguraciones: { chatbotUrl }
        } = useStaticQuery(graphql`
            query ChatBotConfig {
                contentfulConfiguraciones {
                    chatbotUrl
                }
            }
        `);
        return chatbotUrl;
    }

};
