import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

export const ValueToWin = styled.span`
    width: 45%;
    color:${props => props.theme.colors.accents.base.yellow};
    font-family: ${props => props.theme.fonts.lf_bold};
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;

    ${breakpoint('md')`
        width: 100%;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 38px;
        margin-top: 10px;
        color: #FFE000;
        text-shadow: 0px 0px 18px rgba(255, 224, 0, 0.6);
    `}
`


export const Coins = styled.div`
    display: none;
    /* margin: 0 auto; */

    ${breakpoint('sm')`
        width: 100%;
        min-width: 450px;
        max-height: 158px;
        position: absolute;
        left: -40px;
        // top: 60%;
        display: flex;
        justify-content: space-between;
    `}
`

export const ImgAnimate1 = styled.img`
    height: 158px;
    width: 237px;   
    transform: scaleX(-1);
    z-index: -1;
`

export const ImgAnimate2 = styled.img`
    height: 158px;
    width: 237px;   
    z-index: -1;
`