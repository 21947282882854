import React from 'react';
import { ModalGana } from '@componentsShared';

type IInfoModal = {
    checksum: string;
    contrato: string;
    version: string;
    __typename: string;
}

interface IProps {
    modalState: boolean;
    setModalState: () => void;
    infoModal: IInfoModal;
}



const ModalHash = (props: IProps) => {
    const { modalState, setModalState,infoModal } = props;

    const optionsModal = {
        logo: 'terciary',
        icon: '',
        description: '',
        title: infoModal.contrato,
        text: 'Checksum: '+infoModal.checksum,
        boldText: 'AEBD Version: '+infoModal.version,
        primaryButtonText: 'Aceptar',
        primaryButton: () => {
            setModalState()
        },

        dimensions: {
            height: { mobile: '370px', desktop: '370px' },
            width: { desktop: '408px' }
        },
        paddingBox: { mobile: '35px 27px 35px' },
        bold: true,
        size: { desktop: '16px' },
        wordBreak: true
    };

    return (
        <>
            <ModalGana
                visibility={modalState}
                setVisibility={setModalState}
                options={optionsModal}
            />
        </>
    );
};

export default ModalHash;
