import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

`

export const LabelCustom = styled.label`
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 16px;
    display: flex;
    align-items: center;
    position: relative;

    &::before{
        font-family: "icomoon";
        content: "\\e946";
        margin-right: 8px;
    }
`

export const RadioButtonCustom = styled.input`
    display: none;
    transition: all 1s;
    &:checked + ${LabelCustom}:before{ 
        font-family: "icomoon";
        content: "\\ea63";
        color: ${(props) => props.theme.colors.principal.darkGreen}; 
    } 

    &:hover + ${LabelCustom}:before{
        font-family: "icomoon";
        content: "\\ea63"; 
        /* color: ${(props) => props.theme.colors.accents.neon.blue};  */
    }

    &:disabled + ${LabelCustom} {
        color: ${(props) => props.theme.colors.disabled.gray};
        cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
        box-shadow: none;
        border-radius: 50%;
        background: ${(props) => props.theme.colors.disabled.gray};
    }

    &.error + ${LabelCustom}:before  {
        color:${(props) => props.theme.colors.accents.dark.red} !important;
    }
`

