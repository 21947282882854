import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Container, LabelCustom, RadioButtonCustom } from './styled';
import { ErrorSpan } from '@componentsShared';
import { makeId } from 'src/shared/utils/makeId';

interface IProps {
    name: string;
    label: string;
    registerCustom?: any;
    value: string;
    checked?: boolean;
    defaultChecked?: boolean;
    disabled?: boolean;
    onClick: () => void;
}

export const GenreRadioButton = (props: IProps) => {
    const { checked, defaultChecked, disabled, name, onClick, label, value } = props;
    const [isChecked, setIsChecked] = useState(checked);

    const {
        register,
        formState: { errors },
        setValue
    } = useFormContext();

    const id = makeId(4);

    return (
        <Container>
            <RadioButtonCustom
                disabled={disabled}
                type="radio"
                {...register(name, { required: true })}
                id={'#' + name + id}
                value={value}
                onClick={() => setValue(name, value)}
                name={name}
                checked={isChecked}
                defaultChecked={defaultChecked}
            />
            <LabelCustom htmlFor={'#' + name + id}>{label}</LabelCustom>
            {errors.name?.message && <ErrorSpan text={errors.name?.message}></ErrorSpan>}
        </Container>
    );
};
