import React from 'react';
import { Button, Redirect } from '@componentsShared';
import { CartEmpty } from './styled';

type TEmptyCart = {
    color?: string;
};

export const EmptyCart = ({ color }: TEmptyCart) => {
    return (
        <>
            <CartEmpty color={color}>
                <span className="icon-shopping-cart"></span>
                <p>Tu carrito está vacío</p>
                <p className="cartText">
                    Todavía no realizas tus apuestas ¡Es momento de probar tu suerte!
                </p>
                <Redirect url="juegos">
                    <Button text="Quiero Jugar" onClick={() => {}} submit={true} />
                </Redirect>
            </CartEmpty>
        </>
    );
};
