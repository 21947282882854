export enum ConfisysPayments {
    PAYVALIDA = "HABILITA_PAGOS_PAYVALIDA",
    PLACETOPAY = "HABILITA_PAGOS_PLACETOPAY"
}

export enum ConfisysStatus {
    ACTIVO = "S",
    INACTIVO = "N"
}

export interface Confisys {
    nombre: ConfisysPayments;
    valorStr: ConfisysStatus;
}

export type Payments = {
    [key in ConfisysPayments]: boolean;
};

export const getPaymentsInactive = () : Payments => {
    return {
        [ConfisysPayments.PAYVALIDA]: false,
        [ConfisysPayments.PLACETOPAY]: false
    };
}

export const getPaymentsActive = () : Payments => {
    return {
        [ConfisysPayments.PAYVALIDA]: true,
        [ConfisysPayments.PLACETOPAY]: true
    };
}

const getStatus = (confisys: Confisys): boolean => {
    return confisys.valorStr.toUpperCase().trim().includes(ConfisysStatus.ACTIVO);
}

export const getPayments = (listConfisys: Confisys[]) : Payments =>  {
    const payments = getPaymentsInactive();

    listConfisys.map(confisys => {
        payments[confisys.nombre] = getStatus(confisys);
    });

    return payments;
}

export const isActivePayment = (payments: Payments, confisys: ConfisysPayments) => {
    return payments[confisys];
};

