import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const WrapperBoxes = styled.section`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: 1232px;
    padding: 20px 0 40px;

    ${breakpoint('md')`
        width: 95%;
        justify-content: space-between;
    `}
`;
