import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const ContainerStepTwo = styled.div`
    margin-top: 50px;
    max-width: 100%;
`;

export const ContainerTextAcumula = styled.div`
    width: 75%;
    margin: 0 auto 20px;
    display: flex;
    flex-direction: column;

    ${breakpoint('md')`
        flex-direction: row;
        justify-content: space-around;
    `}

    div:first-child {
        ${breakpoint('md')`
            margin-right: 34px;
        `}
    }

    div {
        margin-bottom: 26px;

        ${breakpoint('md')`
            width: 400px;
        `}

        h3 {
            text-align: center;
            margin-bottom: 13px;
            font-size: 16px;

            ${breakpoint('md')`
                text-align: left;
                font-size: 20px;
            `};

            b {
                color: ${(props) => props.theme.colors.accents.base.yellow};
            }
        }

        p {
            font-size: 14px;
            font-family: ${(props) => props.theme.fonts.lf_regular};
            text-align: center;
            line-height: 16.8px;

            ${breakpoint('md')`
                font-size: 16px;
                line-height: 19.2px;
                text-align: justify;
        `};

            b {
                font-weight: bold;
            }

            i {
                color: ${(props) => props.theme.colors.accents.base.yellow};
            }
        }
    }
`;

export const ContainerSliderAcumula = styled.div`
    .slick-slider {
        width: 100%;
        height: 670px;

        ${breakpoint('md')`
            height: 530px;
        `}

        .slick-list {
            width: 100%;
            margin: 0 auto;

            .slick-track {
                padding: 62px 0px;
                width: 80%;

                .slick-active {
                    margin-left: 0px;
                }
            }
        }

        .slick-dots {
            text-align: center;
            width: auto;
            left: 0;
            right: 0;
            li {
                width: 15px;
                height: 15px;
                &.slick-active {
                    button {
                        &:before {
                            font-size: 12px;
                            line-height: 14px;
                            width: 11px;
                            height: 11px;
                            color: transparent;
                            box-shadow: 0px 0px 6px 1.5px
                                ${(props) => props.theme.colors.alerts.yellow};
                            border: 2px solid ${(props) => props.theme.colors.alerts.yellow};
                            top: -2px;
                        }
                    }
                }
                button {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:before {
                        font-size: 10px;
                        line-height: 12px;
                        color: transparent;
                        border: 2px solid ${(props) => props.theme.colors.disabled.gray};
                        border-radius: 50%;
                        width: 7px;
                        height: 7px;
                        opacity: 1;
                    }
                }
            }
        }
    }
`;
