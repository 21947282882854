import React, {useContext} from "react";
import { IBets } from "../cartBodyDetail/types";
import { editBet } from "@utils/EditCarts";
import iconEdit from 'src/shared/assets/images/edit.svg';
import { ModalGana } from "@componentsShared";

interface IProps {
    modalState: boolean;
    setModalState: () => void;
    bet: IBets;
    logo: {
        file: {
            url: string;
            fileName: string;
        }
    };
    idCart: number;
}

export const ModalEditBet = (props: IProps) => {
    const { modalState, setModalState, bet, logo, idCart } = props;

    const optionsModal = {
        logo: 'Object',
        data: logo,
        icon: iconEdit,
        title: '¿Quieres modificar la apuesta?',
        text: 'Este movimiento no podrá deshacerse.',
        primaryButtonText: 'Confirmar',
        primaryButton: () => {
            editBet(bet.sigla, bet, idCart);
            setModalState();
        },
        secondaryButtonText: 'Cancelar',
        secondaryButton: () => {
            setModalState();
        },
        dimensions: { height: { mobile: '429px', desktop: '410px' }, width: { desktop: '535px' } },
        titleFonts: true
    };

    return(
        <ModalGana
            options={optionsModal}
            setVisibility={setModalState}
            visibility={modalState}
        />
    );
};