import { carServices } from "@services/car";

let callBacksSuccess: any[] = [];
let callBacksError: any[] = [];
let timeGetCart: any;

const consultarCarrito = (token: string, tipoDocumento: string, usuario: string) => {
    carServices.getCar(token, tipoDocumento, usuario).then(data => {
        callBacksSuccess.forEach(callS => {
            callS(data);
        });
    }).catch(e => {
        callBacksError.forEach(callE => {
            callE(e);
        })
    }).finally(() => {
        callBacksSuccess = [];
        callBacksError = [];
    })
}

export const getCart = (token: string, tipoDocumento: string, usuario: string, callBackSuccess: any, callBackError: any) => {
    callBacksSuccess.push(callBackSuccess);
    callBacksError.push(callBackError);
    clearTimeout(timeGetCart);
    timeGetCart = setTimeout(() => {
        consultarCarrito(token, tipoDocumento, usuario);
    }, 500);
}