import styled from 'styled-components';

type IPropsContainer = {
    error: boolean;
};

export const Container = styled.div<IPropsContainer>`
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    ${(props) => (props.error ? `max-height: 120px;` : `max-height: 110px;`)}
`;

export const ContentError = styled.div`
    /* margin-bottom: 10px; */
`;

export const DescriptionField = styled.label`
    margin-bottom: 8px;
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
`;

type IPropsSelectContainer = {
    disabled: boolean;
    selectItem: boolean;
};
export const SelectContainer = styled.div<IPropsSelectContainer>`
    padding: 16px;
    position: relative;
    background: ${(props) => props.theme.colors.backgrounds.gray400};
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    cursor: pointer;
    margin-bottom: 5px;

    ${(props) => props.disabled && `background: #272231;`}

    label {
        color: ${({selectItem}) => selectItem ? '#F8F8F8' : '#747474'};
    }

    label, span::after {
        ${(props) => props.disabled === true && ` color: #3E3A47 !important; cursor: no-drop; `}
    }
`;

export const LabelText = styled.label`
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 16px;
`;

interface IIconArrow {
    open: boolean;
}

export const IconArrow = styled.span<IIconArrow>`
    &::after {
        font-family: ${(props) => props.theme.fonts.icons};
        content: ${(props) => (props.open ? "'\\e911'" : "'\\e90e'")};
        transition: all 1s;
        color: ${(props) => props.theme.colors.principal.greenNeon};
        font-size: 20px;
    }
`;

interface ISelectContainerList {
    state: boolean;
    error: boolean;
}

export const SelectContainerList = styled.div<ISelectContainerList>`
    width: 100%;
    margin-top: ${(props) => (props.error ? '-23px' : '0px')};
    border-radius: 16px;
    position: relative;
    visibility: hidden;
    opacity: 0;
    z-index: 10;
    background: ${(props) => props.theme.colors.backgrounds.gray400};
    transition: all 0.09s cubic-bezier(0.33, 0.77, 0.8, -0.12);
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.75);

    ${(props) => props.state && 'visibility: visible;opacity: 1;'}
`;

type IPropsUl = {
    activeScroll: boolean;
};

export const SelectContainerOptions = styled.ul<IPropsUl>`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: 180px;
    overflow-y: ${(props) => (props.activeScroll ? `scroll` : `hidden`)};
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    ::-webkit-scrollbar-thumb {
        background: #c5c3c9;
    }
    ::-webkit-scrollbar:vertical {
        width: 2px;
    }
    ::-webkit-scrollbar-track {
        background: #1d1828;
    }
`;

export const SelectOption = styled.button`
    margin: 0px 8px;
    background: none;
    outline: none;
    border: none;
    text-align: left;
    padding: 16px;
    border-radius: 16px;
    cursor: pointer;
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 16px;

    &:hover {
        background: ${(props) => props.theme.colors.backgrounds.gray500};
    }
`;
