import { decryptNumber } from "@utils/security";

export const getCartResponse = (data: any) => {
    if (!data) {
        return data;
    }

    let arrDecryptNumber = [
        'iva',
        'total'
    ];
    try {
        arrDecryptNumber.forEach(key => {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                data[key] = decryptNumber(data[key]);
            }
        });
        return data;
    } catch (error) {
        console.log(error);
        throw new Error('Error decrypting the response getCart');
    }
}