import styled from "styled-components";
import ReactTooltip from 'react-tooltip';
import breakpoint from 'styled-components-breakpoint';

export const BoxBet = styled.section`
    display: flex;
    flex-wrap: wrap;
    padding: 32px 0 0;
    margin: 10px 0px 0;
    border-top: 1px solid ${(props) => props.theme.colors.backgrounds.gray600};
    ${breakpoint("lg")`
        margin: 10px 32px 0;
        padding: 32px 16px 0 7px;
    `}
`;

export const BoxBetTitle = styled.h2`
    width: 100%;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    color: ${(props) => props.theme.colors.accents.base.yellow};
    font-size: 14px;
    line-height: 32px;
`;

export const BoxBetDesc = styled.p`
    width: 100%;
    font-family: ${(props) => props.theme.fonts.lf_regular};
    color: #fff;
    font-size: 14px;
    line-height: 25px;
`;

export const BoxBetForm = styled.form`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 14px;
    & > div {
        padding: 10px 0;
    }
`;

export const BoxRowForm = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    width: 100%;
    &.contentCenter {
        justify-content: center;
        margin-top: 20px;

        input {
            margin-top: 4px;
            margin-right: 3px;
        }
    }
    label {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 10px;
        color: ${(props) => props.theme.colors.text.gray};
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        font-family: ${(props) => props.theme.fonts.lf_bold};
        &.labelLine {
            width: initial;
            margin-left: 5px;
            font-family: ${(props) => props.theme.fonts.lf_regular};
            font-size: 12
            px;
        }
        span {
            color: #0DF96C;
            font-size: 18px;
        }
    }
    ${breakpoint("lg")`
        width: 45%;
        &.contentCenter {
            width: 100%;
        }
    `}
`;

export const BoxRowFormTwo = styled.div`
    /* display: flex; */
    flex-wrap: wrap;
    margin-top: 10px;
    width: 100%;
    &.contentCenter {
        justify-content: center;
        margin-top: 20px;

        input {
            margin-top: 4px;
            margin-right: 3px;
        }
    }
    label {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 10px;
        color: ${(props) => props.theme.colors.text.gray};
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        font-family: ${(props) => props.theme.fonts.lf_bold};
        &.labelLine {
            width: initial;
            margin-left: 5px;
            font-family: ${(props) => props.theme.fonts.lf_regular};
            font-size: 12
            px;
        }
        span {
            color: #0DF96C;
            font-size: 18px;
        }
    }
    ${breakpoint("lg")`
        width: 45%;
        &.contentCenter {
            width: 100%;
        }
    `}
`;

export const ContentInput = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    position: relative; 
`;

export const InputCustom = styled.input`
    padding-left: 35px;
    padding-right: 16px;
    ${(props) => (props.position === 'left' && `text-align: right;`)}
    width: 100%;
    height: 56px;
    position: relative;
    background: #1D1828;
    font-size: 16px;
    line-height: 24px;
    font-family: ${(props) => props.theme.fonts.lf_regular};
    border-radius: 16px;
    outline: none;
    border: none;
    text-align: end;
    &[type=number]::-webkit-inner-spin-button, 
    &[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }
    &:focus {
        border: 1px solid ${(props) => props.theme.colors.principal.greenNeon};
    }

    /* Disabled */
    &:disabled,
    &[disabled] + span {
        color: #3E3A47;
    }

    &.error {
        border: 1px solid ${(props) => props.theme.colors.accents.dark.red};
    }
`;

export const InputCustomRadio = styled.input`
    height: 16px;
    width: 16px;
    border: 1px solid #C5C3C9;
    appearance: none;
    border-radius: 4px;
    &:before {
        content: none;
    }
    &:checked { 
        border: none;
        &:before {
            font-family: "icomoon";
            content: "\\e944";
            color: ${(props) => props.theme.colors.principal.greenBase}; 
            font-size: 16px;
        }
    } 
`;

export const Icon = styled.span`
    left: 12px;
    font-size: 20px;
    color: ${(props) => props.disabled === true ? props.theme.colors.disabled.gray : props.theme.colors.principal.greenNeon};
    position: absolute;
    top: 18px;
    &.error {
        color: ${(props) => props.theme.colors.accents.dark.red};
    }
`;

export const TooltipCustom = styled(ReactTooltip)`
    border-radius: 16px !important;
    width: 50%;
    box-shadow: 0 0 9px #333;
    max-width: 230px;
    pointer-events: auto !important;

    p {
        color: #3E3A47;
        font-family: ${(props) => props.theme.fonts.lf_medium};
        font-size: 12px;
        line-height: 14px;
        b {
            color: #009f5c;
            font-family: ${(props) => props.theme.fonts.lf_bold};
        }
    }
`;

export const ErrorContainer = styled.span`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #ff2048;
`;
