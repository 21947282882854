import { Button, ButtonIcon } from '@componentsShared';
import React from 'react';
import { ButtonLogin, LoginOptionalText, LoginOptionContainer } from './styled';

type TProps = {
    text: string;
    url: string;
};

export const BottomRegister = ({ text, url }: TProps) => {
    return (
        <LoginOptionContainer>
            <LoginOptionalText>{text}</LoginOptionalText>
            <ButtonLogin href={url}>Iniciar sesión</ButtonLogin>
        </LoginOptionContainer>
    );
};
