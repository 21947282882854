import { breakpoint } from 'src/shared/styles/variables';
import { hexToRGB } from 'src/shared/utils/hexToRgba';
import styled from 'styled-components';
import { IProps } from './types';

export const HeaderContainer = styled.header<IProps>`
    top: ${(props: IProps) => (props.location ? '53px' : '0')};
    width: 100%;
    height: 56px;
    position: fixed;
    left: 0;
    font-family: ${(props) => props.theme.fonts.lf_bold};
    background: ${(props) => hexToRGB(props.theme.colors.backgrounds.gray500, 0.6)};
    z-index: 6;
    display: flex;
    justify-content: space-between;
    backdrop-filter: blur(2px);

    // Desktop
    ${breakpoint('md')<IProps>`
        top: ${(props: IProps) => (props.location ? '40px' : '0')};
        height: 100px;
        background: ${(props) => hexToRGB(props.theme.colors.backgrounds.gray500, 0.6)};
        flex-direction: row;
    `}
`;
export const LogoImage = styled.div<IProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: transparent;
    z-index: 5;
    position: absolute;
    margin: 0 auto;
    align-self: center;

    ${(props) =>
        props.open &&
        `
        display:none;
    `}

    img {
        width: 100px;
        margin-top: 17px;
        height: ${(props) => (props.open ? '24px' : '38px')};

        ${breakpoint('md')`
            margin-top: 0;
        `}
    }

    // Desktop
    ${breakpoint('md')`
        width: 20px;
        position: relative;
        justify-content: flex-start;
        margin: 0 46px;

        a {
            height: 37px;
        }
    `}
`;
export const Shopping = styled.div<IProps>`
    position: absolute;
    width: 46px;
    height: 56px;
    right: 0px;
    top: 0px;
    background: linear-gradient(263.56deg, #0df96c 10.89%, #009f5c 96.04%);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${(props) => (props.open ? '2%' : '1')};
    position: relative;
    z-index: 7;
    cursor: pointer;

    span {
        font-size: 20px;
    }

    // Desktop
    ${breakpoint('sm')`
        width: 57px;
        height: 100%;
    `}

    ${breakpoint('md')`
        position: relative;
        width: 95px;
        height: 100%;

    `}

    & > span {
        font-size: 20px;
        margin-left: 6px;

        ${breakpoint('md')`
            font-size: 27px;
            margin-left: 0px;
        `}
    }
`;

export const IndicatorCar = styled.div`
    width: 24px;
    height: 24px;
    background: ${(props) => props.theme.colors.accents.dark.yellow};
    border-radius: 50%;
    position: absolute;
    left: -12px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    & > span {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
    }
`;

/* ======================== DESKTOP STYLES ================================== */
/** export const MenuItems = styled.ul``*/

export const HeaderLogos = styled.ul`
    display: flex;
    position: absolute;
    align-items: flex-end;

    top: 20px;
    left: 10rem;

    a {
        margin: 0 10px;
        padding-left: 0px;
        font-size: 10px;
        line-height: 12px;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        cursor: pointer;

        &:hover {
            color: ${(props) => props.theme.colors.principal.greenNeon};
        }
    }
    span {
        padding: 0 10px;
    }
    .icon-marker {
        color: ${(props) => props.theme.colors.principal.greenNeon};
        font-size: 12px;

        &:before {
            content: '\\e9c5';
        }
    }

    .icon-shop {
        color: ${(props) => props.theme.colors.principal.greenNeon};
        font-size: 12px;
        &:before {
            content: '\\ea10';
        }
    }
`;

export const ButtonList = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    width: 350px;

    ${breakpoint('sm')`
        flex-wrap: wrap;
        left: 40px;
    `}

    ${breakpoint('md')`
        flex-wrap: wrap;
        left: 0;
    `}

    ${breakpoint('lg')`
        flex-wrap: wrap;
        left: 60px;
    `}
`;

export const TemporaryReturnLink = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    a {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 12px;
        span {
            color: ${(props) => props.theme.colors.principal.greenNeon};
            width: 14px;
            height: 12px;
            margin-right: 5px;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        p {
            margin-left: 5px;
            color: ${(props) => props.theme.colors.principal.greenNeon};
            font-family: ${(props) => props.theme.fonts.lf_bold};
        }
    }

    ${breakpoint('lg')`
        width: initial;
        justify-content: initial;
        a {
            margin-right: 67px;
            font-size: 14px;
            span {
                margin-right: 8px;
                font-size: 24px;
            }
        }
    `}
`;
