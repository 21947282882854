import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';

export const WrapperResolutions = styled.div`
    width: 100%;
    padding: 30px 30px 0;
    justify-content: center;
    display: grid;
`;
export const MainContent = styled.div`
    width: 100%;
    justify-content: center;
    display: grid;

    ${breakpoint('xs')`
      padding-inline: 40px;
    `}
    ${breakpoint('s')`
      padding-inline: 0;
    `}
    ${breakpoint('xxl')`
      padding-inline: 100px;
    `}
`;

export const Icon = styled.div`
    display: none;
    justify-content: center;
    padding-top: 55px;

    ${breakpoint('md')`
      display:flex;
    `}
`;
export const Title = styled.h1`
    font-size: 18px;
    display: flex;
    justify-content: center;
    padding: 14px 0 20px;
    border-bottom: 1px solid #c5c3c9;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    color: ${(props) => props.theme.colors.accents.base.yellow};
    width: 100%;
    justify-self: center;

    ${breakpoint('md')`
      font-size: 28px;
      color: ${(props) => props.theme.colors.text.gray};
      max-width: 90%;
      margin: auto;
    `}
    ${breakpoint('lg')`
      max-width: 95%;

    `}
`;

export const ContentImageText = styled.div`
    width: 100%;
`;

export const ContentResolutions = styled.div`
    width: 100%;
    padding-top: 30px;
    justify-content: center;
    padding-bottom: 40px;

    ${breakpoint('s')`
      display:flex;
      flex-flow: wrap;
      justify-content: space-evenly;
    `}
`;
