import { useContext } from 'react';
import { loginServices } from '@services/login';
import { AppContextUser, initialUserData } from '../context/user';
import { isApolloError } from '@apollo/client';
import CryptoJS from 'crypto-js';
import { setData } from './transferData';
import { initEgoi } from './egoi';
import { decrypt, decryptNumber } from './security';

export const useFetchDataUser = () => {
    const { setDataUser } = useContext(AppContextUser);
    const fetchData = async (user, token, type) => {
        let tokenDecrypt = CryptoJS.AES.decrypt(token, process.env.ENCRYPTION_KEY || '');
        tokenDecrypt = tokenDecrypt.toString(CryptoJS.enc.Utf8);
        if (tokenDecrypt !== "") {
            await loginServices
                .getUserData(user, tokenDecrypt, type)
                .then((data) => {
                    const {
                        consultarUsuario: {
                            nombres,
                            tipoDocumento,
                            primerApellido,
                            usuario,
                            saldo,
                            ideUsuario,
                            puntos,
                            fechaNacimiento,
                            updUserData,
                            descargaweb,
                            email
                        }
                    } = data.data;

                    let nameToShow = nombres + ' ' + primerApellido[0] + '.';
                    const emailDecrypt = decrypt(email);
                    initEgoi(emailDecrypt);
                    setDataUser({
                        nombres: nameToShow,
                        tipoDocumento,
                        primerApellido,
                        usuario,
                        saldo: decryptNumber(saldo),
                        ideUsuario,
                        puntos: decryptNumber(puntos),
                        fechaNacimiento,
                        updUserData,
                        descargaweb,
                        email: emailDecrypt,
                        nombreCompleto:  `${nombres} ${primerApellido}`,
                        isLogged: true,
                        token: tokenDecrypt
                    });
                })
                .catch((e) => {
                    const userData = {
                        token: '',
                        type: '',
                        user: '',
                        isLogged: false
                    };
                    if (isApolloError(e)) {
                        for (const gqlError of e.graphQLErrors) {
                            if (gqlError.message === "Su sesión expiró. Por favor vuelva a iniciar sesión." || gqlError.message === "No se ha enviado el token de autenticación en la petición.") {
                                setData(userData, 'userData');
                                setDataUser(initialUserData);
                                window.location.reload();
                            }
                        }
                    }
                });
        }
    }

    return { fetchData };
};
