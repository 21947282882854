import { Redirect } from '@componentsShared';
import React from 'react';
import Viewport from 'src/shared/hooks/viewport';
import { ContentErrorLogin, ErrorLoginContainer } from './styled';

interface IProps {
    text: string;
}

export const ErrorLoginChangePassword = ({ text }: IProps) => {
    const viewport = Viewport();

    return (
        <ErrorLoginContainer>
            <span className="icon-face-sad"></span>
            <ContentErrorLogin>
                <p>
                    <span>¡Ups!</span> {text}{' '}
                </p>
            </ContentErrorLogin>
        </ErrorLoginContainer>
    );
};
