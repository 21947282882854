import siteUrl from '@utils/siteUrl';
import * as React from 'react';
import { ContainerRedirect } from './styled';

interface IProps {
    url: string;
    text?: string;
    className?: string;
    icon?: string;
    onClick?: () => void;
    children?: React.ReactNode;
    isOpenOtherTab?: boolean
}

export const Redirect = (props: IProps) => {
    const { text, icon, className, onClick, children, isOpenOtherTab = false } = props;
    let url = (props.url || '').trim();
    if (url !== '/' && url[0] === '/' && url[1] !== '/') {
        url = url.slice(1);
    }

    const isExternal = url.includes('http');
    return (
        <ContainerRedirect
            className={className ? className : ''}
            href={
                isExternal
                    ? url
                    : url === '/'
                    ? siteUrl
                    : `${siteUrl}/${url}${
                          url.length > 0 && url[url.length - 1] !== '/' && !url.includes('?')
                              ? '/'
                              : ''
                      }`
            }
            target={(isExternal || isOpenOtherTab) ? '_blank' : undefined}
            rel={(isExternal || isOpenOtherTab) ? 'noopener noreferrer nofollow' : ''}
            onClick={onClick}
        >
            {children ? (
                children
            ) : (
                <>
                    {text}
                    {icon !== undefined && <span className={'icon-' + icon}></span>}
                </>
            )}
        </ContainerRedirect>
    );
};
