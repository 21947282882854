import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';
import imgBackgroundRecoverPassword from 'src/shared/assets/images/background_recoverPassword.png';

export const BoxChangePassword = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${breakpoint('sm')`
        margin-top: 50px;
        margin-bottom: 50px;
    `}
`;

export const HeaderChangePassword = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 16px;
    padding: 24px 15px 54px 15px;
    background: ${(props) => props.theme.colors.backgrounds.gray500};
    border: 0.5px solid ${(props) => props.theme.colors.backgrounds.gray400};
    margin-top: 82px;
`;

export const ContentBoxChangePassword = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 568px;
    border-radius: 16px;
    background: none;

    ${breakpoint('md')`
        padding: 22px 69px;
        background: ${(props) => props.theme.colors.backgrounds.gray500};
        border: 0.5px solid ${(props) => props.theme.colors.backgrounds.gray400};        
    `}

    ${breakpoint('md')`
        background-repeat: no-repeat; 
        background-size: cover;
        background-image: url(${imgBackgroundRecoverPassword});
    `}
`;

export const HeaderBoxChangePassword = styled.div`
    margin: 30px 0px;
    width: 100%;
    text-align: center;

    ${breakpoint('md')`
        margin: 0px;
    `}

    h1 {
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;

        span {
            color: #ffe000;
        }

        ${breakpoint('md')`
            border-bottom: 1px solid ${(props) => props.theme.colors.backgrounds.gray400};
            width:100%;
            padding-bottom: 21px;
            margin-bottom: 16px;
        `}
    }
`;

export const BodyBoxChangePassword = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 16px;
    padding: 24px 15px;
    background: ${(props) => props.theme.colors.backgrounds.gray450};
    border: 0.5px solid ${(props) => props.theme.colors.backgrounds.gray400};

    ${breakpoint('md')`
        background: none;
        border:none;
        padding: 5px 0px 40px 0px;
    `}

    & > p {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        padding: 0px 32px 10px 4px;

        color: ${(props) => props.theme.colors.disabled.gray};

        ${breakpoint('md')`
            text-align:center;
            font-size: 13.6px;
            line-height: 22px;
            padding: 1px ;
        `}
    }
`;

export const ContainerError = styled.div`
    width: 100%;
    padding: 0px 10px;
    margin-top: 39px;
    order: 3;
`;

export const ContentForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    ${breakpoint("sm")`
        margin-top: 30px;
    `}

    & > button {
        margin: 52px 10px 27px 10px;
        max-width: 250px;

        ${breakpoint('md')`
            margin: 29px 0px 0px 0px;
            order: 2;
        `}
    }
`;
