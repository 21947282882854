import { encrypt } from "@utils/security";

const IS_GANA_ENCRYPTION_V2 = process.env.GANA_ENCRYPTION_V2 === '1';

export const addBetToCartRequest = (data: any) => {
    if (!data || !IS_GANA_ENCRYPTION_V2) {
        return data;
    }
    let arrDecryptNumber = [
        'iva',
        'totalApuesta',
        'derecho',
        'cuna',
        'combinado',
        'cifra',
        'vlrUnitAdicional',
        'vlrTotalAdicional',
        'ivaAdicional'
    ];
    try {
        arrDecryptNumber.forEach(key => {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                data[key] = encrypt(data[key]);
            }
        });
        return data;
    } catch (error) {
        console.log(error);
        throw new Error('Error decrypting the request addBetToCart');
    }
}