import { Button, ButtonGoBack, PredictError, ValidatePassword } from '@componentsShared';
import { loginServices } from '@services/login';
import { Token } from 'graphql';
import React, { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import Viewport from 'src/shared/hooks/viewport';
import {
    BodyBoxChangePassword,
    BoxChangePassword,
    ContentBoxChangePassword,
    ContentForm,
    HeaderBoxChangePassword,
    ContainerError
} from './styled';
import { Inputs } from './types';
import CryptoJS from 'crypto-js';
import { ErrorLoginChangePassword } from '@components';
import { encryptText } from '@utils/encrypt';
import { setData } from '@utils/transferData';

type TProps = {
    token: string;
};

export const ContainerChangePassword = (props: TProps) => {
    const { token } = props;
    const methods = useForm({ mode: 'onChange' });
    const viewport = Viewport();
    const [error, setError] = useState(false);
    const [whatIsError, setWhatIsError] = useState('');

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        let password = encryptText(data.password)
        await loginServices
            .updatePassword(token, password)
            .then((data2) => {
                if (data2.data.validarActualizarClave.status) {
                    setError(false);
                    setData(true, 'SuccessChangePassword')
                    window.location.href = '/';

                } else {
                    setWhatIsError(data2.data.validarActualizarClave.message);
                    setError(true);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <BoxChangePassword>
            {viewport.mobile && <ButtonGoBack />}
            <ContentBoxChangePassword>
                <HeaderBoxChangePassword>
                    <h1>
                        <span>Cambiar </span>contraseña
                    </h1>
                </HeaderBoxChangePassword>
                <BodyBoxChangePassword>
                    <p>Ingresa una nueva contraseña, asegúrate de no utilizar la anterior.</p>
                    <FormProvider {...methods}>
                        <ContentForm onSubmit={methods.handleSubmit(onSubmit)}>
                            <ValidatePassword
                                condition={methods.getValues('password')}
                                valid={methods.formState}
                                value={methods.watch('password')}
                            />
                            {error && (
                                <ContainerError>
                                    <ErrorLoginChangePassword text={whatIsError} />
                                </ContainerError>
                            )}
                            <Button
                                text="Recuperar contraseña"
                                disabled={!methods.formState.isValid}
                                submit={true}
                            ></Button>
                        </ContentForm>
                    </FormProvider>
                </BodyBoxChangePassword>
            </ContentBoxChangePassword>
        </BoxChangePassword>
    );
};
