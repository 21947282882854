import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';

export const BoxLeft = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 95%;
    max-width: 816px;
    // min-height: 951px;
    height: auto;

    ${breakpoint('lg')`
        min-height: auto;
    `}
`;

export const BoxLeftDetail = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    // background: ${(props) => props.theme.colors.backgrounds.gray500};
    width: 100%;
    height: 100%;
`;
