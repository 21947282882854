import { gql } from '@apollo/client';
import client from '../../config/apollo-client-gana';
import { addBetToCartRequest } from '@utils/encrypt/addBetToCart';
import { getCartResponse } from '@utils/encrypt/getCart';

export type lotteryToCar = {
    loterieId: number;
    sorteo: string;
    sigla: string;
    desLoteria?: string;
    horaCierre?: string;
};

export type TBetNumbers = {
    numbers: number[];
};

export type EventToCart = {
    idEvento: number;
    abreviatura: string;
    desEvento: string;
    horaCierre: string;
    sorteo: string;
}

export type ElementToCart = {
    abreviatura: string;
    desElemento: string;
    ideElemento: number;
}

export interface IBetsInput {
    user: string;
    sigla: string;
    derecho?: number;
    cuna?: number;
    combinado?: number;
    cifra?: number;
    totalApuesta: number;
    iva: number;
    nombre_colilla?: string;
    numbers: TBetNumbers[];
    date_sorteo: string;
    loteries: lotteryToCar[];
    numero_colilla?: number;
    id?: number;
    evento?: EventToCart;
    elementos?: ElementToCart[];
    vlrUnitAdicional?: number;
    vlrTotalAdicional?: number;
    ivaAdicional?: number;
}

export type IPayValida = {
    token: string,
    tipoDocumento: string,
    usuario: string,
    cartId: number,
    latitud: number,
    longitud: number
}

export type CurrentLocationResponse = {
    getCurrentLocation: {
        latitude?: number;
        longitude?: number;
    }
}

export const queriesCar = {
    getCurrentLocation: gql`
        query getCurrentLocation($input: CurrentLocationRequest) {
            getCurrentLocation(input: $input) {
                latitude
                longitude
            }
        }
    `,
    deleteCart: gql`
        mutation deleteItemCart($input: deleteCartInput!) {
            deleteItemCart(input: $input)
        }
    `,
    addToCar: gql`
        mutation addAllToCart($input: CartAllInputEncrypt!) {
            addAllToCart(input: $input)
        }
    `,
    addBetToCart: gql`
        mutation addBetToCart($input: CartBetInputEncrypt!) {
            addBetToCart(input: $input)
        }
    `,
    updateBetToCart: gql`
    mutation updateBetEncrypt($input: CartBetInputEncrypt!) {
        updateBetEncrypt(input: $input)
    }`,
    getCar: gql`
        query getCart($input: CartInput!) {
            getCart(input: $input) {
                id
                bets {
                    id
                    total
                    iva
                    date_sorteo
                    game
                    sigla
                    numero_colilla
                    nombre_colilla
                    vlrUnitAdicional
                    vlrTotalAdicional
                    ivaAdicional
                    infoAdicionalIncentivo
                    derecho
                    combinado
                    cuna
                    cifra
                    betNumbers {
                        numbers
                        __typename
                    }
                    loteries {
                        loterieId
                        sorteo
                        sigla
                        desLoteria
                        disponible
                        horaCierre
                    }
                    eventochance {
                        abreviatura
                        desEvento
                        idEvento
                        sorteo
                        horaCierre
                    }
                    elementoschance {
                        abreviatura
                        desElemento
                        ideElemento
                    }
                    __typename
                }
                recharges {
                    id
                    number
                    operator
                    package
                    total
                    __typename
                }
                total
                iva
                state
                __typename
            }
        }
    `,
    transactionState: gql`
        query getPayment($input: paymentInput!) {
            getPayment(input: $input) {
                reference
                state
                total
                responseJson
                createdAt
                __typename
            }
        }
    `,
    orderInfo: gql`
        query getOrderInfo($input: OrderInput!) {
            getOrderInfo(input: $input) {
                numTransaccion
                colilla
                serie
                desGame
                __typename
            }
        }
    `,

    placeToPayUrl: gql`
        mutation placeToPayUrl($input: PaymentInput!) {
            placeToPayUrl(input: $input)
        }
    `,
    payValidaUrl: gql`
        mutation payValidaUrl($input: PaymentInput!) {
            payValidaUrl(input: $input)
        }
    `,
    downloadColilla: gql`
        query downloadColillaPdf($input: ColillaPDFInput!) {
            downloadColillaPdf(input: $input)
        }
    `,
    getConfisysPaymentGateway: gql`
        query {
            getConfisysPaymentGateway {
                nombre,
                valorStr
            }
        }
    `,
    getOrderDetail: gql`
        query getOrderDetail($input: OrderDetailInput!) {
            getOrderDetail(input: $input) {
                sigla
                nombre
                numTransaccion
                estadoTransaccion
                __typename
            }
        }
    `,
    getPaymentDetail: gql`
        query getPaymentDetail($paymentId: String) {
            getPaymentDetail(paymentId: $paymentId) {
                sigla
            }
        }
    `,
    getBetsByCartNumber: gql`
        query getBetsByCartNumber($idCart: Int, $numero_colilla: Int) {
            getBetsByCartNumber(idCart: $idCart, numero_colilla: $numero_colilla) {
                id
                combinado
                derecho
                numero_colilla
                numbers
            }
        }
    `
};

export const carServices = {
    getCurrentLocation: (usuario: string, cartId: number, latitude?: number, longitude?: number, errors?: string, accuracy?: number) => {
        return client.query<CurrentLocationResponse>({
            query: queriesCar.getCurrentLocation,
            variables: {
                input: {
                    accuracy,
                    errors,
                    latitude,
                    longitude,
                    usuario,
                    cartId
                }
            }
        })
    },
    deleteCart: (token: string, tipoDocumento: string, usuario: string, bets: Number[]) => {
        return client.mutate({
            mutation: queriesCar.deleteCart,
            variables: {
                input: {
                    token,
                    tipoDocumento,
                    usuario,
                    bets
                }
            }
        });
    },
    addToCar: (bets: IBetsInput[]) => {
        for (let i = 0; i < bets.length; i++) {
            bets[i] = addBetToCartRequest(bets[i]);
        }
        return client.mutate({
            mutation: queriesCar.addToCar,
            variables: {
                input: {
                    bets: bets
                }
            }
        });
    },
    addBetToCart: (input: IBetsInput) => {
        input = addBetToCartRequest(input);
        return client.mutate({
            mutation: queriesCar.addBetToCart,
            variables: {
                input
            }
        });
    },
    updateBetToCart: (input: IBetsInput) => {
        input = addBetToCartRequest(input);
        return client.mutate({
            mutation: queriesCar.updateBetToCart,
            variables: {
                input
            }
        });
    },
    getCar: async (token: string, tipoDocumento: string, usuario: string) => {
        const response =  await client.query({
            query: queriesCar.getCar,
            variables: {
                input: {
                    token,
                    tipoDocumento,
                    usuario
                }
            }
        });

        response.data.getCart  = getCartResponse(response.data.getCart);
        return response;
    },
    transactionState: (
        token: string,
        tipoDocumento: string,
        usuario: string,
        paymentId: string
    ) => {
        return client.query({
            query: queriesCar.transactionState,
            variables: {
                input: {
                    token,
                    tipoDocumento,
                    usuario,
                    paymentId
                }
            }
        });
    },
    orderInfo: (token: string, numTransaccionPasarela: string, fecPedido: string) => {
        return client.query({
            query: queriesCar.orderInfo,
            variables: {
                input: {
                    token,
                    numTransaccionPasarela,
                    fecPedido
                }
            }
        });
    },
    downloadColilla: (token: string, usuario: string, serie: string, colilla: number, fecPedido:string) => {
        return client.query({
            query: queriesCar.downloadColilla,
            variables: {
                input: {
                    token,
                    usuario,
                    serie,
                    colilla,
                    fecPedido
                }
            }
        });
    },
    placeToPayUrl: (
        token: string,
        tipoDocumento: string,
        usuario: string,
        cardId: number,
        latitud: number,
        longitud: number
    ) => {
        return client.query({
            query: queriesCar.placeToPayUrl,
            variables: {
                input: {
                    token: token,
                    tipoDocumento: tipoDocumento,
                    usuario: usuario,
                    cartId: cardId,
                    ip: '127.0.0.1',
                    latitud,
                    longitud
                }
            }
        });
    },
    payValidaUrl: (input: IPayValida) => {
        return client.query({
            query: queriesCar.payValidaUrl,
            variables: {
                input
            }
        });
    },
    getConfisysPaymentGateway: () => {
        return client.query({
            query: queriesCar.getConfisysPaymentGateway
        });
    },

    getOrderDetail: (
        token: string,
        tipoDocumento: string,
        usuario: string,
        reference: string,
        transaction: string
    ) => {
        return client.query({
            query:queriesCar.getOrderDetail,
            variables: {
                input: {
                    token,
                    tipoDocumento,
                    usuario,
                    reference,
                    transaction
                }
            }
        })
    },
    getPaymentDetail: (paymentId: string) => {
        return client.query({
            query: queriesCar.getPaymentDetail,
            variables: {
                paymentId
            }
        });
    },
    getBetsByCartNumber: (idCart: number, numero_colilla: number) => {
        return client.query({
            query: queriesCar.getBetsByCartNumber,
            variables: {
                idCart,
                numero_colilla
            }
        });
    }
};
