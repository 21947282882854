import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const Taps = styled.ul`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 50px;
`;

export const Tap = styled.h2<{ state: boolean }>`
    width: 100px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: ${(props) => props.theme.colors.text.lightGray};
    text-align: center;
    cursor: pointer;

    :nth-child(2) {
        span {
            ${breakpoint('md')({
                marginLeft: '5px'
            })}
        }
    }
    & > span {
        color: ${(props) => props.theme.colors.text.lightGray};
        ${(props) => props.state && 'color:' + props.theme.colors.accents.base.yellow + ';'};
    }

    ${breakpoint('md')({
        width: 'auto',
        flexDirection: 'row'
    })}

    ${(props) =>
        props.state &&
        'border-bottom: 3px solid ' +
            props.theme.colors.principal.greenNeon +
            '; color:' +
            props.theme.colors.text.white +
            ';'};
`;
