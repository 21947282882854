import { DefaultTheme } from 'styled-components';
import { BreakpointFunction } from 'styled-components-breakpoint';
import { colors, fonts, breakpoints } from './variables';

interface IMainTheme {
    colors: {
        principal: {
            greenBase: string;
            greenNeon: string;
            darkGreen: string;
        };
        text: {
            gray: string;
        };
        disabled: {
            gray: string;
        };
        backgrounds: {
            gray400: string;
            gray450: string;
            gray500: string;
            gray600: string;
        };
        accents: {
            neon: {
                purple: string;
                blue: string;
                orange: string;
                yellow: string;
                fuchsia: string;
            };
            base: {
                purple: string;
                blues: string;
                orange: string;
                yellow: string;
            };
            dark: {
                purple: string;
                blues: string;
                red: string;
                yellow: string;
            };
        };
        alerts: {
            orange: string;
            yellow: string;
            green: string;
            red: string;
        };
    };
    fonts: {
        mon_bold: string;
        mon_extrabold: string;
        mon_light: string;
        mon_medium: string;
        mon_regular: string;
        mon_semiBold: string;
        lf_bold: string;
        lf_extrabold: string;
        lf_light: string;
        lf_medium: string;
        lf_regular: string;
        lf_semiBold: string;

        icons: string;
    };
    breakpoints: BreakpointFunction<'xs' | 'sm' | 'md' | 'lg' | 'xl'>;
}

const mainTheme: DefaultTheme = {
    colors,
    fonts,
    breakpoints
};

export const device = (Object.keys(breakpoints) as Array<keyof typeof breakpoints>).reduce(
    (acc, key) => {
        acc[key] = (style: String) => `@media (min-width: ${breakpoints[key]}) { ${style} }`;
        return acc;
    },
    {} as { [index: string]: Function }
);
export default mainTheme;
