import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, .6);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: transparent;
  animation: ${spin} 1s linear infinite;
`

export const ContentLoading = styled.div`
  width: 100%;
  position: absolute;
  inset: 0;
  margin:auto;
  display: flex;
  justify-content: center;
  align-items: center;
`