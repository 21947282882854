import React from 'react';
import { Box, Title, Text, Pdf, ContainerResolutions } from './styled';
interface IPropsFile {
    file: {
        url: string;
    };
    title: string;
}

interface IProps {
    title: string;
    text: string;
    file: IPropsFile;
}
export const CardResolution = (props: IProps) => {
    const { title, text, file } = props;

    const downloadResolution = () => {
        fetch(file.file.url).then((response) => {
            response.blob().then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = file.title + '.pdf';
                a.click();
                a.remove();
            });
        });
    };

    return (
        <ContainerResolutions>
            <Pdf onClick={() => downloadResolution()}>
                <Box>
                    <Title>{title}</Title>
                    <Text>{text}</Text>
                </Box>
            </Pdf>
        </ContainerResolutions>
    );
};
