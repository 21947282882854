import styled from 'styled-components';

export const GoBack = styled.div`
    width: 100%;
    display: flex;
    align-items: center;

    span {
        margin-right: 8px;
        font-size: 18px;
        color: ${(props) => props.theme.colors.principal.greenNeon};
        font-family: ${(props) => props.theme.fonts.icons};
        content: '\\e943';
    }

    a {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        text-decoration: none;
    }
`;
