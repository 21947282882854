import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

export const InputCustomLabel = styled.label`
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
    color: ${(props) => props.theme.colors.text.gray};
    display: flex;
    align-items: center;

    &::after {
        font-family: 'icomoon' !important;
        font-size: 20px;
        margin-left: 8px;
        content: '\\e9ad';
    }
`;

interface IInput{
    position?:string
}

export const InputCustom = styled.input<IInput>`
    padding-left: 16px;
    padding-right: 16px;
    ${(props) => (props.position === 'left' && `text-align: right;` )}
    width: 100%;
    height: 56px;
    position: relative;
    background: ${(props) => props.theme.colors.backgrounds.gray400};
    font-size: 16px;
    line-height: 24px;
    font-family: ${(props) => props.theme.fonts.lf_regular};
    border-radius: 16px;
    outline: none;
    border: none;

    &:focus {
        border: 1px solid ${(props) => props.theme.colors.principal.greenNeon};
    }

    &.error {
        border: 1px solid ${(props) => props.theme.colors.accents.dark.red};
    }
`;

export const InputCustomContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
`;

interface IIcon {
    position?: string;
    disabled?: boolean;
}

export const Icon = styled.span<IIcon>`
    ${(props) => {
        if (props.position === 'left') {
            return `left: 26px;`;
        } else {
            return `right: 26px;`;
        }
    }}
    font-size: 20px;
    color: ${(props) => props.disabled === true ?  props.theme.colors.disabled.gray: props.theme.colors.principal.greenNeon };
    position: absolute;
`;
