import styled from 'styled-components'
import { breakpoint } from '../../../styles/variables';

interface Props {
    show: boolean;
}

export const BoxTooltipIniSesion = styled.div`
    background-color: #FFFFFF;
    border-radius: 16px;
    display: none;
    height: 111.86px;
    right: 65px;
    top: 45.16px;
    width: 325px;

    position: absolute;
    z-index: 10;

    -webkit-box-shadow: 0px 4px 4px 1px rgba(0,0,0,0.25); 
    box-shadow: 0px 4px 4px 1px rgba(0,0,0,0.25);

    &::after {
        content: "";
        display: inline-block;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #fff;
        position: absolute;
        top: -8px;
        left: calc(50% - 8px);
    }

    ${breakpoint('xl')`
        display: ${(props: Props) => (props.show ? 'flex' : 'none')};
    `}
     
`;

export const BoxText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
        color: ${(props) => props.theme.colors.backgrounds.gray500};
        font-size: 14px;
    }

    & p:nth-child(1) {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        margin-bottom: 6px;
    }
    & p:nth-child(2) {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        line-height : 17px;
    }
`;

export const ImgUser = styled.img`
    color: blue;
    width: 31px;
    margin-left: 24px;
    margin-right: 19px;
    margin-top: 40px;
    margin-bottom: 40.86px;
`;

export const ButtonClose = styled.button`
    background-color: transparent;
    border: none;
    align-self: flex-start;
    margin: 13px 15.41px 86.27px 5px;
    padding: 0;

    &:hover {
        cursor: pointer;
    }

    img {
        width: 12.59px;
    }
`;