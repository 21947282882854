import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const BodyModalPaymentSummary = styled.div`
    background: ${(props) => props.theme.colors.backgrounds.gray500};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    /* height: 270px; */
    border-radius: 0 0 24px 24px;
    padding-bottom: 0;

    ${breakpoint('sm')`
    background: none;
`}

    button {
        max-width: 204px;
    }

    h3 {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
    }
`;

export const OptionsToPayment = styled.ul`
    margin: 21px 0 29px 0;
    display: flex;
    flex-direction: column;
    gap: 12px;

    ${breakpoint('sm')`
    gap: 8px;
    margin: 17px 0 25px 0;
`}
`;

export const OptionPayment = styled.li`
    width: 277px;
    background: ${(props) => props.theme.colors.backgrounds.gray400};
    border-radius: 12px;
    max-height: 43px;
    display: flex;
    justify-items: center;
`;

export const LabelOptionPayment = styled.label`
    cursor: pointer;
    font-family: ${(props) => props.theme.fonts.lf_regular};
    display: flex;
    align-items: center;
    position: relative;
    width: 277px;
    height: 43px;
    padding-left: 28px;

    .pse {
        width: 31.8px;
    }

    .visa {
        width: 36.8px;
        margin-left: 24px;
    }

    .masterCard {
        width: 36.8px;
        margin-left: 24px;
    }

    &::before {
        font-family: 'icomoon';
        content: '\\e946';
        margin-right: 28px;
        color: ${(props) => props.theme.colors.disabled.gray}
    }

    & > p {
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        margin-left: 21px;
    }
    & > span {
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        margin-left: 8px;
        color: ${(props) => props.theme.colors.accents.base.yellow};
    }
`;

export const RadioButtonOptionPayment = styled.input`
    display: none;
    transition: all 1s;
    &:checked + ${LabelOptionPayment}:before {
        font-family: 'icomoon';
        content: '\\ea63';
        color: ${(props) => props.theme.colors.principal.greenNeon};
    }

    &:hover + ${LabelOptionPayment}:before {
        font-family: 'icomoon';
        content: '\\ea63';
        /* color: ${(props) => props.theme.colors.accents.neon.blue};  */
    }

    &:disabled + ${LabelOptionPayment} {
        color: ${(props) => props.theme.colors.disabled.gray};
        cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
        box-shadow: none;
        border-radius: 50%;
        background: ${(props) => props.theme.colors.disabled.gray};
    }

    &.error + ${LabelOptionPayment}:before {
        color: ${(props) => props.theme.colors.accents.dark.red} !important;
    }
`;
