import React from 'react';
import { Redirect } from '@componentsShared';
import { GoBack } from './styled';

type TProps = {
    customBack?: {
        url: string;
        text: string;
    };
};

export const ButtonGoBack = (props: TProps) => {
    const { customBack } = props;

    return (
        <GoBack>
            <span className="icon-arrow-small-left"></span>
            {customBack ? (
                <Redirect url={customBack.url} text={customBack.text} />
            ) : (
                <Redirect url="/" text="Regresar al inicio" />
            )}
        </GoBack>
    );
};
