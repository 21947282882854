import styled from 'styled-components';
import breakpoint from "styled-components-breakpoint";

export const WrapperPromotion = styled.section`
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    height: 100%;   
    min-height: 0px;
    position: relative;
    margin-top: 50px;
    .slick-slider {
        .slick-list {
            padding-top: 50px !important;
            padding-bottom: 30px !important;
            .slick-track{
                width: 100%;
                height: 100%;
                & > div {
                    height: 100%;
                    & > div {
                        height: 100%;
                        text-align: center;
                    }
                }
            }
        }
        .slick-dots {
            right: 0;
            left: 0;
            margin: auto;
            text-align: initial;
            width: max-content;
            bottom: -60px;
            display: none;
            li {
                &.slick-active {
                    button {
                        &:before {
                            font-size: 12px;
                            line-height: 14px;
                            width: 11px;
                            height: 11px;
                            color: transparent;
                            box-shadow: 0px 0px 6px 1.5px ${props => props.theme.colors.alerts.yellow};
                            border: 2px solid ${props => props.theme.colors.alerts.yellow};
                        }
                    }
                }
                button {
                    &:before {
                        font-size: 10px;
                        line-height: 12px;
                        color: transparent;
                        border: 2px solid ${props => props.theme.colors.disabled.gray};
                        border-radius: 50%;
                        width: 7px;
                        height: 7px;
                        opacity: 1;
                        bottom: 0;
                        right: 0;
                        margin: auto;
                    }
                }
            }
        }
    }
    ${breakpoint('lg')`
        padding-top: 0;
        .slick-slider {
            .slick-list {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }
            .slider-dots {
                display: block;
            }
        }
    `}
`;

export const PromotionTitle = styled.h2`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 28px;
    line-height: 33px;
    font-family: ${props => props.theme.fonts.mon_bold};
    margin-bottom: 30px;
    span {
        margin-left: 8px;
        color: ${props => props.theme.colors.accents.base.yellow};
    }
    ${breakpoint('lg')`
        font-size: 32px;
        line-height: 38.4px;
        margin-bottom: 40px;
    `}
`;

export const ContentSliderPromotions = styled.div`
    display: flex;
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
`;

export const BoxPromotion = styled.div`
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background: ${props => props.theme.colors.backgrounds.gray500};
    border: 1px solid ${props => props.theme.colors.principal.greenBase};
    box-sizing: border-box;
    box-shadow: 0px 0px 8px rgba(0, 212, 85, 0.5);
    border-radius: 24px;
    min-height: 341px;
    max-width: 1230px;
    width: 288px !important;
    margin: auto;
    height: 99%;
    padding: 45px 35px;
    position: relative;
    ${breakpoint('md')`
        padding: 33px 45px;
    `}
    ${breakpoint('lg')`
        width: 90% !important;
    `}
    ${breakpoint('xxl')`
        width: 99% !important;
    `}
`;

export const BoxPromotionIcon = styled.div`
    display: flex;
    transform: rotate(15deg);
    position: relative;
    top: -10px;
    ${breakpoint('xxl')`
        padding-left: 30px;
    `}
`;

export const BoxPromotionDesc = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    text-align: center;
    max-width: 300px;
    width: 100%;
    order: 2;
    h2 {
        color: ${props => props.theme.colors.principal.greenBase};
        font-size: 28px;
        line-height: 33.6px;
        font-family: ${props => props.theme.fonts.mon_bold};
        width: 100%;
    }
    p {
        width: 100%;
        color: ${props => props.theme.colors.text.gray};
        font-size: 16px;
        font-family: ${props => props.theme.fonts.lf_regular};
        line-height: 20px;
        text-align: center;
        margin-top: 33px;
        margin-top: 25px;
        position: relative;
        b {
            color: ${props => props.theme.colors.accents.base.yellow};
        }
        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            background: #3E3A47;
            height: 1px;
            width: 100%;
        }
    }
    h3 {
        width: 100%;
        margin-top: 30px;
        font-size: 20px;
        line-height: 24px;
        font-family: ${props => props.theme.fonts.mon_bold};
        color: ${props => props.theme.colors.accents.base.yellow};
    }
    ${breakpoint('lg')`
        width: initial;
        text-align: left;
        h2 {
            font-size: 24px;
            line-height: 28px;
        }
        p {
            text-align: left;
            line-height: 19.2px;
            margin-top: 15px;
            font-size: 16px;
            padding-bottom: 20px;
            &:after {
                content: '';
            }
        }
        h3 {
            margin-top: 10px;
            color: ${props => props.theme.colors.text.gray};
        }
    `}
    ${breakpoint('xl')`
        max-width: 370px;
        h2 {
            font-size: 28px;
            line-height: 33.6px;
        }
        p {
            margin-top: 20px;
        }
        h3 {
            margin-top: 20px;
            color: ${props => props.theme.colors.text.gray};
        }
    `}
`;

export const BoxClockPromotion = styled.div`
    display: flex;
    flex-direction: column;
    h4 {
        color: ${props => props.theme.colors.accents.base.yellow};
        font-family: ${props => props.theme.fonts.mon_bold};
        font-size: 40px;
        line-height: 48px;
        letter-spacing: 1px;
        text-shadow: 0px 0px 12px rgba(255, 224, 0, 0.25);
        margin-top: 5px;
        span {
            color: ${props => props.theme.colors.accents.base.yellow};
            margin: 0 5px;
            &:first-child {
                margin: 0 5px 0 0;
            }
        }
    }
    h5 {
        width: 220px;
        display: flex;
        justify-content: space-around;
        margin-top: 2px;
        span {
            color: ${props => props.theme.colors.accents.base.yellow};
            font-family: ${props => props.theme.fonts.lf_regular};
            font-size: 14px;
            line-height: 18px;
        }
    }
    p {
        font-size: 12px;
        line-height: 20px;  
        font-family: ${props => props.theme.fonts.lf_regular};
        color: #fff;
        padding-bottom: 0;
        &:after {
            content: none;
        }
    }
    ${breakpoint('lg')`
        h5 {
            span {
                font-size: 13px;
                line-height: 16px;
            }
        }
    `}
    ${breakpoint('xl')`
        h5 {
            span {
                font-size: 14px;
                line-height: 18px;
            }
        }
    `}
`;

export const BoxPromotionGame = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    border-radius: 24px;
    width: 234px;
    height: 86px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -40px;
    order: 1;
    h3 {
        font-size: 20px;
        font-family: ${props => props.theme.fonts.mon_bold};
        color: #fff;
        line-height: 24px;
        width: 100%;
    }
    ${breakpoint('lg')`
        order: 3;
        width: 320px;
        height: 235px;
        padding: 30px 40px;
        background: #272231;
        border: 1px solid ${props => props.theme.colors.accents.base.yellow};
        box-sizing: border-box;
        box-shadow: 0px 0px 18px 4px rgba(255, 224, 0, 0.25);
        position: relative;
        top: 0;
        margin: 0;
        align-content: center;
        h3 {
            font-size: 17px;
            line-height: 18px;
        }
    `}
    ${breakpoint('xl')`
        width: 418px;
        align-content: flex-start;
        h3 {
            font-size: 20px;
            line-height: 24px;
        }
    `}
`;

export const ImageGame = styled.img`
    width: 100%;
    margin: 0 auto;
    border-radius: 12px;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
    height: 100%;
    ${breakpoint('lg')`
        object-fit: contain;
        width: 276px;
        height: 89px;
        margin-top: 33px;
    `}
`;

export const LinkGame = styled.a`
    position: absolute;
    bottom: -16px;
    right: 0;
    left: 0;
    margin: 0 auto;
    background: ${props => props.theme.colors.principal.greenBase};
    border-radius: 16px;
    padding: 13px 24px;
    font-size: 16px;
    line-height: 20px;
    color: ${props => props.theme.colors.text.gray};
    font-family: ${props => props.theme.fonts.mon_bold};
    width: 160px;
    text-decoration: none;
    transition: all .4s ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
    ${breakpoint('lg')`
        width: 106px;
        border-radius: 12px;
        padding: 8px 32px;
        font-size: 14px;
        line-height: 16px;
    `}
`;

export const ExpectToPlayOnlyMobile = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    h3 {
        font-size: 16px;
        line-height: 19.2px;
        color: ${props => props.theme.colors.text.gray};
        margin-top: 30px;
        padding-bottom: 20px;
    }
    ${breakpoint('lg')`
        h3 {
            margin-top: 40px;
        }
    `}
`;

export const YourPayments = styled.div`
    display: flex;
    background: #2A2239;
    width: 100%;
    position: relative;
    top: 20px;
    height: 187px;
    margin-top: 40px;
    padding: 22px 32px 42px;
    ${breakpoint('lg')`
        height: 129px;
        margin-top: 80px;
    `}
`;

export const ContentYourPayments = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 745px;
    margin: 0 auto;
    p {
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        font-family: ${props => props.theme.fonts.lf_regular};
        color: ${props => props.theme.colors.text.gray};
        width: 100%;
        b {
            font-family: ${props => props.theme.fonts.lf_bold};
        }
    }
    ${breakpoint('md')`
        p {
            width: initial;
            text-align: left;
        }
    `}
`;

export const LogosYourPayments = styled.div`
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    max-width: 400px;
    width: 95%;
    margin: 0 auto;
    a {
        display: flex;
        align-items: center;
    }
    img {
        object-fit: contain;
    }
    ${breakpoint('md')`
        padding-left: 84px;
    `}
`;

export const ArrowCustom = styled.div`
    display: flex;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    z-index: 5;
    height: 50px;
    svg {
        path, circle {
            transition: all .3s ease-in-out;
        }
    }
    &:hover {
        svg {
            circle {
                fill: ${props => props.theme.colors.principal.greenBase}
            }
            path {
                fill: ${props => props.theme.colors.text.white}
            }
        }
    }
    &.prev {
        left: 2%;
    }
    &.next {
        right: 2%;
        transform: rotate(180deg);
    }
    ${breakpoint('lg')`
        &.prev {
            left: 3%;
        }
        &.next {
            right: 3%;
        }
    `}
    ${breakpoint('xxl')`
        &.prev {
            left: -6px;
        }
        &.next {
            right: -6px;
        }
    `}
`;
