import styled from "styled-components";

export const BoxSpinner = styled.div`
    border: 4px solid ${(props) => props.theme.colors.backgrounds.gray400};
    width: 51px;
    height: 51px;
    border-radius: 50%;
    border-left-color: ${(props) => props.theme.colors.accents.neon.blue};
    border-bottom-color: ${(props) => props.theme.colors.accents.neon.blue};
    border-right-color: ${(props) => props.theme.colors.accents.neon.blue};

    -webkit-box-shadow: 0px 1px 4px 2px rgba(0, 163, 255, 0.3); 
    box-shadow: 0px 1px 4px 2px rgba(0, 163, 255, 0.3);
    

    animation: spinner 1s ease infinite;

    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;