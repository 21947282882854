import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';

export const SectionWhatIsPoints = styled.section`
    width: 90%;
    margin: 45px auto 0;
    max-width: 995px;
    /* ${breakpoint('md')`
  
    `}; */
`;

export const WhatIsPointsTitle = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid #c5c3c9;
    img {
        width: 32px;
        height: 32px;
        object-fit: contain;
        display: none;
    }
    h1 {
        width: 100%;
        text-align: center;
        color: #fff;
        font-family: ${(props) => props.theme.fonts.mon_bold};
        margin-top: 14px;
        font-size: 18px;
        line-height: 22px;
        span {
            color: ${(props) => props.theme.colors.accents.base.yellow};
        }
    }
    ${breakpoint('md')`
        img {
            display: flex;
        }
        h1 {
            font-size: 28px;
            line-height: 33px;
        }
    `};
`;

export const WhatIsPointsInfo = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    h2 {
        width: 100%;
        text-align: center;
        color: #fff;
        font-family: ${(props) => props.theme.fonts.mon_bold};
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 16px;
        span {
            color: ${(props) => props.theme.colors.accents.base.yellow};
        }
    }
    ${breakpoint('md')`
        width: 90%;
        h2 {
            margin-top: 40px;
            margin-bottom: 0;
            font-size: 20px;
            line-height: 24px;
        }
    `};
`;

export const WhatIsPointsInfoLeft = styled.div`
    width: 100%;
    order: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        object-fit: contain;
        width: 95%;
        margin: 0 auto;
    }

    ${breakpoint('md')`
        width: 45%;
        order: 1;
        img {
            width: initial;
        }
    `};
`;

export const WhatIsPointsInfoRight = styled.div`
    width: 100%;
    order: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    p {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 14px;
        line-height: 17px;
        color: ${(props) => props.theme.colors.text.gray};
        b {
            font-weight: bold;
        }
        a {
            color: ${(props) => props.theme.colors.principal.greenBase};
        }
    }

    p:first-child{
        padding-bottom: 20px;
    }


    ${breakpoint('md')`
        width: 50%;
        order: 2;
        padding-left: 32px;
        p {
            font-size: 16px;
            line-height: 20px;
        }
    `};
`;
