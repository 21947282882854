import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const BannerContainer = styled.div`
    // background: linear-gradient(to right, #00d455, #004e2d);
    background: linear-gradient(264.61deg, #004e2d 37%, #00d455 139.88%);
    border-radius: 16px;
    margin-top: 40px;
    padding-bottom: 30px;

    ${breakpoint('md')({
        marginTop: '0',
        display: 'flex',
        margin: '75px auto 0',
        height: '140px',
        maxWidth: '704px',
        background: 'linear-gradient(274.3deg, #004E2D 42.64%, #00D455 100.44%)',
        padding: '0'
    })}

    ${breakpoint('lg')({
        marginTop: '0',
        display: 'flex',
        margin: '75px auto 0',
        height: '140px',
        maxWidth: '1056px'
    })}

    a {
        margin: auto;
        padding-top: 20px;
        button {
            margin: auto;
            height: 46px;
            width: 160px;
            font-size: 16px;
            line-height: 20px;

            ${breakpoint('md')({
                height: '46px',
                width: '160px'
            })}

            ${breakpoint('lg')({
                height: '32px',
                width: '133px',
                fontSize: '14px',
                lineHeight: '16px'
            })}
        }
        ${breakpoint('md')({
            margin: 'auto'
        })}
    }
`;

export const ContainerImage = styled.div`
    img{
        display: none;
    }


    img {
        ${breakpoint('md')({
            display: 'block',
            position: 'relative',
            left: '20px',
            top: '-60px',
            height: '200px'
        })}

        ${breakpoint('lg')({
            position: 'relative',
            top: '-86px',
            left: '80px',
            height: 'auto',
        })}
        }
    }
`;

export const ContainerText = styled.div`
    padding-top: 20px;

    h4 {
        font-family: ${(props) => props.theme.fonts.mon_bold};
        text-align: center;
        font-size: 16px;
        line-height: 19.2px;
        padding-bottom: 20px;

        ${(props) =>
            breakpoint('md')({
                fontFamily: props.theme.fonts.mon_bold,
                fontSize: '16px',
                lineHeight: '19.2px',
                textAlign: 'left'
            })}

        ${breakpoint('lg')({
            fontSize: '28px',
            lineHeight: '33.6px'
        })}
    }

    b {
        color: ${(props) => props.theme.colors.accents.base.yellow};
    }

    p {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        text-align: center;
        padding: 0 20px 20px;
        ${(props) =>
            breakpoint('md')({
                fontFamily: props.theme.fonts.lf_regular,
                fontSize: '14px',
                lineHeight: '16.8px',
                width: '230px',
                textAlign: 'left',
                padding: '0'
            })}

        ${breakpoint('lg')({
            alignSelf: 'center',
            fontSize: '16px',
            lineHeight: '19.2px',
            width: '401px'
        })}
    }

    ${breakpoint('md')({
        paddingTop: '0',
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        paddingLeft: '50px'
    })}

    ${breakpoint('lg')({
        alignSelf: 'center',
        paddingLeft: '100px'
    })}
`;
