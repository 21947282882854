import { decryptNumber } from "@utils/security";

export const getGameInfoBilletonResponse = (data: any) => {
    if (!data) {
        return data;
    }

    const arrEncryptValorApuesta = [
		'iva',
        'premioBruto',
        'puntosAGanar',
        'valorApuesta'
	];

	try {
        if (!data.valorApuesta) {
            data.valorApuesta = [];
        }

        for (let i = 0; i < data.valorApuesta.length; i++) {
            const valorApuesta = data.valorApuesta[i];
            arrEncryptValorApuesta.forEach(key => {
                if (Object.prototype.hasOwnProperty.call(valorApuesta, key)) {
                    valorApuesta[key] = decryptNumber(valorApuesta[key]);
                }
            });
        }

		return data;
	} catch (error) {
        console.log(error);
		throw new Error('Error decrypt the response getGameInfoBilleton');
	}
};