import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
`

export const LabelCustom = styled.label`
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 16px;
    display: flex;
    align-items: center;
    position: relative;

    &::before{
        font-family: "icomoon";
        content: "\\ea29";
        margin-right: 12px;
    }
`

export const CheckboxCustom = styled.input`
    display: none;

    &:checked + ${LabelCustom}:before{ 
        font-family: "icomoon";
        content: "\\e944";
        color: ${(props) => props.theme.colors.accents.neon.blue}; 
    } 

    &:hover + ${LabelCustom}:before{
        font-family: "icomoon";
        content: "\\e944"; 
    }

    &:disabled + ${LabelCustom} {
        color: ${(props) => props.theme.colors.disabled.gray};
        cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
        box-shadow: none;
        border-radius: 4px;
        background: ${(props) => props.theme.colors.disabled.gray};
    }

    &.error + ${LabelCustom}:before  {
        color:${(props) => props.theme.colors.accents.dark.red} !important;
    }
`

