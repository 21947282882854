import { hexToRGB } from '@utils/hexToRgba';
import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';
import backgroundSummary from 'src/shared/assets/images/backgroundSummary.png';
import { TPropsRow } from './type';

export const ContainerPaymentSummary = styled.div<{ showMoreDetails?: boolean }>`
    width: 100%;
    height: 360px;
    bottom: 0px;
    right: 0px;
    z-index: 5;
    position: fixed;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.backgrounds.gray500};
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-radius: 24px 24px 0 0;
    box-shadow: none;

    ${(props) =>
        props.showMoreDetails &&
        `
        height: 426px;
    `};

    ${breakpoint('md')`
        height: auto;
        width:30%;
        // max-width: 291px;
        max-height: 440px;
        position: sticky;
        top: 110px;
        margin-top: 65px;
        margin-bottom: 100px;
        background-repeat: no-repeat; 
        background-size: cover;
        background-image: url(${backgroundSummary});
        border: 0.5px solid ${(props) => props.theme.colors.disabled.gray};
        box-shadow: none;
    `}

    ${breakpoint('md')`
        border-radius:  24px 24px 24px 24px;
    `}

    ${breakpoint('lg')`
        // max-width: 340px;
    `}

    ${breakpoint('xxlMax')`
        
    `}

    & > button {
        align-self: center;
        max-width: 204px;
        bottom: 2px;
        position: relative;
    }
`;

export const ContentPaymentSummary = styled.div`
    width: 100%;
    height: 100%;
`;

export const HeaderPaymentSummary = styled.header<{ showMoreDetails?: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${(props) => props.theme.colors.backgrounds.gray400};
    padding: 13px 26px 18px 25px;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.75);
    border-radius: 24px 24px 0px 0px;
    max-height: 89px;

    ${breakpoint('sm')`
        background: none;
    `}

    ${breakpoint('md')`
        border-radius:  24px 24px 24px 24px;
    `}
    ${(props) =>
        props.showMoreDetails &&
        `
        max-height: 155px;
    `};
`;

export const TitlePaymentSummary = styled.h3`
    font-family: ${(props) => props.theme.fonts.lf_bold};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: ${(props) => props.theme.colors.accents.base.yellow};
`;

export const ValueToPayment = styled.span`
    font-family: ${(props) => props.theme.fonts.lf_bold};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: ${(props) => props.theme.colors.accents.base.yellow};
`;

export const BodyPaymentSummary = styled.div`
    background: ${(props) => props.theme.colors.backgrounds.gray500};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;

    & > .backgroundBlack--large {
        width: 204px;
        font-size: 18px;
    }

    ${breakpoint('sm')`
        flex-direction: column;    
    `}

    & > button {
        width: 204px;
    }
`;

export const ModalPaymentSummary = styled.div`
    min-height: 100vh;
    min-width: 100vw;
    top: 0px;
    left: 0px;
    position: fixed;
    min-width: 100vw;
    width: 100%;
    height: 100%;
    z-index: 4;
    background: ${hexToRGB('#1d1828', 0.5)};
    padding: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ContentModalPaymentSummary = styled.div`
    width: 360px;
    height: 420px;
`;

export const HeaderModalPaymentSummary = styled.header`
    width: 100%;
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.colors.backgrounds.gray400};
    padding: 13px 25px 0px 25px;
    border-radius: 24px 24px 0 0;
`;

export const RowHeaderModalPaymentSummary = styled.div<TPropsRow>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${(props) =>
        props.separator && `border-bottom: 1px solid  ${props.theme.colors.disabled.gray};`};

    & h2 {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
    }

    & p {
        margin-top: 12px;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: ${(props) => props.theme.colors.text.gray};
        font-weight: 400;
    }

    & > span {
        margin-top: 12px;
        font-family: ${(props) => props.theme.fonts.lf_bold};
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        width: 100%;
        max-width: 80px;
    }

    ${TitlePaymentSummary} {
        margin: 10px 0;
    }

    & > button {
        align-self: flex-end;
        margin-right: 0px;
        padding: 0px;
        margin-bottom: 5px;
    }
    & > button > span {
        font-size: 24px;
        line-height: 24px;
    }

    ${ValueToPayment} {
        margin: 10px 0;
        line-height: 16px;
    }
`;

export const RowHeaderModalPaymentSummaryPadding = styled.div<TPropsRow>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;

    ${(props) =>
        props.separator && `border-bottom: 1px solid  ${props.theme.colors.disabled.gray};`};

    & p {
        margin-top: 12px;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: ${(props) => props.theme.colors.text.gray};
        font-weight: 400;
    }

    & > span {
        margin-top: 12px;
        font-family: ${(props) => props.theme.fonts.lf_bold};
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        width: 100%;
        max-width: 80px;
    }
`;

export const HeaderPaymentSummaryDesktop = styled.div`
    padding: 14px 0px;
    display: flex;
    justify-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: 0.5px solid ${(props) => props.theme.colors.disabled.gray};
`;

export const RowHeaderPaymentSummary = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const TitlePaymentDesktop = styled.p`
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    margin-right: 25px;
    width: 100%;
    max-width: 56px;
    text-align: right;
`;

export const ValueToPaymentPrimary = styled.span`
    font-family: ${(props) => props.theme.fonts.lf_bold};
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    color: #ffe000;
    width: 100%;
    max-width: 120px;
`;

export const ValueToPaymentSecondary = styled.span`
    font-family: ${(props) => props.theme.fonts.lf_bold};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 32px;
    width: 100%;
    max-width: 120px;
`;

export const TotalToPayment = styled.div`
    width: 100%;
    height: 100px;
    /* border-bottom: 1px solid ${(props) => props.theme.colors.text.gray}; */
    padding: 16px 0 18px 0;
    text-align: center;
    max-height: 96px;

    & > p {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 120%;
        margin-bottom: 12px;
    }

    & > span {
        width: 100%;
        font-family: ${(props) => props.theme.fonts.lf_bold};
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 120%;
        color: #00d455;
        /* position: relative; */
        display: flex;
        justify-content: center;
        align-items: center;
        &:before {
            width: 100%;
            height: 13px;
            content: ' ';
            background: #3e3a47;
            position: absolute;
            max-width: 277px;
            z-index: -1;
            border-radius: 12px;
        }
    }

    border-bottom: 0.5px solid ${(props) => props.theme.colors.disabled.gray};
`;

export const AlertText = styled.div`
    width: 100%;
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    max-width: 90%;
    margin: auto;
    padding-bottom: 10px;

    ${breakpoint('md')`
        max-width: 100%;
        border-top: 1px solid #c9c3c5;
        padding: 19px 35px;
        font-size: 14px;

        b{
            font-size: 14px;
        }
    `}

    b {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: #ffe000;
    }
`;

export const ContainerButtons = styled.div`
    display: flex;
    align-items: center;
    align-self: center;
    flex-direction: row;
    padding: 5px;
    a {
        text-decoration: underline;
        font-family: 'Montserrat-Bold';
        font-size: 14px;
        margin-right: 20px;
        text-align: center;
    }
`;