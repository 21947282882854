import React from 'react';
import { ModalGana } from '@componentsShared';
import { graphql, useStaticQuery } from 'gatsby';
interface IProps {
    modalState: boolean;
    setModalState: () => void;
}

export const ModalSuccessRegister = (props: IProps) => {
    const { modalState, setModalState } = props;
    const dataModalSuccess = useStaticQuery(graphql`
        query MyQuery {
            allContentfulModal(filter: { name: { eq: "Cuenta verificada" } }) {
                edges {
                    node {
                        icon {
                            file {
                                url
                            }
                        }
                        title
                        text
                        AditionalText
                    }
                }
            }
        }
    `);
    const {
        allContentfulModal: { edges }
    } = dataModalSuccess;
    const { node } = edges[0];
    const optionsModal = {
        logo: 'terciary',
        icon: node.icon.file.url,
        title: node.title,
        text: node.text,
        boldText: node.AditionalText,
        primaryButtonText: 'Iniciar sesión',
        primaryButton: () => {
            window.location.href = '/iniciar-sesion';
        },
        dimensions: { height: { mobile: '473px', desktop: '442px' }, width: { desktop: '435px' } },
        sizeButton: '159px',
        aditionalFunction: () => {
            window.location.href = '/';
        }
    };

    return (
        <ModalGana visibility={modalState} setVisibility={setModalState} options={optionsModal} />
    );
};
