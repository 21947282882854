import { ContainerChangePassword } from '@components';
import { Layout, SEO } from '@componentsShared';
import React from 'react';
import { WrapperChangePassword } from './styled';
import { graphql, useStaticQuery } from 'gatsby';

type TProps = {
    token: string;
};

export const ChangePassword = (props: TProps) => {

    const DataChangePassword = useStaticQuery(graphql`
        query DataChangePassword {
            allContentfulPaginaInternaFooter(filter: {page: {eq: "CrearContraseña"}}) {
                nodes {
                    title
                    metaData {
                        title
                        descripcion
                        keyWords
                    }
                }
            }
        }
    `);

    const {
        allContentfulPaginaInternaFooter: {
            nodes: [{ title, metaData }]
        }
    } = DataChangePassword;

    return (
        <Layout>
            <SEO
                index='noindex, nofollow'
                slug="/iniciar-sesion/cambiar-contrasena"
                title={metaData.title}
                description={metaData.descripcion}
                keyWords={metaData.keyWords}
            />
            <WrapperChangePassword>
                <ContainerChangePassword token={props.token} />
            </WrapperChangePassword>
        </Layout>
    );
};
