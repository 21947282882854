import CryptoJS from 'crypto-js';
import { removeSessionStorage } from './transferData';

const IS_GANA_ENCRYPTION_V2 = process.env.GANA_ENCRYPTION_V2 === '1';

const sKey: string = process.env.ENCRYPTION_KEY || '';
const key = CryptoJS.enc.Utf8.parse(sKey);
const iv = CryptoJS.enc.Utf8.parse(sKey);

const isBrowser = () => typeof window !== 'undefined';

export const encrypt = (sbText: string) => {
    if (sbText === undefined || sbText === null) {
        return sbText;
    }
    try {
        if (IS_GANA_ENCRYPTION_V2) {
            return CryptoJS.AES.encrypt(sbText.toString(), key, { iv: iv }).toString();
        }
        return sbText;
    } catch (error) {
        console.error('ERROR ENCRYPT', error);
        throw error;
    }
};

export const decrypt = (message: string | null) => {
    if (message === undefined || message === null) {
        return message;
    }
    try {
        if (IS_GANA_ENCRYPTION_V2) {
            const decryptMessage = CryptoJS.AES.decrypt(message, key, { iv: iv });
            return decryptMessage.toString(CryptoJS.enc.Utf8);
        }
        return message;
    } catch (error) {
        console.error('ERROR DECRYPT BACK', error);
        throw error;
    }
};

export const decryptNumber = (message: string | null) => {
    if (message === undefined || message === null) {
        return message;
    }
    const data = decrypt(message);

    const num = Number(data);
    if (isNaN(num)) {
        return data;
    }

    return num;
};

export const getValueProperty = (
    pathTxt: string,
    object: { [key: string]: any },
    defaultValue: any = null
) => {
    const path = pathTxt.split('.');
    path.forEach((item) => {
        object = object[item];
        if (!object) {
            return defaultValue;
        }
    });
    return object;
};

export const setDataEncrypt = (data: any, nameVariable: string) => {
    const varName = 'V2_' + nameVariable;
    if (isBrowser()) {
        localStorage.setItem(varName, encrypt(JSON.stringify(data)));
        return true;
    }
    return false;
};

export const getDataEncrypt = <T = any>(nameVariable: string) => {
    const varName = 'V2_' + nameVariable;
    if (isBrowser()) {
        let data: T = JSON.parse(decrypt(localStorage.getItem(varName)) || 'null');
        return data;
    }
    return null;
};

export const setSessionEncrypt = (data: any, nameVariable: string) => {
    const varName = 'V2_' + nameVariable;
    if (isBrowser()) {
        sessionStorage.setItem(varName, encrypt(JSON.stringify(data)));
        return true;
    }
    return false;
};

export const getSessionEncrypt = <T=any>(nameVariable: string) => {
    const varName = 'V2_' + nameVariable;
    if (isBrowser()) {
        let data: T = JSON.parse(decrypt(sessionStorage.getItem(varName)) || 'null');
        return data;
    }
    return null;
};

export const removeSessionEncrypt = (nameVariable: string) => {
    return removeSessionStorage('V2_' + nameVariable);
};
