import { gql } from '@apollo/client';
import client from '../../config/apollo-client-gana';

export const queriesLogin = {
    register: gql`
        mutation registro_new_experience($input: UserInput) {
            registro_new_experience(input: $input)
        }
    `,
    mailForwarding: gql`
        mutation reenvioLinkConfirmacion($input: loginInput) {
            reenvioLinkConfirmacion(input: $input) {
                message
                status
                __typename
            }
        }
    `
};

export const registerServices = {
    register: (
        firstName: string,
        lastName: string,
        type: string,
        user: string,
        bornDate: string,
        documentDate: string,
        email: string,
        genre: string,
        city: string,
        country: string,
        phone: string,
        password: string,
        mayoriaEdad: string,
        token: string,
        terminosYCondiciones : string,
        tratamientoDeDatos: string
    ) => {
        return client.mutate({
            mutation: queriesLogin.register,
            variables: {
                input: {
                    nombres: firstName,
                    primerApellido: lastName,
                    fechaNacimiento: bornDate,
                    genero: genre,
                    tipoDocumento: type,
                    usuario: user,
                    idePais: country,
                    fechaExpedicionDocumento: documentDate,
                    ideMunicipioExpedicionDocumento: city,
                    email: email,
                    celular: phone,
                    contrasena: password,
                    ipRegistro: '',
                    direccion: '',
                    ideCiudad: '',
                    ideDepartamento: '',
                    ideDepartamentoExpedicionDocumento: '',
                    idePaisExpedicionDocumento: '',
                    obligacionFiscal: '',
                    segundoApellido: '',
                    telefono: '',
                    mayoriaEdad,
                    token,
                    terminosYCondiciones,
                    tratamientoDeDatos
                }
            }
        });
    },

    mailForwarding: (tipoDocumento: string, usuario: string, clave: string) => {
        return client.mutate({
            mutation: queriesLogin.mailForwarding,
            variables: {
                input: {
                    tipoDocumento: tipoDocumento,
                    usuario: usuario,
                    clave: clave
                }
            }
        });
    }
};
