import React, { useState } from 'react';
import { DropDownCard } from '@components';
import { ButtonIcon } from '@componentsShared';
import Slider from 'react-slick';
import Viewport from 'src/shared/hooks/viewport';

import {
    Arrow,
    ContainerQuestionResponse,
    Header,
    TabButton,
    ContainerContent,
    H1Test
} from './styled';
import { graphql, useStaticQuery } from 'gatsby';

type IPropsSampleArrow = {
    onClick?: () => void;
    arrow?: string;
};

const SampleArrow = (props: IPropsSampleArrow) => {
    const { onClick, arrow } = props;

    return (
        <Arrow onClick={onClick} className={arrow}>
            <ButtonIcon
                icon={`angle-${arrow}`}
                colorDefault={'#0DF96C'}
                title={arrow === 'left' ? 'Anterior' : 'Siguiente'}
                size="small"
                disabled={false}
            />
        </Arrow>
    );
};

export const WrapperQuestionResponse = () => {
    const viewport = Viewport();

    const dataQuestions = useStaticQuery(graphql`
        query dataQuestions {
            allContentfulPaginaInternaFooter(filter: {page: {eq: "PreguntasFrecuentes"}}) {
                nodes {
                  secondarySection {
                    ... on ContentfulSeccionInternaFooter {
                      sectionName
                      sectionContent {
                        ... on ContentfulPreguntasFrecuentes {
                          question
                          answer {
                            raw
                          }
                        }
                      }
                    }
                  }
                }
            }
        }
    `);

    const { secondarySection } = dataQuestions.allContentfulPaginaInternaFooter.nodes[0];
    
    const [step, setStep] = useState(1);

    const settings = {
        className: 'slider-style',
        infinite: true,
        slidesToShow: 1,
        swipeToSlide: true,
        nextArrow: <SampleArrow arrow="right" />,
        prevArrow: <SampleArrow arrow="left" />,
        afterChange: (index: number) => {
            setStep(index + 1);
            setId(-1);
        }
    };

    const [id, setId] = useState(-1);

    const collapseCard = (idCard: number) => {
        if(id === idCard) {
            setId(-1);
        }
    }

    const collapsedComprobation = (index: number) => {
        if(id === index) {
            return false;
        }else {
            return true;
        }
    }

    return (
        <ContainerQuestionResponse>
            <Header>
                {viewport.mobile && (
                    <Slider {...settings}>
                        <TabButton>
                            <h2>GANA</h2>
                        </TabButton>
                        <TabButton>
                            <h2>
                                Pasarelas <span>de pagos</span>
                            </h2>
                        </TabButton>
                        <TabButton>
                            <h2>OKI</h2>
                        </TabButton>
                        <TabButton>
                            <h2>
                                Acompañamiento <span>en la compra</span>
                            </h2>
                        </TabButton>
                    </Slider>
                )}
                {!viewport.mobile && (
                    <>
                        <TabButton
                            active={step === 1}
                            onClick={() => {
                                setId(-1);
                                setStep(1);
                            }}
                        >
                            <h2>GANA</h2>
                        </TabButton>
                        <TabButton
                            active={step === 2}
                            onClick={() => {
                                setId(-1);
                                setStep(2);
                            }}
                        >
                            <h2>
                                Pasarelas <span>de pagos</span>
                            </h2>
                        </TabButton>
                        <TabButton
                            active={step === 3}
                            onClick={() => {
                                setId(-1);
                                setStep(3);
                            }}
                        >
                            <h2>OKI</h2>
                        </TabButton>
                        <TabButton
                            active={step === 4}
                            onClick={() => {
                                setId(-1);
                                setStep(4);
                            }}
                        >
                            <h2>
                                Acompañamiento <span>en la compra</span>
                            </h2>
                        </TabButton>
                    </>
                )}
            </Header>
            {step === 1 ? (
                <ContainerContent>
                    {secondarySection[0].sectionContent.map((item: any, index: any) => {
                        const collapsed = collapsedComprobation(index)
                        
                        return (
                            <DropDownCard
                                id={index}
                                collapsed={collapsed}
                                setId={setId}
                                collapseCard={collapseCard}
                                key={index}
                                title={item.question}
                                content={item.answer.raw}
                            />
                        );
                    })}
                </ContainerContent>
            ) : step === 2 ? (
                <ContainerContent>
                    {secondarySection[1].sectionContent.map((item: any, index: any) => {
                        const collapsed = collapsedComprobation(index)
                        
                        return (
                            <DropDownCard
                                id={index}
                                collapsed={collapsed}
                                setId={setId}
                                collapseCard={collapseCard}
                                key={index}
                                title={item.question}
                                content={item.answer.raw}
                            />
                        );
                    })}
                </ContainerContent>
            ) : step === 3 ? (
                <ContainerContent>
                    {secondarySection[2].sectionContent.map((item: any, index: any) => {
                        const collapsed = collapsedComprobation(index)
                        
                        return (
                            <DropDownCard
                                id={index}
                                collapsed={collapsed}
                                setId={setId}
                                collapseCard={collapseCard}
                                key={index}
                                title={item.question}
                                content={item.answer.raw}
                            />
                        );
                    })}
                </ContainerContent>
            ) : (
                <ContainerContent>
                    {secondarySection[3].sectionContent.map((item: any, index: any) => {
                        const collapsed = collapsedComprobation(index)
                        
                        return (
                            <DropDownCard
                                id={index}
                                collapsed={collapsed}
                                setId={setId}
                                collapseCard={collapseCard}
                                key={index}
                                title={item.question}
                                content={item.answer.raw}
                            />
                        );
                    })}
                </ContainerContent>
            )}
        </ContainerQuestionResponse>
    );
};
