import { ModalGana } from '@componentsShared';
import { setDataStorage } from '@utils/transferData';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

interface ModalDenyProps {
    modalState: boolean;
    setModalState: () => void;
}

export const ModalDenyLocation = (props: ModalDenyProps) => {
    const { modalState, setModalState } = props;
    const dataModalDenayLocation = useStaticQuery(graphql`
        query QueryDenyLocation {
            allContentfulModal(filter: { name: { eq: "Ubicacion no compartida" } }) {
                edges {
                    node {
                        icon {
                            file {
                                url
                            }
                        }
                        title
                        text
                        AditionalText
                        url,
                        customLinks {
                            type,
                            text,
                            value,
                            target
                        }
                    }
                }
            }
        }
    `);

    const {
        allContentfulModal: { edges }
    } = dataModalDenayLocation;

    const { node } = edges[0];

    const optionsModal = {
        logo: 'terciary',
        icon: node.icon.file.url,
        title: node.title,
        text: node.text,
        hyperLink: node.AditionalText,
        url: node.url,
        primaryButtonText: 'Continuar',
        primaryButton: () => {
            sessionStorage.setItem('closeModalLocation', 'true');
            setModalState();
        },
        dimensions: { height: { mobile: 'auto', desktop: 'auto' }, width: { desktop: '484px' } },
        // sizeButton: '159px'
        bold: true,
        size: { desktop: '16px' },
        aditionalFunction: () => {
            sessionStorage.setItem('closeModalLocation', 'true');
        },
        customLinks: node.customLinks
    };

    return (
        <ModalGana visibility={modalState} setVisibility={setModalState} options={optionsModal} />
    );
};
