import React, { createContext, useState } from 'react';

interface IPropsProvider {
    children: React.ReactNode;
}
interface IDataUser {
    isLogged: boolean;
    token: string;
    tipoDocumento: string;
    nombres: string;
    primerApellido: string;
    usuario: string;
    saldo: string;
    ideUsuario: string;
    puntos: number;
    fechaNacimiento: string;
    updUserData: boolean;
    descargaweb: boolean;
    email: string;
    nombreCompleto?: string;
}

interface IPropsApp {
    dataUser: IDataUser;
    setDataUser: (dataGame: IDataUser) => void;
}

export const initialUserData = {
    isLogged: false,
    token: '',
    tipoDocumento: '',
    nombres: '',
    primerApellido: '',
    usuario: '',
    saldo: '',
    ideUsuario: '',
    puntos: 0,
    fechaNacimiento: '',
    updUserData: false,
    descargaweb: false,
    email: ''
};

const UserContext = (props: IPropsProvider) => {
    const [dataUser, setDataUser] = useState<IDataUser>(initialUserData);

    const { children } = props;

    return (
        <>
            <AppContextUser.Provider value={{ dataUser, setDataUser }}>
                {children}
            </AppContextUser.Provider>
        </>
    );
};

export default UserContext;
export const AppContextUser = createContext<IPropsApp>({} as IPropsApp);
