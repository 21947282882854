import React, { useContext, useState, useEffect } from 'react';
import { Button, ButtonIcon, Redirect } from '@componentsShared';
import {
    BodyPaymentSummary,
    ContainerButtons,
    ContainerPaymentSummary,
    ContentModalPaymentSummary,
    ContentPaymentSummary,
    HeaderModalPaymentSummary,
    HeaderPaymentSummary,
    HeaderPaymentSummaryDesktop,
    ModalPaymentSummary,
    RowHeaderModalPaymentSummary,
    RowHeaderModalPaymentSummaryPadding,
    RowHeaderPaymentSummary,
    TitlePaymentDesktop,
    TitlePaymentSummary,
    TotalToPayment,
    ValueToPayment,
    ValueToPaymentPrimary,
    ValueToPaymentSecondary
} from './styled';
import { useScrollBlock } from 'src/shared/hooks/useScrollBlock';
import Viewport from 'src/shared/hooks/viewport';
import { SelectPayment } from '../selectPayment';
import { IProps } from './type';
import { converterNumber } from '@utils/decimalNumberConverter';
import { colors } from 'src/shared/styles/variables';
import { carServices } from '@services/car';
import { getDataStorage, setDataStorage } from '@utils/transferData';
import { AppContextUser } from 'src/shared/context/user';
import { pushEskEvent, pushFbqEvent, pushGtagEvent } from '@utils/metricManagementUtil';
import { addBeat } from '@utils/egoi';
import { trackEcommerceOrder } from '@utils/egoi';
import { getSessionEncrypt, setSessionEncrypt } from '@utils/security';
import { UserLocation, hasLocation } from '@utils/location';

export const DetailToPay = (props: IProps) => {
    const [isCollapse, setIsCollapse] = useState(false);
    const [isColapseMoreDetails, setIsColapseMoreDetails] = useState(false);
    const [paymentSelected, setPaymentSelected] = useState('PlaceToPay');
    const [blockScroll, allowScroll] = useScrollBlock();
    const { dataUser } = useContext(AppContextUser);

    const {
        betsCart,
        setPayment,
        isDisablePaymentButton,
        setModalLocated,
        setModalNotLocated,
        showToast,
        location,
        setLocation
    } = props;

    const viewport = Viewport();

    const guardarOrden = () => {
        if (!betsCart) {
            return;
        }
        betsCart.bets.forEach((element) => {
            addBeat(element);
        });
        trackEcommerceOrder(betsCart.id.toString(), betsCart.total, betsCart.iva);
    };

    const fetchUrlPayment = async () => {
        setPayment(true);
        try {
            if (hasLocation(location)) {
                setSessionEncrypt(location, 'hasLocation');
            }
            if (paymentSelected === 'PlaceToPay' && betsCart !== undefined) {
                await carServices
                    .placeToPayUrl(
                        dataUser.token,
                        dataUser.tipoDocumento,
                        dataUser.usuario,
                        betsCart.id,
                        location.latitude || 0,
                        location.longitude || 0
                    )
                    .then((data) => {
                        if (data.errors === undefined) {
                            guardarOrden();
                            initialCheckoutScripConversion();
                            window.location.href = data.data.placeToPayUrl;
                        } else {
                            showToast(data.errors[0].message);
                            setPayment(false);
                            setDataStorage(false, 'isLocated');
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setPayment(false);
                        setDataStorage(false, 'isLocated');
                    }).finally(() => {
                        setLocation({});
                    });
            } else if (betsCart !== undefined) {
                await carServices
                    .payValidaUrl({
                        token: dataUser.token,
                        tipoDocumento: dataUser.tipoDocumento,
                        usuario: dataUser.usuario,
                        cartId: betsCart.id,
                        latitud: location.latitude || 0,
                        longitud: location.longitude || 0
                    })
                    .then((data) => {
                        if (data.errors === undefined) {
                            guardarOrden();
                            initialCheckoutScripConversion();
                            window.location.href = 'https://' + data.data.payValidaUrl;
                        } else {
                            showToast(data.errors[0].message);
                            setPayment(false);
                            setDataStorage(false, 'isLocated');
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setPayment(false);
                        setDataStorage(false, 'isLocated');
                    }).finally(() => {
                        setLocation({});
                    });
            }
        } catch (error) {
            setDataStorage(false, 'isLocated');
            setPayment(false);
            if (typeof error === 'string') {
                showToast(error);
            }
            console.log(error);
        }
    };

    const validations = () => {
        const isNotLocated = getDataStorage('isNotLocated');
        const hasLocation = getSessionEncrypt<UserLocation>('hasLocation');
        if (hasLocation) {
            setLocation(hasLocation);
        } else if (isNotLocated) {
            setModalNotLocated(true);
        } else {
            setModalLocated(true);
        }
    };

    useEffect(() => {
        if (hasLocation(location)) {
            fetchUrlPayment();
        }
    }, [location]);

    const initialCheckoutScripConversion = () => {
        pushGtagEvent('begin_checkout', {
            transaction_id: '',
            value: (betsCart?.total || 0) - (betsCart?.iva || 0),
            tax: betsCart?.iva,
            currency: 'COP',
            coupon: ''
        });
        pushFbqEvent('InitiateCheckout', {
            currency: 'COP',
            value: betsCart?.total || 0
        });
        pushEskEvent();
    };

    return (
        <ContainerPaymentSummary showMoreDetails={isColapseMoreDetails}>
            {isCollapse && (
                <ModalPaymentSummary>
                    <ContentModalPaymentSummary>
                        <HeaderModalPaymentSummary>
                            <RowHeaderModalPaymentSummary separator={true}>
                                <h2>Resumen</h2>
                                <ButtonIcon
                                    icon="cross-small"
                                    title="Cerrar"
                                    onClick={() => {
                                        setIsCollapse(false);
                                        allowScroll();
                                    }}
                                ></ButtonIcon>
                            </RowHeaderModalPaymentSummary>
                            <RowHeaderModalPaymentSummary>
                                <p>Subtotal</p>
                                <span>
                                    ${' '}
                                    {converterNumber((betsCart?.total || 0) - ((betsCart?.iva || 0)))}
                                </span>
                            </RowHeaderModalPaymentSummary>
                            <RowHeaderModalPaymentSummary separator={true}>
                                <p>IVA</p>
                                <span>$ {converterNumber(betsCart?.iva)}</span>
                            </RowHeaderModalPaymentSummary>
                            <RowHeaderModalPaymentSummary>
                                <TitlePaymentSummary>Valor total a pagar</TitlePaymentSummary>
                                <ValueToPayment>
                                    $ {converterNumber(betsCart?.total)}
                                </ValueToPayment>
                            </RowHeaderModalPaymentSummary>
                        </HeaderModalPaymentSummary>
                    </ContentModalPaymentSummary>
                </ModalPaymentSummary>
            )}
            {!isCollapse && viewport.mobile && (
                <ContentPaymentSummary>
                    <HeaderPaymentSummary showMoreDetails={isColapseMoreDetails}>
                        <RowHeaderModalPaymentSummary separator={true}>
                            <h2>Resumen</h2>
                            <ButtonIcon
                                icon={!isColapseMoreDetails ? 'angle-small-up' : 'angle-small-down'}
                                title="Cerrar"
                                colorDefault={colors.principal.greenBase}
                                onClick={() => {
                                    setIsColapseMoreDetails(!isColapseMoreDetails);
                                }}
                            ></ButtonIcon>
                        </RowHeaderModalPaymentSummary>
                        {isColapseMoreDetails && (
                            <>
                                <RowHeaderModalPaymentSummary>
                                    <p>Subtotal</p>
                                    <span>
                                        ${' '}
                                        {converterNumber(
                                            (betsCart?.total || 0) - (betsCart?.iva || 0)
                                        )}
                                    </span>
                                </RowHeaderModalPaymentSummary>
                                <RowHeaderModalPaymentSummaryPadding separator={true}>
                                    <p>IVA</p>
                                    <span>$ {converterNumber(betsCart?.iva)}</span>
                                </RowHeaderModalPaymentSummaryPadding>
                            </>
                        )}
                        <RowHeaderModalPaymentSummary>
                            <TitlePaymentSummary>Valor total a pagar</TitlePaymentSummary>
                            <ValueToPayment>$ {converterNumber(betsCart?.total)}</ValueToPayment>
                        </RowHeaderModalPaymentSummary>
                    </HeaderPaymentSummary>
                    <BodyPaymentSummary>
                        <SelectPayment
                            paymentSelected={paymentSelected}
                            setPaymentSelected={setPaymentSelected}
                            totalToPay={Number(dataUser.saldo)}
                        />
                        <ContainerButtons>
                            <Redirect url="juegos/" text="Agregar otra apuesta" />
                            <Button
                                typeButton="backgroundBlack--large"
                                text="Pagar"
                                disabled={isDisablePaymentButton}
                                onClick={validations}
                            ></Button>
                        </ContainerButtons>
                    </BodyPaymentSummary>
                </ContentPaymentSummary>
            )}

            {!viewport.mobile && (
                <>
                    <HeaderPaymentSummaryDesktop>
                        <RowHeaderPaymentSummary>
                            <TitlePaymentDesktop>Subtotal</TitlePaymentDesktop>
                            <ValueToPaymentPrimary>
                                $ {converterNumber((betsCart?.total || 0) - (betsCart?.iva || 0))}
                            </ValueToPaymentPrimary>
                        </RowHeaderPaymentSummary>
                        <RowHeaderPaymentSummary>
                            <TitlePaymentDesktop>IVA</TitlePaymentDesktop>
                            <ValueToPaymentSecondary>
                                $ {converterNumber(betsCart?.iva)}
                            </ValueToPaymentSecondary>
                        </RowHeaderPaymentSummary>
                    </HeaderPaymentSummaryDesktop>
                    <TotalToPayment>
                        <p>Total a pagar</p>
                        <span>$ {converterNumber(betsCart?.total)}</span>
                    </TotalToPayment>
                    <SelectPayment
                        paymentSelected={paymentSelected}
                        setPaymentSelected={setPaymentSelected}
                        totalToPay={Number(dataUser.saldo)}
                    />
                    <ContainerButtons>
                        <Redirect url="juegos/" text="Agregar otra apuesta" />
                        <Button
                            text="Pagar"
                            disabled={isDisablePaymentButton}
                            onClick={validations}
                        />
                    </ContainerButtons>
                </>
            )}
        </ContainerPaymentSummary>
    );
};
