import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

interface IProps {
    top?: boolean;
    active?: boolean;
    cha?: boolean;
}

export const ContainerWrapper = styled.div<IProps>`
    background: ${(props) => props.theme.colors.backgrounds.gray500};
    border: 1px solid rgba(197, 195, 201, 0.5);
    border-radius: 16px;
    max-height: 55px;
    margin-top: ${(props: IProps) => (props.cha ? '24px' : '40px')};
    overflow: hidden;
    width: ${(props: IProps) => (props.cha ? '100%' : '95%')};

    transition: all 0.3s ease-in-out;
    transition-delay: 0.1s;

    &.open {
        max-height: 1200px;
    }

    ${breakpoint('md')`
        max-height: 40px;
        width: 100%;

        &.open {
            max-height: 1200px;
        }
    `}
`;

export const HeaderWrapper = styled.div<IProps>`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 7px 25px 0 25px;

    h1 {
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        width: auto;

        span {
            color: ${(props) => props.theme.colors.accents.base.yellow};
        }
    }

    button span {
        font-size: 12px;
    }

    &:before {
        content: '';
        display: inline-block;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid transparent;
    }

    ${breakpoint('md')`
        margin: ${(props: IProps) => (props.top ? '9px 37px 11px 37px' : '13px 37px 11px 37px')};

        h1 {
            width: auto;
        }

        button span {
            font-size: 14px;
        }
    `}
`;

export const Hr = styled.div`
    border-top: 0.5px solid ${(props) => props.theme.colors.disabled.gray};
    margin: 8px 13px 0 13px;
    opacity: 0.5;

    &.color-bar {
        border-top: 3px solid ${(props) => props.theme.colors.principal.greenNeon};
        opacity: 1;
        margin: 15px auto;
        width: 100px;
    }

    ${breakpoint('md')`
        margin: 8px 35px 0 35px;
    `}
`;

export const HeaderContent = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 27px;
    padding: 0 24px 0 24px;

    > p {
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-size: 14px;

        span {
            color: ${(props) => props.theme.colors.accents.base.yellow};
        }
    }

    button span {
        font-size: 10px;
    }

    ${breakpoint('md')`
        justify-content: space-around;

        button span {
            font-size: 14px;
        }
    `}
`;

export const StepButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-size: 14px;
    height: 45px;
    position: relative;
    width: 140px;

    p {
        color: ${(props) => props.theme.colors.backgrounds.gray400};
        transition: all 0.2s ease-in-out;
    }

    span {
        color: ${(props) => props.theme.colors.backgrounds.gray400};
        transition: all 0.2s ease-in-out;
    }

    ${(props: IProps) =>
        props.active ?
        `
        p {
            color: #F8F8F8;
        }

        span {
            color: #FFE000;
        }

        &:after {
            content: ' ';
            background: #0DF96C;
            height: 3px;
            width: 100px;
            position: absolute;
            bottom: -10px;
            left: calc(50% - 50px);
        }
        `
        : 
        `
        &:hover {
            p, span {
                color: #C5C3C9; 
            }
        }
        `
    };
`;

export const ContainerContent = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center; 
`;

export const StepThree = styled(ContainerContent)`
    margin-bottom: 31px;

    ${breakpoint('md')`
        height: auto;
        margin-top: 31px;
        margin-bottom: 31px;
    `}
`;

export const StepOne = styled(ContainerContent)`
    max-height: 530px;

    ${breakpoint('md')`
        max-height: 900px;
    `}
`;

export const StepTwo = styled(ContainerContent)`
    max-height: 600px;
    padding-bottom: 20px;

    ${breakpoint('md')`
        max-height: 900px;
        padding-bottom: 0px;
    `}
`;
