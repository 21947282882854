import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';
import Ellipse from 'src/shared/assets/images/Ellipse.png';
import isAllyBackground from 'src/shared/assets/images/background_login.png';

export const MainContainer = styled.div`
    max-width: 995px;
    border: 0.5px solid ${(props) => props.theme.colors.principal.greenNeon};
    margin: auto;
    border-radius: 16px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    max-height: 256px;
    flex-direction: column;
    align-items: center;
    background: ${(props) => props.theme.colors.backgrounds.gray500};
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${isAllyBackground});
    margin-top: 40px;
    // padding: 0 60px;

    & > div:nth-child(2n) {
        order: 1;

        ${breakpoint('md')({
            order: '0'
        })}
    }

    ${(props) =>
        breakpoint('md')({
            background: props.theme.colors.backgrounds.gray500,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage: 'url(' + Ellipse + ')',
        })};

    ${breakpoint('md')({
        padding: '0 40px',
        height: '94px',
        flexDirection: 'row',
        margin: '32px auto 0',
    })}
`;

export const SectionOne = styled.div`
    margin: auto;
    text-align: center;
    width: 230px;
    border-bottom: 1px solid #3e3a47;

    p {
        padding: 20px 20px 10px;
        // padding-top: 32px;
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-size: 18px;
        line-height: 21.6px;

        ${(props) =>
            breakpoint('md')({
                fontFamily: props.theme.fonts.mon_bold,
                fontSize: '22px',
                lineHeight: '26.4px',
                padding: '0',
                paddingTop: '0'
            })};

        b {
            color: ${(props) => props.theme.colors.accents.base.yellow};
        }
    }

    ${breakpoint('md')({
        width: '330px',
        marginRight: '30px',
        border: 'none',
        paddingRight: '10px',
        borderRight: '1px solid #3E3A47',
        textAlign: 'left'
    })}
`;
export const SectionTwo = styled.div`
    text-align: center;
    padding: 10px 20px;
    width: 100%;

    h3 {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        font-size: 16px;
        line-height: 19.2px;

        ${breakpoint('md')({})}
    }

    p {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 16px;
        line-height: 19.2px;

        ${breakpoint('md')({})}

        b {
            font-family: ${(props) => props.theme.fonts.lf_bold};
        }
    }

    ${breakpoint('md')({
        margin: '0',
        padding: '0',
        width: '100%',
        textAlign: 'center'
        // paddingRight: '60px'
    })}
`;

export const FormCase = styled.form`
    width: 100%;
    ${breakpoint('md')({
        display: 'flex',
        paddingRight: '40px'
    })}

    button {
        width: 155px;
        margin: auto auto 10px;
        height: 48px;
        ${breakpoint('md')({
            height: '32px',
            margin: 'auto',
            width: '115px'
        })}
    }

    div {
        border-bottom: 1px solid #3e3a47;
        width: 232px;
        margin: auto;
        padding: 0;
        padding-top: 6px;
        padding-bottom: 20px;

        ${breakpoint('md')({
            width: '100%',
            display: 'flex',
            maxWidth: '461px',
            borderRight: '1px solid #3E3A47',
            padding: '0',
            paddingRight: '40px',
            borderBottom: 'none'
        })}
    }

    & > div {
        border: none;
        ${breakpoint('md')({
            border: 'none'
        })}
    }
`;

export const SectionThree = styled.div`
    text-align: center;
    padding: 12px 20px 20px;
    border-top: 1px solid #3e3a47;
    width: 260px;
    border-bottom: 1px solid #3e3a47;

    button {
        margin: auto;

        ${breakpoint('md')({
            margin: 'none'
        })}
    }

    ${breakpoint('md')({
        margin: '0',
        padding: '0',
        width: 'auto',
        textAlign: 'center',
        paddingRight: '20px',
        border: 'none'
    })}

    p {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        ${breakpoint('md')({
            paddingBottom: '10px'
        })}
    }
`;

export const ContentTooltip = styled.div`
    width: 100%;
    margin: 13px 45px 0 16px;

    & > span {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        color: #1d1828;
    }

    ul {
        margin-left: 19px;
    }
`;
export const ItemList = styled.li`
    display: flex;

    p {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #1d1828;
    }

    &::before {
        content: '\\2022';
        margin-right: 10px;
        margin-left: -10px;
        font-size: large;
        color: ${(props) => props.theme.colors.principal.greenBase};
    }

    span {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        color: #1d1828;
    }
`;
