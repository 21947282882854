import { ErrorSpan } from '@componentsShared';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Container,
    SelectContainer,
    LabelText,
    IconArrow,
    SelectContainerList,
    SelectContainerOptions,
    SelectOption,
    DescriptionField,
    ContentError
} from './styled';

interface IOption {
    value: string;
    text: string;
}

interface IProps {
    options: Array<IOption>;
    placeholder?: string;
    name: string;
    required?: string;
    label: string;
    defaultSelection?: IOption;
}

export const SelectDate = (props: IProps) => {
    let optionEmpty: IOption = {
        value: '',
        text: ''
    };

    const { options, placeholder, name, required, label, defaultSelection } = props;

    const { register, formState, setValue, clearErrors, getValues } = useFormContext();

    const [isOpen, setIsOpen] = useState(false);
    const [selection, setSelection] = useState<IOption>(optionEmpty);

    useEffect(() => {
        if (defaultSelection !== undefined) {
            setSelection(defaultSelection);
            setValue(name, defaultSelection.value);
        } else {
            setTimeout(() => {
                options.forEach((element) => {
                    if (element.value === getValues(name)) {
                        setSelection(element);
                        setValue(name, element.value);
                    }
                });
            }, 500);
        }
    }, []);

    return (
        <Container error={formState.errors[name]}>
            <DescriptionField>{label}</DescriptionField>
            <SelectContainer onClick={() => setIsOpen(!isOpen)}>
                <LabelText>{selection.text === '' ? placeholder : selection.text}</LabelText>
                <IconArrow open={isOpen}> </IconArrow>
            </SelectContainer>
            <input type="hidden" id={'#' + name} {...register(name, { required: required })} />
            {formState.errors[name]?.message && (
                <ContentError>
                    <ErrorSpan text={formState.errors[name]?.message}></ErrorSpan>
                </ContentError>
            )}
            <SelectContainerList error={formState.errors[name]} state={isOpen}>
                <SelectContainerOptions
                    role="listbox"
                    activeScroll={
                        options
                            ? options.length > 3
                                ? true
                                : placeholder && options.length > 2
                                ? true
                                : false
                            : false
                    }
                >
                    {placeholder && (
                        <SelectOption
                            type="button"
                            onClick={() => {}}
                            key="a1"
                        >
                            {placeholder}
                        </SelectOption>
                    )}

                    {options &&
                        options.map((option, key) => (
                            <SelectOption
                                type="button"
                                onClick={() => {
                                    clearErrors(name);
                                    setValue(name, option.value);
                                    setIsOpen(false);
                                    setSelection(option);
                                }}
                                key={key}
                            >
                                {option.text}
                            </SelectOption>
                        ))}
                </SelectContainerOptions>
            </SelectContainerList>
        </Container>
    );
};
