import React from 'react';
import Viewport from 'src/shared/hooks/viewport';
import { StepperContainer, StepperList } from './styled';

type TProps = {
    step1?: string;
    step2?: string;
    step3?: string;
};

export const Stepper = (props: TProps) => {
    const viewport = Viewport();
    return (
        <StepperContainer>
            <StepperList>
                <li
                    className={`${props.step1 === '1' ? 'active' : ''} ${
                        props.step1 === 'ok' ? 'ready' : ''
                    }   `}
                >
                    <a className="circle" href="#">
                        1
                    </a>
                    {!viewport.mobile && <span>Datos personales</span>}
                </li>
                <li
                    className={`${props.step2 === '2' ? 'active' : ''} ${
                        props.step2 === 'ok' ? 'ready' : ''
                    }`}
                >
                    <a className="circle" href="#">
                        2
                    </a>
                    {!viewport.mobile && <span>Verificación</span>}
                </li>
                <li
                    className={`${props.step3 === '3' ? 'active' : ''} ${
                        props.step3 === 'ok' ? 'ready' : ''
                    } `}
                >
                    <a className="circle" href="#">
                        3
                    </a>
                    {!viewport.mobile && <span>Datos de contacto</span>}
                </li>
            </StepperList>
        </StepperContainer>
    );
};
