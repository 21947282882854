import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
`

export const SwitchContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const LabelCustom = styled.label`
    cursor: pointer;
    text-indent: -9999px;
    width: 30px;
    height: 16px;
    background: ${(props)=>props.theme.colors.disabled.gray};
    display: block;
    border-radius: 100px;
    position: relative;
    

    &:after {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 12px;
        height: 12px;
        background: ${(props)=>props.theme.colors.text.gray};
        border-radius: 90px;
        transition: 0.3s;
    }
`

export const SwitchCustom = styled.input`

    height: 0;
    width: 0;
    display: none;

    &:checked + ${LabelCustom} {
        background: ${(props)=>props.theme.colors.principal.greenNeon};
    }

    &:checked + ${LabelCustom}:after {
        left: calc(100% - 2px);
        transform: translateX(-100%);
    }

    ${LabelCustom}:active:after {
        width: 100px;
    }

`

export const LabelText = styled.label`
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 16px;
    margin-left: 8px;
`   
