import React, { useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Carousel, Timer } from '@componentsShared';
import Viewport from 'src/shared/hooks/viewport';
import { IArrow, IBanner, IPropsBanner } from './types';
import {
    WrapperBanner,
    ArrowCustom,
    ContentBanner,
    BgBanner,
    InfoBanner,
    TimerBanner,
    BoxTime,
    TwoPoints,
    LinkGame
} from './styled';
import moment from 'moment';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const SampleArrowCustom = (props: IArrow) => {
    const { onClick, side } = props;
    return (
        <ArrowCustom onClick={onClick} className={side}>
            <svg width="99" height="67" viewBox="0 0 99 67" fill="none">
                <rect
                    width="127"
                    height="67"
                    rx="24"
                    transform="matrix(-1 0 0 1 99 0)"
                    fill="#272231"
                    fillOpacity="0.75"
                />
                <path
                    d="M72.5054 52C72.8039 52.0006 73.0959 51.9119 73.3437 51.745C73.5894 51.5821 73.7779 51.346 73.8825 51.07C73.9981 50.7954 74.0292 50.4925 73.9723 50.2C73.9155 49.9107 73.7749 49.6446 73.5681 49.435L61.3392 37.1801C60.4983 36.3363 60.0258 35.1926 60.0258 34.0001C60.0258 32.8076 60.4983 31.6639 61.3392 30.8201L73.5681 18.5652C73.8428 18.2797 73.9935 17.8967 73.9872 17.5002C73.9872 17.1024 73.8296 16.7209 73.5489 16.4395C73.2682 16.1582 72.8874 16.0002 72.4904 16.0002C72.0947 15.9939 71.7126 16.1449 71.4277 16.4202L59.1986 28.6751C58.5017 29.3718 57.9487 30.1994 57.5714 31.1106C57.1941 32.0218 57 32.9986 57 33.9851C57 34.9716 57.1941 35.9484 57.5714 36.8595C57.9487 37.7707 58.5017 38.5984 59.1986 39.2951L71.4277 51.55C71.5681 51.6935 71.7358 51.8073 71.9209 51.8846C72.106 51.9619 72.3048 52.0011 72.5054 52Z"
                    fill="#C5C3C9"
                />
            </svg>
        </ArrowCustom>
    );
};

export const BannerHomePpal = (props: IPropsBanner) => {
    const viewport = Viewport();
    const { banner } = props;
    const [timers, setTimers] = useState({
        days: '0',
        hours: '0',
        minutes: '0',
        seconds: '0'
    });

    const customSettings = {
        dots: true,
        infinite: true,
        autoplay: false,
        speed: 1000,
        autoplaySpeed: 10000,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: viewport.mobile ? false : <SampleArrowCustom side="next" />,
        prevArrow: viewport.mobile ? false : <SampleArrowCustom side="prev" />
    };

    return (
        <WrapperBanner>
            <Carousel customSettings={customSettings}>
                {banner.map((ban: IBanner, index) => {
                    const {
                        clock,
                        clockDate,
                        desktopImage,
                        mobileImage,
                        locationInformativeText,
                        informativeText,
                        url,
                        buttonText,
                        description
                    } = ban;

                    const seconds = moment(clockDate).diff(moment(new Date()), 'seconds');
                    const imageDesk = getImage(desktopImage);
                    const imageMobil = getImage(mobileImage);
                    return (
                        <ContentBanner key={index} className={locationInformativeText}>
                            <Timer timeSeconds={seconds} setTimers={setTimers} />
                            <BgBanner>
                                {viewport.desktop || viewport.tablet
                                    ? imageDesk !== undefined && (
                                          <GatsbyImage
                                              image={imageDesk}
                                              alt={desktopImage.description}
                                          />
                                      )
                                    : imageMobil !== undefined && (
                                          <GatsbyImage
                                              image={imageMobil}
                                              alt={mobileImage.description}
                                          />
                                      )}
                            </BgBanner>
                            {informativeText ? (
                                <InfoBanner>
                                    {documentToReactComponents(JSON.parse(informativeText.raw))}
                                    {clock && (
                                        <TimerBanner>
                                            <BoxTime>
                                                <h3>{timers.days}</h3>
                                                <span>Días</span>
                                            </BoxTime>
                                            <TwoPoints>:</TwoPoints>
                                            <BoxTime>
                                                <h3>{timers.hours}</h3>
                                                <span>Horas</span>
                                            </BoxTime>
                                            <TwoPoints>:</TwoPoints>
                                            <BoxTime>
                                                <h3>{timers.minutes}</h3>
                                                <span>Min.</span>
                                            </BoxTime>
                                            <TwoPoints>:</TwoPoints>
                                            <BoxTime>
                                                <h3>{timers.seconds}</h3>
                                                <span>Seg.</span>
                                            </BoxTime>
                                        </TimerBanner>
                                    )}
                                    <LinkGame className={locationInformativeText} href={url}>
                                        {buttonText}
                                    </LinkGame>
                                </InfoBanner>
                            ) : (
                                <LinkGame className={locationInformativeText} href={url}>
                                    {buttonText}
                                </LinkGame>
                            )}
                        </ContentBanner>
                    );
                })}
            </Carousel>
        </WrapperBanner>
    );
};
