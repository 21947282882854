import { SUERTUDO_SIGLA, SUERTUDO_STORAGE_DATA_GAME, SUERTUDO_URL_BASE } from '@constants/suertudoConstants';
import { lotteriesServices } from '@services/lotteries';
import { suertudoServices } from '@services/suertudo';
import { setSessionEncrypt } from '@utils/security';
import siteUrl from '@utils/siteUrl';
import moment, { Moment } from 'moment';
import { IBets } from 'src/shared/components/molecules/cartBodyDetail/types';

export interface IBet {
    number: string;
    numberOne: string;
    numberTwo: string;
    numberThree: string;
    numberFour: string;
    collapse: boolean;
}

export type ILottery = {
    ideLoteria: number;
    desLoteria: string;
    abreviatura: string;
    horaCierre: string;
    numCifras: number;
    sorteo: string;
    numeroFavorito?: string;
    disponible?: boolean;
};

export interface IElement {
    ideElemento: number;
    desElemento: string;
    abreviatura: string;
}

export interface IEvent {
    idEvento: number;
    desEvento: string;
    abreviatura: string;
    horaCierre: string;
    horaSorteo: string;
    sorteo: string;
}

export interface IButt {
    name: string;
    date: string;
    lottery: ILottery;
    valueTotal: number;
    valueTotalElement: number;
    totalIva: number;
    totalIvaElement: number;
    bet: IBet;
    collapse: boolean;
    id: number;
    sigla: string;
    cifra: number;
    isValid: boolean;
    event: IEvent;
    elements: IElement[];
    idBetCart?: string;
}

export interface IDataGame {
    valueTotal: number;
    valueTotalElement: number;
    valueToWin: number;
    valueToWin4C: number;
    valueToWin4CEvent: number;
    valueToWin3C: number;
    valueToWin3CEvent: number;
    valueToWin2C: number;
    valueToWin2CEvent: number;
    points: number;
    totalPoints: number;
    betIva: number;
    butts: IButt[];
    betValue: number;
    elementValue: number;
    elementIva: number;
    actualDate: Moment;
}

export const editSuertudo = async (bet: IBets) => {
    let betSuertudo: IBet = {
        collapse: false,
        number: bet.betNumbers[0].numbers.join(''),
        numberOne: getNumber(bet.betNumbers[0].numbers[0]),
        numberTwo: getNumber(bet.betNumbers[0].numbers[1]),
        numberThree: getNumber(bet.betNumbers[0].numbers[2]),
        numberFour: getNumber(bet.betNumbers[0].numbers[3])
    };

    let elements: IElement[] = []

    bet.elementoschance.map((item) => {
        let element: IElement = {
            abreviatura: item.abreviatura,
            desElemento: item.desElemento,
            ideElemento: item.ideElemento
        }
        elements.push(element);
    });

    const dateSelected = moment.utc(bet.date_sorteo, 'YYYY-MM-DD');
    const dateLotteries = dateSelected.format('DD-MM-YYYY');

    let lottery: ILottery = {} as ILottery;

    await lotteriesServices.getLotteries(dateLotteries, bet.sigla)
    .then((data) => {
        const lotteries: ILottery[] = parsedLotteries(data.data?.getGameInfo?.loterias);
        lottery = lotteries.find(lot => {
            return (lot.ideLoteria === bet.loteries[0]?.loterieId);
        }) || {} as ILottery;
    })
    .catch(err => {
        console.log('Error', err);
        lottery = {} as ILottery;
    });

    const butt: IButt = {
        bet: betSuertudo,
        cifra: 4,
        collapse: false,
        date: dateLotteries,
        elements: elements,
        event: {
            abreviatura: bet.eventochance.abreviatura,
            desEvento: bet.eventochance.desEvento,
            horaCierre: bet.eventochance.horaCierre,
            horaSorteo: '',
            idEvento: bet.eventochance.idEvento,
            sorteo: bet.eventochance.sorteo
        },
        id: bet.numero_colilla || 1,
        lottery: lottery,
        isValid: true,
        name: bet.nombre_colilla || 'Apuesta 1',
        sigla: SUERTUDO_SIGLA,
        totalIva: bet.iva,
        totalIvaElement: bet.ivaAdicional,
        valueTotal: bet.total || 0,
        valueTotalElement: bet.vlrTotalAdicional,
        idBetCart: bet.id
    }

    const dataGame: IDataGame = {
        actualDate: moment().utc(),
        betIva: 0,
        betValue: 0,
        butts: [butt],
        elementIva: 0,
        elementValue: 0,
        points: 0,
        totalPoints: 0,
        valueTotal: 0,
        valueTotalElement: 0,
        valueToWin: 0,
        valueToWin2C: 0,
        valueToWin2CEvent: 0,
        valueToWin3C: 0,
        valueToWin3CEvent: 0,
        valueToWin4C: 0,
        valueToWin4CEvent: 0
    }
    console.log(dataGame);
    setSessionEncrypt(dataGame, SUERTUDO_STORAGE_DATA_GAME);
    window.location.href = siteUrl + SUERTUDO_URL_BASE;
};

const getNumber = (num: number) => {
    if (!num) {
        return '0';
    }
    return num.toString();
};

const parsedLotteries = (lotteries: ILottery[]): ILottery[] => {
    const newLotteriesList: ILottery[] = lotteries.map((lott) => {
        lott.sorteo = lott.sorteo.toString();
        lott.abreviatura = lott.abreviatura.toLowerCase();
        lott.disponible = true;

        return lott;
    });
    return newLotteriesList;
};