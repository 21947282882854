import styled from 'styled-components'

export const ContentSlider = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    .slick-slider {
        width: 100%;
        height: 100%;
        .slick-list {
            padding: 40px 0;
            width: 100%;
            height: 100%;
        }
        .slick-dots {
            bottom: -35px;
            li {
                button {
                    &:before {
                        font-size: 10px;
                    }
                }
            }
        }
    }
`;