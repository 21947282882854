import React from 'react';
import { ButtonCustom } from './styled';

interface IButton {
    typeButton?: string;
    text: string;
    onClick?: () => void;
    icon?: string;
    disabled?: boolean;
    submit?: boolean;
}

export const Button = (props: IButton) => {
    const { typeButton, text, onClick, icon, disabled, submit } = props;

    return (
        <ButtonCustom
            disabled={disabled}
            type={submit ? 'submit' : 'button'}
            className={typeButton}
            onClick={onClick}
        >
            {text} {icon !== undefined && <span className={'icon-' + icon}></span>}
        </ButtonCustom>
    );
};
