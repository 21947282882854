export type TRegisterData = {
    firstName: string;
    lastName: string;
    day: string;
    month: string;
    year: string;
    genre: string;
    type: string;
    user: string;
    mayoriaEdad: string
    terminosYCondiciones: string
    tratamientoDeDatos: string
};

export enum OPCIONES {
    ACEPTAR = "S",
    RECHAZAR = "N"
}