import CryptoJS from 'crypto-js';

export const encryptText = (textToEncrypt) => {
    const key = process.env.ENCRYPTION_KEY
    const encryptedKey = CryptoJS.enc.Utf8.parse(key)
    const iv = CryptoJS.enc.Utf8.parse(key)

    const encryptedText = CryptoJS.AES.encrypt(textToEncrypt.toString(), encryptedKey, { iv }).toString()
    
    return encryptedText
}