import React, { useEffect, useState } from 'react';
import { Button } from '@componentsShared';
import {
    BodyModalPaymentSummary,
    LabelOptionPayment,
    OptionPayment,
    OptionsToPayment,
    RadioButtonOptionPayment
} from './styled';

import { carServices } from '@services/car';
import logoOki from 'src/shared/assets/images/Logo-Oki.svg';
import logoMasterCard from 'src/shared/assets/images/Logo_Mastercard.svg';
import logoPse from 'src/shared/assets/images/Logo_Pse.svg';
import logoVisa from 'src/shared/assets/images/Logo_Visa.svg';
import { converterNumber } from '@utils/decimalNumberConverter';
import { ConfisysPayments, getPayments, isActivePayment, Payments, getPaymentsActive, getPaymentsInactive } from '@utils/payment';

type TProps = {
    setPaymentSelected: (text: string) => void;
    paymentSelected: string;
    totalToPay?: number;
};

export const SelectPayment = (props: TProps) => {
    const [paymentMethods, setPaymentMethods] = useState<Payments>(getPaymentsInactive());
    const { paymentSelected, setPaymentSelected, totalToPay = 0 } = props;

    const setDefaultPayment = (payments: Payments) => {
        if (isActivePayment(payments, ConfisysPayments.PLACETOPAY)) {
            setPaymentSelected('PlaceToPay');
        } else if (isActivePayment(payments, ConfisysPayments.PAYVALIDA)) {
            setPaymentSelected('PayValida');
        }
    }

    const getConfisysPaymentGateway = async () => {
        await carServices.getConfisysPaymentGateway()
            .then((data) => {
                const {
                    data: { getConfisysPaymentGateway }
                } = data;
                const payments = getPayments(getConfisysPaymentGateway);
                setPaymentMethods(payments);
                setDefaultPayment(payments);
            })
            .catch((err) => {
                console.log('Error getConfisysPaymentGateway', err);
                const payments = getPaymentsActive();
                setPaymentMethods(payments);
                setDefaultPayment(payments);
            });
    };

    useEffect(() => {
        getConfisysPaymentGateway();
    }, []);

    return (
        <BodyModalPaymentSummary>
            <h3>Elige un método de pago</h3>
            <OptionsToPayment>
                {isActivePayment(paymentMethods, ConfisysPayments.PLACETOPAY) && (
                    <OptionPayment>
                        <RadioButtonOptionPayment
                            type="radio"
                            id={'#PlaceToPay'}
                            name="payment"
                            onChange={() => {
                                setPaymentSelected('PlaceToPay');
                            }}
                            checked={paymentSelected === 'PlaceToPay'}
                        />
                        <LabelOptionPayment htmlFor="#PlaceToPay">
                            <img src={logoOki} alt="LogoOki" />
                            <p>Saldo </p> <span>$ {converterNumber(totalToPay)}</span>
                        </LabelOptionPayment>
                    </OptionPayment>
                )}
                {isActivePayment(paymentMethods, ConfisysPayments.PAYVALIDA) && (
                    <OptionPayment>
                        <RadioButtonOptionPayment
                            type="radio"
                            id={'#PayValida'}
                            name="payment"
                            onChange={() => {
                                setPaymentSelected('PayValida');
                            }}
                            checked={paymentSelected === 'PayValida'}
                        />
                        <LabelOptionPayment htmlFor="#PayValida">
                            <img src={logoPse} className="pse" alt="Logo PSE" />
                            <img src={logoVisa} className="visa" alt="Logo VISA" />
                            <img src={logoMasterCard} className="masterCard" alt="Logo Master Card" />
                        </LabelOptionPayment>
                    </OptionPayment>
                )}
            </OptionsToPayment>
            {/* <Button text="Pagar"></Button> */}
        </BodyModalPaymentSummary>
    );
};
