import styled from 'styled-components';
import { hexToRGB } from '../../../utils/hexToRgba';
// let blueRgba = ;

export const Container = styled.div`
    width: 100%;
    padding: 10px;
`;

export const ProgressBarCustom = styled.div`
    width: 100%;
    /* max-width: 500px; */
    border-radius: 10px;
    background: ${(props) => props.theme.colors.backgrounds.gray400};
    height: 4px;
    border-radius: 4px;
`;

interface IProgress {
    width: string;
}

export const Progress = styled.div<IProgress>`
    height: 100%;
    width: ${(props) => props.width};
    background: ${(props) => props.theme.colors.accents.neon.blue};
    box-shadow: 0px 0px 4px ${(props) => props.theme.colors.accents.neon.blue},
        0px 0px 20px ${(props) => hexToRGB(props.theme.colors.accents.neon.blue)};
    border-radius: 4px;
`;

export const CircleBig = styled.div`
    width: 78px;
    height: 78px;

    & svg {
        width: 78px;
        height: 78px;
    }
`;

export const CircleBigBg = styled.circle`
    fill: none;
    stroke-width: 4px;
    stroke: #343035;
`;

interface ICircleBigProgress {
    percentage: number;
}

export const CircleBigProgress = styled.circle<ICircleBigProgress>`
    fill: none;
    stroke-width: 4px;
    stroke: ${(props) => props.theme.colors.accents.neon.blue};
    filter: drop-shadow(0px 0px 4px ${(props) => props.theme.colors.accents.neon.blue})
        drop-shadow(0px 0px 4px ${(props) => hexToRGB(props.theme.colors.accents.neon.blue)});
    stroke-linecap: round;
    stroke-dasharray: 140;
    stroke-dashoffset: ${(props)=>props.percentage};
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    animation: big 1.5s ease-in-out;
`;
