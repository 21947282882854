import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const ContainerStepFour = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;

    ${breakpoint('md')`
        margin-top: 50px;
        padding: 0 50px;
    `}
`;

export const Cards = styled.div`
    width: 100%;
    margin: 0 auto;

    .slick-dots {
        text-align: center;
        width: auto;
        bottom: 25px;
        left: 0;
        right: 0;
        margin: 0 auto;
        li {
            width: 15px;
            height: 15px;
            &.slick-active {
                button {
                    &:before {
                        font-size: 12px;
                        line-height: 14px;
                        width: 11px;
                        height: 11px;
                        color: transparent;
                        box-shadow: 0px 0px 6px 1.5px ${(props) => props.theme.colors.alerts.yellow};
                        border: 2px solid ${(props) => props.theme.colors.alerts.yellow};
                        top: -2px;
                    }
                }
            }
            button {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                &:before {
                    font-size: 10px;
                    line-height: 12px;
                    color: transparent;
                    border: 2px solid ${(props) => props.theme.colors.disabled.gray};
                    border-radius: 50%;
                    width: 7px;
                    height: 7px;
                    opacity: 1;
                }
            }
        }
    }
    .slick-list {
        margin: auto;
        text-align: center;
    }

    div:nth-child(2n) {
        div {
            img {
                margin: 0;
            }
        }
    }

    ${breakpoint('md')`
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        width: 100%;

        div:nth-child(3n){
            div{
                div{
                    border: none;
                }
            }
        }

    `}
`;

export const CardStep = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 212px;
    height: 212px;
    border: 1px solid ${(props) => props.theme.colors.accents.base.yellow};
    border-radius: 16px;
    margin: 0 auto 80px auto;

    ${breakpoint('md')`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        border: none;
        width: 100%;
        height: 100px;

        &:first-child{
            img{
                padding-left: 120px;
            }
            p{
                &:nth-child(3n){

                    position:relative;
                    right:40px;
                }
            }
        }

        &:nth-child(2n) {
            img{
                order: 2;
                padding-right: 100px;

                &:nth-child(1n){
                    position: relative;
                    right: 20px;
                }
            }
            div{
                order: 1;
            }
            
            p{
                text-align: right;
                
                &:nth-child(3n){
                    width: 300px;
                    margin-right: 20px;
                }
            }
        }

        &:nth-child(3n) {
            img{
                padding-left: 120px;
            }
            p{
                &:nth-child(3n){

                    position:relative;
                    right:40px;
                }
            }
        }
    `}
`;

export const CardImage = styled.img`
    height: 80px;
    padding-top: 20px;
    padding-bottom: 7px;

    ${breakpoint('md')`
        height: 119px;
        margin: 0 auto;

        &:first-child{
            padding-left: 100px;
        }
    `}
`;

export const CardText = styled.p`
    p {
        margin: auto;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 14px;
        line-height: 16.8px;

        & > b {
            font-family: ${(props) => props.theme.fonts.lf_extrabold};
        }
    }

    ${breakpoint('md')`
        img:first-child{
            padding-left: 100px;
        }
        &:nth-child(2n) {
                img{
                    order: 2;
                    padding-right: 100px;
                }
                div{
                    order: 1;
                }
                
                p{
                    width: 300px;
                }
                width: 300px;
            } 
        
    `}
`;

export const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 30px;
    width: 80px;
    height: 80px;
    border: 1px solid ${(props) => props.theme.colors.accents.base.yellow};
    border-radius: 50%;
    background: #1d1828;
    margin: auto;

    p {
        font-family: ${(props) => props.theme.fonts.mon_extrabold};
        font-size: 40px;
        line-height: 24px;
        color: ${(props) => props.theme.colors.accents.base.yellow};
    }

    ${breakpoint('md')`
        left: 0;
        top: 0;
        margin: auto;
        width: 66px;
        height: 66px;
    `}
`;

export const Line = styled.div`
    top: 64px;
    height: 144px;

    position: absolute;
    border: 1px solid ${(props) => props.theme.colors.accents.base.yellow};

    & div:last-child {
        display: none;
    }
`;

export const AuxiliarText = styled.p`
    margin-bottom: 43px;
    text-align: center;
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 12px;
    line-height: 18px;
`;
