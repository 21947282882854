export const setData = (data, nameVariable) => {
    const isBrowser = () => typeof window !== 'undefined';
    if (isBrowser()) {
        localStorage.setItem(nameVariable, JSON.stringify(data));
        return true;
    }
};

export const getData = (nameVariable) => {
    const isBrowser = () => typeof window !== 'undefined';
    if (isBrowser()) {
        // let data = 
        return JSON.parse(localStorage.getItem(nameVariable));
    }
};

export const removeLocalStorage = (name) => {
    const isBrowser = () => typeof window !== 'undefined';
    if (isBrowser()) {
        localStorage.removeItem(name)
        return true;
    }
}

export const setDataStorage = (data, nameVariable) => {
    const isBrowser = () => typeof window !== 'undefined';
    if (isBrowser()) {
        sessionStorage.setItem(nameVariable, JSON.stringify(data));
        return true;
    }
};

export const getDataStorage = (nameVariable) => {
    const isBrowser = () => typeof window !== 'undefined';
    if (isBrowser()) {
        let data = JSON.parse(sessionStorage.getItem(nameVariable));
        // localStorage.removeItem(nameVariable)
        return data;
    }
};

export const removeSessionStorage = (name) => {
    const isBrowser = () => typeof window !== 'undefined';
    if (isBrowser()) {
        sessionStorage.removeItem(name)
        return true;
    }
}
