import { ModalGana } from '@componentsShared';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface IProps {
    modalState: boolean;
    setModalState: () => void;
}

export const ModalTyc = (props: IProps) => {
    const { modalState, setModalState } = props;

    const TycPqrs = useStaticQuery(graphql`
        query queryTycPqrs {
            allContentfulPaginaInternaFooter(filter: { page: { eq: "Contactanos" } }) {
                nodes {
                    secondarySection {
                        ... on ContentfulPreguntasFrecuentes {
                            answer {
                                raw
                            }
                            question
                        }
                    }
                }
            }
        }
    `);

    const {
        allContentfulPaginaInternaFooter: {
            nodes: [{ secondarySection }]
        }
    } = TycPqrs;

    const optionsModal = {
        logo: 'terciary',
        icon: '',
        title: secondarySection[0].question,
        text: '',
        boldText: '',
        tycText: documentToReactComponents(JSON.parse(secondarySection[0].answer.raw)),
        primaryButtonText: 'Aceptar',
        primaryButton: () => {
            setModalState();
        },
        dimensions: { height: { mobile: 'auto', desktop: '625px' }, width: { desktop: '1171px' } },

        sizeButton: '136px'
    };

    return (
        <ModalGana visibility={modalState} setVisibility={setModalState} options={optionsModal} />
    );
};
