import styled from "styled-components";
import { breakpoint } from '../../../shared/styles/variables';

export const BoxRedirect = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    div:nth-child(1) {
        margin-top: 62px;
        margin-bottom: 20px;
    }

    ${breakpoint('md')`
        top: calc(50vh - 260px);
    `}
    
    ${breakpoint('xxl')`
        top: calc(50vh - 310px);
    `}
`;

export const Title = styled.h1`
    color: ${(props) => props.theme.colors.accents.neon.blue};
    font-size: 16px;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    margin-bottom: 18px;

    ${breakpoint('md')`
        font-size: 18px;
    `}
`;

export const BoxTexts = styled.div`
    text-align: center;
    p {
        color: ${(props) => props.theme.colors.text.gray};
        line-height: 18px;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 14px;
        width: 220px;

        ${breakpoint('md')`
            width: 520px;
            font-size: 16px;

            span {
                font-family: ${(props) => props.theme.fonts.lf_bold};
            }
        `}
    }

    p:nth-child(1) {
        margin-bottom: 18px;
    }
`;