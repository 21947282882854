import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';
import registerBackground from 'src/shared/assets/images/registerBackground.png';

export const RegisterBox = styled.div`
    ${breakpoint('md')`
        padding: 0px 15px;

    `}
`;

export const ContainerRegisterTop = styled.div`
    ${breakpoint('md')`
        position: absolute;
        width: 100%;
        z-index: 1;
        padding: 6px 54px 0 54px;

        div{
            margin: 28px auto;
        }

    `}
`;

export const RegisterTitle = styled.h1`
    padding-top: 15px;
    text-align: center;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-size: 20px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.accents.base.yellow};

    ${breakpoint('md')`
       padding: 15px;
       border-bottom: 0.5px solid #3E3A47;
    `}
`;

export const RegisterForm = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 1024px;
    background: ${(props) => props.theme.colors.backgrounds.gray450};
    border-radius: 16px;
    position: relative;
    margin: 0 auto 20px;
    border: 0.5px solid #c5c3c9;

    ${breakpoint('md')`
        background-image: url(${registerBackground});
        background-repeat: no-repeat; 
        background-size: cover;
        border: none;
       
    `}
`;

export const RegisterBody = styled.div`
    width: 100%;
    padding: 0 14px 30px 14px;

    ${breakpoint('md')`
        padding: 0 53px;
    `}
`;

export const TitleContainer = styled.div`
    padding: 13px 0;
    border-bottom: 0.5px solid #3e3a47;

    ${breakpoint('md')`
        padding: 80px 0; 

        div{
            position: relative;
            top: 8rem;
        }
    `}
`;

export const AuxText = styled.p`
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-size: 16px;
    font-weight: 19.2px;
    color: ${(props) => props.theme.colors.accents.base.yellow};
    position: relative;
    top: 7.5rem;
`;

export const MainText = styled.p`
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 14px;
    line-height: 18px;
    color: #c5c3c9;
    margin-top: 14px;

    ${breakpoint('md')`
        margin-top: 50px;
        
    `}
`;

export const FormRegister = styled.form`
    padding: 25px 0;

    ${breakpoint('md')`
        padding: 28px 0;
    `}
`;

export const InputContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 30px;

    div {
        label {
            font-size: 14px;
            line-height: 24px;
            font-family: ${(props) => props.theme.fonts.lf_bold};
        }

        padding: 0;

        input {
            text-transform: uppercase;
            height: 56px;

            &::placeholder {
                text-transform: initial;
            }
        }

        &:last-child {
            label {
                margin-top: 28px;
            }
        }
    }
    ${breakpoint('md')`
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 58px;
        margin-bottom: 25px;


            input {
                max-width: 430px;
            }
            div{
                &:last-child {
                    label {
                        margin-top: 0;
                    }
                }
            }
        }
    `}
`;

export const SelectContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;

    div {
        padding-left: 0;
    }

    ${breakpoint('md')`
        padding-top: 12px;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 58px;
    `}
`;

export const GenreContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 98px);
    font-family: ${(props) => props.theme.fonts.lf_regular};
    padding: 0 9px;
    margin-bottom: 10px;

    div {
        padding: 4px 4px 20px 4px;
    }

    label {
        color: #f8f8f8;
        font-size: 14px;
        line-height: 18px;
        padding: 0 10px;
    }

    ${breakpoint('md')`
         div {
            margin-top: 18px;
        }
    `}
`;

export const LegalAgeContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 98px);
    font-family: ${(props) => props.theme.fonts.lf_regular};
    padding: 0 9px;
    margin-bottom: 10px;

    div {
        padding: 4px 4px 20px 4px;
    }

    label {
        color: #f8f8f8;
        font-size: 14px;
        line-height: 18px;
        position: relative;
    }

    ${breakpoint('md')`
        display: flex;
        align-items: center;
        padding: 0;
        margin-bottom: 20px;

        label {
            padding-bottom: 0;
            margin-right: 80px;
        }

        div {
            width: 90px;
        }
    `}
`;

export const DocumentContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;

    div {
        div {
            label {
                // color: #8e8c94;
            }
        }
    }

    div:first-child {
        padding: 0;

        div {
            padding: 17px;
        }
    }

    div:last-child {
        padding: 0;
        label {
            margin: 0;
            margin-top: 10px;
            margin-bottom: 8px;
        }

        input {
            min-width: 290px;
        }
    }

    ${breakpoint('md')`
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 58px;
        padding: 0;

        div:last-child {
            input {
                max-width: 430px;
            }
            label {
                margin-top: 0;
            }
        }
    `}
`;

export const TextLabel = styled.label`
    font-family: ${(props) => props.theme.fonts.lf_bold};
    font-size: 14px;
    font-weight: 24px;
    padding-left: 2px;

    ${breakpoint('md')`
        position: absolute;
        padding-bottom: 30px;
    `}
`;

export const DocumentType = styled.div`
    div {
        padding: 0;
        min-width: 296px;

        div {
            padding: 16px;
        }
    }

    ${breakpoint('md')`

    div {
        max-width: 430px;

        div {
            padding: 16px;
        }
        
        &::placeholder{
                color: #f00;
        }   
        
    }
            `}
`;

export const FormButtons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 42px;

    button {
        font-size: 16px;
        width: 175px;
        margin-bottom: 42px;
    }

    .redirect {
        text-decoration: none;
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-size: 16px;
        line-height: 21.6px;
    }

    ${breakpoint('md')`
        flex-direction: row-reverse;
        justify-content: center;
        align-items: baseline;

        button{
            font-size: 14px;
            width: 138px;
            heigth: 32px;
            margin-bottom: 42px;
            margin-left: 56px;
        }

        .redirect {
            font-size: 14px;
            line-height: 21.6px;
        }
    `}
`;

export const CustomCheck = styled.input`
    height: 16px;
    width: 16px;
    border: 1px solid #c5c3c9;
    appearance: none;
    border-radius: 4px;
    position: relative;
    top: 5px;
    &:before {
        content: none;
    }
    &:checked {
        border: none;
        &:before {
            font-family: 'icomoon';
            content: '\\e944';
            color: ${(props) => props.theme.colors.principal.greenBase};
            font-size: 16px;
        }
    }
`;
export const ContainerPolitics = styled.div`
    display: flex;
    padding: 0 25px 0 10px;
    margin-top: 30px;   

    input {
        padding: 5px;
        margin-right: 15px;
    }

    ${breakpoint('md')`
        margin-top: 28px;
        margin-left: -72px;
        justify-content: center;

        input {
            padding: 0px;
            
        }
    
    `}
`;

export const ContainerTratments = styled.div`
    display: flex;
    padding: 0 25px 0 10px;
    margin-top: 30px;
    

    input {
        padding: 5px;
        margin-right: 15px;
    }

    ${breakpoint('md')`
        margin-top: 28px;
        justify-content: center;
        margin-left: 50px;

        input {
            padding: 0px;
            
        }
    
    `}
`;

export const Politics = styled.label`
    a {
        border-bottom: 1px solid #0df96c;
        color: #0df96c;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 14px;
        font-weight: normal;
        margin-left: 2px;
        line-height: 24px;
        padding-bottom: 2px;
        text-decoration: none;
    }
`;

export const Tratments = styled.label`
    a {
        border-bottom: 1px solid #0df96c;
        color: #0df96c;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 14px;
        font-weight: normal;
        margin-left: 2px;
        line-height: 24px;
        padding-bottom: 2px;
        text-decoration: none;
    }
`;

export const ContainerErrorAge = styled.div`
    background: ${(props) => props.theme.colors.accents.neon.orange};
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 8px 23px 8px 25px;
    margin-top: 30px;
    width: 100%;

    p {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 14px;

        span {
            font-family: ${(props) => props.theme.fonts.lf_bold};
        }
    }

    & > span {
        font-size: 25px;
        margin-right: 20px;
    }

    ${breakpoint('md')`
        margin: 30px auto;
        width: 50%;

        p {
            font-size: 14px;
        }
    
        & > span {
            font-size: 30px;
        }
    `}
`;

export const ContainerLegalAge = styled.div`
    position: relative;
`;

export const ContentError = styled.div`
    position: absolute;
    bottom: 0;
    left: 5px;

    &.errorGenre {
        bottom: 12px;
        left: 10px;
    }

    ${breakpoint('md')`
        &.errorGenre {
            bottom: 35px;
            left: 10px;
        }
    `}
`;
