import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { Header, Footer, ValidateLogin, LabelCookies } from '@componentsShared';
import theme from '../../../styles/theme';
import { Main, Watermark, WrapperLayout } from './styled';
import GlobalStyles from '../../../styles/globalStyles';
import GlobalFonts from '../../../styles/globalFonts';
import '../../../styles/style.css';
import UserContext from 'src/shared/context/user';
import { getData, setData } from '@utils/transferData';
import { Helmet } from 'react-helmet';
import { cfConfigurationService } from '@servicesCF/configuration';

interface IProps {
    children: React.ReactNode;
    showSummary?: boolean | string;
    slug?: string;
}

export const Layout = (props: IProps) => {
    const { children, showSummary, slug } = props;

    const userData = {
        token: '',
        type: '',
        user: '',
        isLogged: false
    };

    const chatBotUrl = cfConfigurationService.getChatbotUrl();

    useEffect(() => {
        let data = getData('userData');

        if (data === null) {
            setData(userData, 'userData');
        }

    }, []);

    const [topLocation, setTopLocation] = useState(false);
    useEffect(() => {
        if (sessionStorage.getItem('closeModalLocation') == 'true') {
            setTopLocation(true);
        } else {
            setTopLocation(false);
        }
    });

    useEffect(() => {
        if ('permissions' in navigator) {
            navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                if (result.state === 'granted') {
                    setTopLocation(false);
                    sessionStorage.setItem('closeModalLocation', 'false');
                }
                result.onchange = () => {
                    if (result.state === 'granted') {
                        setTopLocation(false);
                    }
                };
            });
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <UserContext>
                <GlobalFonts />
                <GlobalStyles />
                <WrapperLayout id="top">
                    <ValidateLogin />
                    <Header />
                    <LabelCookies />
                    <Main location={topLocation} className={slug === '/' ? 'home' : ''}>
                        <Helmet>
                            <script
                                async
                                type="text/javascript"
                                src="//script.crazyegg.com/pages/scripts/0024/0375.js"
                            ></script>
                        </Helmet>
                        <Helmet>
                            <script
                                async
                                src="https://cdn.gravitec.net/storage/3033b85f9a4d956839b97a1f003d8fb2/client.js" 
                            ></script>
                        </Helmet>
                        {chatBotUrl && (
                            <Helmet>
                                <script
                                    async
                                    id="prodId"
                                    type="text/javascript"
                                    src={chatBotUrl}
                                ></script>
                            </Helmet>
                        )}

                        {process.env.URL_SCRIPT_EGOI && (
                            <Helmet>
                                <script id='egoi_script'>
                                    {`
                                    if(window._mtmReady){
                                        console.error('Connected sites script already loaded. You might have it dupplicated.');
                                    } else {
                                        window._mtmReady=true;
                                        var _mtm = window._mtm = window._mtm || [];
                                        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
                                        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                                        g.async=true; g.src='${process.env.URL_SCRIPT_EGOI}';
                                        s.parentNode.insertBefore(g,s);
                                    }
                                    `}
                                </script>
                            </Helmet>
                        )}
                        {children}
                    </Main>
                    {process.env.ENVIRONMENT !== 'production' && (
                        <Watermark>{process.env.ENVIRONMENT}</Watermark>
                    )}
                    <Footer showSummary={showSummary} />
                </WrapperLayout>
            </UserContext>
        </ThemeProvider>
    );
};
