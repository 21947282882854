import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorSpan } from '@componentsShared';
import { Container, TextareaCustomLabel, TextAreaCustom } from './styled';
interface IProps {
    placeholder: string;
    name: string;
    label: string;
    registerCustom?: any;
    required: string;
    disabled?: boolean;
    maxLength?: {
        value: number,
        message: string
    }
    icon?: {
        position: string;
        icon: string;
    };
}

export const TextArea = (props: IProps) => {
    const { placeholder, name, label, required, disabled, maxLength } = props;

    const { register, formState } = useFormContext();

    return (
        <Container>
            <TextareaCustomLabel htmlFor={name}>{label}</TextareaCustomLabel>
            <TextAreaCustom
                placeholder={placeholder}
                {...register(name, { 
                    required: required,
                    maxLength: maxLength ?? {
                        value: 255,
                        message: 'Máximo 255 caracteres'
                    }
                })}
                className={formState.errors[name]?.message && 'error'}
                id={name}
                name={name}
                disabled={disabled}
            ></TextAreaCustom>
            {formState.errors[name]?.message && (
                <ErrorSpan text={formState.errors[name]?.message}></ErrorSpan>
            )}
        </Container>
    );
};

