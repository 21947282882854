import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const BoxRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    /*     width: 95%;
    border-radius: 16px;
    height: auto;
    min-height: auto; */
    width: 95%;

    ${breakpoint('md')`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 400px;
        border-radius: 16px;
        height: auto;
        margin-top: 45px;
        `}// min-height: 800px;
`;
