import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const ButtonCustom = styled.button`
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 7px 24px;
    font-size: 14px;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #f8f8f8;
    background: linear-gradient(90deg, rgba(0, 159, 92, 1) 0%, rgba(13, 249, 108, 1) 100%);

    ${breakpoint('md')`
        border-radius: 12px;
        padding: 8px 32px;
    `}

    &:hover {
        box-shadow: 0 0 6px #0df96c;
    }

    &:active {
        background: #009f5c;
    }

    /* Disabled */
    &:disabled,
    &[disabled] {
        background: ${(props) => props.theme.colors.backgrounds.gray450};
        color: ${(props) => props.theme.colors.backgrounds.gray400};
        border-color: ${(props) => props.theme.colors.backgrounds.gray400};

        ${breakpoint('sm')`
            background: ${(props) => props.theme.colors.backgrounds.gray500};
        `} 
        &:active {
            background: ${(props) => props.theme.colors.backgrounds.gray450};
            box-shadow: none;
        }
        &:hover{
            box-shadow: none;
        }
    }
    /* Button Disabled on background  dark */
    &.btnDisableBlack{
        &:disabled,
        &[disabled]{
            background: ${(props) => props.theme.colors.backgrounds.gray450};
            box-shadow: none;
            color: #3E3A47;
        }
    }
    /* Button Disabled on background  light */
    &.btnDisableWhite{
        &:disabled,
        &[disabled]{
            background: transparent;
            border: 1px solid #C5C3C9;
            box-shadow: none;
            color: #C5C3C9;
        }
    }
    /* Secundary */
    &.secundary {
        background: #272231;
        box-shadow: inset 0 0 0 2px #00a3ff;
        color: #00a3ff;
        &:hover {
            background: #00a3ff;
            color: #f8f8f8;
            box-shadow: 0 0 6px #00a3ff;
        }
        &:active {
            background: #00a3ff;
        }
    }
    /* Background white and text blue */
    &.white {
        background: #F8F8F8;
        border-radius: 12px;
        color: #00A3FF;
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-size: 13px;
        // height: 32px;
        padding: 9px 32px 9px 28px;
        width: 123px;
    }
    /* Destructive */
    &.destructive {
        background: #ff2048;
        &:hover {
            background: #d91135;
            box-shadow: none;
        }
        &:active {
            background: #d91135;
        }
    }
    /* Destructive */

    /* Sin Fondo */
    &.bottomless {
        background: none;
        border: 1px solid ${(props) => props.theme.colors.principal.greenNeon};
        padding: 10px 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        ${breakpoint('lg')`
            border-radius: 12px;
            padding: 7px 20px;
        `}
        &:hover {
            border-color: ${(props) => props.theme.colors.principal.darkGreen};
            background: none;
            color: ${(props) => props.theme.colors.principal.darkGreen};
            box-shadow:none;
        }

        &:active {
            color: ${(props) => props.theme.colors.principal.darkGreen};
            background: none;
        }

        &:disabled {
            color: ${(props) => props.theme.colors.disabled.gray};
            border: 1px solid ${(props) => props.theme.colors.backgrounds.gray400};
            animation: none;
            box-shadow: none;
            background: none;
        }
    }

    span {
        margin-left: 8px;
    }
    
    &.underlined {
        background: none;
        color: ${(props) => props.theme.colors.principal.darkGreen};
        &:hover {
            border: none;
            outline: none;
            box-shadow: none;
            color: ${(props) => props.theme.colors.principal.greenNeon};
        }
        &:active {
            color: ${(props) => props.theme.colors.principal.greenBase};
        }
        &:disabled {
            color: ${(props) => props.theme.colors.disabled.gray};
        }
    }
    &.text-icon {
        background: none;
        color: ${(props) => props.theme.colors.text.white};

        span {
            color: ${(props) => props.theme.colors.principal.greenNeon};
        }
        &:hover {
            border: none;
            outline: none;
            box-shadow: none;
        }
    }

    .boton {
        color: #fff !important;
        font-size: 20px;
        font-weight: 500;
        // padding: 0.5em 1.2em;
        background: #318aac;
        border: 2px solid;
        border-color: #318aac;
        position: relative;
        }
        .boton:before {
        content:"";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 0px;
        // height: 100%;
        background: rgba(255, 255, 255, 0.1);
        transition: all 1s ease;
        }
        .boton:hover:before {
        width: 100%;
    }

    &.backgroundBlack {
        width: 115px;
        height: 46px;

        &:disabled {
            background: ${(props) => props.theme.colors.backgrounds.gray450};
            color: ${(props) => props.theme.colors.backgrounds.gray400};
            border-color: ${(props) => props.theme.colors.backgrounds.gray400};
        }

        &--large {
            width: 195px;
            height: 46px;
            margin: 0;
            padding-left: 0;
            padding-right: 0;
            justify-content: center;
        }

        &--large-mobile {
            width: 165px;
            height: 46px;
            margin: 0;
            padding-left: 0;
            padding-right: 0;
            justify-content: center;

            &:disabled {
                background: ${(props) => props.theme.colors.backgrounds.gray500};

                span {
                    pointer-events: none;
                    color: ${(props) => props.theme.colors.backgrounds.gray400};
                }
            }
        }
    }

`;
