import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

type PropsMessage = {
    isGood?: boolean;
};

export const ContainerError = styled.div``;

export const ErrorMessage = styled.p<PropsMessage>`
    padding: 0 0 10px 0;
    font-size: 10px;
    line-height: 12px;
    font-family: ${(props) => props.theme.fonts.lf_regular};

    ${breakpoint('md')`
        font-size: 14px;
    `}

    &.goodPass {
        color: ${(props) => props.theme.colors.principal.greenNeon};
    }

    &.badPass {
        color: ${(props) => props.theme.colors.alerts.red};
    }
`;
