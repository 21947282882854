import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ModalCase, ModalTyc } from '@components';
import { Button, InputCustom, Redirect, Select, TextArea, Toast, ValidateTypeDocument } from '@componentsShared';
import {
    Container,
    ContainerDataUser,
    ContainerInputs,
    ContainerPolitics,
    ContainerRequest,
    CustomCheck,
    DescriptionTypeRequest,
    DocumentContainer,
    FormButtons,
    FormPqrs,
    Politics,
    TermsAndConditions
} from './styled';
import siteUrl from '@utils/siteUrl';
import { InputCustomRadio } from 'src/shared/components/molecules/contentBet/styled';
import { generalDataServices } from '../../../shared/services/gana/generalData';

type PropsPqrs = {
    auxImage: {
        file: { url: string },
        description: string;
    };
    typesRequest: [{
        answer: any,
        question: string
    }]
};

type ITypeReq = {
    value: string;
    text: string;
}

const RequestData: ITypeReq[] = [
    { value: '1', text: 'Petición' },
    { value: '2', text: 'Queja' },
    { value: '3', text: 'Reclamo' },
    { value: '4', text: 'Sugerencia' },
    { value: '5', text: 'Felicitación' }
];

type IPQRS = {
    type: string;
    user: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    solicitud: string;
    message: string;
}

export const ContainerPqrs = (props: PropsPqrs) => {
    const { auxImage, typesRequest } = props;
    const methods = useForm({ mode: 'onChange' });
    const [isActive, setIsActive] = useState(true);
    const [isActiveModalSuccessPQRS, setIsActiveModalSuccessPQRS] = useState(false);
    const [activeModal, setActiveModal] = useState(false);
    const [descriptionRequest, setDescriptionRequest] = useState()

    const [validationDocument, setValidationDocument] = useState({
        validation: {
            value: / /,
            message: ''
        },
        type: 'number'
    });

    const [toastState, setToastState] = useState({
        state: false,
        text: ''
    });

    const showToast = (text: string) => {
        setToastState({ state: !toastState.state, text: text });
        setTimeout(() => {
            setToastState({ state: false, text: '' });
        }, 10000);
    };

    const showModalSuccessPQRS = () => {
        methods.setValue('solicitud', '')
        methods.setValue('user', '')
        methods.setValue('firstName', '')
        methods.setValue('lastName', '')
        methods.setValue('email', '')
        methods.setValue('phone', '')
        methods.setValue('message', '')
        setIsActiveModalSuccessPQRS(!isActiveModalSuccessPQRS);
    };

    const registerPQRS = ({type, user, firstName, lastName, email, phone, solicitud, message}: IPQRS) => {
        const contentful = require('contentful-management')
        const client = contentful.createClient({
            accessToken: process.env.CONTENTFUL_MANAGEMENT_API_ACCESS_TOKEN
        })

        client.getSpace(process.env.CONTENTFUL_SPACE_ID)
            .then((space: any) => space.getEnvironment(process.env.CONTENTFUL_ENVIRONMENT))
            .then((environment: any) => environment.createEntry('pqrsRegisters', {
                fields: {
                    documentType: {'en-US': type},
                    documentNumber: {'en-US': user},
                    name: { 'en-US': firstName},
                    lastName: {'en-US': lastName},
                    email: {'en-US': email},
                    phoneNumber: {'en-US': phone},
                    typeRequest: {'en-US': solicitud},
                    message: {'en-US': message}
                }
                }))
                .then((entry: any) => {
                    setIsActive(!isActive)
                    showModalSuccessPQRS()
                })
                .catch(() => {
                    setIsActive(!isActive)
                    showToast('¡Ups! no pudimos registrar tu caso, intenta mas tarde.')
                    console.error()
                })

    }

    const displayModal = () => {
        setActiveModal(!activeModal);
    };

    const onSubmit = async (data: any) => {
        setIsActive(!isActive)
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(async function (position) {
                data.latitud = position.coords.latitude
                data.longitud = position.coords.longitude

                try {
                    const resp = await generalDataServices.registerPQRS({
                        tipo_identificacion: data.type,
                        numero_identificacion: data.user,
                        nombre: data.firstName,
                        apellido: data.lastName,
                        correo: data.email,
                        telefono: data.phone,
                        mensaje: data.message,
                        pqrs_type_id: data.solicitud,
                        latitud: data.latitud,
                        longitud: data.longitud
                    })

                    if (resp.data.storePQRS) {
                        const typeRequest: ITypeReq = RequestData.find(typeReq => typeReq.value === data.solicitud) ?? {} as ITypeReq
                        data.solicitud = typeRequest.text
                        registerPQRS(data)
                    }
                } catch (error: any) {
                    setIsActive(!isActive)
                    showToast(error.message)
                }
            });
        }
    };

    useEffect(() => {
        setDescriptionRequest(
            (typesRequest[Number(methods.watch('solicitud')) - 1]?.answer.raw)
                ?? JSON.stringify('')
        )
    }, [methods.watch('solicitud')])
    
    return (
        <Container>
            {activeModal && <ModalTyc modalState={activeModal} setModalState={displayModal} />}
            {isActiveModalSuccessPQRS && <ModalCase modalState={isActiveModalSuccessPQRS} setModalState={showModalSuccessPQRS} />}
            <FormProvider {...methods}>
                <FormPqrs onSubmit={methods.handleSubmit(onSubmit)}>
                    <ContainerRequest
                        isSelectOption={methods.watch('solicitud') ? true : false}
                    >
                        <Select
                            options={RequestData}
                            name="solicitud"
                            required="Selecciona el tipo de solicitud, es obligatorio"
                            label="Tipo de solicitud"
                            placeholder="Selecciona"
                        />
                        {
                            methods.watch('solicitud') &&
                                <DescriptionTypeRequest>
                                    {documentToReactComponents(JSON.parse(descriptionRequest ?? ''))}
                                </DescriptionTypeRequest>
                        }

                        <img src={auxImage.file.url} alt={auxImage.description} />
                    </ContainerRequest>
                    <ContainerDataUser>
                        <DocumentContainer>
                            <ValidateTypeDocument />
                        </DocumentContainer>

                        <ContainerInputs>
                            <InputCustom
                                label="Nombres"
                                name="firstName"
                                type="text"
                                placeholder="Escribe tus nombres"
                                required="Este campo es obligatorio, agrega tus nombres"
                                minLength={{
                                    value: 3,
                                    message: 'Agrega al menos 3 letras'
                                }}
                                maxLength={{
                                    value: 30,
                                    message: 'Agrega máximo 30 letras'
                                }}
                                pattern={{
                                    value: /^[^\s][a-zñA-ZÑ\s]*$/,
                                    message: 'Evita usar tildes o caracteres especiales no válidos'
                                }}
                                className={validationDocument.type === 'text' ? 'uppercase' : ''}
                            />
                            <InputCustom
                                name="lastName"
                                label="Apellidos"
                                type="text"
                                placeholder="Escribe tus apellidos"
                                required="Este campo es obligatorio, agrega tus apellidos"
                                minLength={{
                                    value: 3,
                                    message: 'Agrega al menos 3 letras'
                                }}
                                maxLength={{
                                    value: 30,
                                    message: 'Agrega máximo 30 letras'
                                }}
                                pattern={{
                                    value: /^[^\s][a-zñA-ZÑ\s]*$/,
                                    message: 'Evita usar tildes o caracteres especiales no válidos'
                                }}
                                className={validationDocument.type === 'text' ? 'uppercase' : ''}
                            />
                        </ContainerInputs>

                        <ContainerInputs>
                            <InputCustom
                                label="Correo electrónico"
                                name="email"
                                type="email"
                                placeholder="P. ej. info@correo.com"
                                required="Este campo es obligatorio, agrega tu correo"
                                maxLength={{
                                    value: 80,
                                    message: 'Agrega máximo 80 caracteres'
                                }}
                                pattern={{
                                    value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})+$/,
                                    message: 'Correo no válido'
                                }}
                            />
                            <InputCustom
                                name="phone"
                                label="Celular"
                                type="number"
                                placeholder="Escribe tu número celular"
                                required="Este campo es obligatorio, agrega tu celular"
                                minLength={{
                                    value: 10,
                                    message: 'Agrega solo 10 números'
                                }}
                                maxLength={{
                                    value: 10,
                                    message: 'Agrega solo 10 números'
                                }}
                                pattern={{
                                    value: /^3[0-9]*$/,
                                    message: 'Número no válido, debes comenzar por el número 3'
                                }}
                            />
                        </ContainerInputs>

                        <TextArea
                            placeholder="Escribe aquí el detalle de tu solicitud para facilitarnos la gestión de tu requerimiento."
                            name="message"
                            label="¿En qué te podemos ayudar?"
                            required="El mensaje es requerido"
                            maxLength={{
                                value: 255,
                                message: 'El mensaje debe tener máximo 255 caracteres'
                            }}
                        />

                        <TermsAndConditions>
                            <InputCustomRadio
                                type="checkbox"
                                className="form-check-input"
                                checked={!isActive}
                                onClick={() => setIsActive(!isActive)}
                                onChange={() => {}}
                            />
                             <a target="_blank" href={siteUrl + "/tratamiento-datos/"}>Acepto política de tratamiento de datos</a>
                        </TermsAndConditions>

                        <FormButtons>
                            <Button
                                text="Enviar"
                                onClick={() => {}}
                                disabled={isActive}
                                submit={true}
                            ></Button>
                        </FormButtons>
                    </ContainerDataUser>
                </FormPqrs>
            </FormProvider>
            {toastState.state === true && (
                <Toast text={toastState.text} icon="cross" type="danger pqrs" />
            )}
        </Container>
    );
};
