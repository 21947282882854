import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

type TTitleColor = {
    color?: string;
};

export const CartEmpty = styled.div<TTitleColor>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    margin-bottom: 71px;

    span {
        font-size: 46px;
        color: #c5c3c9;
    }

    p {
        padding-top: 21px;
        color: ${(props) => (props.color ? `${props.color}` : `${'#3e3a47'}`)};
        font-size: 20px;
        line-height: 24px;
        font-family: ${(props) => props.theme.fonts.mon_extrabold};
    }

    .cartText {
        padding-top: 17px;
        color: #c5c3c9;
        font-size: 16px;
        line-height: 19px;
        font-family: ${(props) => props.theme.fonts.mon_extrabold};
        text-align: center;
    }

    button {
        margin-top: 25px;
        font-size: 16px;
        line-height: 20px;
    }

    ${breakpoint('md')`
        button{
            font-size: 14px;
            line-height: 16px;
        }
    `}

    ${breakpoint('lg')`
        button{
            font-size: 14px;
            line-height: 16px;
        }
    `}
`;
