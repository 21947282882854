import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    /* padding: 20px 55px 41px; */
    padding: 0 20px 27px 20px;
    max-width: 1350px;
    margin: auto;

    ${breakpoint('md')`
        display: flex;
    `}

`;
