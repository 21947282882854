import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const ContainerAnimation = styled.div`
    width: 100%;
    display: flex;
    height: 50px;
    overflow: hidden;
    justify-content: center;
`;

interface IPropsNumber {
    number?: string;
}
export const ContainerCifra = styled.div<IPropsNumber>`
    transform: translateY(0px);
    &.positionOne {
        transform: ${(props) => `translateY(${props.number})`};
        animation-name: move;
        animation-duration: 2s;
        /* animation-delay: 1s; */
    }
    &.positionTwo {
        transform: ${(props) => `translateY(${props.number})`};
        animation-name: move;
        animation-duration: 2s;
        /* animation-delay: 2s; */
    }
    &.positionThree {
        transform: ${(props) => `translateY(${props.number})`};
        animation-name: move;
        animation-duration: 2s;
    }
    &.positionFour {
        transform: ${(props) => `translateY(${props.number})`};
        animation-name: move;
        animation-duration: 2s;
        /* animation-delay: 4s; */
    }

    @keyframes move {
        from {
            transform: translateY(0px);
        }
    }
`;

export const Ball = styled.span`
    width: 45px;
    height: 45px;
    background: ${(props) => props.theme.colors.text.white};
    color: ${(props) => props.theme.colors.backgrounds.gray500};
    border-radius: 50%;
    outline: none;
    border: none;
    margin: 4px 2px;
    text-align: center;
    font-size: 28px;
    font-family: ${(props) => props.theme.fonts.lf_bold}, ${(props) => props.theme.fonts.icons};
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${(props) => props.theme.colors.disabled.gray};

    ${breakpoint('md')`
        margin: 4px;
    `}

    &.disabled {
        background: ${(props) => props.theme.colors.disabled.gray};
        &::placeholder {
            color: ${(props) => props.theme.colors.backgrounds.gray400};
        }
    }
`;

export const ButtonRandom = styled.button`
    width: 45px;
    height: 45px;
    background: none;
    border-radius: 50%;
    outline: none;
    border: none;
    margin: 2px;
    text-align: center;
    font-size: 28px;
    font-family: ${(props) => props.theme.fonts.icons};
    border: 2px solid ${(props) => props.theme.colors.backgrounds.gray500};
    cursor: pointer;

    span {
        color: ${(props) => props.theme.colors.backgrounds.gray500};
    }

    ${breakpoint('md')`
        margin: 4px;
    `}
`;
