import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const SectionContent = styled.section<{ isEven?: boolean; resolution?: boolean }>`
    width: 100%;
    margin-top: 34px;
    display: flex;
    flex-direction: ${(props) => (props.resolution ? 'column-reverse' : 'column')};
    gap: 10px;

    ${(props) =>
        props.resolution &&
        breakpoint('s')({
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        })}

    ${breakpoint('md')({
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '85px'

    })}

${(props) =>
        props.isEven &&
        props.resolution &&
        breakpoint('s')({
            flexDirection: 'row-reverse'
        })}

    ${(props) =>
        props.isEven &&
        breakpoint('md')({
            flexDirection: 'row-reverse'
        })}
`;

export const RichText = styled.div<{ resolution?: boolean }>`
    width: 100%;

    ${(props) =>
        props.resolution &&
        breakpoint('s')({
            width: '50%'
        })}

    ${(props) =>
        props.resolution &&
        breakpoint('md')({
            width: '50%',
            display: 'flex',
            justifyContent: 'center'
        })}

    ${breakpoint('md')({
        width: '50%'
    })}
`;

export const ContainerText = styled.div<{ resolution?: boolean }>`
    width: 100%;
    ${breakpoint('md')({
        maxWidth: '348px'
    })}

    ${breakpoint('lg')({
        maxWidth: '437px'
    })}

    & > p {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 120%;
        margin-bottom: ${(props) => (props.resolution ? '15px' : '20px')};
        ${breakpoint('lg')({
        fontSize: '16px'
        })}
        & > b {
            font-family: ${(props) => props.theme.fonts.lf_bold};
        }
        & > i {
          color: ${(props) => props.theme.colors.accents.neon.yellow};
      }
    }
`;

export const ContainerMedia = styled.div<{ resolution?: boolean }>`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    ${(props) =>
        props.resolution &&
        breakpoint('s')({
            width: '50%'
        })}
    ${breakpoint('md')({
        width: '50%'
    })}

    & > img {
        width: 100%;
        ${breakpoint('md')({
            maxWidth: '435px'
        })}
    }
`;

export const ContainerVideo = styled.div<{ resolution?: boolean }>`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    ${(props) =>
        props.resolution &&
        breakpoint('s')({
            width: '50%'
        })}
    ${breakpoint('md')({
        width: '50%'
    })}
`;
