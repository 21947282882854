import { breakpoint } from "src/shared/styles/variables";
import styled from "styled-components";

type IProps = {
    active?: boolean
}

export const ContainerQuestionResponse = styled.section`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;

    ${breakpoint('xl')`
        width: 950px;
        margin: 0 auto;
    `};
`;

export const Header = styled.div`
    width: 85%;

    .slider-style {
        .slick-track {
            align-items: center;
            display: flex;
        }

        .slick-list {
            margin: 0 auto;
            width: 90%;
        }
    }

    ${breakpoint('md')`
        display: flex;
        justify-content: space-between;
        width: 95%;
    `};

    ${breakpoint('xl')`
        width: 85%;
    `};
`;

export const Arrow = styled.div`
    cursor: pointer;
    position: absolute;
    bottom: 13px;
    z-index: 1;

    button span {
        font-size: 12px;
    }

    &.left {
        left: 0;
    }
    
    &.right {
        right: 0;
    }
`;

export const TabButton = styled.button<IProps>`
    background: transparent;
    border: none;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-size: 14px;

    h2 {
        color: ${(props) => props.theme.colors.accents.base.yellow};
        max-width: 150px;
        margin: 0 auto;
    }

    ${breakpoint('md')`
        height: 65px;
        position: relative;
        width: 150px;
        padding-bottom: 3px;
    
        h2 {
            color: #56525D;
            transition: all 0.2s ease-in-out;
            margin: 0 auto;
            max-width: 150px;
        }
    
        span {
            color: #56525D;
            transition: all 0.2s ease-in-out;
        }
    
        ${(props: IProps) =>
            props.active ?
            `
            h2 {
                color: #FFE000;
            }
    
            span {
                color: #F8F8F8;
            }
    
            &:after {
                content: ' ';
                background: #0DF96C;
                height: 3px;
                width: 100px;
                position: absolute;
                bottom: 6px;
                left: calc(50% - 50px);
            }
            `
            : 
            `
            &:hover {
                h2, span {
                    color: #C5C3C9; 
                }
            }
            `
        };
    `};
    
    ${breakpoint('xl')`
        cursor: pointer;
        height: 45px;
        width: auto;
        
        h2 {
            margin: 0;
            width: auto;
            max-width: 250px;
        }

        ${(props: IProps) =>
            `  
            &:after {
                bottom: -4px;
            }
            `
        };
    `};
`;

export const ContainerContent = styled.div`
    height: auto;
    margin-top: 22px;
    width: 90%;

    & > div {
        margin-bottom: 16px;
    }
    
    & > div:last-child {
        margin-bottom: 0;
    }

    ${breakpoint('md')`
        width: 100%;
    `};
    
    ${breakpoint('xl')`
        margin-top: 48px;
    `};
`;

export const H1Test = styled.h1`

`;