import React from 'react';
import { ModalGana } from '@componentsShared';
import { graphql, useStaticQuery } from 'gatsby';
import { loginServices } from '@services/login';

interface IProps {
    modalState: boolean;
    setModalState: () => void;
    user: string;
    type: string;
}

export const ModalNewPassword = (props: IProps) => {
    const { modalState, setModalState, user, type } = props;
    const dataModalNewPassword = useStaticQuery(graphql`
        query NewPasswordQuery {
            allContentfulModal(filter: { name: { eq: "Link cambio contraseña" } }) {
                edges {
                    node {
                        icon {
                            file {
                                url
                            }
                        }
                        title
                        text
                        AditionalText
                    }
                }
            }
        }
    `);
    const {
        allContentfulModal: { edges }
    } = dataModalNewPassword;
    const { node } = edges[0];

    const recoverPassword = async (type: string, user: string) => {
        await loginServices
            .recoverPassword(type, user)
            .then((data) => {
                console.log('DAta 1', data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const fetchRecoverPassword = () => {
        if (user !== null && type !== null) {
            recoverPassword(type, user);
        }
    };

    const optionsModal = {
        logo: 'terciary',
        icon: node.icon.file.url,
        title: node.title,
        text: node.text,
        primaryButtonText: 'Entendido',
        primaryButton: () => {
            setModalState();
            window.location.href = '/'
        },
        secondaryButtonText: 'Reenviar link',
        secondaryButton: () => {
            fetchRecoverPassword();
        },
        dimensions: { height: { desktop: '395px' }, width: { desktop: '485px' } }
    };
    return (
        <ModalGana visibility={modalState} setVisibility={setModalState} options={optionsModal} />
    );
};
