import React from 'react';
import { FinalAuxBanner, FinalBanner } from '@componentsShared';
import { graphql, useStaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export const InternalQuestionBanner = () => {
    const QuestionBanner = useStaticQuery(graphql`
    query questionBanner {
        allContentfulBanner(filter: { game: { eq: "BannerPreguntasFrecuentes" } }) {
            nodes {
                desktopImage {
                    file {
                        url
                    }
                  description
                }
                adText {
                    raw
                }
                textLink
                hyperlink
            }
        }
    }
    `);

    const {
        allContentfulBanner: {
            nodes: [{ desktopImage, adText, textLink, hyperlink }]
        }
    } = QuestionBanner;

    return <FinalAuxBanner image={desktopImage} text={adText} buttonText={textLink} alt={desktopImage.description} hyperlink={hyperlink}/>;
};
