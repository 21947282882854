import { hexToRGB } from '@utils/hexToRgba';
import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const WrapperGames = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    margin-top: 60px;
    min-height: 500px;
    .slick-list {
        padding-bottom: 53px !important;
        padding-top: 29px !important;
        min-height: 324px;
        margin-top: 26px;
        text-align: center;
    }
    button {
        margin: 0 auto;
        font-size: 18px;
        line-height: 22px;
        padding: 13px 26.5px;
        &.bottomless {
            padding: 11px 21px !important;
            border: 2px solid #0df96c !important;
        }
    }
    ${breakpoint('lg')`
    .slick-slider .slick-list {
        width: 100%;
        margin: 0 auto;
    }
        button{
            &.bottomless{
                padding: 4px 31px !important;
                font-size: 14px;
                border: 1.5px solid #0DF96C  !important;
            }
        }
    `}
`;
export const ContainerGames = styled.div`
    width: 100%;
    max-width: 1300px;
    margin: 0px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

`;
export const CardGame = styled.div`
    position: relative;
    min-width: 284px;
    max-width: 284px;
    min-height: 280px;
    max-height: 280px;
    margin-bottom: 60px;
    margin-top: 40px;
    margin-right: 20px;
    margin-left: 20px;
    background: ${(props) => props.theme.colors.backgrounds.gray400};
    border: 1px solid ${(props) => props.theme.colors.principal.greenBase};
    box-sizing: border-box;
    box-shadow: 0px 0px 18px 4px rgba(0, 212, 85, 0.25);
    border-radius: 24px;
    &.Disabled {
        background: ${(props) => hexToRGB(props.theme.colors.backgrounds.gray400, 0.3)};
        border: 1px solid rgba(197, 195, 201, 0.3);
        box-shadow: none;
        button {
            &:disabled {
                background: ${(props) => hexToRGB(props.theme.colors.backgrounds.gray500, 0.6)};
            }
        }
    }

    img {
        position: absolute;
        top: -25px;
        left: 0;
        right: 0;
        opacity: 1;
        margin: auto;
    }
    button {
        position: absolute;
        bottom: -18px;
        padding: 13px 53px;
        font-size: 18px;
        line-height: 21.6px;
        border-radius: 16px;
        left: 50%;
        transform: translate(-50%);
    }
    ${breakpoint('lg')`
        button{
            font-size: 14px !important;
            border-radius: 12px !important;
            padding: 5.5px 32.2px !important;
        }
    `}

    ${breakpoint('md')`
        margin-right: 10px;
        margin-left: 10px;
    `}
`;
export const GroupText = styled.div`
    font-family: ${(props) => props.theme.fonts.lf_bold};
    font-size: 12px;
    line-height: 22px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 89px;
    margin-bottom: 15px;
    b {
        color: ${(props) => props.theme.colors.accents.neon.yellow};
    }
    &.Disabled {
        b,
        div {
            background: ${(props) => hexToRGB(props.theme.colors.backgrounds.gray500, 0.45)};
            width: 110px;
            padding: 6px;
            border-radius: 16px;
            height: 36px;
        }
    }
    ${breakpoint('lg')`
     margin-bottom: 26px;
    `}
`;
export const Points = styled.div`
    font-weight: lighter;
    font-size: 12px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.principal.greenBase};
    background: ${(props) => props.theme.colors.backgrounds.gray450};
    border-radius: 16px;
    margin-right: 15px;
    padding: 6px 10px;
    p {
        color: ${(props) => props.theme.colors.principal.greenBase};
        span {
            color: ${(props) => props.theme.colors.principal.greenBase};
        }
    }
`;

export const ContentTextValue = styled.div`
    margin: 0 auto;
    width: 248px;
    height: 99px;
    background: ${(props) => props.theme.colors.backgrounds.gray450};
    border-radius: 10px;
    padding-top: 15px;

    &.shortly {
        padding-top: 32px;
        background: ${(props) => hexToRGB(props.theme.colors.backgrounds.gray500, 0.45)};

        strong {
            color: ${(props) => props.theme.colors.accents.base.yellow};
            font-family: ${(props) => props.theme.fonts.mon_bold};
            font-size: 24px;
            line-height: 29px;
        }
    }
    p {
        max-width: 161px;
        margin: 0 auto;
        text-align: center;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 14px;
        line-height: 20px;
    }
    span {
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-size: 28px;
        line-height: 34px;
        text-align: center;
        color: ${(props) => props.theme.colors.accents.base.yellow};
        text-shadow: 0px 0px 12px rgba(255, 224, 0, 0.25);
    }
`;
export const TitleGames = styled.h1`
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-size: 28px;
    line-height: 33.6px;
    text-align: center;
    width: 100%;
    max-width: 278px;
    max-height: 136px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: ${(props) => props.theme.colors.text.gray};
    text-shadow: 0px 0px 9px rgba(255, 224, 0, 0.25);
    span {
        color: ${(props) => props.theme.colors.accents.base.yellow};
    }
    ${breakpoint('lg')`
        max-width: 525px;
        font-size: 32px;
        margin-bottom: 42px;
        line-height: 38px;

    `}
`;

export const BackBox = styled.div`
    width: 80%;
    height: 180px;
    background: ${(props) => props.theme.colors.backgrounds.gray500};
    margin-top: 85px;
    position: absolute;
`;
export const ArrowCustom = styled.div`
    display: flex;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    z-index: 4;
    height: 50px;
    svg {
        path,
        circle {
            transition: all 0.3s ease-in-out;
        }
    }
    &:hover {
        svg {
            circle {
                fill: ${(props) => props.theme.colors.principal.greenBase};
            }
            path {
                fill: ${(props) => props.theme.colors.text.white};
            }
        }
    }
    &.prev {
        left: 2%;
    }
    &.next {
        right: 2%;
        transform: rotate(180deg);
    }
    ${breakpoint('lg')`
        &.prev {
            left: 1%;
        }
        &.next {
            right: 1%;
        }
    `}

    ${breakpoint('xxl')`
        &.prev {
            left: -6px;
        }
        &.next {
            right: -6px;
        }
    `}
`;
