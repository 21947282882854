import { createBreakpoint, createMap } from "styled-components-breakpoint";

export const colors = {
    principal: {
        greenBase: '#00D455',
        greenNeon: '#0DF96C',
        darkGreen: '#009F5C'
    },
    text: {
        gray: '#F8F8F8',
        white: '#FFFFFF',
        lightGray: '#56525E'
    },
    disabled: {
        gray: '#C5C3C9'
    },
    backgrounds: {
        gray400: '#3E3A47',
        gray450: '#272231',
        gray500: '#1D1828',
        gray600: '#5e5e5e'
    },
    accents: {
        neon: {
            purple: '#9938FA',
            blue: '#00A3FF',
            orange: '#FF7A00',
            yellow: '#FFFA00',
            fuchsia: '#FF279B'
        },
        base: {
            purple: '#6C01D7',
            blues: '#0051EE',
            orange: '#FF5620',
            yellow: '#FFE000'
        },
        dark: {
            purple: '#40007F',
            blues: '#000E8B',
            red: '#D70000',
            yellow: '#FFB802'
        }
    },
    alerts: {
        orange: '#FF7A00',
        yellow: '#FFB802',
        green: '#00D455',
        red: '#FF2048'
    }
};

export const fonts = {
    mon_bold: 'Montserrat-Bold',
    mon_extrabold: 'Montserrat-ExtraBold',
    mon_light: 'Montserrat-Light',
    mon_medium: 'Montserrat-Medium',
    mon_regular: 'Montserrat-Regular',
    mon_semiBold: 'Montserrat-SemiBold',

    lf_bold: 'LibreFranklin-Bold',
    lf_extrabold: 'LibreFranklin-ExtraBold',
    lf_light: 'LibreFranklin-Light',
    lf_medium: 'LibreFranklin-Medium',
    lf_regular: 'LibreFranklin-Regular',
    lf_semiBold: 'LibreFranklin-SemiBold',

    icons: 'icomoon'
};

export const breakpoints = {
    xxs: 360,
    xs: 480,
    s: 640,
    sm: 768,
    md: 769,
    lg: 1024,
    xl: 1200,
    xxl: 1320,
    xxlMax: 14050,
};

export const breakpoint = createBreakpoint(breakpoints);
export const map = createMap(breakpoints);