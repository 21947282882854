import styled from "styled-components";

interface Props {
    show: boolean
}

export const BoxImg = styled.div`
    background: ${(props) => props.theme.colors.text.white};
    border-radius: 8px;
    display: ${(props: Props) => (props.show ? 'flex' : 'none')};
    height: 54px;
    justify-content: center;
    /* position: sticky; */
    top: ${(props: Props) => (props.show ? '135px' : '0px')};
    width: 353px;

    transition: all 0.3s ease-in-out;

    z-index: 5;

    img {
        align-self: center;
        height: 45px;
        width: 101px;
    }

    &.super-chance {
        margin: auto !important;
        width: 85%;
    }
`;