import React, { useEffect, useState } from 'react';
import { ErrorSpan } from '@componentsShared';
import { useFormContext } from 'react-hook-form';
import {
    ContainerDataList,
    ContainerOptions,
    InputList,
    LabelInput,
    Option,
    Options
} from './styled';
import { IProps } from './types';

export const InputDataList = (props: IProps) => {
    const {
        list,
        label,
        fore,
        placeholders,
        disabled,
        name,
        ClassName,
        setShowCities,
        showCities,
        pattern,
        fullCountries,
        fullCities
    } = props;
    const [inputText, setInputText] = useState('');
    const [resultList, setResultList] = useState(list);
    const [isOpen, setIsOpen] = useState(false);
    const { register, formState, setValue, clearErrors } = useFormContext();

    let regExt: RegExp = /[a-zA-Z 0-9]/;
    let patternValue: RegExp = pattern !== undefined ? pattern.value : regExt;
    let patternMessage = pattern !== undefined ? pattern.message : '';

    const filterInput = (inputText: string) => {
        const resultSearch = list.filter((element: string) =>
            element.toLowerCase().includes(inputText.toLowerCase())
        );
        if (resultSearch.length === 0) {
            setResultList(['Sin opciones']);
            return;
        }
        setResultList(resultSearch);
    };

    const ChangeSelect = (text: string) => {
        setInputText(text);
        setValue(name + 'main', text);
    };

    useEffect(() => {
        if (inputText.toLocaleLowerCase() === 'colombia') {
            setShowCities && setShowCities(true);
        } else {
            setShowCities && setShowCities(false);
        }
    }, [inputText]);

    useEffect(() => {
        if (formState.errors[name + 'main']?.message) {
            setIsOpen(false);
        }
    }, [formState.errors[name + 'main']?.message]);

    useEffect(() => {
        list && setResultList(list);
    }, [list]);

    return (
        <ContainerDataList>
            <LabelInput htmlFor={'#' + fore}>{label}</LabelInput>
            <InputList
                {...register(name + 'main', {
                    required:
                        name + 'main' === 'country'
                            ? 'Debes ingresar tu nacionalidad'
                            : 'El campo es requerido',
                    minLength: {
                        value: 2,
                        message: 'Debe contener al menos dos caracteres'
                    },
                    maxLength: {
                        value: 30,
                        message: 'No debe contener más de 30 caracteres'
                    },
                    pattern: {
                        value: patternValue,
                        message: patternMessage
                    }
                })}
                type="text"
                name={name + 'main'}
                id={'#' + fore}
                autoComplete="off"
                placeholder={placeholders}
                disabled={disabled}
                className={ClassName}
                thereIsError={formState.errors[name + 'main']}
                whatIsError={formState.errors[name + 'main']?.type === 'pattern' ? 'alert' : ''}
                onChange={(e) => {
                    filterInput(e.target.value);
                    ChangeSelect(e.target.value.toUpperCase());
                    if (name === 'city' && !showCities) {
                        setValue(name, e.target.value.toUpperCase());
                    }
                    // clearErrors(name);
                }}
                onFocus={() => {
                    setIsOpen(true);
                }}
                onBlur={() => {
                    setTimeout(() => {
                        setIsOpen(false);
                    }, 300);
                }}
            />
            <input {...register(name)} name={name} type="hidden" />
            {formState.errors[name + 'main']?.message && (
                <ErrorSpan
                    text={formState.errors[name + 'main']?.message}
                    type={formState.errors[name + 'main']?.type === 'pattern' ? 'alert' : undefined}
                />
            )}
            {name !== 'city' && (
                <ContainerOptions state={isOpen}>
                    <Options
                        activeScroll={
                            resultList.length > 3
                                ? true
                                : placeholders && resultList.length > 3
                                ? true
                                : false
                        }
                    >
                        {resultList.map((option: string, index: number) => (
                            <Option
                                key={'option-' + name + index}
                                onClick={() => {
                                    ChangeSelect(option);
                                    let country = fullCountries.filter((element) => {
                                        if (
                                            element.name
                                                .toString()
                                                .toLowerCase()
                                                .includes(option.toLowerCase())
                                        ) {
                                            return element;
                                        }
                                    });
                                    /* setValue(name, option); */
                                    if (resultList[0] !== 'Sin opciones') {
                                        setValue(name, country[0].id);
                                    }
                                    setValue(name + 'main', option);
                                }}
                            >
                                {option}
                            </Option>
                        ))}
                    </Options>
                </ContainerOptions>
            )}
            {name === 'city' && showCities ? (
                <ContainerOptions state={isOpen}>
                    <Options
                        activeScroll={
                            resultList.length > 3
                                ? true
                                : placeholders && resultList.length > 3
                                ? true
                                : false
                        }
                    >
                        {resultList.map((option: string, index: number) => (
                            <Option
                                key={'option-' + name + index}
                                onClick={() => {
                                    ChangeSelect(option);

                                    let city = fullCities.filter((element) => {
                                        if (
                                            element.name
                                                .toString()
                                                .toLowerCase()
                                                .includes(option.toLowerCase())
                                        ) {
                                            return element;
                                        }
                                    });
                                    setValue(name, city[0].code.toString());
                                    setValue(name + 'main', option);
                                }}
                            >
                                {option}
                            </Option>
                        ))}
                    </Options>
                </ContainerOptions>
            ) : (
                <></>
            )}
        </ContainerDataList>
    );
};
