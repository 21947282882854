import styled from 'styled-components';
import { IPropsError } from './types';

export const TextError = styled.span<IPropsError>`
    font-size: 10px;
    color: ${(props) =>
        props.type === 'alert'
            ? props.theme.colors.accents.neon.orange
            : props.theme.colors.alerts.red};
    margin-top: 10px;
    display: flex;
    align-items: center;

    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;

    /* &::before {
        font-family: 'icomoon' !important;
        font-size: 12px;
        margin-right: 8px;
        content: '\\e979';
    } */
`;
