import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';

export const ContainerRedime = styled.div`
    margin-top: 20px;
    max-width: 100%;
`;

export const ContainerTextRedime = styled.div`
    width: 75%;
    margin: 20px auto;

    ${breakpoint('md')`
          margin: 0 auto 20px;
    `};

    p {
        font-size: 14px;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        text-align: center;
        line-height: 16.8px;

        ${breakpoint('md')`
          font-size: 16px;
          line-height: 19.2px;
        `};

        b {
            font-weight: bold;
        }

        i {
            color: ${(props) => props.theme.colors.accents.base.yellow};
        }
    }
`;

export const ContainerSliderRedime = styled.div`
    .slick-slider {
        width: 100%;
        height: 675px;

        ${breakpoint('md')`
          height: 550px;
        `};

        .slick-list {
            width: 100%;
            margin: 0 auto;

            .slick-track {
                padding: 62px 0px;
                width: 100%;

                .slick-active {
                    margin-left: 0.9%;

                    ${breakpoint('xs')`
                        margin-left: 2.5%;
                    `};

                    ${breakpoint('s')`
                        margin-left: 3.5%;
                    `};

                    ${breakpoint('sm')`
                        margin-left: 4.2%;
                    `};

                    ${breakpoint('md')`
                        margin-left: 1%;
                    `};

                    ${breakpoint('lg')`
                        margin-left: 0;
                    `};
                }
            }
        }

        .slick-dots {
            text-align: center;
            width: auto;
            left: 0;
            right: 0;
            margin: 0 auto;
            li {
                width: 15px;
                height: 15px;
                &.slick-active {
                    button {
                        &:before {
                            font-size: 12px;
                            line-height: 14px;
                            width: 11px;
                            height: 11px;
                            color: transparent;
                            box-shadow: 0px 0px 6px 1.5px
                                ${(props) => props.theme.colors.alerts.yellow};
                            border: 2px solid ${(props) => props.theme.colors.alerts.yellow};
                            top: -2px;
                        }
                    }
                }
                button {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:before {
                        font-size: 10px;
                        line-height: 12px;
                        color: transparent;
                        border: 2px solid ${(props) => props.theme.colors.disabled.gray};
                        border-radius: 50%;
                        width: 7px;
                        height: 7px;
                        opacity: 1;
                    }
                }
            }
        }
    }
`;
