import React, { useEffect, useState } from 'react';
import {
    BottomRegister,
    Button,
    ButtonGoBack,
    Redirect,
    InputDataList,
    DatePicker,
    TitleStep,
    Loading,
    ErrorSpan
} from '@componentsShared';
import {
    AuxText,
    ContainerRegisterTop,
    DocumentContainerStepTwo,
    MainText,
    RegisterBody,
    RegisterForm,
    RegisterTitle,
    TitleContainer,
    TextLabel,
    SelectContainer,
    FormRegister,
    FormButtons,
    ContainerInputs,
    RegisterBox,
    ContainerLoading,
    ContentError
} from './styled';
import { Stepper } from 'src/shared/components/molecules/checkSteps';
import Viewport from 'src/shared/hooks/viewport';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { getDataStorage, setDataStorage } from '@utils/transferData';
import { generalDataServices } from '@services/generalData';
import { IDeparments, IInputs, TDataPais, TDataNationality, TCountries, TCities } from './types';

export const RegisterStepTwo = () => {
    const methods = useForm({ mode: 'onChange' });
    const viewport = Viewport();
    const [disable, setDisable] = useState(true);
    const [newState, setNewState] = useState({});
    const [cities, setCities] = useState<string[]>([]);
    const [fullCitiesState, setFullCitiesState] = useState<TCities[]>([]);
    const [countries, setCountries] = useState<string[]>([]);
    const [fullCountriesState, setFullCountries] = useState<TCountries[]>([]);
    const [showCities, setShowCities] = useState(true);
    const [ageUser, setAgeUser] = useState(0);

    const onSubmit: SubmitHandler<IInputs> = (data) => {
        const allData = Object.assign(newState, data);
        setDataStorage(allData, 'registerData');
        window.location.href = '/registro/datos-contacto';
    };

    const fetchCountry = async () => {
        await generalDataServices
            .getPaisesNationality()
            .then((data: TDataNationality) => {
                const {
                    data: { getPaisesNationality }
                } = data;
                
                const countries = getPaisesNationality.map(country => country.name)
                setCountries(countries)
                
                const countriesFull = getPaisesNationality.map(country => country)
                setFullCountries(countriesFull)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchCities = async () => {
        await generalDataServices
            .getPaises()
            .then((data: TDataPais) => {
                const {
                    data: {
                        getPaises: [colombia]
                    }
                } = data;

                let arrayCities: string[] = [];
                let fullCities: TCities[] = [];
                colombia.departments.forEach((department: IDeparments) => {
                    department.cities.forEach((city) => {
                        arrayCities.push(city.name);
                        fullCities.push(city);
                    });
                });

                setCities(arrayCities);
                setFullCitiesState(fullCities);
            })
            .catch((err) => {
                console.log('error', err);
            });
    };

    const fetchRegisterData = () => {
        let userRegisterData = getDataStorage('registerData');
        if (userRegisterData !== null) {
            methods.setValue('citymain', userRegisterData.citymain);
            methods.setValue('countrymain', userRegisterData.countrymain);
            methods.setValue('city', userRegisterData.city);
            methods.setValue('country', userRegisterData.country);
            methods.setValue('eDay', userRegisterData.eDay);
            methods.setValue('eMonth', userRegisterData.eMonth);
            methods.setValue('eYear', userRegisterData.eYear);
            setNewState(userRegisterData);

            let { countrymain, citymain } = getDataStorage('registerData');
            if (countrymain !== undefined && citymain !== undefined) {
                setDisable(false);
            } else {
                setDisable(true);
            }
        }
    };

    function calculateAge() {
        let userRegisterDataDate = getDataStorage('registerData');
        if (userRegisterDataDate !== null) {
            const { day, month, year } = userRegisterDataDate;
            let buildString = `${month}-${day}-${year}`;
            let today = new Date();
            let birthday = new Date(buildString);
            let age = today.getFullYear() - birthday.getFullYear();
            let m = today.getMonth() - birthday.getMonth();

            if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
                age--;
            }
            setAgeUser(age);
        }
    }

    useEffect(() => {
        fetchRegisterData();
        fetchCountry();
        fetchCities();
        calculateAge();
    }, []);

    return (
        <RegisterBox>
            {viewport.mobile && <ButtonGoBack />}
            {viewport.mobile && (
                <ContainerRegisterTop>
                    <RegisterTitle>Registro</RegisterTitle>
                    <Stepper step1="ok" step2="2" />
                </ContainerRegisterTop>
            )}
            <RegisterForm>
                {!viewport.mobile && (
                    <ContainerRegisterTop>
                        <RegisterTitle>Registro</RegisterTitle>
                        <Stepper step1="ok" step2="2" />
                    </ContainerRegisterTop>
                )}
                <RegisterBody>
                    <TitleContainer>
                        {viewport.mobile && <TitleStep number="2" text="Verificación"></TitleStep>}

                        {!viewport.mobile && <AuxText>Verificación</AuxText>}
                    </TitleContainer>
                    <MainText>
                        Para hacer todo por lo legal, necesitamos estos datos. Trae tu documento, lo
                        necesitarás.
                    </MainText>
                    {
                        (countries.length < 1 && cities.length < 1) 
                            ?   <ContainerLoading>
                                    <Loading />
                                </ContainerLoading>
                            : <FormProvider {...methods}>
                                <FormRegister onSubmit={methods.handleSubmit(onSubmit)}>
                                    <ContainerInputs>
                                        <InputDataList
                                            list={countries}
                                            label="Nacionalidad"
                                            fore="nacionalidad_id"
                                            name={'country'}
                                            require={true}
                                            disabled={false}
                                            placeholders="Elige un país"
                                            ClassName="uppercase"
                                            setShowCities={setShowCities}
                                            showCities={showCities}
                                            pattern={{
                                                value: /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/,
                                                message: 'Caracteres no válidos'
                                            }}
                                            fullCountries={fullCountriesState}
                                            fullCities={fullCitiesState}
                                        />

                                        {
                                            !methods.watch('country') &&
                                                <ContentError className='errorCountry'>
                                                    <ErrorSpan text='Debes seleccionar un país de la lista'></ErrorSpan>
                                                </ContentError>
                                        }
                                    </ContainerInputs>
                                    <DocumentContainerStepTwo>
                                        <TextLabel>Fecha de expedición documento identidad</TextLabel>
                                        <SelectContainer>
                                            <DatePicker
                                                isInputExpedition={true}
                                                nameDay={'eDay'}
                                                nameMonth={'eMonth'}
                                                nameYear={'eYear'}
                                                startDate={ageUser}
                                            />
                                        </SelectContainer>
                                        <InputDataList
                                            list={cities}
                                            label="Ciudad de expedición documento identidad"
                                            fore="ciudad_id"
                                            require={true}
                                            disabled={false}
                                            placeholders="Escribe tu ciudad"
                                            name={'city'}
                                            ClassName="uppercase"
                                            showCities={showCities}
                                            pattern={{
                                                value: /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/,
                                                message: 'Evita usar tildes o caracteres especiales no válidos'
                                            }}
                                            fullCities={fullCitiesState}
                                            fullCountries={fullCountriesState}
                                        />
                                        {
                                            (!methods.watch('city') && methods.watch('country') === "1")  &&
                                                <ContentError className='errorCity'>
                                                    <ErrorSpan text='Debes seleccionar una ciudad de la lista'></ErrorSpan>
                                                </ContentError>
                                        }
                                    </DocumentContainerStepTwo>
                                    <FormButtons>
                                        <Button
                                            text="Continuar"
                                            onClick={() => {}}
                                            disabled={
                                                disable
                                                    ? methods.watch('country') === '' ||
                                                    methods.watch('eDay') === '' ||
                                                    methods.watch('eMonth') === '' ||
                                                    methods.watch('eYear') === '' ||
                                                    methods.watch('city') === '' ||
                                                    methods.watch('city') === undefined
                                                    : false
                                            }
                                            submit={true}
                                        ></Button>
                                        <Redirect
                                            className="redirect"
                                            url="registro/datos-personales"
                                            text="Regresar"
                                        />
                                    </FormButtons>
                                </FormRegister>
                            </FormProvider>
                    }
                </RegisterBody>
            </RegisterForm>
            <BottomRegister text="¡Ya tengo una cuenta! Yo soy cliente Gana" url="/iniciar-sesion" />
        </RegisterBox>
    );
};
