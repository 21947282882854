import {
    BottomRegister,
    Button,
    ButtonGoBack,
    DatePicker,
    GenreRadioButton,
    InputCustom,
    Redirect,
    TitleStep,
    ValidateTypeDocument,
    Stepper,
    ErrorSpan
} from '@componentsShared';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, SubmitHandler, useFormContext } from 'react-hook-form';
import { ModalNotRegistered } from 'src/components/molecules/modalNotRegistered';
import Viewport from 'src/shared/hooks/viewport';
import { getDataStorage, setDataStorage } from 'src/shared/utils/transferData';
import {
    AuxText,
    ContainerErrorAge,
    ContainerLegalAge,
    ContainerPolitics,
    ContainerTratments,
    ContainerRegisterTop,
    ContentDate,
    ContentError,
    CustomCheck,
    DocumentContainer,
    FormButtons,
    FormRegister,
    GenreContainer,
    InputContainer,
    LegalAgeContainer,
    MainText,
    Politics,
    Tratments,
    RegisterBody,
    RegisterBox,
    RegisterForm,
    RegisterTitle,
    SelectContainer,
    TextLabel,
    TitleContainer
} from './styled';
import { OPCIONES, TRegisterData } from './types';

export const RegisterData = () => {
    const methods = useForm({ mode: 'onChange' });
    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const viewport = Viewport();
    const [ddDate, setDDate] = useState({});
    const [isLegalAge, setIsLegalAge] = useState(false);
    const [isSelectedAge, setIsSelectedAge] = useState(false);

    const [isSelectCheckGenre, setIsSelectCheckGenre] = useState(true);
    const [isSelectCheckLegalAge, setIsSelectCheckLegalAge] = useState(true);

    const [validationDocument, setValidationDocument] = useState({
        validation: {
            value: / /,
            message: ''
        },
        type: 'number'
    });

    const getRegisterData = () => {
        let userRegisterData = getDataStorage('registerData');
        if (userRegisterData !== null) {
            methods.setValue('firstName', userRegisterData.firstName);
            methods.setValue('lastName', userRegisterData.lastName);
            methods.setValue('legalAge', userRegisterData.legalAge);
            methods.setValue('day', userRegisterData.day);
            methods.setValue('month', userRegisterData.month);
            methods.setValue('year', userRegisterData.year);
            methods.setValue('genre', userRegisterData.genre);
            methods.setValue('type', userRegisterData.type);
            methods.setValue('user', userRegisterData.user);
        }
    };

    const calculateAge = (day: number, month: string, year: number) => {
        let buildString = `${month}-${day}-${year}`;
        let today = new Date();
        let birthday = new Date(buildString);
        let age = today.getFullYear() - birthday.getFullYear();
        let m = today.getMonth() - birthday.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
            age--;
        }
        setIsLegalAge(age >= 18 ? true : false);
    };

    const validateCheckInputs = () => {
        if (!methods.watch('legalAge')) {
            setIsSelectCheckLegalAge(false);
        }

        if (!methods.watch('genre')) {
            setIsSelectCheckGenre(false);
        }
    };

    const onSubmit: SubmitHandler<TRegisterData> = (data) => {
        const mayoriaEdad: string = methods.watch('legalAge');        
        data.mayoriaEdad = mayoriaEdad;    
        data.terminosYCondiciones = checked1 ? OPCIONES.ACEPTAR : OPCIONES.RECHAZAR;
        data.tratamientoDeDatos = checked2 ? OPCIONES.ACEPTAR : OPCIONES.RECHAZAR;
        setDataStorage(data, 'registerData');
        window.location.href = '/registro/verificacion';
    };

    useEffect(() => {
        getRegisterData();
    }, []);

    useEffect(() => {
        if ((methods.watch('year'), methods.watch('month'), methods.watch('day'))) {
            setIsSelectedAge(true);
            calculateAge(methods.watch('day'), methods.watch('month'), methods.watch('year'));
            return;
        }
        setIsSelectedAge(false);
    }, [methods.watch('year'), methods.watch('month'), methods.watch('day')]);

    useEffect(() => {
        if (methods.watch('legalAge')) {
            setIsSelectCheckLegalAge(true);
        }

        if (methods.watch('genre')) {
            setIsSelectCheckGenre(true);
        }
    }, [methods.watch('legalAge'), methods.watch('genre')]);

    return (
        <RegisterBox>
            {viewport.mobile && <ButtonGoBack />}
            {viewport.mobile && (
                <ContainerRegisterTop>
                    <RegisterTitle>Registro</RegisterTitle>
                    <Stepper step1="1" />
                </ContainerRegisterTop>
            )}
            <RegisterForm>
                {!viewport.mobile && (
                    <ContainerRegisterTop>
                        <RegisterTitle>Registro</RegisterTitle>
                        <Stepper step1="1" />
                    </ContainerRegisterTop>
                )}
                <RegisterBody>
                    <TitleContainer>
                        {viewport.mobile && (
                            <TitleStep number="1" text="Datos Personales"></TitleStep>
                        )}

                        {!viewport.mobile && <AuxText>Datos Personales</AuxText>}
                    </TitleContainer>
                    <MainText>
                        A través de estos datos podremos sostener esta relación por mucho tiempo.
                    </MainText>
                    <FormProvider {...methods}>
                        <FormRegister onSubmit={methods.handleSubmit(onSubmit)}>
                            <InputContainer>
                                <InputCustom
                                    label="Nombre"
                                    name="firstName"
                                    type="text"
                                    placeholder="Escribe tu nombre"
                                    required="Este campo es obligatorio, agrega tu nombre"
                                    minLength={{
                                        value: 3,
                                        message: 'Agrega al menos 3 letras'
                                    }}
                                    maxLength={{
                                        value: 30,
                                        message: 'Agrega máximo 30 letras'
                                    }}
                                    pattern={{
                                        value: /^[^\s][a-zñA-ZÑ\s]*$/,
                                        message: 'Evita usar tildes o caracteres especiales no válidos'
                                    }}
                                    className={
                                        validationDocument.type === 'text' ? 'uppercase' : ''
                                    }
                                />
                                <InputCustom
                                    name="lastName"
                                    label="Apellido"
                                    type="text"
                                    placeholder="Escribe tu apellido"
                                    required="Este campo es obligatorio, agrega tu apellido"
                                    minLength={{
                                        value: 3,
                                        message: 'Agrega al menos 3 letras'
                                    }}
                                    maxLength={{
                                        value: 30,
                                        message: 'Agrega máximo 30 letras'
                                    }}
                                    pattern={{
                                        value: /^[^\s][a-zñA-ZÑ\s]*$/,
                                        message: 'Evita usar tildes o caracteres especiales no válidos'
                                    }}
                                    className={
                                        validationDocument.type === 'text' ? 'uppercase' : ''
                                    }
                                />
                            </InputContainer>

                            <ContainerLegalAge>
                                {viewport.mobile && <TextLabel>¿ Eres mayor de edad ?</TextLabel>}
                                <LegalAgeContainer>
                                    {!viewport.mobile && (
                                        <TextLabel className="genreText">
                                            ¿ Eres mayor de edad ?
                                        </TextLabel>
                                    )}
                                    <GenreRadioButton
                                        name="legalAge"
                                        label="Si"
                                        value="Si"
                                        onClick={() => {}}
                                    />

                                    <GenreRadioButton
                                        name="legalAge"
                                        label="No"
                                        value="No"
                                        onClick={() => {}}
                                    />
                                </LegalAgeContainer>
                                {!isSelectCheckLegalAge && (
                                    <ContentError>
                                        <ErrorSpan text={'Campo obligatorio'} />
                                    </ContentError>
                                )}
                            </ContainerLegalAge>

                            <TextLabel>Fecha de nacimiento</TextLabel>
                            <SelectContainer>
                                <DatePicker nameYear="year" nameMonth="month" nameDay="day" />

                                <ContainerLegalAge>
                                    {viewport.mobile && <TextLabel>Género</TextLabel>}
                                    <GenreContainer>
                                        {!viewport.mobile && (
                                            <TextLabel className="genreText">Género</TextLabel>
                                        )}
                                        <GenreRadioButton
                                            name="genre"
                                            label="Masculino"
                                            value="Masculino"
                                            onClick={() => {}}
                                        />

                                        <GenreRadioButton
                                            name="genre"
                                            label="Femenino"
                                            value="Femenino"
                                            onClick={() => {}}
                                        />

                                        <GenreRadioButton
                                            name="genre"
                                            label="Otro"
                                            value="Otro"
                                            onClick={() => {}}
                                        />
                                    </GenreContainer>
                                    {!isSelectCheckGenre && (
                                        <ContentError className="errorGenre">
                                            <ErrorSpan text={'Campo obligatorio'} />
                                        </ContentError>
                                    )}
                                </ContainerLegalAge>
                            </SelectContainer>

                            <DocumentContainer>
                                <ValidateTypeDocument />
                            </DocumentContainer>

                            {methods.watch('legalAge') === 'No' && (
                                <ContainerErrorAge>
                                    <span className="icon-face-sad"></span>
                                    <p>
                                        <span>¡Ups!</span> Solo puedes registrarte si eres mayor de
                                        edad
                                    </p>
                                </ContainerErrorAge>
                            )}

                            <ContainerPolitics>
                                <CustomCheck
                                    type="checkbox"
                                    name="politicas"
                                    onClick={() => {
                                        setChecked1(!checked1);
                                    }}
                                />

                                <Politics>
                                    <Redirect url="terminos-y-condiciones/" isOpenOtherTab>
                                        Acepto los términos y condiciones
                                    </Redirect>
                                </Politics>
                            </ContainerPolitics>

                            <ContainerTratments>
                                <CustomCheck
                                    type="checkbox"
                                    name="tratamientos"
                                    onClick={() => {
                                        setChecked2(!checked2);
                                    }}
                                />

                                <Tratments>
                                    <Redirect url="tratamiento-datos/" isOpenOtherTab>
                                        Acepto la política de tratamiento de datos personales
                                    </Redirect>
                                </Tratments>
                            </ContainerTratments>

                            <FormButtons>
                                <Button
                                    text="Continuar"
                                    onClick={validateCheckInputs}
                                    disabled={
                                        checked1 && checked2 && methods.watch('legalAge') === 'Si'
                                            ? false
                                            : true
                                    }
                                    submit={true}
                                ></Button>
                                <Redirect className="redirect" url="registro/" text="Regresar" />
                            </FormButtons>
                        </FormRegister>
                    </FormProvider>
                </RegisterBody>
            </RegisterForm>
            <BottomRegister text="¡Ya tengo una cuenta! Yo soy cliente Gana" url="/iniciar-sesion" />
        </RegisterBox>
    );
};
