import styled from 'styled-components';
import { IPropsContainerOptions, IInputProp, IPropsUl } from './types';
import { breakpoint } from 'src/shared/styles/variables';

export const ContainerDataList = styled.div`
    width: 100%;
    position: relative;
    padding: 0px;
    height: 120px;
    ${breakpoint('md')`
        width: 40%;
        min-width: 308px;
    `}

    ${breakpoint('lg')`
    width: 100%;
        max-width: 430px;
        height: 125px;
    `}; // overflow: hidden;
`;

export const LabelInput = styled.label`
    color: ${(props) => props.theme.colors.text.gray};
    font-family: ${(props) => props.theme.fonts.lf_bold};
    font-size: 14px;
    display: block;
    margin-bottom: 14px !important;
`;

export const InputList = styled.input<IInputProp>`
    color: ${(props) => props.theme.colors.text.gray};
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 16px;
    background-color: ${(props) => props.theme.colors.backgrounds.gray400};
    border-radius: 16px;
    outline: none;
    border: none;
    padding: 16px;
    height: 56px;
    width: 100%;
    margin-bottom: 5px;
    border: 1px solid transparent;

    border-color: ${(props) =>
        props.thereIsError
            ? props.whatIsError === 'alert'
                ? props.theme.colors.accents.neon.orange
                : props.theme.colors.alerts.red
            : 'transparent'};

    &.uppercase {
        text-transform: uppercase;
    }
    &::placeholder {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        text-transform: none;
    }
    &:focus {
        border: 1px solid ${(props) => props.theme.colors.principal.greenNeon};
    }

    ${breakpoint('md')`
        max-width: 410px;
    `}
`;

export const ContainerOptions = styled.div<IPropsContainerOptions>`
    width: 100%;
    border-radius: 16px;
    position: absolute;
    top: 80px;
    visibility: hidden;
    opacity: 0;
    z-index: 10;
    display: none;
    margin-top: 8px;
    padding: 5px 0px;
    background: ${(props) => props.theme.colors.backgrounds.gray400};
    transition: all 0.09s cubic-bezier(0.33, 0.77, 0.8, -0.12);
    ${(props) => props.state && 'visibility: visible;opacity: 1;display: block;'}
    ${breakpoint('md')`
        max-width: 410px;
    `}
`;

export const Options = styled.ul<IPropsUl>`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow-y: ${(props) => (props.activeScroll ? `scroll` : `hidden`)};
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    max-height: 115px;
    z-index: 10;

    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    ::-webkit-scrollbar-thumb {
        background: #c5c3c9;
    }
    ::-webkit-scrollbar:vertical {
        width: 2px;
    }
    ::-webkit-scrollbar-track {
        background: #1d1828;
    }
`;

export const Option = styled.li`
    margin: 0px 8px;
    background: none;
    outline: none;
    border: none;
    text-align: left;
    padding: 10px 16px;
    border-radius: 8px;
    line-height: 18px;
    cursor: pointer;
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 16px;
    &:hover {
        background: ${(props) => props.theme.colors.backgrounds.gray500};
    }
`;

/* export const DataList = styled.datalist`
  background-color: ${(props) => props.theme.colors.backgrounds.gray400};
`

export const Option = styled.option`
  background-color: ${(props) => props.theme.colors.backgrounds.gray400};
` */
