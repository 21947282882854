import React from 'react';
import { BannerContainer, ContainerImage, ContainerText } from './styled';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Redirect, Button } from '@componentsShared';

export const FinalAuxBanner = (props: any) => {
    const { image, text, buttonText, alt, hyperlink } = props;
    return (
        <BannerContainer>
            <ContainerImage>
                <img src={image.file.url} alt={alt} />
            </ContainerImage>
            <ContainerText>{documentToReactComponents(JSON.parse(text.raw))}</ContainerText>
            <Redirect url={hyperlink}>
                <Button text={buttonText} />
            </Redirect>
        </BannerContainer>
    );
};
