export const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
export const getCookie = (cName) => {
    if (typeof document !== `undefined`) {
        const name = cName + "=";
        const cDecoded = decodeURIComponent(document.cookie); //to be careful
        const cArr = cDecoded.split('; ');
        let res;
        cArr.forEach(val => {
            if (val.indexOf(name) === 0) res = val.substring(name.length);
        })
        return res
    }
}
export const eraseCookie = (name) => {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const saveCookieByHours = (name, value, hours) => {
    const parsedValue = JSON.stringify(value)
    const todayDate = new Date()
    todayDate.setTime(todayDate.getTime() + (hours * 60 * 60 * 1000))
    const expires = "; expires=" + todayDate.toUTCString();

    document.cookie = name + "=" + (parsedValue || "") + expires + "; path=/";
}