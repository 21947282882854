import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';
import { TPropsUI } from './types';

export const ContainerGameTable = styled.div`
    width: 289px;
    height: 588px;
    background-color: ${(props) => props.theme.colors.backgrounds.gray450};
    position: relative;
    border-radius: 16px;

    ${breakpoint('md')`
      height: 456px;
    `};
`;

export const ContainerLogo = styled.div`
    background-color: #fff;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 235px;
    height: 53.8px;
    top: -40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 8px;

    img {
        height: 40px;
    }

    &.acumula {
        background-color: ${(props) => props.theme.colors.backgrounds.gray400};

        h5 {
            margin-left: 16px;
            font-size: 14px;
            font-family: ${(props) => props.theme.fonts.mon_bold};
            width: 84px;
        }

        img {
            height: auto;
            width: 24px;
        }
    }
`;

export const HeaderTable = styled.div`
    width: 100%;
    height: 69px;
    border-radius: 16px 16px 0 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.backgrounds.gray400};
    display: flex;
    align-items: center;
    padding: 0px 18px;

    p {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        font-size: 16px;
        margin-top: 15px;
        width: 50%;
        text-align: center;
    }

    &.acumula {
        p:first-child {
            width: 65%;
        }

        p:last-child {
            width: 35%;
        }
    }
`;

export const ContainerContentTable = styled.div`
    width: 100%;
    height: 519px;
    margin-top: 7px;

    ${breakpoint('md')`
        max-height: 373px;
    `};
`;
export const ContentTable = styled.div<TPropsUI>`
    width: 270px;
    height: 519px;
    margin: 0 auto;
    overflow-y: ${(props) => (props.activeScroll ? `scroll` : `hidden`)};

    ${breakpoint('md')`
      height: 373px;
      padding: 0px 9px;


        ::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        ::-webkit-scrollbar-thumb {
            background: #1d1828;
        }
        ::-webkit-scrollbar:vertical {
            width: 1px;
        }
        ::-webkit-scrollbar-track {
            background: #c5c3c9;
        }
    `};
`;

export const RowTable = styled.div`
    width: 100%;
    border-radius: 6px;
    display: flex;
    align-content: center;
    justify-content: center;
    min-height: 40px;
    margin: 4px 0;

    &.acumula {
        div:first-child {
            width: 65%;
        }

        div:last-child {
            width: 35%;
        }
    }

    div {
        width: 50%;
        text-align: center;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 10px;
    }

    &.light {
        background-color: ${(props) => props.theme.colors.backgrounds.gray400};
    }

    &.dark {
        background-color: ${(props) => props.theme.colors.backgrounds.gray450};
    }
`;
