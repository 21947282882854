import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';

export const CartHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    padding: 17px 20px 18px 27px;
    height: 84px;
    width: 100%;
    background: linear-gradient(to left, #0df96c, #009f5c);

    .icon-shopping-cart {
        font-size: 24px;
        position: relative;
        top: 0;
    }

    ${breakpoint('md')`
        height: 100px;
        padding: 17px 28px 18px 27px;
        width: 100%;
        
        .icon-shopping-cart {
            font-size: 27px;
        }
    `}
`;

export const CartModalTitle = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .icon-cross {
        align-self: start;
        font-size: 13px;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
    }

    ${breakpoint('md')`
        .icon-cross {
            align-self: center;
        }
    `}
`;

export const TextContainer = styled.div`
    padding-left: 20px;
    p:first-child {
        font-size: 16px;
        line-height: 15.2px;
        font-family: ${(props) => props.theme.fonts.mon_bold};
    }

    p {
        font-size: 14px;
        font-weight: 24px;
        font-family: ${(props) => props.theme.fonts.lf_bold};
        line-height: 21px;

        b {
            color: ${(props) => props.theme.colors.accents.base.yellow};
        }
    }

    span {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 12px;
        font-weight: 24px;
    }

    ${breakpoint('md')`
        p:first-child {
            font-size: 20px;
            line-height: 16.2px;
            font-family: ${(props) => props.theme.fonts.mon_bold};
        }
    `}
`;
