import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';
import imgBackgroundMobile from '../../../assets/images/backgroundMobile.png';
import imgBackgroundDesktop from '../../../assets/images/backgroundDesktop.png';
import { hexToRGB } from 'src/shared/utils/hexToRgba';

type IPropslocation = {
    location?: boolean;
};

export const WrapperLayout = styled.div`
    background-image: url(${imgBackgroundMobile});
    background-color: ${(props) => props.theme.colors.backgrounds.gray500};
    color: ${(props) => props.theme.colors.text.gray};
    background-repeat: repeat;
    background-size: cover;

    ${breakpoint('md')`
        background-image: url(${imgBackgroundDesktop});
        background-size: contain;

    `}
`;

export const Main = styled.main<IPropslocation>`
    min-height: 75vh;
    position: relative;
    padding-top: ${(props: IPropslocation) => (props.location ? '110px' : '54px')};
    max-width: 1366px;
    margin: 0 auto;

    ${breakpoint('md')<IPropslocation>`
        padding-top: ${(props: IPropslocation) => (props.location ? '130px' : '100px')};
    `}

    &.home {
        padding-top: ${(props: IPropslocation) => (props.location ? '46px' : '0')};
    }
`;

export const Watermark = styled.span`
    position: fixed;
    bottom: 84px;
    left: 30px;
    font-size: 50px;
    font-family: ${(props) => props.theme.fonts.lf_bold};
    color: ${(props) => hexToRGB(props.theme.colors.accents.neon.blue, 0.6)};
    pointer-events: none;
    z-index: 10;
`;
