import React, { useState } from 'react';
import { ModalGana } from '@componentsShared';
import { graphql, useStaticQuery } from 'gatsby';
import { registerServices } from '@services/register';
import { getDataStorage } from '@utils/transferData';
import CryptoJS from 'crypto-js';
import { encryptText } from '@utils/encrypt';
interface IProps {
    modalState: boolean;
    setModalState: () => void;
}

export const ModalMessage = (props: IProps) => {
    const { modalState, setModalState } = props;
    const dataModalMessage = useStaticQuery(graphql`
        query modalMessageQuery {
            allContentfulModal(filter: { name: { eq: "Correo de verificacion" } }) {
                edges {
                    node {
                        icon {
                            file {
                                url
                            }
                        }
                        title
                        text
                        AditionalText
                    }
                }
            }
        }
    `);
    const {
        allContentfulModal: { edges }
    } = dataModalMessage;
    const { node } = edges[0];
    const mailForwardingSendData = async (
        tipoDocumento: string,
        usuario: string,
        clave: string
    ) => {
        await registerServices
            .mailForwarding(tipoDocumento, usuario, clave)
            .then((data) => {
                console.log('DAta 1', data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const fetchRegisterData = () => {
        let userRegisterData = getDataStorage('registerData');
        if (userRegisterData !== null) {
            let user = encryptText(userRegisterData.user);
            let password = encryptText(userRegisterData.password);
            mailForwardingSendData(userRegisterData.type, user, password);
        }
    };
    const optionsModal = {
        logo: 'secundary',
        icon: node.icon.file.url,
        title: node.title,
        text: node.text,
        primaryButtonText: 'Entendido',
        primaryButton: () => {
            window.location.href = '/'
            setModalState();
            sessionStorage.removeItem('registerData');
        },
        secondaryButtonText: 'Reenviar link',
        secondaryButton: () => {
            fetchRegisterData();
        },
        aditionalFunction: () => {
            sessionStorage.removeItem('registerData');
            window.location.href = '/'
        }
    };
    return (
        <ModalGana visibility={modalState} setVisibility={setModalState} options={optionsModal} />
    );
};
