import React from 'react';
import { PreviewYoutube, SectionContentPage } from '@componentsShared';
import { Container, ContainerVideo, Content, HeaderPage, SectionContentPageMobileAbout, TitlePage } from './styled';
import { colorText } from '@utils/colorText';
import Viewport from 'src/shared/hooks/viewport';
import { TPropsAboutUs } from './types';

export const ContentAboutUs = (props: TPropsAboutUs) => {
    const { title, urlVideo, imageText, pageIcon } = props.data;

    const viewport = Viewport();

    return (
        <Container>
            <HeaderPage>
                <img src={pageIcon.file.url} alt={pageIcon.file.fileName} />
                <TitlePage dangerouslySetInnerHTML={{ __html: colorText(title) }} />
            </HeaderPage>

            <ContainerVideo>
                <PreviewYoutube
                    url={urlVideo}
                    height={{ mobile: '159', desktop: viewport.tablet ? '263' : '520' }}
                    width={{ mobile: '295', desktop: viewport.tablet ? '600' : '926' }}
                    playerVars={{
                        autoplay: 1
                    }}
                />
            </ContainerVideo>

            <Content>
                {viewport.desktop ? (
                    imageText.map((item, key) => (
                        <SectionContentPage
                            video={item.video}
                            text={item.text.raw}
                            img={item.mediaFile?.file}
                            isEven={key % 2 === 0}
                        />
                    )))
                    : (
                        imageText.map((item, key) => (
                            <SectionContentPageMobileAbout
                                video={item.video}
                                text={item.text.raw}
                                img={item.mediaFile?.file}
                                resolution={viewport.mobile}
                                isEven={true}
                            />
                        )))}

            </Content>
        </Container>
    );
};
