import styled from "styled-components";

export const ContainerRedirect = styled.a`
    cursor: pointer;
    text-decoration: none;

    &.editLottery {
        align-self: center;
        margin-top: 32px;
        width: max-content;
    }
`