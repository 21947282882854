import { ButtonIcon } from '@componentsShared';
import React, { ReactNode, useEffect, useState } from 'react';
import {
    ButtonClose,
    ContentLogo,
    IconMessage,
    LogoContainer,
    ModalBox,
    ModalContainer,
    Title,
    Text,
    PrimaryButtonModal,
    SecondaryButtonModal,
    ContentButton,
    BoldText,
    ContentText,
    AditionalHyperLink,
    ShowText,
    ShowContainer,
    ShowButton,
    RichText,
    RawTycText,
    CustomContent
} from './styled';
import { LogoGana } from '../../atoms/logoGana/index';
import { useScrollBlock } from 'src/shared/hooks/useScrollBlock';
import { isBrowser } from 'react-device-detect';

interface CustomLink {
    type: 'desktop' | 'mobile';
    text: string;
    value: string;
    target?: string;
}
interface IModalGana {
    visibility: boolean;
    setVisibility: () => void;
    children?: ReactNode;
    options: {
        logo?: string;
        data?: {
            file: {
                url: string;
                fileName: string;
            };
        };
        icon?: string;
        description?: string;
        title?: string;
        text?: string;
        richText?: any;
        tycText?: any;

        boldText?: string;
        primaryButtonText: string;
        primaryButton: () => void;
        primaryButtonDisabled?: boolean;
        secondaryButtonText?: string;
        secondaryButton?: () => void;
        dimensions?: {
            height?: { mobile?: string; desktop?: string };
            width?: { mobile?: string; desktop?: string };
        };
        paddingBox?: { mobile?: string; desktop?: string };
        sizeButton?: string;
        titleFonts?: boolean;
        aditionalFunction?: () => void;
        hyperLink?: string;
        url?: string;
        greenBold?: boolean;
        size?: { mobile?: string; desktop?: string };
        bold?: boolean;
        showHide?: string;
        wordBreak?: boolean;
        text1?: string;
        hiddenTitle?: boolean;
        hiddenContentText?: boolean;
        customLinks?: CustomLink[];
    };
}

export const ModalGana = (props: IModalGana) => {
    const [blockScroll, allowScroll] = useScrollBlock();
    const [readMore, setreadMore] = useState(false);

    const { visibility, setVisibility, options, children } = props;
    const {
        logo,
        data,
        icon,
        description,
        title,
        text,
        tycText,
        richText,
        boldText,
        primaryButton,
        secondaryButton,
        primaryButtonText,
        secondaryButtonText,
        dimensions,
        paddingBox,
        sizeButton,
        aditionalFunction,
        hyperLink,
        url,
        greenBold,
        size,
        bold,
        titleFonts,
        showHide,
        text1,
        hiddenTitle,
        hiddenContentText,
        primaryButtonDisabled,
        customLinks
    } = options;
    useEffect(() => {
        if (visibility) {
            blockScroll();
        }
    }, [visibility]);

    const filterLinksByType = (customLink: CustomLink): boolean => {
        if (isBrowser) {
            return customLink.type === 'desktop';
        } else {
            return customLink.type === 'mobile';
        }
    };

    return (
        <ModalContainer visibile={visibility}>
            <ModalBox dimensions={dimensions} paddingBox={paddingBox}>
                <LogoContainer>
                    <ButtonClose>
                        <ButtonIcon
                            icon="cross-small"
                            onClick={() => {
                                setVisibility();
                                if (aditionalFunction !== undefined) {
                                    aditionalFunction();
                                }
                                allowScroll();
                            }}
                            title="Cerrar"
                        ></ButtonIcon>
                    </ButtonClose>
                    {logo && (
                        <ContentLogo className="tycText">
                            <LogoGana type={logo} data={data} />
                        </ContentLogo>
                    )}
                </LogoContainer>
                {icon && icon.length >= 1 && (
                    <IconMessage>
                        <img src={icon} alt={description} title={description}></img>
                    </IconMessage>
                )}
                <Title
                    libreF={titleFonts}
                    className={tycText ? 'titleTyCText' : ''}
                    hiddenTitle={hiddenTitle}
                >
                    {title}
                </Title>
                {!hiddenContentText && (
                    <ContentText className={tycText ? 'contentTyCText' : ''}>
                        {text && (
                            <Text
                                greenBold={greenBold}
                                size={size}
                                className={options?.wordBreak ? 'wordBreak' : ''}
                            >
                                {text}
                            </Text>
                        )}
                        {text1 && (
                            <Text
                                greenBold={greenBold}
                                size={size}
                                className={options?.wordBreak ? 'wordBreak' : ''}
                            >
                                {text1}
                            </Text>
                        )}

                        {richText && <RichText>{richText}</RichText>}
                        {tycText && (
                            <RawTycText className={tycText ? 'rawTyCText' : ''}>
                                {tycText}
                            </RawTycText>
                        )}
                        {hyperLink && (
                            <AditionalHyperLink href={url}>{hyperLink}</AditionalHyperLink>
                        )}
                        {customLinks?.length &&
                            customLinks.length > 0 &&
                            customLinks.filter(filterLinksByType).map((link) => (
                                <AditionalHyperLink href={link.value} target={link.target}>
                                    {link.text}
                                </AditionalHyperLink>
                            ))}
                        {boldText && <BoldText bold={bold}>{boldText}</BoldText>}
                        {showHide && (
                            <ShowContainer>
                                <ShowButton
                                    onClick={() => {
                                        setreadMore(!readMore);
                                    }}
                                >
                                    {!readMore ? 'Leer más' : 'Leer menos'}
                                </ShowButton>
                                <ShowText className={readMore ? 'show' : 'hide'}>
                                    {showHide}
                                </ShowText>
                            </ShowContainer>
                        )}
                    </ContentText>
                )}
                {children && <CustomContent>{children}</CustomContent>}
                <ContentButton>
                    <PrimaryButtonModal
                        disabled={primaryButtonDisabled}
                        sizeButton={sizeButton}
                        onClick={() => {
                            primaryButton();
                            allowScroll();
                        }}
                    >
                        {primaryButtonText}
                    </PrimaryButtonModal>
                    {secondaryButton && (
                        <SecondaryButtonModal
                            sizeButton={sizeButton}
                            onClick={() => {
                                // setVisibility();
                                secondaryButton();
                                allowScroll();
                            }}
                        >
                            {secondaryButtonText}
                        </SecondaryButtonModal>
                    )}
                </ContentButton>
            </ModalBox>
        </ModalContainer>
    );
};
