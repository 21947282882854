import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';

export const BoxElement = styled.div`
    margin: 0 auto;
    display: flex;
    width: 98%;
    min-width: 90px;
    max-width: 90px;
    height: 117px;
    background: ${(props) => props.theme.colors.accents.base.yellow};
    border: 1px solid ${(props) => props.theme.colors.accents.base.yellow};
    border-radius: 16px;
    justify-content: space-around;
    padding: 8px 8px 6px;
    transition: all 0.3s ease-in-out;
    position: relative;
    cursor: pointer;
    flex-direction: column;

    &.elementActive {
        background: ${(props) => props.theme.colors.principal.greenBase};
        border: 1px solid ${(props) => props.theme.colors.principal.greenBase};
        box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.40) inset;
    }
`;

export const BoxElementImg = styled.div`
    display: flex;
    width: 100%;
    margin: 0 auto;
    height: 77%;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    img {
        object-fit: cover;
        width: 100%;
        height: 90%;
        object-position: center;
    }
`;

export const BoxElementTitle = styled.h3`
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-family: ${(props) => props.theme.fonts.lf_bold};
    color: #000000;
    font-size: 12px;
    line-height: 12px;
    text-transform: capitalize;
    height: 23%;
    width: 100%;
    margin-top: 6px;
`;