import { hexToRGB } from '@utils/hexToRgba';
import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

interface IProps {
    open: boolean;
    desktop?: boolean;
}
interface ILogoProps {
    logoOki: boolean;
}

interface IDropSec2 {
    scroll: boolean;
    open: boolean;
}

export const ContainerGeneral = styled.div<IProps>`
    width: 100%;
    min-width: ${(props) => (props.open ? '100vw' : '')};
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    min-height: 100vh;
    transform: ${(props: IProps) => (props.open ? 'translateX(0)' : 'translateX(-100%)')};
    transition: 0.3s all ease-in-out;

    ${breakpoint('md')`
        min-height: 0;
        min-width: 0;
        background: none;
        min-width: 0;
    `}
`;

export const StyledMenu = styled.nav<IProps>`
    display: flex;
    flex-direction: column;
    background: #272231;
    height: 100%;
    min-height: 100vh;
    width: 256px;
    text-align: left;
    padding: 20px 23px;
    position: absolute;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    transform: ${(props: IProps) => (props.open ? 'translateX(0)' : 'translateX(-100%)')};
    transition: 0.3s all ease-in-out;

    ${breakpoint('md')`
          flex-direction: row;
          background: none;
          min-height: 0;
          width: 100%;
          transform: none;
          transition: none;
          
    `}
`;

export const ContainerLogo = styled.div`
    margin: 0 auto;
    img {
        width: 70px;
        height: 26px;
    }

    ${breakpoint('md')`
        display: none;
    `}
`;
export const StyledSubMenu = styled.ul<IProps>`
    display: ${(props: IProps) => (props.open ? 'flex' : 'none')};
    flex-direction: column;
    background: ${(props) => props.theme.colors.backgrounds.gray450};
    width: 200px;
    padding-top: 12px;

    a {
        justify-content: flex-start;
        padding-left: 22px;
        font-size: 14px;
        font-weight: 16.8px;
        font-family: ${(props) => props.theme.fonts.lf_regular};
    }

    ${breakpoint('md')`
        padding-top: 44px;
        max-width: 128px;
        border: none;
        text-align: left;
        height: auto;
        
        border-radius: 6px;
        position: fixed;
        top: 48px;
        padding-left: 10px;
        padding-right: 10px;

        border: 1px solid ${(props) => props.theme.colors.principal.greenNeon};

        li:first-child{
            border-top: 0.5px solid #3e3a47bf;
        }

        li:last-child{
            border: none;
        }

        li{
            border-left: none;
        }

        a{
            margin: 1rem 0;
            padding-left:10px;
        }
        
        `}

    ${breakpoint('lg')`
        max-width: 136px;
    `}

    ${breakpoint('xl')`
        max-width: 172px;
    `}
`;

export const ContentMenu = styled.ul<IDropSec2>`
    width: 106%;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 200px;
    margin-top: 4px;
    height: ${(props: IDropSec2) => (props.open ? '155px' : '240px')};

    &::-webkit-scrollbar-track {
        background-color: ${(props) => props.theme.colors.backgrounds.gray400};
        border-radius: 4px;
    }

    &::-webkit-scrollbar {
        border-radius: 4px;
        margin-top: 10px;
        width: 2px;
        background-color: ${(props) => props.theme.colors.disabled.gray};
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.colors.disabled.gray};
    }
    ${breakpoint('md')`
        overflow: hidden;
        display: inline-flex;
        position: relative;
        top: 40px;
        align-items: baseline;
        padding-left:40px;
        height: 40px;
        
        li{
            max-width: 128px;
        }

        a{
            font-size: 14px;
            font-weight: 22px;
        }
    `}

    ${breakpoint('lg')`
        li{
            max-width: 172px;
        }
    `}
`;

export const ContentItem = styled.li`
    width: 100%;
    padding: 16px;

    ${breakpoint('md')`
        padding: 0;
        border-left: 1px solid #3e3a47;
        cursor: pointer;
        max-width: 128px;

        a{
            position: relative;
            z-index: 1;

           
        }

        &:last-child{
            border-right: 1px solid #3e3a47;
            
        }
    `}
`;

export const ItemMenu = styled.a`
    text-decoration: none;
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 14px;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.text.gray};

    button{
        padding-left: 16px;
    }

    &.active {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 14px;
        color: ${(props) => props.theme.colors.text.gray};
        font-family: ${(props) => props.theme.fonts.lf_bold};
        position: relative;

        ${breakpoint('md')`
            font-family: ${(props) => props.theme.fonts.lf_regular};
            align-items: baseline;
        `}
    }

    &.active:before {
        content: '';
        width: 5px;
        height: 5px;
        background: ${(props) => props.theme.colors.accents.neon.blue};
        margin-left: -10px;
        margin-right: 7px;
        border-radius: 50%;

        ${breakpoint('md')`
            background: none;
            margin: 0;
        `}
    }

    ${breakpoint('md')`
        justify-content: center;
        height: 100%;
        &:hover{
            color: ${(props) => props.theme.colors.principal.greenNeon};
        }
    `}
`;

export const ItemMenuBold = styled.span<IProps>`
    text-decoration: none;
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #f8f8f8;
    padding: ${(props) => (props.open ? '8.5px 0 8.5px 35px' : '0 0 0 6px')};
    font-family: ${(props) => props.theme.fonts.lf_bold};
`;
export const ContentSectionTwo = styled.ul`
    width: 100%;
    border-top: 1px solid #5e5e5e;

    padding: 10px 0px;

    &::-webkit-scrollbar-track {
        background-color: ${(props) => props.theme.colors.disabled.gray};
        border-radius: 4px;
    }

    &::-webkit-scrollbar {
        border-radius: 4px;
        width: 2px;
        background-color: ${(props) => props.theme.colors.disabled.gray};
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.colors.backgrounds.gray500};
    }

    ${breakpoint('md')`
        display: none;
        border: none;
        padding: 0px;
        top: 20px;
        position: absolute;
        
        
        li {
            border:none;
            max-width: 150px;
            
        }
        
        a {
            font-size: 10px;
            left: 40px;
            line-height: 12px;
            max-width: 500px;
        }

        span{
            display: none;
        }

        
    `}

    ${breakpoint('xl')`
        
    `}
`;
export const ContentLogos = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-self: center;
    margin-top: 25px;
    width: -webkit-fill-available;

    ${breakpoint('md')`
        width: 0;
        align-self: flex-start;
        position: absolute;
        left: 10rem;
        justify-content: center;
        top: -14px;
        padding-left: 10px;
        cursor: pointer;
        
    `}
`;

export const ContainerLineLogo = styled.div`
    position: relative;
`;

export const LogosImg = styled.img<ILogoProps>`
    width: ${(props) => (props.logoOki ? '35px' : '45px')};
    height: 23px;

    ${breakpoint('md')`
        margin-left: 24px;
    `}
`;

export const LineLogo = styled.div`
    border: 0.5px solid #5e5e5e;
    position: absolute;
    right: -25px;
    top: 0px;
    height: 22px;
    ${breakpoint('md')`
        border: none;
    `};
`;
