import { graphql, useStaticQuery } from 'gatsby';
import { CfConfiguracionJuego, Imagen } from './types';


export const cfImagenesService = {
    getImagenesIncentivos: (): Imagen[] => {
        const {
            allContentfulImagenes
        } = useStaticQuery<CfConfiguracionJuego>(graphql`
            query QueryImagenesIncentivos {
                allContentfulImagenes(filter: {type: {eq: "ImagenesIncentivos"}}) {
                    nodes {
                    type
                    images {
                        title
                        description
                        file {
                        url
                        fileName
                        contentType
                        }
                    }
                    }
                }
            }
        `);
        return allContentfulImagenes.nodes;
    }
};