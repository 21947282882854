import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const ContainerStepOne = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    
`;

export const ContainerSectionOne = styled.div`
    display: flex;
    flex-direction: column-reverse;

    img {
        width: 250px;
        margin: auto;
    }

    ${breakpoint('lg')({
        display: 'flex',
        flexDirection: 'row-reverse',
        margin: '30px auto 0 auto'
    })}

    img {
        ${breakpoint('lg')({
            width: '328px',
            paddingTop: '20px'
        })}
    }
`;

export const ContainerTextOne = styled.div`
    text-align: center;
    margin-top: 32px;
    p {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 14px;
        line-height: 16.8px;
        color: #f8f8f8;
        font-weight: normal;

        ${breakpoint('lg')({
            paddingBottom: '16px',
            fontSize: '16px',
            lineHeight: '19.2px'
        })}
    }

    h3 {
        font-family: ${(props) => props.theme.fonts.mon_bold};
        line-height: 19.2px;
        margin-bottom: 14px;

        ${breakpoint('lg')({
            paddingBottom: '16px',
            fontSize: '20px',
            lineHeight: '24px'
        })}
    }
    b {
        color: ${(props) => props.theme.colors.accents.base.yellow};
    }

    button {
        margin: 30px auto 20px;
        height: 46px;

        ${breakpoint('lg')({
            margin: '0',
            height: 'auto',
        })}
    }

    /*     ${breakpoint('lg')({
        width: '300px',
        display: 'flex',
        flexDirection: 'column'
    })}
 */
    p {
        ${breakpoint('lg')({
            width: '330px',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '22px'
        })}
    }
    ${breakpoint('lg')({
        marginRight: '100px',
        paddingTop: '50px',
        textAlign: 'left',
        marginTop: '0'
    })}
`;

export const ContainerSectionTwo = styled.div`
    display: flex;
    flex-direction: column-reverse;

    img {
        height: 273px;
        margin: auto;
    }

    ${breakpoint('lg')({
        display: 'flex',
        flexDirection: 'row',
        margin: '30px auto 0 auto'
    })}

    img {
        ${breakpoint('lg')({
            height: 'auto',
            width: 'auto',
            marginRight: '50px',
            position: 'relative',
            top: '-96px',
            marginLeft: '20px'
        })}
    }
`;

export const ContainerTextTwo = styled.div`
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 275px;
    margin: auto;

    p {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 14px;
        line-height: 16.8px;
        color: #f8f8f8;
        font-weight: normal;

        b {
            color: #fff;
        }

        ${breakpoint('lg')({
            paddingBottom: '16px',
            fontSize: '16px',
            lineHeight: '19.2px'
        })}
    }

    h3 {
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-size: 16px;
        line-height: 19.2px;
        margin-bottom: 14px;

        ${breakpoint('lg')({
            fontSize: '20px',
            lineHeight: '24px',
            paddingBottom: '16px',
            marginBottom: '0'
        })}
    }

    b {
        color: ${(props) => props.theme.colors.accents.base.yellow};
    }

    /*     ${breakpoint('lg')({
        width: '300px',
        display: 'flex',
        flexDirection: 'column'
    })}
 */
    p {
        ${breakpoint('lg')({
            width: '330px',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '22px'
        })}
    }
    ${breakpoint('lg')({
        margin: '0',
        marginRight: '100px',
        paddingTop: '100px',
        paddingLeft: '60px',
        textAlign: 'left',
        paddingBottom: '0',
        width: 'auto'
    })}
`;
