import React from 'react';
import { Redirect } from '../../atoms/redirect';
import { ContainerBreadCrumbs, Crumb } from './styled';

type TProps = {
    crumbs: { pathname: string; crumbLabel: string }[];
};

export const Breadcrumbs = (props: TProps) => {
    const { crumbs } = props;
    return (
        <ContainerBreadCrumbs>
            {crumbs.map((crumb, key) => {
                return (
                    <Crumb key={key}>
                        <Redirect url={crumb.pathname}>{crumb.crumbLabel}</Redirect>
                    </Crumb>
                );
            })}
        </ContainerBreadCrumbs>
    );
};
