import { gql } from '@apollo/client';
import client from '../../config/apollo-client-gana';

export type Confisys = {
    nombre: string,
    valorStr: string
}

export const queriesSystem = {
    getConfisys: gql`
        query ($confisys: [String]) {
            getConfisys(confisys: $confisys) {
                nombre
                valorStr
            }
        }

    `
};

export const systemServices = {
    getConfisys: (confisys: string[]) => {
        return client.query<{ getConfisys: Confisys[] }>({
            query: queriesSystem.getConfisys,
            variables: {
                confisys: confisys
            }
        });
    },
};
