import { IBets } from 'src/shared/components/molecules/cartBodyDetail/types';
import { editSuperChance } from './editSuperChance';
import { editBono } from './editBono';
import { editBilleton, updateLotteryBilleton } from './editBilleton';
import { editBillonario } from './editBillonario';
import { BC_SIGLA_3_CIFRAS, BC_SIGLA_4_CIFRAS } from '@constants/bonoConstants';
import { BILLONARIO_SIGLA_3C, BILLONARIO_SIGLA_4C } from '@constants/billonarioConstants';
import { SUERTUDO_SIGLA } from '@constants/suertudoConstants';
import { editSuertudo } from './editSuertudo';

export const editBet = (sigla: string, bet: IBets, idCart: number) => {
    switch (sigla) {
        case 'CHA':
        case 'SEM':
            return editSuperChance(bet, idCart);
        case BC_SIGLA_3_CIFRAS:
        case BC_SIGLA_4_CIFRAS:
            return editBono(bet);

        case 'M3C':
        case 'REB':
            return editBilleton(bet);

        case BILLONARIO_SIGLA_3C:
        case BILLONARIO_SIGLA_4C:
            return editBillonario(bet);
        case SUERTUDO_SIGLA:
            return editSuertudo(bet);
        default:
            break;
    }
};