import { hexToRGB } from '@utils/hexToRgba';
import theme from 'src/shared/styles/theme';
import { breakpoint, fonts } from 'src/shared/styles/variables';
import styled from 'styled-components';

interface IProps {
    visibile: boolean;
}
interface IPropsd {
    dimensions?: {
        height?: { mobile?: string; desktop?: string };
        width?: { mobile?: string; desktop?: string };
    };
    paddingBox?: { mobile?: string; desktop?: string };
}
interface IPropsb {
    sizeButton?: string;
    disabled?: boolean;
}
interface IPropsBold {
    greenBold?: boolean;
    size?: { mobile?: string; desktop?: string };
}
interface IPropsOptionalText {
    bold?: boolean;
}

interface IProsTitle {
    libreF?: boolean;
    hiddenTitle?: boolean;
}

export const ModalContainer = styled.div<IProps>`
    position: fixed;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1001;
    background: ${(props) => hexToRGB(props.theme.colors.backgrounds.gray450, 0.7)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${(props) =>
        props.visibile &&
        `
            visibility: visible;
            opacity: 1;
        `}
`;
export const ModalBox = styled.div<IPropsd>`
    background: ${(props) => props.theme.colors.text.white};
    width: ${(props) =>
        props.dimensions?.width?.mobile ? props.dimensions.width.mobile : '290px'};
    height: ${(props) =>
        props.dimensions?.height?.mobile ? props.dimensions.height.mobile : '450px'};
    left: 479px;
    top: 162px;
    border-radius: 16px;
    padding: ${(props) => (props.paddingBox?.mobile ? props.paddingBox.mobile : ' 35px 33px 35px')};

    ${breakpoint('md')<IPropsd>`
        height: ${(props) =>
            props.dimensions?.height?.desktop ? props.dimensions.height.desktop : '395px'};
        width: ${(props) =>
            props.dimensions?.width?.desktop ? props.dimensions.width.desktop : '408px'};
        padding: ${(props) => (props.paddingBox?.desktop ? props.paddingBox.desktop : ' 38px 42px 47px')};
    `}
`;
export const ButtonClose = styled.div`
    position: absolute;
    top: -18px;
    right: -38px;
    z-index: 5;
    button span {
        color: ${(props) => props.theme.colors.backgrounds.gray400};
        font-size: 24px;
    }
`;
export const ContentLogo = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 33px;

    img {
        max-width: 168px;
        margin: 0 auto;
    }

    &.tycText {
        padding: 0;
    }
`;

export const LogoContainer = styled.div`
    position: relative;
    width: 100%;
`;

export const IconMessage = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 22px;
    margin-top: 23px;
`;
export const Title = styled.p<IProsTitle>`
    margin-top: ${(props) => (props.hiddenTitle ? '10px' : ' 20px')};
    color: ${(props) => props.theme.colors.backgrounds.gray500};
    font-family: ${(props) => (props.libreF ? fonts.lf_bold : fonts.mon_bold)};
    font-size: ${(props) => (props.libreF ? '14px' : ' 20px')};
    margin-bottom: ${(props) => (props.hiddenTitle ? '0px' : ' 20px')};
    display: flex;
    justify-content: center;
    text-align: center;

    &.titleTyCText {
        font-family: ${(props) => fonts.mon_bold};
        font-size: 18px;
    }

    ${breakpoint('md')`
        font-size: 28px;

        &.titleTyCText {
            font-size: 28px;
        }
    `}
`;
export const ContentText = styled.div`
    margin-bottom: 27px;
    padding: 0px 1px 0px;

    &.contentTyCText {
        overflow-y: auto;
        height: 270px;
        margin-right: -10px;
        padding-right: 12px;

        p {
            line-height: 16.8px;
            text-align: left;
        }

        ::-webkit-scrollbar {
            -webkit-appearance: none;
            margin-right: 50px !important;
        }
        ::-webkit-scrollbar-thumb {
            background: #c5c3c9;
        }
        ::-webkit-scrollbar:vertical {
            width: 2px;
        }
        ::-webkit-scrollbar-track {
            background: #1d1828;
        }
    }

    ${breakpoint('md')`
        margin-bottom: 31px;
        padding: 0px 20px 0px;

        &.contentTyCText {
            height: auto;
            overflow: visible;
            margin-right: 0;
            padding-right: 20px;

            p {
                line-height: 19.2px;
                text-align: center;
                font-size: 16px;
            }
        }
    `}
`;
export const Text = styled.p<IPropsBold>`
    color: ${(props) =>
        props.greenBold ? theme.colors.principal.darkGreen : theme.colors.backgrounds.gray500};
    font-family: ${(props) => (props.greenBold ? fonts.mon_bold : fonts.lf_regular)};
    font-size: ${(props) => (props.size?.mobile ? props.size.mobile : ' 14px')};
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    padding: 0px 0px 8px;
    text-align: center;
    line-height: 22px;
    ${breakpoint('md')<IPropsBold>`
        margin-bottom: 5px;
        font-size:${(props) => (props.size?.desktop ? props.size.desktop : ' 14px')};
    `}

    &.wordBreak {
        word-break: break-all;
    }
`;
export const BoldText = styled.p<IPropsOptionalText>`
    color: ${(props) => props.theme.colors.backgrounds.gray500};
    font-family: ${(props) => (props.bold ? fonts.lf_regular : fonts.lf_bold)};
    font-size: 14px;
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 22px;

    ${breakpoint('md')`
        font-size: 16px;
    `}
`;
export const ContentButton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${breakpoint('md')`
        justify-content: space-evenly;
        flex-direction: row-reverse;
    `}
`;
export const PrimaryButtonModal = styled.button<IPropsb>`
    border-radius: 12px;
    background: linear-gradient(258.32deg, #0df977 17.23%, #009f5c 100%);
    color: #f8f8f8;
    font-size: 14px;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    border: none;
    width: ${(props) => (props.sizeButton ? props.sizeButton : '125px')};
    height: 46px;
    padding: 2px;
    cursor: pointer;
    ${breakpoint('md')`
        width: 141px;
        height: 32px;
        margin-top: 0px;
    `}

    &:disabled,
    &[disabled] {
        background: linear-gradient(258.32deg, ${(props) => props.theme.colors.backgrounds.gray400} 17.23%, ${(props) => props.theme.colors.backgrounds.gray600} 100%);
    }
`;
export const SecondaryButtonModal = styled.button<IPropsb>`
    border-radius: 12px;
    background-color: #ffffff;
    color: #009f5c;
    font-size: 14px;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    border: none;
    width: ${(props) => (props.sizeButton ? props.sizeButton : '125px')};
    height: 46px;
    padding: 2px;
    margin-top: 13px;
    cursor: pointer;
    ${breakpoint('md')`
        width: 141px;
        height: 32px;
        margin-top: 0px;
    `}
`;

export const AditionalHyperLink = styled.a`
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-family: ${(props) => props.theme.fonts.lf_regular};
    color: #009f5c;
    text-decoration: underline;
    text-align: center;
    padding: 10px 0;
`;

export const ShowContainer = styled.div``;

export const ShowButton = styled.p`
    font-family: ${(props) => props.theme.fonts.lf_regular};
    color: #009f5c;
    text-align: center;
    padding: 32px 0 10px 0;
    cursor: pointer;
    text-decoration: underline;
`;

export const ShowText = styled.p`
    height: auto;
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
    text-align: center;
    color: #000;

    &.hide {
        display: none;
    }
`;

export const RichText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    p {
        color: #000;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 14px;
        line-height: 22.4px;
        padding-bottom: 10px;

        b {
            color: ${(props) => props.theme.colors.principal.darkGreen};
            font-family: ${(props) => props.theme.fonts.lf_extrabold};
        }

        :nth-child(2n) {
            padding-top: 8px;
        }
    }

    h3 {
        color: #000;
        font-family: ${(props) => props.theme.fonts.mon_extrabold};
        font-size: 16px;
        line-height: 19.2px;
        padding-top: 10px;
    }
`;

export const RawTycText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    p {
        color: #000;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 14px;
        line-height: 22.4px;

        b {
            color: ${(props) => props.theme.colors.principal.darkGreen};
            font-family: ${(props) => props.theme.fonts.lf_extrabold};
        }

        a {
            color: ${(props) => props.theme.colors.principal.darkGreen};
        }

        :nth-child(3n) {
            ${breakpoint('md')({
                paddingTop: '10px'
            })}
        }
    }

    h3 {
        color: #000;
        font-family: ${(props) => props.theme.fonts.mon_extrabold};
        font-size: 16px;
        line-height: 19.2px;
    }
`;

export const CustomContent =  styled.div`
    display: block;
`