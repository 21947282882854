import styled from "styled-components"
import { breakpoint } from 'src/shared/styles/variables';


export const TitleStepContainer = styled.div`
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    font-family: ${(props) => props.theme.fonts.lf_bold};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    padding: 0px 16px 23px;

    &.titleStepBillonario {
        padding-left: 0;
    }

    ${breakpoint('lg')`
        align-items: center;
    `}
    
    ${breakpoint('lg')`
        align-items: center;
    `}
`

export const NumberStep = styled.span`
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    color: ${(props) => props.theme.colors.accents.base.yellow};
    font-size: 16px;
    line-height: 24px;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    border: 2px solid ${(props) => props.theme.colors.accents.base.yellow};
    margin-right: 11px;
`