import styled from "styled-components";
import { breakpoint } from 'src/shared/styles/variables';
import { hexToRGB } from "@utils/hexToRgba";
import { IPropsIncentivo } from "./types";

export const IncentiveBetContainer = styled.div<IPropsIncentivo>`
    border: 1px solid ${(props) => props.theme.colors.accents.base.yellow};
    box-shadow: 0px 0px 20px ${(props) => hexToRGB(props.theme.colors.accents.base.yellow, 0.40)};
    position: relative;
    border-radius: 1rem;
    display: flex;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 641px;

    .incentive--icono {
        display: ${(props) => (props.small?'none':'inherit')};
        height: 57px;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(37%,-50%);
        ${breakpoint("s") <IPropsIncentivo>`
            display: inherit;
        `}
    }
`;

export const IncentiveBetImagen = styled.div<IPropsIncentivo>`
    display: none;
    position: relative;
    transform: translate(1rem, -1.7rem);
    margin-right: 1rem;
    width: 30.1%;
    flex-shrink: 0;
    flex-grow: 0;

    .incentive--imagen {
        border-radius: 1rem;
        background-image: url(${props => (props.bgImage)});
        background-repeat: no-repeat;
        background-size: cover;
        padding-left: 100%;
        padding-top: 62%;

        img {
            display: none;
        }
    }

    &::before {
        border-radius: 1rem 1rem 0 0;
        content: "";
        box-shadow: 0px 0px 20px ${(props) => hexToRGB(props.theme.colors.accents.base.yellow, 0.40)};
        height: 1rem;
        width: 100%;
        position: absolute;
        z-index: -1;
    }

    ${breakpoint("s") <IPropsIncentivo>`
        display: ${(props) => (!props.small ? 'table' : 'none')};
    `} 
`;

export const IncentiveBetMsg = styled.p<IPropsIncentivo>`
    font-family: ${(props) => props.theme.fonts.mon_regular};
    width: 100%;
    color: #FFF;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding: 1rem;
    text-align: center;
    line-height: 18px;

    span {
        color: ${(props) => (props.theme.colors.accents.base.yellow)};
    }

    ${breakpoint("s") <IPropsIncentivo>`
        ${(props) => (!props.small ? 'font-size: 20px;' : '')}
        padding: 2.1rem;
    `} 

    ${breakpoint("sm") <IPropsIncentivo>`
        text-align: ${(props) => (!props.small ? 'left' : 'center')};
        font-size: ${(props) => (!props.small ? '26px' : '14px')};
        padding: ${(props) => (!props.small ? '3.5%;' : '1rem')};
        line-height: ${(props) => (!props.small ? '32px' : '18px')};
    `}

    ${breakpoint("md") <IPropsIncentivo>`
        font-size: ${(props) => (!props.small ? '24px' : '14px')};
    `}

    ${breakpoint("xl") <IPropsIncentivo>`
        font-size: ${(props) => (!props.small ? '24px' : '14px')};
    `}
`;