import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

export const Numbers = styled.form`
    width: 100%;
    display: flex;
    height: 50px;
    overflow: hidden;
    justify-content: center;
    /* margin-bottom: 34px; */
`;

export const ButtonRandom = styled.button`
    width: 45px;
    height: 45px;
    background: ${(props) => props.theme.colors.text.white};
    border-radius: 50%;
    outline: none;
    border: none;
    margin: 2px;
    text-align: center;
    font-size: 28px;
    font-family: ${(props) => props.theme.fonts.icons};
    border: 2px solid ${(props) => props.theme.colors.principal.darkGreen};
    cursor: pointer;

    span{
        color: ${(props) => props.theme.colors.principal.darkGreen};
    }

    &:disabled {
        background: ${(props) => props.theme.colors.disabled.gray};
    }

    &:hover {
        border-color: ${(props) => props.theme.colors.principal.greenNeon};
        span{
            color: ${(props) => props.theme.colors.principal.greenNeon};
        }
    }

    &:active {
        border-color: ${(props) => props.theme.colors.principal.greenBase};

        span{
            color: ${(props) => props.theme.colors.principal.greenBase};
        }
    }


    ${breakpoint('md')`
        margin: 4px;
    `}
`

export const ErrorContainer = styled.span`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;

    &.buttonRandomLess{
        margin: 0px 0px 10px 0px;
        color: ${(props) => props.theme.colors.accents.neon.orange}
    }
    
    span {
        color: ${(props) => props.theme.colors.alerts.yellow};
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        max-width: 450px;
        word-wrap: break-word;
        text-align: center;

        strong {
            font-family: ${(props) => props.theme.fonts.lf_bold};
            color: ${(props) => props.theme.colors.alerts.yellow};
        }
    }
`;

export const ButtonRandomBlock = styled.div`
    margin-top: 24px;
`;