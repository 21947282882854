import { ButtonIcon } from '@componentsShared';
import React, { useRef } from 'react';
import Viewport from 'src/shared/hooks/viewport';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BoxContent, ContainerCard, HeaderCard, Title } from './styled';
import { colorText } from '@utils/colorText';

type IProps = {
    title: string;
    content: string;
    collapsed: boolean;
    setId: (id: number) => void;
    id: number;
    collapseCard: (id: number) => void;
    scrollableContent?: boolean;
    legal?: boolean;
};

export const DropDownCard = (props: IProps) => {
    const { title, content, collapsed, setId, id, collapseCard, scrollableContent, legal } = props;

    const viewport = Viewport();
    
    const refContent = useRef<any>(null);

    let contentScroll = false;

    if (scrollableContent && refContent?.current?.innerText.length > 450) {
        contentScroll = true;
    } else {
        contentScroll = false;
    }

    return (
        <ContainerCard
            className={collapsed ? '' : 'open'}
            heightAuto={title.length > 80}
            legal={legal}
        >
            <HeaderCard>
                <Title dangerouslySetInnerHTML={{ __html: colorText(title) }} />
                <ButtonIcon
                    icon={`angle-${collapsed ? 'down' : 'up'}`}
                    colorDefault={'#0DF96C'}
                    title={collapsed ? 'Desplegar' : 'Plegar'}
                    size="small"
                    disabled={false}
                    onClick={() => {
                        setId(id);
                        collapseCard(id);
                    }}
                />
            </HeaderCard>
            {!viewport.mobile && <hr />}
            <BoxContent className={contentScroll ? 'content-scrollable' : ''} ref={refContent}>
                {documentToReactComponents(JSON.parse(content))}
            </BoxContent>
        </ContainerCard>
    );
};
