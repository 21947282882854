import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { IInput } from './types';

export const Container = styled.div`
    width: 100%;
    height: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

export const InputCustomLabel = styled.label`
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
    color: ${(props) => props.theme.colors.text.gray};
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
        font-size: 20px;
        color: ${(props) => props.theme.colors.principal.greenBase};
    }
`;

export const TooltipCustom = styled(ReactTooltip)`
    border-radius: 16px !important;
    box-shadow: 0 0 9px #333;
    max-width: 260px;
    pointer-events: auto !important;

    & > .styled__ContentTooltip-bTolay {
        width: 90%;
    }
`;
interface IIcon {
    position?: string;
    disabled?: boolean;
    color?: string;
}

export const Icon = styled.button<IIcon>`
    font-size: 20px;
    color: ${(props) => props.theme.colors.disabled.gray};
    position: absolute;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    ${(props) => {
        if (props.position === 'left') {
            return `left: 16px;`;
        } else {
            return `right: 16px;`;
        }
    }}
`;

export const SecondaryIcon = styled.span<IIcon>`
    font-size: 20px;
    color: ${(props) => (props.color ? props.color : props.theme.colors.disabled.gray)};
    position: absolute;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    ${(props) => {
        if (props.position === 'left') {
            return `left: 56px;`;
        } else {
            return `right: 56px;`;
        }
    }}
`;

export const InputCustomStyle = styled.input<IInput>`
    padding-left: 16px;
    padding-right: 16px;
    ${(props) => {
        if (props.position === 'left') {
            return `padding-left: 50px;`;
        } else {
            return `padding-right: 50px;`;
        }
    }}
    ${(props) => props.position === 'left' && `text-align: right;`}
    width: 100%;
    height: 56px;
    position: relative;
    background: ${(props) => props.theme.colors.backgrounds.gray400};
    font-size: 16px;
    line-height: 24px;
    font-family: ${(props) => props.theme.fonts.lf_regular};
    border-radius: 16px;
    outline: none;
    border: none;
    -moz-appearance: textfield;
    /* Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:focus {
        border: 1px solid ${(props) => props.theme.colors.principal.greenNeon};
    }

    &:focus + ${Icon} {
        color: ${(props) => props.theme.colors.principal.greenNeon};
    }

    &.error {
        border: 1px solid
            ${(props) =>
                props.typeError === 'alert'
                    ? props.theme.colors.accents.neon.orange
                    : props.theme.colors.alerts.red};
    }

    &.error + ${Icon} {
        color: ${(props) =>
            props.typeError === 'alert'
                ? props.theme.colors.accents.neon.orange
                : props.theme.colors.alerts.red};
    }
`;

export const InputCustomContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
`;
