import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const ContainerRewardPlanGame = styled.div`
    width: 100%;
    /* height: 100%; */
    /* height: 360px; */
    /* padding: 20px 0px;
    margin: 10px; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${breakpoint('md')`
        padding: 0px;
        max-height: 870px;
    `}

    .slick-slider {
        width: 100%;
        height: 340px;
        padding-top: 10px;

        .slick-list {
            width: 100%;
            margin: 0 auto;

            .slick-slide {
                margin-left: 0px;
            }
        }
    }
    .slick-dots {
        text-align: center;
        width: auto;
        left: 0;
        right: 0;
        margin: 0 auto;
        li {
            width: 15px;
            height: 15px;
            &.slick-active {
                button {
                    &:before {
                        font-size: 12px;
                        line-height: 14px;
                        width: 11px;
                        height: 11px;
                        color: transparent;
                        box-shadow: 0px 0px 6px 1.5px ${(props) => props.theme.colors.alerts.yellow};
                        border: 2px solid ${(props) => props.theme.colors.alerts.yellow};
                        top: -2px;
                    }
                }
            }
            button {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                &:before {
                    font-size: 10px;
                    line-height: 12px;
                    color: transparent;
                    border: 2px solid ${(props) => props.theme.colors.disabled.gray};
                    border-radius: 50%;
                    width: 7px;
                    height: 7px;
                    opacity: 1;
                }
            }
        }
    }
`;

export const ContentAwards = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 3px;
`;

export const ContainerModalidad = styled.div`
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ContentModalidad = styled.div`
    width: 100%;
    height: 154px;
    max-width: 659px;
    background: ${(props) => props.theme.colors.backgrounds.gray400};
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    border-radius: 16px;

    & > h3 {
        position: absolute;
        left: -30px;
        transform: rotate(-90deg);
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        & > span {
            color: #ffe000;
        }
    }
`;

export const TextAuxiliar = styled.div`
    width: 100%;
    margin-top: 37px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    & > p {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;

        & > b {
            font-weight: bold;
        }
    }
`;
