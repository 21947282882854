import React from 'react';
import { ModalGana } from '@componentsShared';
import { graphql, useStaticQuery } from 'gatsby';
interface IProps {
    modalState: boolean;
    setModalState: () => void;
    title?: string;
    text?: string;
}

export const ModalNotRegistered = (props: IProps) => {
    const { modalState, setModalState, title, text } = props;
    const dataModalNotRegistered = useStaticQuery(graphql`
        query NotRegisteredQuery {
            allContentfulModal(filter: { name: { eq: "No estoy registrado" } }) {
                edges {
                    node {
                        icon {
                            file {
                                url
                            }
                        }
                        title
                        text
                        AditionalText
                    }
                }
            }
        }
    `);
     const {
        allContentfulModal: { edges }
    } = dataModalNotRegistered;
    const { node } = edges[0];
 
    const optionsModal = {
        logo: 'terciary',
        icon: node.icon.file.url,
        title: title || node.title,
        text: text || node.text,
        primaryButtonText: 'Quiero registrarme',
        primaryButton: () => {
            window.location.href = '/registro/datos-personales';
            setModalState();
        },
        dimensions: {height:{mobile:"397px",desktop:"442px"}, width:{ desktop:"435px"}},

        sizeButton: "208px"


    };
    return (
     
        <ModalGana visibility={modalState} setVisibility={setModalState} options={optionsModal} />
        
    );
};
