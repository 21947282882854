declare global {
    var grecaptcha: any;
};

export const getTokenReCaptcha = async () => {
    try {
        const token: string =  await window.grecaptcha.execute(getReCaptchaKey());
        return token;
    } catch (error) {
        console.log(error);
    }
    return '';
}

export const getReCaptchaKey = (): string => {
    return process.env.SITEKEY || '';
}