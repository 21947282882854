import styled from "styled-components";
import breakpoint from 'styled-components-breakpoint';

export const ContainerLotteriesSelected = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    ${breakpoint("md")`
        width: 50%;
    `}
`

export const ContentLotteriesDesktop = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
    ${breakpoint("md")`
        gap: 13px;
        width: 50%;
    `}

    &.superchance-confirmation {
        justify-content: start;

        div {
            margin: 0;
        }
    }
        
    &.superchance-stepTwo {
        gap: 5px;
        justify-content: center;

        div {
            margin: 0;
        }

        ${breakpoint("md")`
            gap: 40px;
        `}
    }
`;