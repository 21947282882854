import React, { useContext, useEffect, useState } from 'react';
import {
    Breadcrumbs,
    Button,
    ButtonGoBack,
    ButtonIcon,
    EmptyCart,
    Loading,
    LotteryBox,
    ModalGana,
    ModalLocation,
    ModalNotLocation,
    Toast
} from '@componentsShared';
import { DetailToPay, PageToLoading } from '@components';
import {
    CartTitle,
    ContentCart,
    TitleDate,
    WrapperCartDetail,
    ContentDetail,
    ContentDetailLogo,
    ContentDetailTop,
    ContentDetailTopTitle,
    ContentDetailTopIcons,
    ContentDetailBottom,
    ContentDetailBottomLeft,
    ContentDetailBottomRight,
    ContentDetailNumbers,
    ContentDetailNumbersBox,
    ContentDetailMediumLotteries,
    ContainerDetailNumbers,
    ContainerDetailLotteries,
    ContainerEmptyCart,
    WrapperRedirect,
    ContainerGoBack,
    ContentDetailIncentive,
    ContainerDetailEvent,
    ContentDetailElements
} from './styled';
import { carServices } from '@services/car';
import { converterNumber } from '@utils/decimalNumberConverter';
import { IBet, IBetCart, IProps } from './types';
import iconDelete from 'src/shared/assets/images/delete.svg';
import iconEdit from 'src/shared/assets/images/edit.svg';
import { AppContextUser } from 'src/shared/context/user';
import Viewport from 'src/shared/hooks/viewport';
import { isApolloError } from '@apollo/client';
import siteUrl from '@utils/siteUrl';
import { getData } from '@utils/transferData';
import moment from 'moment';
import 'moment/locale/es';
import { capitalizeWord } from '@utils/capitalizeWord';
import { graphql, useStaticQuery } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { actualizarTotalCarrito, addBeat } from '@utils/egoi';
import { IncentiveMessage } from 'src/components/atoms/incentiveMessage';
import { getCart } from '@utils/carrito';
import { editBet } from '@utils/EditCarts';
import { colorText } from '@utils/colorText';
import { ElementBox } from 'src/shared/components/molecules/elementBox';
import { UserLocation, hasLocation } from '@utils/location';

export const ContainerCartDetail = ({ page }: IProps) => {
    const [isShowMoreNumbers, setIsShowMoreNumbers] = useState(false);
    const [isShowMoreLotteries, setIsShowMoreLotteries] = useState(false);
    const [isEmpty, setIsEmpty] = useState(true);
    const [betsCart, setBetsCart] = useState<IBetCart>();
    const [idCartDelete, setIdCartDelete] = useState(0);
    const [betToEdit, setBetToEdit] = useState<IBet>({} as IBet);
    const [imgGame, setImgGame] = useState({
        file: {
            url: '',
            fileName: ''
        }
    });
    const [isLoadingCart, setIsLoadingCart] = useState(true);
    const [isPayment, setIsPayment] = useState(false);
    const [isDisablePaymentButton, setIsDisablePaymentButton] = useState(false);
    const viewport = Viewport();
    const [showModal, setShowModal] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const { dataUser } = useContext(AppContextUser);
    const [currentGame, setCurrentGame] = useState('');
    const [toastState, setToastState] = useState({
        state: false,
        text: ''
    });

    let toastTimeout: any = null;
    const showToast = (text: string) => {
        if (text && text.trim() !== '') {
            clearTimeout(toastTimeout);
            setToastState({ state: true, text: text });
            toastTimeout = setTimeout(() => {
                setToastState({ state: false, text: '' });
            }, 6000);
        }
    };
    const [modalState, setModalState] = useState(false);
    const [notLocatedState, setNotLocatedState] = useState(false);
    const [location, setLocation] = useState<UserLocation>({});

    const displayModalNotLocated = () => {
        setNotLocatedState(!notLocatedState);
    };

    const displayModalLocated = () => {
        setModalState(!modalState);
    };

    moment.locale('es');
    const displayModal = () => {
        setShowModal(!showModal);
    };

    const displayModalEdit = () => {
        setShowModalEdit(!showModalEdit);
    };

    const logoLotteriesCart = useStaticQuery(graphql`
        query LogosGameToCart {
            allContentfulConfiguracionJuego {
                edges {
                    node {
                        abbreviation
                        logo {
                            file {
                                url
                            }
                            title
                            description
                        }
                    }
                }
            }
        }
    `);

    const {
        allContentfulConfiguracionJuego: { edges: arrayLogosGame }
    } = logoLotteriesCart;

    const arrayLogoGame = arrayLogosGame;
    const filteredImgGame = (sigla: string) => {
        return arrayLogoGame?.find((itemBet: any) => itemBet.node.abbreviation === sigla);
    };

    const fetchDataCart = async () => {
        if (!dataUser.token) {
            return;
        }
        const success = (data: any) => {
            setBetsCart(data.data.getCart);
            if (data.data.getCart.bets.length > 0) {
                data.data.getCart.bets.forEach((element: IBet) => {
                    addBeat(element);
                });
                actualizarTotalCarrito(data.data.getCart.total);
                setIsEmpty(false);
            }
            setIsLoadingCart(false);
        };
        const error = (e: any) => {
            setIsLoadingCart(false);
            if (isApolloError(e)) {
                for (const gqlError of e.graphQLErrors) {
                    console.log(gqlError);
                }
            }
        };
        getCart(
            dataUser.token,
            dataUser.tipoDocumento || '',
            dataUser.usuario || '',
            success,
            error
        );
    };

    const identifyCurrentGame = (title: string) => {
        if (title.toLowerCase().includes('super')) {
            setCurrentGame('Súper Chance');
            return;
        }

        if (title.toLowerCase().includes('bille')) {
            setCurrentGame('Billetón');
            return;
        }

        if (title.toLowerCase().includes('billo')) {
            setCurrentGame('Billonario');
            return;
        }
    };

    const optionsModalEdit = {
        logo: 'Object',
        data: imgGame,
        icon: iconEdit,
        title: '¿Quieres modificar la apuesta?',
        text: 'Este movimiento no podrá deshacerse.',
        primaryButtonText: 'Confirmar',
        primaryButton: () => {
            editBet(betToEdit.sigla, betToEdit, betsCart?.id || 0);
            displayModalEdit();
        },
        secondaryButtonText: 'Cancelar',
        secondaryButton: () => {
            displayModalEdit();
        },
        dimensions: { height: { mobile: '429px', desktop: '410px' }, width: { desktop: '535px' } },
        titleFonts: true
    };

    const optionsModal = {
        logo: 'secundary',
        icon: iconDelete,
        title: `¿Quieres eliminar esta apuesta de ${currentGame} del carrito?`,
        text: 'Este movimiento no podrá deshacerse. ',
        primaryButtonText: 'Eliminar',
        primaryButton: () => {
            fetchDeleteCar();
            displayModal();
        },
        secondaryButtonText: 'Cancelar',
        secondaryButton: () => {
            displayModal();
        },
        dimensions: { height: { mobile: '429px', desktop: '410px' }, width: { desktop: '535px' } },
        titleFonts: true
    };

    const fetchDeleteCar = async () => {
        // TODO: data of cart from context
        /* const betToDelete: IBet = betsCart?.bets.find((element) => Number(element.id) === idCartDelete) ?? {} as IBet
        const newBetList: IBet[] = betsCart?.bets.filter((element) => Number(element.id) !== idCartDelete) ?? [];
        
        const newBetsCartList: IBetCart = {
            ...betsCart!,
            bets: newBetList,
            iva: betsCart?.iva! - betToDelete.iva ,
            total: betsCart?.total! - betToDelete.total
        }

        setIsLoadingCart(true); */
        await carServices
            .deleteCart(dataUser.token, dataUser.tipoDocumento, dataUser.usuario, [idCartDelete])
            .then((data) => {
                window.location.reload();
                /* fetchDataCart();
                setBetsCart(newBetsCartList)
                setIsLoadingCart(false); */
            })
            .catch((err) => {
                console.log('error', err);
            });
    };

    const updateLocation = (location: UserLocation) => {
        if (hasLocation(location)) {
            if (location.accuracy === -1 && location.latitude === -1 && location.longitude === -1) {
                showToast("Error al validar la ubicación");
                setLocation({});
            } else {
                setLocation(location);
            }
        } else {
            setLocation({});
        }
    };

    useEffect(() => {
        setIsLoadingCart(true);
        fetchDataCart();
    }, [dataUser]);

    useEffect(() => {
        let dataUserLocal = getData('userData');
        if (dataUserLocal !== null && !dataUserLocal.isLogged) {
            window.location.href = siteUrl + '/';
        }
    }, []);

    useEffect(() => {
        isPayment && scrollTo('#top');
    }, [isPayment]);

    useEffect(() => {
        let cantNotDisponible = 0;
        betsCart?.bets.forEach((element) => {
            element.loteries.forEach((lottery) => {
                if (lottery.disponible === false && lottery.disponible !== undefined) {
                    cantNotDisponible += 1;
                }
            });
        });

        cantNotDisponible > 0 && setIsDisablePaymentButton(true);
    }, [betsCart]);

    let cantToShow = viewport.tablet ? 5 : viewport.desktop ? 7 : 4;
    let now = moment();

    let crumbs = [
        {
            crumbLabel: 'inicio',
            pathname: '/'
        },
        {
            crumbLabel: 'Carrito',
            pathname: 'carrito/'
        }
    ];

    return (
        <>
            {isPayment ? (
                <WrapperRedirect id="PaymentLoading">
                    <PageToLoading
                        titulo="Danos un momento"
                        primaryText="Se abrirá una nueva página para que realices la transacción, "
                        strongText="¡tranquilo, es un sitio seguro!."
                        secundaryText="Te estamos redirigiendo al sitio de pago."
                    />
                </WrapperRedirect>
            ) : (
                <WrapperCartDetail>
                    <ContainerGoBack>
                        {!viewport.mobile && <Breadcrumbs crumbs={crumbs} />}
                        {viewport.mobile && <ButtonGoBack />}
                    </ContainerGoBack>
                    {isLoadingCart ? (
                        <Loading />
                    ) : (
                        isEmpty && (
                            <>
                                <ContainerEmptyCart>
                                    <CartTitle state={false}>
                                        <h1
                                            dangerouslySetInnerHTML={{
                                                __html: colorText(page.title)
                                            }}
                                        />
                                    </CartTitle>
                                    <EmptyCart />
                                </ContainerEmptyCart>
                            </>
                        )
                    )}

                    <ContentCart>
                        {!isEmpty && (
                            <>
                                <CartTitle state={true}>
                                    <h1
                                        dangerouslySetInnerHTML={{
                                            __html: colorText(page.title)
                                        }}
                                    />
                                </CartTitle>
                                <TitleDate>
                                    {capitalizeWord(now.format('dddd, D')) +
                                        ' de ' +
                                        now.format('MMMM')}
                                </TitleDate>

                                {betsCart?.bets.map((item, key) => {
                                    let stateBet = '';
                                    let cantNotDisponible = 0;
                                    let lotteryNotDisponible = '';
                                    item.loteries.forEach((element) => {
                                        if (
                                            element.disponible === false &&
                                            element.disponible !== undefined
                                        ) {
                                            cantNotDisponible += 1;
                                            lotteryNotDisponible += element.desLoteria + ', ';
                                        }
                                    });

                                    if (cantNotDisponible === item.loteries.length) {
                                        stateBet = 'danger';
                                    }

                                    if (
                                        cantNotDisponible > 0 &&
                                        item.loteries.length > cantNotDisponible
                                    ) {
                                        stateBet = 'alert';
                                    }

                                    const logoGame = filteredImgGame(item.sigla);
                                    const {
                                        node: { logo }
                                    } = logoGame;

                                    return (
                                        <ContentDetail key={key} stateBet={stateBet}>
                                            <ContentDetailLogo>
                                                <img src={logo.file.url} alt={logo.description} />
                                            </ContentDetailLogo>
                                            <ContentDetailTop>
                                                <ContentDetailTopTitle>
                                                    {item.nombre_colilla
                                                        ? item.nombre_colilla
                                                        : 'Apuesta ' + (item.numero_colilla ?? '1')}
                                                </ContentDetailTopTitle>
                                                <ContentDetailTopIcons>
                                                    {/* <span className="icon-edit"></span> */}
                                                    <ButtonIcon
                                                        title="Editar Apuesta"
                                                        icon="edit"
                                                        colorDefault="#C5C3C9"
                                                        onClick={() => {
                                                            setBetToEdit(item);
                                                            setImgGame(logo);
                                                            displayModalEdit();
                                                        }}
                                                    />
                                                    <ButtonIcon
                                                        icon="trash"
                                                        title="Eliminar"
                                                        onClick={() => {
                                                            identifyCurrentGame(logo.title);
                                                            displayModal();
                                                            setIdCartDelete(Number(item.id));
                                                        }}
                                                    ></ButtonIcon>
                                                </ContentDetailTopIcons>
                                            </ContentDetailTop>
                                            <ContainerDetailLotteries
                                                className={item.eventochance ? 'eventoChance' : ''}
                                            >
                                                {item.eventochance && (
                                                    <ContainerDetailEvent>
                                                        <p>Evento: </p>
                                                        <span>{item.eventochance?.desEvento}</span>
                                                    </ContainerDetailEvent>
                                                )}
                                                <ContentDetailMediumLotteries
                                                    isShowMore={isShowMoreLotteries}
                                                    cantToShow={cantToShow}
                                                >
                                                    {item.loteries.map((lottery, index) => {
                                                        let lotteryToSend = {
                                                            desLoteria: lottery.desLoteria,
                                                            ideLoteria: lottery.loterieId,
                                                            sorteo: lottery.sorteo,
                                                            abreviatura: lottery.sigla,
                                                            horaCierre: '',
                                                            numCifras: 4
                                                        };

                                                        let className = 'lotteryNoActive ';

                                                        if (lottery.disponible === false) {
                                                            className += ' lotteryAlert ';
                                                        }
                                                        if (
                                                            stateBet === 'alert' ||
                                                            stateBet === 'danger'
                                                        ) {
                                                            className += ' alert ';
                                                        }

                                                        return (
                                                            <LotteryBox
                                                                key={index}
                                                                dataLottery={lotteryToSend}
                                                                lotteryActive={false}
                                                                className={`${className} lotteryCart`}
                                                            />
                                                        );
                                                    })}
                                                </ContentDetailMediumLotteries>
                                                {item.loteries.length > cantToShow - 1 && (
                                                    <Button
                                                        typeButton="underlined withe"
                                                        text={
                                                            !isShowMoreLotteries
                                                                ? 'Ver más'
                                                                : 'Ver menos'
                                                        }
                                                        icon={
                                                            isShowMoreLotteries
                                                                ? 'angle-up'
                                                                : 'angle-down'
                                                        }
                                                        onClick={() => {
                                                            setIsShowMoreLotteries(
                                                                !isShowMoreLotteries
                                                            );
                                                        }}
                                                    ></Button>
                                                )}
                                            </ContainerDetailLotteries>
                                            <ContainerDetailNumbers
                                                className={
                                                    (stateBet !== '' ? 'alert' : '') +
                                                    (item.elementoschance?.length > 0
                                                        ? 'elementos'
                                                        : '')
                                                }
                                            >
                                                <ContentDetailNumbers
                                                    isShowMore={isShowMoreNumbers}
                                                    cantToShow={cantToShow}
                                                >
                                                    {item.betNumbers.map((number, index) => (
                                                        <ContentDetailNumbersBox key={index}>
                                                            <p>Número {index + 1}</p>
                                                            <span>{number.numbers.join('')}</span>
                                                        </ContentDetailNumbersBox>
                                                    ))}
                                                </ContentDetailNumbers>
                                                {item.betNumbers.length > cantToShow - 1 && (
                                                    <Button
                                                        typeButton="underlined withe"
                                                        text={
                                                            !isShowMoreNumbers
                                                                ? 'Ver más'
                                                                : 'Ver menos'
                                                        }
                                                        icon={
                                                            isShowMoreNumbers
                                                                ? 'angle-up'
                                                                : 'angle-down'
                                                        }
                                                        onClick={() => {
                                                            setIsShowMoreNumbers(
                                                                !isShowMoreNumbers
                                                            );
                                                        }}
                                                    ></Button>
                                                )}
                                                {item.elementoschance?.length > 0 && (
                                                    <ContentDetailElements>
                                                        {item.elementoschance?.map((elemento) => {
                                                            return (
                                                                <>
                                                                    <ElementBox
                                                                        elementActive={true}
                                                                        dataElement={elemento}
                                                                        evento={item.eventochance.desEvento}
                                                                    />
                                                                </>
                                                            );
                                                        })}
                                                    </ContentDetailElements>
                                                )}
                                            </ContainerDetailNumbers>
                                            <ContentDetailIncentive>
                                                <IncentiveMessage
                                                    fechaApuesta={item.date_sorteo}
                                                    valorApuesta={item.total}
                                                    sigla={item.sigla}
                                                    apuesta={item}
                                                    className="incentivos--carrito"
                                                ></IncentiveMessage>
                                            </ContentDetailIncentive>
                                            <ContentDetailBottom
                                                className={
                                                    stateBet === 'alert'
                                                        ? 'alertText'
                                                        : stateBet === 'danger'
                                                        ? 'dangerText'
                                                        : ''
                                                }
                                            >
                                                <ContentDetailBottomLeft
                                                    className={
                                                        stateBet === 'alert'
                                                            ? 'alertText'
                                                            : stateBet === 'danger'
                                                            ? 'dangerText'
                                                            : ''
                                                    }
                                                >
                                                    <span
                                                        className={
                                                            stateBet === 'alert'
                                                                ? 'icon-exclamation'
                                                                : stateBet === 'danger'
                                                                ? 'icon-cross-small'
                                                                : 'icon-check'
                                                        }
                                                    />
                                                    <p>
                                                        {stateBet === '' &&
                                                            `Las loterías se encuentran disponibles.`}
                                                        {stateBet === 'alert' &&
                                                            `La lotería ${lotteryNotDisponible} no se encuentra disponible, edita o elimina esta apuesta.`}
                                                        {stateBet === 'danger' &&
                                                            `Todas las loterías han cerrado. Esta apuesta se eliminará y no se cobrará en el total.`}
                                                    </p>
                                                </ContentDetailBottomLeft>
                                                <ContentDetailBottomRight
                                                    className={stateBet !== '' ? 'alert' : ''}
                                                >
                                                    <p>Valor total</p>
                                                    {item.vlrTotalAdicional && item.ivaAdicional &&
                                                    item.vlrTotalAdicional ? (
                                                        <span>
                                                            $
                                                            {converterNumber(
                                                                Math.round(
                                                                    item.total +
                                                                        parseInt(
                                                                            item.vlrTotalAdicional
                                                                        ) + 
                                                                    item.ivaAdicional
                                                                )
                                                            )}
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            $
                                                            {converterNumber(
                                                                Math.round(item.total)
                                                            )}
                                                        </span>
                                                    )}
                                                </ContentDetailBottomRight>
                                            </ContentDetailBottom>
                                        </ContentDetail>
                                    );
                                })}
                            </>
                        )}
                    </ContentCart>
                    {!isEmpty && (
                        <DetailToPay
                            isDisablePaymentButton={isDisablePaymentButton}
                            betsCart={betsCart}
                            setPayment={setIsPayment}
                            setModalLocated={setModalState}
                            setModalNotLocated={setNotLocatedState}
                            modaLocated={false}
                            modalNotLocated={false}
                            showToast={showToast}
                            location={location}
                            setLocation={setLocation}
                        />
                    )}
                    <ModalGana
                        visibility={showModal}
                        setVisibility={displayModal}
                        options={optionsModal}
                    />
                    <ModalGana
                        visibility={showModalEdit}
                        setVisibility={displayModalEdit}
                        options={optionsModalEdit}
                    />
                    <ModalLocation
                        modalState={modalState}
                        setModalState={displayModalLocated}
                        updateLocation={updateLocation}
                        user={dataUser.usuario}
                        cartId={betsCart?.id || 0}
                    />
                    <ModalNotLocation
                        modalState={notLocatedState}
                        setModalState={displayModalNotLocated}
                    />
                    {toastState.state === true && (
                        <Toast text={toastState.text} icon="cross" type="danger" />
                    )}
                </WrapperCartDetail>
            )}
        </>
    );
};
