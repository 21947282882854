import { breakpoint } from 'src/shared/styles/variables';
import styled, { keyframes } from 'styled-components';
import imgBackgroundRegisterLogin from 'src/shared/assets/images/background_register_login.png';
import imgBackgroundLogin from 'src/shared/assets/images/background_login.png';

export const BoxLogin = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    ${breakpoint('sm')`
        margin-top: 13px;
        margin-bottom: 22px;
    `}
`;

export const BoxLoginContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${breakpoint('md')`
        flex-direction: row;
        margin-top: 40px;
    `}
`;

export const ContentLogin = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-self: flex-end;
    order: 0;

    ${breakpoint('md')`
        order: 2;
        max-width: 568px;
        margin-left:17px;
        border-radius: 16px;
        border: 0.5px solid ${(props) => props.theme.colors.backgrounds.gray400};
    `}

    ${breakpoint('md')`
        background: ${(props) => props.theme.colors.backgrounds.gray500};
    `}

    ${breakpoint('md')`
        background-repeat: no-repeat; 
        background-size: cover;
        background-image: url(${imgBackgroundLogin});
    `}
`;

export const HeaderContentLogin = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 35px;
    padding: 0px 16px;

    ${breakpoint('md')`
        margin-top: 24px;
    `}

    h1 {
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;

        ${breakpoint('md')`
            padding-bottom: 21px;
            border-bottom: 1px solid #3E3A47;
            max-width:430px;
            margin: 0 auto;
        `}

        span {
            color: #ffe000;
        }
    }
    p {
        margin-top: 10px;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;

        ${breakpoint('md')`
            line-height: 24px;
        `}

        span {
            margin-top: 10px;
            font-weight: 700;
        }
    }
`;

export const BodyContentLogin = styled.div`
    width: 100%;
    margin-top: 24px;
    border-radius: 16px;
    padding: 24px 15px 54px 15px;
    background: ${(props) => props.theme.colors.backgrounds.gray500};
    border: 0.5px solid ${(props) => props.theme.colors.backgrounds.gray400};
    display: flex;
    justify-content: center;
    flex-direction: column;

    ${breakpoint('md')`
        order: 2;
        border: none;
        margin-top: 0px;
        padding: 14px 55px 42px 55px;
        background:none;
    `}
`;

export const FormLogin = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & .uppercase {
        text-transform: uppercase;
    }
`;

export const CardRegister = styled.div`
    height: auto;
    max-height: 391px;
    width: 100%;
    height: auto;
    margin-top: 14px;
    border-radius: 16px;
    padding: 24px 15px 54px 15px;
    background: ${(props) => props.theme.colors.backgrounds.gray500};
    border: 0.5px solid ${(props) => props.theme.colors.backgrounds.gray400};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 21px;
    order: 0;

    a {
        margin: 0 auto;
        text-decoration: none;
    }
    ${breakpoint('md')`
        margin-top: 0px;
        width: 293px;
    `}

    ${breakpoint('md')`
        background-repeat: no-repeat; 
        background-size: cover;
        background-image: url(${imgBackgroundRegisterLogin});
    `}

    img {
        width: 84px;
        height: 84px;
        margin: 0 auto;

        ${breakpoint('md')`
            width: 110px;
            height: 110px;
            margin-bottom: 21px;
        `}
    }
    h2 {
        margin: 15px auto;
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #ffe000;

        ${breakpoint('md')`
            font-size: 14px;
            line-height: 24px;
        `}
    }
    p {
        display: none;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        max-width: 169px;
        ${breakpoint('md')`
            display: block;
            margin-bottom: 22px;
            
        `};
    }
`;

export const FooterForm = styled.div`
    margin-top: 25px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    padding: 0px 10px;

    & > button {
        margin: 32px auto;
        width: 161px;

        background: #00d455;

        ${breakpoint('sm')`
            margin-bottom: 24px;
            margin-top: 29px;
        `}
    }
    .active {
        animation: loading 7s;
        animation-iteration-count: 1;
        background: linear-gradient(90deg, #00d455 80%, #0df96c 20%);
    }

    @keyframes loading {
        0% {
            background: linear-gradient(-90deg, #0df96c 100%, #00d455 0%);
        }
        10% {
            background: linear-gradient(-90deg, #0df96c 90%, #00d455 10%);
        }
        20% {
            background: linear-gradient(-90deg, #0df96c 80%, #00d455 20%);
        }
        30% {
            background: linear-gradient(-90deg, #0df96c 70%, #00d455 30%);
        }
        40% {
            background: linear-gradient(-90deg, #0df96c 60%, #00d455 40%);
        }
        50% {
            background: linear-gradient(-90deg, #0df96c 50%, #00d455 50%);
        }
        60% {
            background: linear-gradient(90deg, #00d455 60%, #0df96c 40%);
        }
        70% {
            background: linear-gradient(90deg, #00d455 70%, #0df96c 30%);
        }
        100% {
            background: linear-gradient(90deg, #00d455 80%, #0df96c 20%);
        }
    }

    a {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: ${(props) => props.theme.colors.principal.greenNeon};
        margin: 0 auto;
        text-decoration: none;
        border-bottom: 1px solid ${(props) => props.theme.colors.principal.greenNeon};
    }

    & > div {
        display: flex;
        justify-content: center;
        align-items: center;

        input:checked + label:before {
            color: #0df96c !important;
        }

        & > label:before {
            color: #fff !important;
        }
    }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
    border: 4px solid rgba(255, 255, 255, 0.6);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: transparent;
    animation: ${spin} 1s linear infinite;
`;

export const ContentLoading = styled.div`
    width: 100%;
`;
