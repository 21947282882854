import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';

interface IButton {
    colorDefault?: string;
    hoverDisabled?: boolean;
    header?: boolean;
}

export const ButtonCustom = styled.button<IButton>`
    margin-right: 15px;
    outline: none;
    border: none;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    font-size: 18px;
    cursor: pointer;
    z-index: 1;

    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;

    p {
        margin-left: 8px;
    }

    span {
        font-size: 18px;
        color: ${(props) => props.colorDefault};
    }

    ${(props) =>
        props.hoverDisabled &&
        `&:hover {
            p, span {
                color: ${props.theme.colors.principal.greenNeon};
            }
        }
    `}

    &:active {
        color: ${(props) => props.theme.colors.principal.darkGreen};
    }

    &:disabled {
        cursor: not-allowed;
    }

    &.small {
        font-size: 12px !important;
        margin-right: 0px;
    }

    &.secondary {
        &__active {
            p,
            span {
                color: ${(props) => props.theme.colors.accents.neon.blue};
            }
        }
    }

    ${breakpoint('md')<IButton>`
        margin-left: ${(props) => (props.header ? '16px' : '0px')}    
    `};
`;
