import { getData, removeLocalStorage } from '@utils/transferData';
import React, { createContext, useState } from 'react';

interface IPropsProvider {
    children: React.ReactNode;
}

export interface IBet {
    number: string;
    numberOne: string;
    numberTwo: string;
    numberThree: string;
    numberFour: string;
    derecho: number;
    combinado: number;
    collapse: boolean;
    numberMaximo?: number;
    errorDerecho: string;
    errorCombinado: string;
    idBet?: number;
}

export type ILottery = {
    ideLoteria: number;
    desLoteria: string;
    abreviatura: string;
    horaCierre: string;
    numCifras: number;
    sorteo: string;
    numeroFavorito?: string;
};

interface IErrors {
    stepOne: string;
    stepTwo: string;
    stepThree: {
        numbers: string;
        bets: string;
        modalities: string;
    };
}

export interface IButt {
    name: string;
    date: string;
    lotteries: ILottery[];
    errors: IErrors;
    valueNeto: number;
    valueTotal: number;
    bets: IBet[];
    collapse: boolean;
    isStored?: boolean;
    id: number;
    saveChanges?: boolean;
    idBetCart?: string;
}
export interface IButtDataJson {
    dataJson: {
        name: string;
        date: string;
        lotteries: ILottery[];
        errors: IErrors;
        valueNeto: number;
        valueTotal: number;
        bets: IBet[];
        collapse: boolean;
        isStored?: boolean;
        id: number;
        saveChanges?: boolean;
    };
}

export interface IDataGame {
    vlrMaxBet: string;
    vlrMinBet: string;
    valueTotal?: number;
    valueToWin?: number;
    butts: IButt[];
}

interface IPropsApp {
    dataGame: IDataGame;
    setDataGame: (dataGame: IDataGame) => void;
}

export const LotteryInitial: ILottery = {
    abreviatura: '',
    desLoteria: '',
    horaCierre: '',
    ideLoteria: 0,
    numCifras: 4,
    sorteo: '0',
    numeroFavorito: ''
};

let date = new Date();

export let initialButt: IButt = {
    name: 'Apuesta ',
    date: date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear(),
    lotteries: [],
    collapse: false,
    isStored: false,
    id: 0,
    errors: {
        stepOne: '',
        stepTwo: '',
        stepThree: {
            numbers: '',
            bets: '',
            modalities: '',
        }
    },
    valueNeto: 0,
    valueTotal: 0,
    bets: [
        {
            number: '',
            numberOne: '',
            numberTwo: '',
            numberThree: '',
            numberFour: '',
            derecho: 0,
            combinado: 0,
            collapse: false,
            errorDerecho: '',
            errorCombinado: ''
        }
    ]
};
const SuperChanceContext = (props: IPropsProvider) => {
    initialButt.name = 'Apuesta 1';

    let local: IDataGame = getData('dataGameLocalStorage');

    const [dataGame, setDataGame] = useState<IDataGame>(local || {
        vlrMaxBet: '100000',
        vlrMinBet: '1008',
        valueTotal: 0,
        valueToWin: 0,
        butts: [initialButt]
    });

    const { children } = props;

    return (
        <>
            <AppContext.Provider value={{ dataGame, setDataGame }}>{children}</AppContext.Provider>
        </>
    );
};

export default SuperChanceContext;
export const AppContext = createContext<IPropsApp>({});