import React, { useEffect, useState } from 'react';
import { InputCustom, PredictError } from '@componentsShared';
import { ContentTooltip, ItemList } from './styled';
import Viewport from 'src/shared/hooks/viewport';

type TProps = {
    condition?: string;
    valid?: any;
    value?: string;
};

const Tooltip = () => {
    return (
        <ContentTooltip>
            <span>Tu contraseña debe tener:</span>
            <ul>
                <ItemList>
                    <p>
                        <span>Mínimo 8 caracteres</span> entre letras, números y caracteres
                        especiales, p.ej. Lunaysol54*
                    </p>
                </ItemList>
                <ItemList>
                    <p>
                        <span>1 mayúscula</span> al menos
                    </p>
                </ItemList>
                <ItemList>
                    <p>Evita poner tu número de identificación</p>
                </ItemList>
            </ul>
        </ContentTooltip>
    );
};

export const ValidatePassword = ({ condition, valid, value }: TProps) => {
    const viewport = Viewport();
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isShowConfirm, setIsShowConfirm] = useState(false);
    const toggleShowPassword = () => {
        setIsShowPassword(!isShowPassword);
    };

    const toggleShowConfirm = () => {
        setIsShowConfirm(!isShowConfirm);
    };

    return (
        <>
            <InputCustom
                name="password"
                label="Crea tu contraseña"
                type={isShowPassword ? 'text' : 'password'}
                placeholder="Escribe tu contraseña"
                required="Contraseña requerida"
                icon={{
                    position: 'right',
                    icon: isShowPassword ? 'eye-crossed' : 'eye',
                    onClick: toggleShowPassword
                }}
                minLength={{
                    value: 8,
                    message: 'Tu contraseña debe tener mínimo 8 caracteres con una mayúscula, letras y números'
                }}
                secondaryIcon={{
                    position: 'right',
                    icon: !valid.errors.password && condition !== '' ? 'check' : '',
                    color: ''
                }}
                color="#0DF96C"
                pattern={{
                    value: /^(?=.*\d)(?=.*[\u0021-\u002b\u002e\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{6,40}$/,
                    message: '¡Uy! Necesitas al menos agregar una letra mayúscula, un número y un carácter especial'
                }}
                tooltip={{
                    isShow: true,
                    content: <Tooltip />
                }}
            />

            <InputCustom
                name="confirmPassword"
                label="Confirma tu contraseña"
                type={isShowConfirm ? 'text' : 'password'}
                placeholder="Escríbela nuevamente"
                required="Debe repetir la contraseña"
                icon={{
                    position: 'right',
                    icon: isShowConfirm ? 'eye-crossed' : 'eye',
                    onClick: toggleShowConfirm
                }}
                secondaryIcon={{
                    position: 'right',
                    icon: !valid.errors.password && valid.isValid ? 'check' : '',
                    color: ''
                }}
                condition={condition}
                message="La contraseña no coincide"
                color="#0DF96C"
            />
            {/* <PredictError value={value} valid={valid} /> */}
        </>
    );
};
