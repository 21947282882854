import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';
import Ellipse from 'src/shared/assets/images/Ellipse.png';
import isAllyBackground from 'src/shared/assets/images/background_login.png';

export const MainContainer = styled.div`
    max-width: 949px;
    border: 0.5px solid ${(props) => props.theme.colors.principal.greenNeon};
    // margin: auto;
    border-radius: 16px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 60px;
    display: flex;
    max-height: 256px;
    flex-direction: column;
    align-items: center;
    background: ${(props) => props.theme.colors.backgrounds.gray500};
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${isAllyBackground});

    & > div:nth-child(2n) {
        order: 1;

        ${breakpoint('md')({
            order: '0'
        })}
    }

    ${(props) =>
        breakpoint('md')({
            // maxHeight: '94px',
            background: props.theme.colors.backgrounds.gray500,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage: 'url(' + Ellipse + ')'
        })};

    ${breakpoint('md')({
        alignItems: 'center',
        padding: '0 40px 0 20px',
        height: '94px',
        justifyContent: 'space-between',
        flexDirection: 'row',
        margin: 'auto',
        marginBottom: '60px'
    })}
`;

export const SectionOne = styled.div`
    margin: auto;

    p {
        padding: 30px;
        // padding-top: 32px;
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-size: 18px;
        line-height: 21.6px;

        ${(props) =>
            breakpoint('md')({
                fontFamily: props.theme.fonts.mon_bold,
                fontSize: '22px',
                lineHeight: '26.4px',
                padding: '0',
                paddingTop: '0'
            })};

        b {
            color: ${(props) => props.theme.colors.accents.base.yellow};
        }
    }

    ${breakpoint('md')({
        margin: '0',
        width: '300px',
        borderRight: '1px solid #3E3A47',
        height: '40px',
        paddingTop: '7px'
    })}
`;
export const SectionTwo = styled.div`
    text-align: center;
    padding: 10px 20px 20px;
    width: 208px;
    display: flex;

    img {
        padding-right: 10px;

        ${breakpoint('md')({
            position: 'relative',
            top: '8px'
        })}
    }

    h3 {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        font-size: 16px;
        line-height: 19.2px;

        ${breakpoint('md')({})}
    }

    p {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 16px;
        line-height: 19.2px;

        ${breakpoint('md')({})}

        b {
            font-family: ${(props) => props.theme.fonts.lf_bold};
        }
    }

    ${breakpoint('md')({
        margin: '0',
        padding: '0',
        width: '300px',
        borderRight: '1px solid #3E3A47',
        textAlign: 'center',
        paddingRight: '49px'
    })}
`;
export const SectionThree = styled.div`
    text-align: center;
    padding: 12px 20px 20px;
    border-top: 1px solid #3e3a47;
    width: 260px;
    border-bottom: 1px solid #3e3a47;

    button {
        margin: auto;
        height: 32px;

        ${breakpoint('md')({
            margin: 'none'
        })}
    }

    ${breakpoint('md')({
        margin: '0',
        padding: '0',
        width: 'auto',
        textAlign: 'center',
        paddingRight: '20px',
        border: 'none'
    })}

    p {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        padding-bottom: 10px;

        ${breakpoint('md')({
            paddingBottom: '10px'
        })}
    }
`;

export const Title = styled.p`
    padding: 30px;
    font-family: ${(props) => props.theme.fonts.mon_bold};

    span {
        color: ${(props) => props.theme.colors.accents.base.yellow};
    }

    ${breakpoint('md')({
        fontSize: '22px',
        lineHeight: '26.4px',
        padding: '0',
        paddingTop: '0'
    })}
`;

export const RawTitleText = styled.div``;
