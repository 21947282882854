import React from 'react';
import { PreviewYoutube } from '../../atoms/previewYoutube';
import { ContainerMedia, ContainerText, ContainerVideo, RichText, SectionContent } from './styled';
import { TSectionContentPage } from './types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export const SectionContentPage = (props: TSectionContentPage) => {
    const { isEven, text, img, video, resolution, className } = props;

    return (
        <SectionContent isEven={isEven} resolution={resolution} className={className || ''}>
            <RichText resolution={resolution}>
                <ContainerText resolution={resolution}>
                    {documentToReactComponents(JSON.parse(text))}
                </ContainerText>
            </RichText>
            {img !== undefined && (
                <ContainerMedia resolution={resolution}>
                    <img src={img.url} alt={img.fileName} />
                </ContainerMedia>
            )}
            {video !== null ||
                video !== '/' ||
                (video !== undefined && (
                    <ContainerVideo resolution={resolution}>
                        <PreviewYoutube url={video} />
                    </ContainerVideo>
                ))}
        </SectionContent>
    );
};
