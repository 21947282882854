import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';
import wrapperBackground from 'src/shared/assets/images/registerBackground.png';
import Ellipse from 'src/shared/assets/images/Ellipse.png';

type IPropsContainerRequest = {
    isSelectOption: boolean;
}

export const Container = styled.div`
    width: 100%;
    margin-bottom: 20px;

    /*     ${breakpoint('lg')({
        backgroundImage: 'url(' + wrapperBackground + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        padding: '40px',
        width: '100%'
    })} */

`;

export const FormPqrs = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 16px;

    ${(props) =>
        breakpoint('lg')({
            background: props.theme.colors.backgrounds.gray500,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            border: '0.5px solid' + props.theme.colors.backgrounds.gray400,
            backgroundImage: 'url(' + Ellipse + ')',

            flexDirection: 'row',
            padding: '40px 20px',
            width: '100%',
            height: 'auto'
        })}

    ${breakpoint('lg')({
        maxWidth: '995px',
        margin: 'auto'
    })}
`;

export const ContainerRequest = styled.div<IPropsContainerRequest>`
    width: 100%;
    img {
        display: none;
    }

    img {
        ${breakpoint('lg')({
            display: 'block',
            width: '200px',
            position: 'relative',
            top: '42px',
            margin: 'auto',
            marginBottom: '1px'
        })}
    }

    ${breakpoint('md')({
        margin: 'auto',
        maxWidth: '330px',
        marginBottom: '20px'
    })}
    & > div {
        ${breakpoint('lg')`
            padding-bottom: ${({isSelectOption}: IPropsContainerRequest) => {
                return isSelectOption ? '100px' : '310px;'
            }};
        `}
    }

    ${breakpoint('lg')({
        width: '758px',
        borderRight: '1px solid #3E3A47',
        margin: '0',
        paddingRight: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center'
    })}
`;

export const ContainerDataUser = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background: ${(props) => props.theme.colors.backgrounds.gray500};
    border: .5px solid rgba(170, 170, 170, .7);
    border-radius: 16px;
    padding: 24px 5px 10px;
    margin-top: 20px;
    margin-left: 0;

    & > div:nth-child(5n) {
        label {
            font-weight: bold;
        }
        label::after {
            content: '';
        }
        textarea {
            width: 100%;
            height: 137px;

            ${breakpoint('md')({
                height: '83px'
            })}

            ${breakpoint('lg')({
                height: '137px'
            })}
        }
    }
    & > div:nth-child(5n) {
        ${breakpoint('md')({
            maxWidth: '100%',
            width: '100%'
        })}

        ${breakpoint('lg')({
            maxWidth: '100%',
            width: '100%'
        })}
    }

    div {
        ${breakpoint('md')({
            maxWidth: 'initial',
            border: 'none'
        })}
    }

    div {
        ${breakpoint('lg')({
            maxWidth: 'initial',
            border: 'none'
        })}
    }

    ${breakpoint('md')({
        justifyContent: 'space-between',
        padding: '0',
        columnGap: '20px',
        marginTop: '0'
    })}

    ${breakpoint('lg')({
        border: 'none',
        justifyContent: 'space-between',
        background: 'none',
        padding: '0',
        columnGap: '20px',
        marginTop: '0',
        marginLeft: '23px'
    })}

    & > div:nth-child(6n) {
        max-width: 100% !important;
    }
`;

export const ContainerPolitics = styled.div`
    display: flex;
    padding: 0 25px 0 10px;
    margin-top: 30px;
    width: 100%;
    justify-content: center;
    max-width: 100%;

    input {
        padding: 5px;
        margin-right: 10px;
    }

    ${breakpoint('md')({
        marginTop: '28px',
        justifyContent: 'center',
        width: '100% !important',
        maxWidth: '100%'
    })}

    input {
        ${breakpoint('md')({
            padding: '0px',
            marginRight: '10px'
        })}
    }
    div {
        ${breakpoint('lg')({
            maxWidth: '100%'
        })}
    }
`;

export const CustomCheck = styled.input`
    height: 16px;
    width: 16px;
    border: 1px solid #c5c3c9;
    appearance: none;
    border-radius: 4px;
    position: relative;
    top: 5px;
    &:before {
        content: none;
    }
    &:checked {
        border: none;
        &:before {
            font-family: 'icomoon';
            content: '\\e944';
            color: ${(props) => props.theme.colors.principal.greenBase};
            font-size: 16px;
        }
    }
`;

export const Politics = styled.label`
    a {
        border-bottom: 1px solid #0df96c;
        color: #0df96c;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 14px;
        font-weight: normal;
        margin-left: 2px;
        line-height: 24px;
        padding-bottom: 2px;
        text-decoration: none;
    }
`;

export const FormButtons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 42px;

    button {
        font-size: 16px;
        width: 160px;
        height: 46px;
    }

    ${breakpoint('md')`
        flex-direction: row-reverse;
        justify-content: center;
        align-items: baseline;
        width: 100%;
        margin: 37px auto 0;
        margin-bottom: 50px;

        button{
            font-size: 14px;
            width: 160px;
            height: 46px;
            margin-bottom: 0;
        }

    `}
    
    ${breakpoint('lg')`
        margin-bottom: 40px;

        button {
            width: 110px;
            height: 32px;
        }

    `}
`;

export const TermsAndConditions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 29px;
    margin-bottom: 21px;

    a {
        cursor: pointer;
        align-self: center;
        border-bottom: 1px solid ${(props) => props.theme.colors.principal.greenBase};
        color: ${(props) => props.theme.colors.principal.greenBase};
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 14px;
        margin-left: 9px;
        padding-bottom: 4px;
        text-decoration: none;
    }

    ${breakpoint('md')`
        margin-top: 25px;
        margin-bottom: 0;
        width: 100%;

        a {
            transition: all 0.3s ease-in-out;
        }

        a:hover {
            color: ${(props) => props.theme.colors.principal.greenNeon};
        }
    `};
`;

export const DescriptionTypeRequest = styled.p`
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 14px;
    padding: 0 10px;
    line-height: 16.8px;
    margin-top: 20px;
    margin-bottom: 20px;

    ${breakpoint('lg')`
        margin-top: 10px;
    `}
`;

export const DocumentContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 10px;

    div {
        div {
            label {
                // color: #8e8c94;
            }
        }
    }

    div:first-child {
        padding: 0;

        div {
            padding: 17px;
        }
    }

    div:last-child {
        padding: 0;
        label {
            margin: 0;
            margin-top: 10px;
            margin-bottom: 8px;
        }

        input {
            min-width: 290px;
        }
    }

    ${breakpoint('md')`
        grid-template-columns: repeat(2, 1fr);
        padding: 0;
        width: 100%;
        margin-right: 24px;
        margin-left: 24px;
        grid-column-gap: 24px;
        margin-top: 40px;

        div:last-child {
            input {
                max-width: 430px;
            }
            label {
                margin-top: 0;
            }
        }
    `}

    ${breakpoint('lg')`
        margin-top: 10px;
        margin-right: 14px;
        margin-left: 14px;
    `}
`;

export const ContainerInputs = styled.div`
    max-width: 290px;
    width: 100%;

    & > div {
        padding-right: 0;
        padding-left: 0;
    }

    ${breakpoint('md')`
        display: flex;
        column-gap: 24px;
        margin-right: 24px;
        margin-left: 24px;
    `}
    
    ${breakpoint('lg')`
        margin-right: 14px;
        margin-left: 14px;
    `}
`;