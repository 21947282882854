import React, { useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    InputCustom,
    Redirect,
    ValidateTypeDocument
} from '@componentsShared';
import {
    BodyContentLogin,
    BoxLogin,
    BoxLoginContent,
    CardRegister,
    ContentLogin,
    FooterForm,
    FormLogin,
    HeaderContentLogin
} from './styled';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import diamond from './035-diamond.svg';
import { loginServices } from '@services/login';
import CryptoJS from 'crypto-js';
import { getData, getDataStorage, removeSessionStorage, setData } from '@utils/transferData';
import { useFetchDataUser } from '@utils/useFetchDataUser';
import { setCookie, getCookie, eraseCookie } from '@utils/handlerCookies';
import { ErrorLogin } from '@components';
import { IProps, Inputs } from './types';
import Viewport from 'src/shared/hooks/viewport';
import { ModalNotRegistered } from 'src/components/molecules/modalNotRegistered';
import siteUrl from '@utils/siteUrl';
import { isApolloError } from '@apollo/client';
import { encryptText } from '@utils/encrypt';
import { registerServices } from '@services/register';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { getReCaptchaKey, getTokenReCaptcha } from '@utils/recaptcha';
import { getDataEncrypt } from '@utils/security';
import { colorText } from '@utils/colorText';

export const ContainerLogin = ({ page }: IProps) => {
    const methods = useForm({ mode: 'onChange' });
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notRegisteredModalState, notRegisteredSetModalState] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const displayModalNotRegistered = () => {
        notRegisteredSetModalState(!notRegisteredModalState);
    };
    const [tokenRecaptcha, setTokenRecaptcha] = useState('');
    const viewport = Viewport();

    const { fetchData } = useFetchDataUser();

    let dataLocal: object = {};

    const handlerRememberMe = (user: string, password: string, state: boolean, type: string) => {
        let nameCookie = 'RememberMe';

        if (state) {
            let cookieRemember = getCookie(nameCookie);
            let objetCookie = {
                user,
                password,
                type
            };
            if (cookieRemember === null) {
                setCookie(nameCookie, JSON.stringify(objetCookie), 365);
            } else {
                eraseCookie(nameCookie);
                setCookie(nameCookie, JSON.stringify(objetCookie), 365);
            }
        } else {
            eraseCookie(nameCookie);
        }
    };

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        setLoading(true);
        let user = encryptText(data.user);
        let password = encryptText(data.password);
        let rememberMe = data.remember;

        const mailForwardingSendData = async () => {
            await registerServices
                .mailForwarding(data.type, user, password)
                .then((data) => {
                    console.log('Se reenvió correo activación', data);
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        let token = tokenRecaptcha;
        if (!token) {
            token = await getTokenReCaptcha();
        }

        await loginServices
            .getToken(user, password, data.type, token)
            .then((result) => {
                const {
                    data: {
                        login: { token }
                    },
                    errors
                } = result;

                console.log(errors);

                handlerRememberMe(user, password, rememberMe, data.type);

                let setDataUserLogin = {
                    user,
                    token: CryptoJS.AES.encrypt(token, process.env.ENCRYPTION_KEY || '').toString(),
                    type: data.type,
                    isLogged: true
                };

                dataLocal = getDataEncrypt('butts') || {};
                fetchData(user, token, data.type);
                setData(setDataUserLogin, 'userData');

                /* Redirect to an active game */
                let gameAbb: 'REB' | 'DG3' | 'CHA' = getDataStorage('gameAbb');
                removeSessionStorage('gameAbb');

                let urlsGames = {
                    REB: {
                        url: siteUrl + '/juegos/billeton/confirmacion-apuesta/'
                    },
                    DG3: {
                        url: siteUrl + '/juegos/billonario/confirmacion-apuesta/'
                    },
                    CHA: {
                        url: siteUrl + '/juegos/super-chance/confirmacion-apuesta/'
                    }
                };

                let url = gameAbb ? urlsGames[gameAbb].url : siteUrl + '/';

                const backUrl = getDataStorage('back_url');
                if (backUrl) {
                    url = siteUrl + backUrl;
                    removeSessionStorage('back_url');
                }

                window.location.href = url;

                setLoading(false);
                setErrorMessage('');
            })
            .catch((e) => {
                const msg = e.message || '';
                setError(true);
                setErrorMessage(msg);
                setLoading(false);
                notRegisteredSetModalState(viewport.mobile);
                if (msg.includes('activar la cuenta')) {
                    mailForwardingSendData();
                }
                console.log(isApolloError(e));
            })
            .finally(() => {
                setLoading(false);
                methods.resetField('password');
            });
    };

    const toggleShowPassword = () => {
        setIsShowPassword(!isShowPassword);
    };

    useEffect(() => {
        let valueRememberMe = getCookie('RememberMe');
        if (valueRememberMe !== undefined) {
            let objectRememberMe = JSON.parse(valueRememberMe);
            let user = CryptoJS.AES.decrypt(
                objectRememberMe.user,
                process.env.ENCRYPTION_KEY || ''
            );
            let password = CryptoJS.AES.decrypt(
                objectRememberMe.password,
                process.env.ENCRYPTION_KEY || ''
            );
            methods.setValue('user', user.toString(CryptoJS.enc.Utf8));
            methods.setValue('password', password.toString(CryptoJS.enc.Utf8));
            methods.setValue('type', objectRememberMe.type);
            methods.setValue('remember', true);
        }
    }, []);

    const handleUserValidation = () => {
        getTokenReCaptcha().then((token) => {
            setTokenRecaptcha(token);
        });
    };

    useEffect(() => {
        if (methods.formState.isValid) {
            handleUserValidation();
            return;
        }
        setTokenRecaptcha('');
    }, [methods.formState.isValid]);

    useEffect(() => {
        if (error && viewport.mobile) displayModalNotRegistered();
    }, [error]);

    return (
        <BoxLogin>
            <BoxLoginContent>
                <ContentLogin>
                    <HeaderContentLogin>
                        <h1 dangerouslySetInnerHTML={{ __html: colorText(page.title) }} />
                        <p>
                            Si estás aquí es porque eres de los nuestros. <span>¡Bienvenido!</span>
                        </p>
                    </HeaderContentLogin>
                    <BodyContentLogin>
                        <FormProvider {...methods}>
                            <FormLogin onSubmit={methods.handleSubmit(onSubmit)}>
                                <GoogleReCaptchaProvider reCaptchaKey={getReCaptchaKey()}>
                                    <ValidateTypeDocument />
                                    <InputCustom
                                        name="password"
                                        label="Contraseña"
                                        type={isShowPassword ? 'text' : 'password'}
                                        placeholder="Escribe tu contraseña"
                                        required="Debes escribir tu contraseña"
                                        icon={{
                                            position: 'right',
                                            icon: isShowPassword ? 'eye-crossed' : 'eye',
                                            onClick: toggleShowPassword
                                        }}
                                    />
                                    <FooterForm>
                                        {!error && (
                                            <Checkbox label="Recordar mis datos" name="remember" />
                                        )}
                                        {error && !viewport.mobile && (
                                            <ErrorLogin message={errorMessage} />
                                        )}
                                        <Button
                                            text="Inicia sesión"
                                            disabled={!methods.formState.isValid}
                                            submit={true}
                                            typeButton={loading ? ' active' : ''}
                                        ></Button>
                                        <Redirect
                                            url="iniciar-sesion/recuperar-contrasena"
                                            text="¡Ay!, olvidé mi contraseña"
                                        />
                                    </FooterForm>
                                </GoogleReCaptchaProvider>
                            </FormLogin>
                        </FormProvider>
                    </BodyContentLogin>
                </ContentLogin>
                <CardRegister>
                    <img src={diamond} alt="Imagen de un diamante" />
                    <h2>¿Aún no tienes cuenta?</h2>
                    <p>Conoce los beneficios que tenemos para ti</p>
                    <Redirect url="registro/datos-personales">
                        <Button text="Regístrate" typeButton="bottomless" />
                    </Redirect>
                </CardRegister>
            </BoxLoginContent>
            <ModalNotRegistered
                modalState={notRegisteredModalState}
                setModalState={displayModalNotRegistered}
                text={errorMessage}
            />
        </BoxLogin>
    );
};
