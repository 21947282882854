import { lotteriesServices } from '@services/lotteries';
import { IBets, ILot } from '../../components/molecules/cartBodyDetail/types';
import { IBet, IButt, IDataGame, ILottery } from '../../context/superchance';
import { getTotalValue } from '@utils/getValueTotal';
import siteUrl from '@utils/siteUrl';
import moment from 'moment';
import { setData, setDataStorage } from '@utils/transferData';
import { carServices } from '@services/car';

type DataGame = {
    vlrMaxBet: string;
    vlrMinBet: string;
    valueTotal: number;
    valueToWin: number;
    butts: Butt[];
};

type Butt = {
    name: string;
    date: string;
    lotteries: Lottery[];
    collapse: boolean;
    isStored: boolean;
    id: number;
    errors: Errors;
    valueNeto: number;
    valueTotal: number;
    bets: Bet[];
    idBetCart: string;
};

type Bet = {
    number: string;
    numberOne: string;
    numberTwo: string;
    numberThree: string;
    numberFour: string;
    derecho: number;
    combinado: number;
    collapse: boolean;
    errorDerecho: string;
    errorCombinado: string;
    numberMaximo?: number;
    idBet: number;
};

type Errors = {
    stepOne: string;
    stepTwo: string;
    stepThree: StepThree;
};

type StepThree = {
    numbers: string;
    bets: string;
    modalities: string;
};

type Lottery = {
    abreviatura: string;
    desLoteria: string;
    horaCierre: string;
    ideLoteria: number;
    numCifras: number;
    sorteo: string;
    numeroFavorito: string;
};

type responseBets = {
    id: number;
    derecho: number;
    combinado: number;
    numero_colilla: number;
    numbers: number[][];
};

export const editSuperChance = async (bet: IBets, idCart: number) => {
    const bets: responseBets[] =
        (await carServices
            .getBetsByCartNumber(idCart, bet.numero_colilla)
            .then((data) => {
                if (data?.data?.getBetsByCartNumber) {
                    return data.data.getBetsByCartNumber;
                }
            })
            .catch((err) => {
                console.log(err);
            })) || ([] as responseBets[][]);

    console.log(bets);

    const betNumbers = bets.map((lines, index: number) => {
        const betSuperChance: Bet = {
            collapse: false,
            combinado: 0,
            derecho: 0,
            errorCombinado: '',
            errorDerecho: '',
            number: '',
            numberOne: '',
            numberTwo: '',
            numberThree: '',
            numberFour: '',
            idBet: 0
        };
        betSuperChance.number = lines.numbers[0].join('');
        if (betSuperChance.number.length > 3) {
            betSuperChance.combinado = lines.combinado;
            betSuperChance.derecho = lines.derecho;
            betSuperChance.idBet = lines.id;
            betSuperChance.numberOne = getNumber(lines.numbers[0][0]);
            betSuperChance.numberTwo = getNumber(lines.numbers[0][1]);
            betSuperChance.numberThree = getNumber(lines.numbers[0][2]);
            betSuperChance.numberFour = getNumber(lines.numbers[0][3]);
        } else {
            betSuperChance.combinado = lines.combinado;
            betSuperChance.derecho = lines.derecho;
            betSuperChance.idBet = lines.id;
            betSuperChance.numberTwo = getNumber(lines.numbers[0][0]);
            betSuperChance.numberThree = getNumber(lines.numbers[0][1]);
            betSuperChance.numberFour = getNumber(lines.numbers[0][2]);
        }
        return betSuperChance;
    });

    const dateSelected = moment.utc(bet.date_sorteo, 'YYYY-MM-DD');
    const dateLotteries = dateSelected.format('DD/MM/YYYY');

    const lotteries: Lottery[] =
        (await lotteriesServices
            .getLotteries(dateLotteries, bet.sigla)
            .then((data) => {
                const list: Lottery[] = [] as Lottery[];
                bet.loteries.map((lot: ILot, index: number) => {
                    const dataLoterries: Lottery[] = data.data.getGameInfo.loterias;
                    const lottery: Lottery =
                        dataLoterries.find((lottery: Lottery) => {
                            return lottery.ideLoteria == lot.loterieId;
                        }) || ({} as Lottery);
                    list.push(lottery);
                });
                return list;
            })
            .catch((err) => {
                console.log('Error', err);
            })) || ([] as Lottery[]);

    const buttSuperChance: Butt = {
        bets: betNumbers,
        collapse: false,
        date: dateSelected.format('D-M-YYYY'),
        lotteries: lotteries,
        id: bets[0].numero_colilla,
        name: bet.nombre_colilla,
        valueNeto: bet.total ? bet.total - bet.iva : 0,
        valueTotal: bet.total ? bet.total : 0,
        errors: {
            stepOne: '',
            stepTwo: '',
            stepThree: {
                bets: '',
                modalities: '',
                numbers: ''
            }
        },
        isStored: false,
        idBetCart: bet.id
    };

    let dataSuperChance: DataGame = {
        butts: [buttSuperChance],
        vlrMaxBet: '100000',
        vlrMinBet: '1008',
        valueTotal: 0,
        valueToWin: 0
    };

    dataSuperChance.valueTotal = getTotalValue(dataSuperChance.butts);

    async () => {
        await lotteriesServices
            .getGameInfoVlr(bet.date_sorteo, 'CHA')
            .then((data) => {
                dataSuperChance.vlrMaxBet = data.data.getGameInfo.vlrMaximoColilla;
                dataSuperChance.vlrMinBet = data.data.getGameInfo.vlrMinimoColilla;
            })
            .catch((err) => console.log(err));
    };
    
    setData(dataSuperChance, 'dataGameLocalStorage');
    setData(dateSelected.format('D-M-YYYY'), 'dayBet0');
    window.location.href = siteUrl + '/juegos/super-chance/';
};

const getNumber = (num: number) => {
    if (!num) {
        return '0';
    }
    return num.toString();
};