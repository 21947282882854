import React, { useContext, useState } from 'react';
import { ModalGana } from '@componentsShared';
import iconDelete from 'src/shared/assets/images/delete.svg';
import { carServices } from '@services/car';
import { AppContextUser } from 'src/shared/context/user';

interface IProps {
    modalState: boolean;
    setModalState: () => void;
    idCarrito: number;
    fetchDataCart?: () => void;
}

export const ModalDeleteBet = (props: IProps) => {
    const { modalState, setModalState, idCarrito, fetchDataCart } = props;
    const optionsModal = {
        logo: 'secundary',
        icon: iconDelete,
        title: '¿Seguro que quieres eliminar esta apuesta del carrito?',
        text: 'Este movimiento no podrá deshacerse. ',
        primaryButtonText: 'Eliminar',
        primaryButton: () => {
            fetchDeleteCar();
            setModalState();
        },
        secondaryButtonText: 'Cancelar',
        secondaryButton: () => {
            setModalState();
        },
        dimensions: { height: { mobile: '429px', desktop: '410px' }, width: { desktop: '535px' } },
        titleFonts: true
    };
    const { dataUser } = useContext(AppContextUser);

    const fetchDeleteCar = async () => {
        await carServices
            .deleteCart(dataUser.token, dataUser.tipoDocumento, dataUser.usuario, [idCarrito])
            .then((data) => {
                window.location.reload()
                /* if (fetchDataCart !== undefined) {
                    fetchDataCart();
                } */
            })
            .catch((err) => {
                console.log('error', err);
            });
    };
    return (
        <ModalGana visibility={modalState} setVisibility={setModalState} options={optionsModal} />
    );
};
