import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const WrapperFrequentQuestions = styled.div`
    width: 100%;
    height: 100%;
    padding: 36px;

    ${breakpoint('md')({
        // maxWidth: '995px',
        margin: 'auto'
        // padding: '50px'
    })}
`;

export const Icon = styled.div`
    display: none;
    justify-content: center;
    padding-top: 55px;

    ${breakpoint('md')`
      display:flex;
    `}
`;

export const ContainerHeader = styled.div`
    img {
        display: none;
        margin: auto;

        ${breakpoint('md')({
            display: 'block',
            width: '30px'
        })}
    }

    ${breakpoint('md')({
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: 'auto',
        textAlign: 'center',
        maxWidth: '995px'
    })}
`;

export const Title = styled.h1`
    font-size: 28px;
    display: flex;
    justify-content: center;
    padding: 14px 0 20px;
    border-bottom: 1px solid #c5c3c9;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    color: ${(props) => props.theme.colors.accents.base.yellow};

    span {
        padding-right: 10px;
    }
`;

export const ContainerMainText = styled.div`
    display: flex;
    align-items: center;

    img {
        display: none;

        ${breakpoint('md')({
            display: 'block'
        })}
    }

    ${breakpoint('md')({
        margin: '40px auto',
        maxWidth: '995px',
    })}

    p {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 14px;
        line-height: 16.8px;
        color: #f8f8f8;
        width: 529px;
        padding: 0;
        padding-top: 20px;
        padding-bottom: 20px;

        ${breakpoint('md')({
            paddingLeft: '68px',
            fontSize: '16px',
            lineHeight: '19.2px',
            paddingTop: '0',
            paddingBottom: '0'
        })}
    }

    b {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 14px;
        line-height: 16.8px;
        color: #f8f8f8;

        ${(props) =>
            breakpoint('md')({
                fontFamily: props.theme.fonts.lf_bold,
                fontSize: '16px',
                lineHeight: '19.2px'
            })}
    }
`;
