export type IBet = {
    id: string;
    total: number;
    iva: number;
    date_sorteo: string;
    game: string;
    sigla: string;
    numero_colilla: number;
    nombre_colilla: string;
    vlrUnitAdicional: string;
    vlrTotalAdicional: string;
    ivaAdicional: number;
    infoAdicionalIncentivo: string;
    betNumbers: {
        numbers: number[];
    }[];
    loteries: {
        loterieId: number;
        sorteo: string;
        sigla: string;
        desLoteria: string;
        disponible: boolean;
    }[];
};

export interface IBetCart {
    id: number;
    bets: IBet[];
    recharges: {
        id: number;
        number: number;
        operator: string;
        package: string;
        total: number;
    };
    total: number;
    iva: number;
    state: string;
}

declare global {
    var _egoiaq: any;

};

export const pushEgoi = (params: any[]) => {
    try {
        if (typeof _egoiaq != "undefined") {
            _egoiaq.push(params)
        }
    } catch (error) {
        console.error(error)
    }
}

export const initEgoi = (email: string) => {
    pushEgoi(['setSubscriber', email]); // You can use the uid (unique identifier) or the email or the phone number
}

export const addBeat = (element: IBet) => {
    let total = 1
    const categories = [element.sigla];
    element.loteries.forEach(loteria => {
        categories.push(loteria.sigla)
    })
    if (element.sigla.toUpperCase().includes('CHA')) {
        total = element.betNumbers.length
    }
    addProductEgoi(element.id, element.game, categories, element.total, total)
}
export const addProductEgoi = (id: string, name: string, categories: string[], price: number, quantity: number) => {
    pushEgoi(['addEcommerceItem',
        id, // (required) SKU: Product unique identifier
        name, // (optional) Product name
        categories, // (optional) Product category, string or array of up to 5 categories
        price, // (recommended) Product price
        quantity// (optional, default to 1) Product quantity
    ]);
}

export const actualizarTotalCarrito = (total: number) => {
    pushEgoi(['trackEcommerceCartUpdate', total]);
}

export const trackEcommerceOrder = (id: string, total: number, iva: number, shipping: number = 0, discount: boolean = false) => {
    const subtotal = total - iva;
    const tax = Math.round((iva / subtotal) * 100)
    pushEgoi(['trackEcommerceOrder',
        id,
        total,
        subtotal,
        tax,
        shipping,
        discount]);
}