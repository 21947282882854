import React, { useContext, useEffect, useState } from 'react';
import { HeaderContainer, IndicatorCar, LogoImage, Shopping } from './styled';
import { graphql, useStaticQuery } from 'gatsby';
import { Burger, CartModal, LogoGana, Menu, UbicationMessage, UserData } from '@componentsShared';
import Viewport from 'src/shared/hooks/viewport';
import { useScrollBlock } from 'src/shared/hooks/useScrollBlock';
import { carServices } from '@services/car';
import { AppContextUser } from 'src/shared/context/user';
import { getData, getDataStorage } from '@utils/transferData';
import { Redirect } from '../../atoms/redirect';
import { getCart } from '@utils/carrito';

export const Header = () => {
    const [open, setOpen] = useState(false);
    const [cartOpen, setCartOpen] = useState(false);
    const viewport = Viewport();
    const dataHeader = useStaticQuery(graphql`
        query QueryHeader {
            allContentfulHeader {
                nodes {
                    logoSection {
                        logos {
                            url
                            name
                            image {
                                file {
                                    fileName
                                    url
                                }
                            }
                        }
                    }
                    primarySection {
                        titles {
                            name
                            url
                            subtitle {
                                name
                                url
                            }
                        }
                    }
                    secundarySection {
                        titles {
                            name
                            url
                            subtitle {
                                name
                                url
                            }
                        }
                    }
                }
            }
        }
    `);
    const { allContentfulHeader } = dataHeader;
    const {
        nodes: [{ logoSection, primarySection, secundarySection }]
    } = allContentfulHeader;
    const [blockScroll, allowScroll] = useScrollBlock();

    const [betsInCart, setBetsInCart] = useState(0);
    const { dataUser } = useContext(AppContextUser);

    const fetchDataCar = async () => {
        if (!dataUser.token) {
            return;
        }

        const success = (data: any) => {
            setBetsInCart(data.data.getCart.bets.length);
        };

        const error = (err: any) => {
            console.log('error', err);
        };

        getCart(dataUser.token, dataUser.tipoDocumento, dataUser.usuario, success, error);
    };

    const handleToggle = () => {
        setCartOpen(!cartOpen);
    };

    useEffect(() => {
        if (cartOpen === false) {
            allowScroll();
        } else {
            blockScroll();
        }
    }, [cartOpen]);

    useEffect(() => {
        if (open === false) {
            allowScroll();
        } else {
            blockScroll();
        }
    }, [open]);

    useEffect(() => {
        let dataUserLocal = getData('userData');

        if (dataUserLocal !== null) {
            if (dataUserLocal.isLogged === true) {
                dataUserLocal.isLogged !== undefined &&
                    dataUser.tipoDocumento !== '' &&
                    dataUser.usuario !== '' &&
                    fetchDataCar();
            }
        }
    }, [dataUser]);

    return (
        <>
            {viewport.mobile && (
                <HeaderContainer open={open}>
                    <Burger open={open} setOpen={setOpen} />
                    <Menu
                        open={open}
                        setOpen={setOpen}
                        dataPrimary={primarySection[0].titles}
                        dataSecundary={secundarySection[0].titles}
                        logosHeader={logoSection[0].logos}
                    />
                    <LogoImage open={open}>
                        <Redirect url="/">
                            <LogoGana type="Primary" />
                        </Redirect>
                    </LogoImage>

                    <Shopping open={open} onClick={handleToggle}>
                        {dataUser.isLogged && (
                            <IndicatorCar>
                                <span>{betsInCart}</span>
                            </IndicatorCar>
                        )}
                        <span className="icon-shopping-cart"></span>
                    </Shopping>

                    <CartModal handleClose={handleToggle} open={cartOpen} />
                </HeaderContainer>
            )}
            {!viewport.mobile && (
                <HeaderContainer open={open}>
                    <LogoImage>
                        <Redirect url="/">
                            <LogoGana type="Primary" />
                        </Redirect>
                    </LogoImage>
                    <Menu
                        open={open}
                        setOpen={setOpen}
                        dataPrimary={primarySection[0].titles}
                        dataSecundary={secundarySection[0].titles}
                        logosHeader={logoSection[0].logos}
                    />

                    {/* <HeaderLogos>
                        <a>
                            <span className="icon-shop"></span>
                            Quieres ser aliado GANA
                        </a>
                        <a className="location">
                            <span className="icon-marker"></span>
                            Permitir tu ubicacion
                        </a>
                    </HeaderLogos> */}

                    <UserData />

                    <Shopping open={open} onClick={handleToggle}>
                        {dataUser.isLogged && (
                            <IndicatorCar>
                                <span>{betsInCart}</span>
                            </IndicatorCar>
                        )}
                        <span className="icon-shopping-cart"></span>
                    </Shopping>
                    <CartModal handleClose={handleToggle} open={cartOpen} />
                </HeaderContainer>
            )}
        </>
    );
};
