import React from 'react';
import { Button, FinalAuxBanner, Redirect, Carousel } from '@componentsShared';
import { ContainerImage, ContainerText, WrapperCalculate, ContainerSlider, ArrowCustom, ContainerImageReverse } from './styled';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Viewport from 'src/shared/hooks/viewport';

import { IArrow } from './types';


const SampleArrowCustom = (props: IArrow) => {
    const { onClick, side } = props;
    return (
        <ArrowCustom onClick={onClick} className={side}>
            <svg width="45" height="45" viewBox="0 0 45 45" fill="none">
                <circle r="22.5" transform="matrix(-1 0 0 1 22.5 22.5)" fill="#00D455" />
                <path
                    d="M18.25 19.8505L23.9862 14.118C24.2242 13.9028 24.5359 13.7873 24.8566 13.7956C25.1774 13.8038 25.4827 13.9351 25.7094 14.1622C25.936 14.3894 26.0667 14.6949 26.0742 15.0157C26.0818 15.3365 25.9657 15.6479 25.75 15.8855L20.0175 21.618C19.7831 21.8524 19.6515 22.1703 19.6515 22.5017C19.6515 22.8332 19.7831 23.1511 20.0175 23.3855L25.75 29.118C25.9844 29.3525 26.116 29.6706 26.1158 30.0022C26.1157 30.3338 25.9839 30.6517 25.7493 30.8861C25.5148 31.1205 25.1967 31.2521 24.8651 31.252C24.5336 31.2519 24.2156 31.12 23.9812 30.8855L18.25 25.153C17.547 24.4497 17.152 23.4961 17.152 22.5017C17.152 21.5074 17.547 20.5537 18.25 19.8505Z"
                    fill="#F8F8F8"
                />
            </svg>
        </ArrowCustom>
    );
};

const SampleArrowCustomMobile = (props: IArrow) => {
    const { onClick, side } = props;
    return (
        <ArrowCustom onClick={onClick} className={side}>
        </ArrowCustom>
    );
};


export const CalculateYourPrize = (props: any) => {
    const { dataBanner } = props;
    const viewport = Viewport();

    const customSettings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 10000,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: viewport.mobile ? <SampleArrowCustomMobile side="next" />  : <SampleArrowCustom side="next" />,
        prevArrow: viewport.mobile ? <SampleArrowCustomMobile side="prev" />  : <SampleArrowCustom side="prev" />
    };

    return (
        <WrapperCalculate>
            <Carousel customSettings={customSettings}>
                {!viewport.mobile && dataBanner?.map((banner: any) =>
                banner.bannerType === 'Banner sencillo' || !viewport.desktop? (
                <ContainerSlider>
                        <ContainerImage>
                            <img src={banner.desktopImage.file.url} alt={banner.desktopImage.description} />
                        </ContainerImage>
                        <ContainerText>
                            {documentToReactComponents(JSON.parse(banner.adText.raw))}
                        </ContainerText>
                        <Redirect url={banner.hyperlink}>
                            <Button text="Conoce el juego" />
                        </Redirect>
                </ContainerSlider>
                ):(

                <ContainerSlider>
                    <ContainerText>
                        {documentToReactComponents(JSON.parse(banner.adText.raw))}
                    </ContainerText>
                    <Redirect url={banner.hyperlink}>
                        <Button text="Conoce el juego" />
                    </Redirect>
                    <ContainerImageReverse>
                        <img src={banner.desktopImage.file.url} alt={banner.desktopImage.description} />
                    </ContainerImageReverse>
                </ContainerSlider>
                ))}
                {!viewport.desktop &&dataBanner?.map((banner: any) =>
                 ( <ContainerSlider>
                    <ContainerImage>
                        <img src={banner.desktopImage.file.url} alt={banner.desktopImage.description} />
                    </ContainerImage>
                    <ContainerText>
                        {documentToReactComponents(JSON.parse(banner.adText.raw))}
                    </ContainerText>
                    <Redirect url={banner.hyperlink}>
                        <Button text="Conoce el juego" />
                    </Redirect>
            </ContainerSlider>)
                )}
            </Carousel>

        </WrapperCalculate >

    );
};
