import { createGlobalStyle } from 'styled-components';

import LibreFranklin_Bold_Eot from '../assets/fonts/LibreFranklin-Bold/LibreFranklin-Bold.eot';
import LibreFranklin_Bold_svg from '../assets/fonts/LibreFranklin-Bold/LibreFranklin-Bold.svg#LibreFranklin-Bold';
import LibreFranklin_Bold_ttf from '../assets/fonts/LibreFranklin-Bold/LibreFranklin-Bold.ttf';
import LibreFranklin_Bold_woff from '../assets/fonts/LibreFranklin-Bold/LibreFranklin-Bold.woff';
import LibreFranklin_Bold_woff2 from '../assets/fonts/LibreFranklin-Bold/LibreFranklin-Bold.woff2';

import LibreFranklin_Extra_Bold_Eot from '../assets/fonts/LibreFranklin-ExtraBold/LibreFranklin-ExtraBold.eot';
import LibreFranklin_Extra_Bold_svg from '../assets/fonts/LibreFranklin-ExtraBold/LibreFranklin-ExtraBold.svg#LibreFranklin-ExtraBold';
import LibreFranklin_Extra_Bold_ttf from '../assets/fonts/LibreFranklin-ExtraBold/LibreFranklin-ExtraBold.ttf';
import LibreFranklin_Extra_Bold_woff from '../assets/fonts/LibreFranklin-ExtraBold/LibreFranklin-ExtraBold.woff';
import LibreFranklin_Extra_Bold_woff2 from '../assets/fonts/LibreFranklin-ExtraBold/LibreFranklin-ExtraBold.woff2';

import LibreFranklin_Light_Eot from '../assets/fonts/LibreFranklin-Light/LibreFranklin-Light.eot';
import LibreFranklin_Light_svg from '../assets/fonts/LibreFranklin-Light/LibreFranklin-Light.svg#LibreFranklin-Light';
import LibreFranklin_Light_ttf from '../assets/fonts/LibreFranklin-Light/LibreFranklin-Light.ttf';
import LibreFranklin_Light_woff from '../assets/fonts/LibreFranklin-Light/LibreFranklin-Light.woff';
import LibreFranklin_Light_woff2 from '../assets/fonts/LibreFranklin-Light/LibreFranklin-Light.woff2';

import LibreFranklin_Medium_Eot from '../assets/fonts/LibreFranklin-Medium/LibreFranklin-Medium.eot';
import LibreFranklin_Medium_svg from '../assets/fonts/LibreFranklin-Medium/LibreFranklin-Medium.svg#LibreFranklin-Medium';
import LibreFranklin_Medium_ttf from '../assets/fonts/LibreFranklin-Medium/LibreFranklin-Medium.ttf';
import LibreFranklin_Medium_woff from '../assets/fonts/LibreFranklin-Medium/LibreFranklin-Medium.woff';
import LibreFranklin_Medium_woff2 from '../assets/fonts/LibreFranklin-Medium/LibreFranklin-Medium.woff2';

import LibreFranklin_Regular_Eot from '../assets/fonts/LibreFranklin-Regular/LibreFranklin-Regular.eot';
import LibreFranklin_Regular_svg from '../assets/fonts/LibreFranklin-Regular/LibreFranklin-Regular.svg#LibreFranklin-Regular';
import LibreFranklin_Regular_ttf from '../assets/fonts/LibreFranklin-Regular/LibreFranklin-Regular.ttf';
import LibreFranklin_Regular_woff from '../assets/fonts/LibreFranklin-Regular/LibreFranklin-Regular.woff';
import LibreFranklin_Regular_woff2 from '../assets/fonts/LibreFranklin-Regular/LibreFranklin-Regular.woff2';

import LibreFranklin_SemiBold_Eot from '../assets/fonts/LibreFranklin-SemiBold/LibreFranklin-SemiBold.eot';
import LibreFranklin_SemiBold_svg from '../assets/fonts/LibreFranklin-SemiBold/LibreFranklin-SemiBold.svg#LibreFranklin-SemiBold';
import LibreFranklin_SemiBold_ttf from '../assets/fonts/LibreFranklin-SemiBold/LibreFranklin-SemiBold.ttf';
import LibreFranklin_SemiBold_woff from '../assets/fonts/LibreFranklin-SemiBold/LibreFranklin-SemiBold.woff';
import LibreFranklin_SemiBold_woff2 from '../assets/fonts/LibreFranklin-SemiBold/LibreFranklin-SemiBold.woff2';

import Montserrat_Bold_Eot from '../assets/fonts/Montserrat-Bold/Montserrat-Bold.eot';
import Montserrat_Bold_svg from '../assets/fonts/Montserrat-Bold/Montserrat-Bold.svg#Montserrat-Bold';
import Montserrat_Bold_ttf from '../assets/fonts/Montserrat-Bold/Montserrat-Bold.ttf';
import Montserrat_Bold_woff from '../assets/fonts/Montserrat-Bold/Montserrat-Bold.woff';
import Montserrat_Bold_woff2 from '../assets/fonts/Montserrat-Bold/Montserrat-Bold.woff2';

import Montserrat_Extra_Bold_Eot from '../assets/fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.eot';
import Montserrat_Extra_Bold_svg from '../assets/fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.svg#Montserrat-ExtraBold';
import Montserrat_Extra_Bold_ttf from '../assets/fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.ttf';
import Montserrat_Extra_Bold_woff from '../assets/fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.woff';
import Montserrat_Extra_Bold_woff2 from '../assets/fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.woff2';

import Montserrat_Light_Eot from '../assets/fonts/Montserrat-Light/Montserrat-Light.eot';
import Montserrat_Light_svg from '../assets/fonts/Montserrat-Light/Montserrat-Light.svg#Montserrat-Light';
import Montserrat_Light_ttf from '../assets/fonts/Montserrat-Light/Montserrat-Light.ttf';
import Montserrat_Light_woff from '../assets/fonts/Montserrat-Light/Montserrat-Light.woff';
import Montserrat_Light_woff2 from '../assets/fonts/Montserrat-Light/Montserrat-Light.woff2';

import Montserrat_Medium_Eot from '../assets/fonts/Montserrat-Medium/Montserrat-Medium.eot';
import Montserrat_Medium_svg from '../assets/fonts/Montserrat-Medium/Montserrat-Medium.svg#Montserrat-Medium';
import Montserrat_Medium_ttf from '../assets/fonts/Montserrat-Medium/Montserrat-Medium.ttf';
import Montserrat_Medium_woff from '../assets/fonts/Montserrat-Medium/Montserrat-Medium.woff';
import Montserrat_Medium_woff2 from '../assets/fonts/Montserrat-Medium/Montserrat-Medium.woff2';

import Montserrat_Regular_Eot from '../assets/fonts/Montserrat-Regular/Montserrat-Regular.eot';
import Montserrat_Regular_svg from '../assets/fonts/Montserrat-Regular/Montserrat-Regular.svg#Montserrat-Regular';
import Montserrat_Regular_ttf from '../assets/fonts/Montserrat-Regular/Montserrat-Regular.ttf';
import Montserrat_Regular_woff from '../assets/fonts/Montserrat-Regular/Montserrat-Regular.woff';
import Montserrat_Regular_woff2 from '../assets/fonts/Montserrat-Regular/Montserrat-Regular.woff2';

import Montserrat_SemiBold_Eot from '../assets/fonts/Montserrat-SemiBold/Montserrat-SemiBold.eot';
import Montserrat_SemiBold_svg from '../assets/fonts/Montserrat-SemiBold/Montserrat-SemiBold.svg#Montserrat-SemiBold';
import Montserrat_SemiBold_ttf from '../assets/fonts/Montserrat-SemiBold/Montserrat-SemiBold.ttf';
import Montserrat_SemiBold_woff from '../assets/fonts/Montserrat-SemiBold/Montserrat-SemiBold.woff';
import Montserrat_SemiBold_woff2 from '../assets/fonts/Montserrat-SemiBold/Montserrat-SemiBold.woff2';

import icons_Eot from '../assets/fonts/icons/icon.eot?nx73ch';
import icons_svg from '../assets/fonts/icons/icon.svg?nx73ch#icomoon';
import icons_ttf from '../assets/fonts/icons/icon.ttf?nx73ch';
import icons_woff from '../assets/fonts/icons/icon.woff?nx73ch';

export default createGlobalStyle`

    @font-face {
        font-family: 'LibreFranklin-Bold';
        src: url(${LibreFranklin_Bold_Eot}) format('embedded-opentype'),local('LibreFranklin-Bold'),
            url(${LibreFranklin_Bold_woff}) format('woff'), url(${LibreFranklin_Bold_ttf}) format('truetype'),
            url(${LibreFranklin_Bold_svg}) format('svg'),url(${LibreFranklin_Bold_woff2}) format('woff2');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'LibreFranklin-ExtraBold';
        src: url(${LibreFranklin_Extra_Bold_Eot});
        src: url(${LibreFranklin_Extra_Bold_Eot}) format('embedded-opentype'),
            url(${LibreFranklin_Extra_Bold_woff}) format('woff'), url(${LibreFranklin_Extra_Bold_ttf}) format('truetype'),
            url(${LibreFranklin_Extra_Bold_svg}) format('svg'),url(${LibreFranklin_Extra_Bold_woff2}) format('woff2');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'LibreFranklin-Light';
        src: url(${LibreFranklin_Light_Eot});
        src: url(${LibreFranklin_Light_Eot}) format('embedded-opentype'),
            url(${LibreFranklin_Light_woff}) format('woff'), url(${LibreFranklin_Light_ttf}) format('truetype'),
            url(${LibreFranklin_Light_svg}) format('svg'),url(${LibreFranklin_Light_woff2}) format('woff2');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'LibreFranklin-Medium';
        src: url(${LibreFranklin_Medium_Eot});
        src: url(${LibreFranklin_Medium_Eot}) format('embedded-opentype'),
            url(${LibreFranklin_Medium_woff}) format('woff'), url(${LibreFranklin_Medium_ttf}) format('truetype'),
            url(${LibreFranklin_Medium_svg}) format('svg'),url(${LibreFranklin_Medium_woff2}) format('woff2');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'LibreFranklin-Regular';
        src: url(${LibreFranklin_Regular_Eot});
        src: url(${LibreFranklin_Regular_Eot}) format('embedded-opentype'),
            url(${LibreFranklin_Regular_woff}) format('woff'), url(${LibreFranklin_Regular_ttf}) format('truetype'),
            url(${LibreFranklin_Regular_svg}) format('svg'),url(${LibreFranklin_Regular_woff2}) format('woff2');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'LibreFranklin-SemiBold';
        src: url(${LibreFranklin_SemiBold_Eot});
        src: url(${LibreFranklin_SemiBold_Eot}) format('embedded-opentype'),
            url(${LibreFranklin_SemiBold_woff}) format('woff'), url(${LibreFranklin_SemiBold_ttf}) format('truetype'),
            url(${LibreFranklin_SemiBold_svg}) format('svg'),url(${LibreFranklin_SemiBold_woff2}) format('woff2');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'Montserrat-Bold';
        src: url(${Montserrat_Bold_Eot});
        src: url(${Montserrat_Bold_Eot}) format('embedded-opentype'),
            url(${Montserrat_Bold_woff}) format('woff'), url(${Montserrat_Bold_ttf}) format('truetype'),
            url(${Montserrat_Bold_svg}) format('svg'),url(${Montserrat_Bold_woff2}) format('woff2');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'Montserrat-ExtraBold';
        src: url(${Montserrat_Extra_Bold_Eot});
        src: url(${Montserrat_Extra_Bold_Eot}) format('embedded-opentype'),
            url(${Montserrat_Extra_Bold_woff}) format('woff'), url(${Montserrat_Extra_Bold_ttf}) format('truetype'),
            url(${Montserrat_Extra_Bold_svg}) format('svg'),url(${Montserrat_Extra_Bold_woff2}) format('woff2');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'Montserrat-Medium';
        src: url(${Montserrat_Medium_Eot});
        src: url(${Montserrat_Medium_Eot}) format('embedded-opentype'),
            url(${Montserrat_Medium_woff}) format('woff'), url(${Montserrat_Medium_ttf}) format('truetype'),
            url(${Montserrat_Medium_svg}) format('svg'),url(${Montserrat_Medium_woff2}) format('woff2');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'Montserrat-Light';
        src: url(${Montserrat_Light_Eot});
        src: url(${Montserrat_Light_Eot}) format('embedded-opentype'),
            url(${Montserrat_Light_woff}) format('woff'), url(${Montserrat_Light_ttf}) format('truetype'),
            url(${Montserrat_Light_svg}) format('svg'),url(${Montserrat_Light_woff2}) format('woff2');
        font-weight: normal;
        font-style: normal;
    }  
    @font-face {
        font-family: 'Montserrat-Regular';
        src: url(${Montserrat_Regular_Eot});
        src: url(${Montserrat_Regular_Eot}) format('embedded-opentype'),
            url(${Montserrat_Regular_woff}) format('woff'), url(${Montserrat_Regular_ttf}) format('truetype'),
            url(${Montserrat_Regular_svg}) format('svg'),url(${Montserrat_Regular_woff2}) format('woff2');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'Montserrat-SemiBold';
        src: url(${Montserrat_SemiBold_Eot});
        src: url(${Montserrat_SemiBold_Eot}) format('embedded-opentype'),
            url(${Montserrat_SemiBold_woff}) format('woff'), url(${Montserrat_SemiBold_ttf}) format('truetype'),
            url(${Montserrat_SemiBold_svg}) format('svg'),url(${Montserrat_SemiBold_woff2}) format('woff2');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'icomoon';
        src: url(${icons_Eot});
        src: url('../assets/fonts/icons/icon.eot?a0rhfu#iefix') format('embedded-opentype'),
            url(${icons_woff}) format('woff'), url(${icons_ttf}) format('truetype'),
            url(${icons_svg}) format('svg');
        font-weight: normal;
        font-style: normal; 
        font-display: block;
    }
`;