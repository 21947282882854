import React from 'react';
import { ButtonCustom } from './styled';

interface IButton {
    typeButton?: string;
    onClick?: () => void;
    icon: string;
    colorDefault?: string;
    title: string;
    size?: string;
    children?: React.ReactNode;
    hoverDisabled?: boolean;
    className?: string;
    active?: boolean;
    disabled?: boolean;
    header?: boolean;
}

export const ButtonIcon = (props: IButton) => {
    const {
        typeButton,
        onClick,
        icon,
        colorDefault,
        title,
        size,
        children,
        hoverDisabled,
        className,
        active,
        disabled,
        header
    } = props;

    let classActive = '';
    if (active) {
        classActive = typeButton + '__active';
    }

    return (
        <ButtonCustom
            title={title}
            className={typeButton + ' ' + classActive + ' ' + size + ' ' + className}
            onClick={onClick}
            colorDefault={colorDefault}
            hoverDisabled={hoverDisabled}
            disabled={disabled}
            header={header}
            type="button"
        >
            <span className={'icon-' + icon}></span>
            {children && <p> {children} </p>}
        </ButtonCustom>
    );
};
