import React from 'react';
import { Breadcrumbs, ButtonGoBack, Layout, SectionContentPage, SEO } from '@componentsShared';
import {
    WrapperResolutions,
    Title,
    Icon,
    ContentResolutions,
    ContentImageText,
    MainContent
} from './styled';
import { CardResolution } from '@components';
import { graphql, useStaticQuery } from 'gatsby';
import Viewport from 'src/shared/hooks/viewport';
import { TResolution, TSections } from './types';

const Resolutions = () => {
    const viewport = Viewport();
    let crumbs = [
        {
            crumbLabel: 'Inicio',
            pathname: '/'
        },
        {
            crumbLabel: 'Resoluciones',
            pathname: '/resoluciones/'
        }
    ];
    const dataResolution = useStaticQuery(graphql`
        query resolutionsQuery {
            allContentfulPaginaInternaFooter(filter: { page: { eq: "Resoluciones" } }) {
                nodes {
                    title
                    pageIcon {
                        file {
                            url
                        }
                        title
                    }
                    imageText {
                        title
                        text {
                            raw
                        }
                        mobileText {
                            raw
                        }
                        mediaFile {
                            file {
                                url
                            }
                            title
                        }
                    }
                    secondarySection {
                        ... on ContentfulResoluciones {
                            title
                            resolution
                            resolutionFile {
                                file {
                                    url
                                }
                                title
                            }
                        }
                    }
                    metaData {
                        title
                        descripcion
                        keyWords
                    }
                }
            }
        }
    `);
    
    const {
        allContentfulPaginaInternaFooter: { nodes }
    } = dataResolution;
    const data = nodes[0];

    return (
        <Layout>
            <SEO
                title={data.metaData.title}
                description={data.metaData.descripcion}
                keyWords={data.metaData.keyWords}
                slug="/resoluciones/"
            />
            <WrapperResolutions>
                {viewport.mobile && <ButtonGoBack />}
                {!viewport.mobile && <Breadcrumbs crumbs={crumbs} />}
                <MainContent>
                    <Icon>
                        <img src={data.pageIcon.file.url} alt={data.pageIcon.title}></img>
                    </Icon>
                    <Title>{data.title}</Title>
                    {typeof window !== 'undefined' && window.innerWidth < 640 && (
                        <ContentImageText>
                            <SectionContentPage
                                text={data.imageText[0].mobileText.raw}
                                isEven={false}
                                img={data.imageText[0].mediaFile?.file}
                                video={
                                    data.imageText[0].video == undefined
                                        ? null
                                        : data.imageText[0].video
                                }
                                resolution={true}
                            />
                        </ContentImageText>
                    )}
                    {typeof window !== 'undefined' && window.innerWidth >= 640 && (
                        <ContentImageText>
                            {data.imageText.map((section: TSections, key: number) => (
                                <SectionContentPage
                                    key={key}
                                    text={section.text.raw}
                                    isEven={key % 2 === 0}
                                    img={section.mediaFile?.file}
                                    video={section.video == undefined ? null : section.video}
                                    resolution={true}
                                />
                            ))}
                        </ContentImageText>
                    )}
                    <ContentResolutions>
                        {data.secondarySection.map((element: TResolution, index: number) => {
                            return (
                                <CardResolution
                                    key={index}
                                    title={element.title}
                                    text={element.resolution}
                                    file={element.resolutionFile}
                                />
                            );
                        })}
                    </ContentResolutions>
                </MainContent>
            </WrapperResolutions>
        </Layout>
    );
};

export default Resolutions;
