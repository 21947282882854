import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';
export const WrapperLegal = styled.div`
    width: 100%;
    justify-content: center;
    display: grid;

    width: 100%;
    height: auto;
    margin: -12px auto 75px;
    ${breakpoint('lg')({
        maxHeight: '800px',
        maxWidth: '949px',
        margin: 'auto'
    })}
`;
export const Title = styled.h2`
    font-size: 18px;
    justify-content: center;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    color: ${(props) => props.theme.colors.accents.base.yellow};
    width: 100%;
    text-align: center;
    position: relative;
    font-size: 20px;
    text-align: center;
    &:before,
    &:after {
        position: absolute;
        top: 51%;
        overflow: hidden;
        width: 38%;
        margin-inline: 30px;
        height: 1px;
        content: '\a0';
        background-color: #c5c3c9;
    }

    &:before {
        margin-left: -40%;
        text-align: right;
    }
    &:after {
        margin-left: 2%;
    }
    ${breakpoint('md')`
    &:before,
    &:after {
        width: 43%;
    };
    &:before {
      margin-left: -45%;
  }

    `}
`;

export const ContentLegal = styled.div`
    width: 100%;
    padding-top: 30px;
    justify-content: center;
    display: grid;
    padding-bottom: 40px;

    ${breakpoint('md')`
      display:flex;
      flex-flow: wrap;
    padding-top: 58px;

    `}
`;
export const ContentDropDownCard = styled.div``;
