import { SectionContentPage } from 'src/shared/components/molecules/sectionContentPage';
import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
`;

export const HeaderPage = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0px 2px 20px 2px;
    border-bottom: 1px solid ${(props) => props.theme.colors.disabled.gray};

    & > img {
        margin-bottom: 14px;
        display: none;

        ${breakpoint('md')({
            display: 'block'
        })}
    }
`;

export const TitlePage = styled.h1`
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 120%;

    span {
        margin-right: 5px;
        color: ${(props) => props.theme.colors.accents.base.yellow};
    }
`;

export const ContainerVideo = styled.div`
    width: 100%;
    height: 100%;
    margin: 20px 0;
    display: flex;
    justify-content: center;

    ${breakpoint('sm')`
        display: block;
    `};
`;

export const Content = styled.div`
    weight: 100%;
    height: 100%;
`;

export const SectionContentPageMobileAbout = styled(SectionContentPage)`

    ${breakpoint('s')`
        display: flex;
        flex-direction: column-reverse;
        p {
            width: 60%;
            margin: auto;
        }
        img {
            width: 70% !important;
        }
    `};
`