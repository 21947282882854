import React, { useEffect, useState } from 'react';
import { ContainerToast, TextToast } from './styled';
interface IProps {
    icon: string;
    text: string;
    type?: string;
    html?: string;
    duracion?: number;
}

export const Toast = (props: IProps) => {
    const { icon, text, type, html, duracion } = props;
    const [state, setState] = useState(false);

    useEffect(() => {
        if ('permissions' in navigator) {
            navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                if (result.state === 'granted') {
                    setState(true);
                } else {
                    setState(false);
                }
                result.onchange = () => {
                    if (result.state === 'granted') {
                        setState(true);
                    } else {
                        setState(false);
                    }
                };
            });
        }
    }, []);
    return (
        <ContainerToast state={state} duracion={duracion} className={type}>
            <TextToast className={type}>
                <span className={'icon-' + icon}></span>
                {html ? (
                    <p dangerouslySetInnerHTML={{ __html: html }} />
                ): (
                    <p>{text}</p>
                )}
            </TextToast>
        </ContainerToast>
    );
};
