import { Redirect } from '@componentsShared';
import React from 'react';
import Viewport from 'src/shared/hooks/viewport';
import { ContentErrorLogin, ErrorLoginContainer } from './styled';
import { IProps } from './type';

export const ErrorLogin = (props: IProps) => {
    const { message } = props;

    const getMessage = (message: String) => {
        const ms = message.trim();
        return ms.endsWith(".") ? ms : ms+'.';
    }

    const viewport = Viewport();

    return (
        <ErrorLoginContainer>
            <span className="icon-face-sad"></span>
            <ContentErrorLogin>
                <p>
                    <span>¡Ups!</span>
                    {
                        !message?
                        ' parece que no estás registrado con nosotros. ':
                        ` ${getMessage(message)} `
                    }
                    {!viewport.mobile && (
                        <Redirect url="registro/" text="Quiero registrarme"></Redirect>
                    )}
                </p>
                {viewport.mobile && <Redirect url="registro/" text="Quiero registrarme"></Redirect>}
            </ContentErrorLogin>
        </ErrorLoginContainer>
    );
};
