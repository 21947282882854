import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';

export const DateContainer = styled.div`
    width: 100%;
    display: flex;
    font-family: ${(props) => props.theme.fonts.lf_regular};

    label {
        font-size: 16px;
        line-height: 24px;
        color: #8e8c94;
        padding-left: 16px;
    }

    ${breakpoint('md')`
        margin-left: 0px;
    `}
`;
