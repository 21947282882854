import { useState, useEffect } from 'react';

export default function Viewport() {
    const [viewport, setViewport] = useState({
        mobile: false,
        tablet: false,
        desktop: false
    });

    const updateDimensions = () => {
        if (window.innerWidth > 0 && window.innerWidth < 767) {
            setViewport({ mobile: true, tablet: false, desktop: false });
        }
        if (window.innerWidth > 768 && window.innerWidth < 1024) {
            setViewport({ mobile: false, tablet: true, desktop: false });
        }
        if (window.innerWidth > 1025) {
            setViewport({ mobile: false, tablet: false, desktop: true });
        }
    };

    useEffect(() => {
        updateDimensions();

        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    return viewport;
}
