import styled from 'styled-components';
import breakpoint from "styled-components-breakpoint";

export const WrapperResults = styled.section`
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
    background: ${props => props.theme.colors.backgrounds.gray500};
    border: 1px solid ${props => props.theme.colors.principal.greenBase};
    box-sizing: border-box;
    box-shadow: 0px 0px 18px rgba(0, 212, 85, 0.5);
    height: 330px;
    height: 270px;
    padding: 18px 0 35px;
    position: relative;
    ${breakpoint('xl')`
        border-radius: 24px;
        top: -42px;
        box-shadow: 0px 0px 8px rgba(0, 212, 85, 0.5);
        height: 236px;
        padding: 25px 0px 35px;
    `}
`;

export const ResultTopTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    order: 1;
    padding: 0 30px;
    h2 {
        font-size: 14px;
        line-height: 16px;
        font-family: ${props => props.theme.fonts.mon_bold};
        color: ${props => props.theme.colors.accents.base.yellow};
    }
    span {
        font-size: 14px;
        line-height: 22px;
        font-family: ${props => props.theme.fonts.lf_regular};
        color: ${props => props.theme.colors.text.gray};
        margin-top: 6px;
        &:first-letter {
            text-transform: uppercase;
        }
    }
    ${breakpoint('xl')`
        width: 40%;
        align-items: flex-start;
        h2 {
            font-size: 20px;
            line-height: 22px;
        }
        span {
            font-size: 16px;
            margin-top: 0;
        }
    `}
`;

export const ResultTopViewAll = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    order: 3;
    padding: 0 30px;
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: ${props => props.theme.fonts.mon_bold};
        font-size: 17px;
        line-height: 20px;
        color: ${props => props.theme.colors.text.gray};
        text-decoration: none;
        transition: all 0.5s ease-in-out;
        border: 2px solid ${props => props.theme.colors.principal.greenNeon};
        box-sizing: border-box;
        border-radius: 16px;
        padding: 13px 15px 13px 20px;
        width: 293px;
        &:hover {
            transform: scale(1.1);
        }
        span {
            margin-left: 12px;
            font-size: 24px;
            line-height: 22px;
            color: ${props => props.theme.colors.principal.greenNeon};
        }
    }
    ${breakpoint('xl')`
        width: 40%;
        order: 2;
        justify-content: flex-end;
        a {
            width: initial;
            border: none;
            padding: 0;
            font-size: 14px;
            font-family: ${props => props.theme.fonts.lf_bold};
            justify-content: initial;
        }
        span {
            font-size: 18px;
        }
    `}
`;

export const ResultBottom = styled.div`
    display: flex;
    order: 2;
    width: 100%;
    margin: 35px 0 50px;
    .slick-slider {
        .slick-list {
            margin: 0;
            .slick-track {
            }
        }
    }
    ${breakpoint('xl')`
        order: 3;
        margin: 0;
        .slick-slider {
            overflow: hidden;
            .slick-list {
                margin: 25px 0 0 50px;
                padding: 0 60px 0 35px !important;
                .slick-track {
                    padding: 5px;
                }
            }
        }
    `}
`;

export const ResultBottomBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    background: #272231;
    border: 1px solid ${props => props.theme.colors.accents.base.yellow};
    box-sizing: border-box;
    box-shadow: 0px 0px 4px 1px rgba(255, 224, 0, 0.25);
    border-radius: 24px;
    position: relative;
    width: 175px !important;
    height: 95px;
    padding: 17px 17px 25px 28px;
    margin-right: 50px;
    margin-left: 5px;
    &.astro {
        padding: 10px 18px 27px 50px;
    }
    ${breakpoint('xl')`
        width: 225px !important;
        height: 109px;
        padding: 20px 18px 27px 50px;
        box-shadow: 0px 2px 7px 3px rgba(255, 224, 0, 0.25);
        margin-right: 55px;
        margin-left: 0;
        // &:last-child {
        //     margin-right: 0;
        // }
    `}
`;

export const BoxLotteryImg = styled.img`
    position: absolute;
    left: -36px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    object-fit: contain;
    object-position: center;
    height: 55px;
    width: 55px;
    border-radius: 50%;
    background: #fff;
    ${breakpoint('xl')`
        height: 72px;
        width: 72px;
    `}
`;

export const BoxLotteryName = styled.p`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    font-family: ${props => props.theme.fonts.mon_bold};
    font-size: 14px;
    line-height: 22px;
    color: ${props => props.theme.colors.text.gray};
    white-space: nowrap;
`;

export const BoxLotteryNumber = styled.div`
    display: flex;
    justify-content: space-between;
    width: 118px;
    margin-top: 6px;
    ${breakpoint('xl')`
        width: 155px;
        margin-top: 7px;
    `}
`;

export const CircleNumber = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #fff;
    width: 24px;
    height: 24px;
    font-size: 18px;
    line-height: 22px;
    color: ${props => props.theme.colors.backgrounds.gray500};
    font-family: ${props => props.theme.fonts.lf_bold};
    ${breakpoint('xl')`
       width: 34px;
        height: 34px;
        font-size: 22px;
    `}
`;

export const IsAstro = styled.span`
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    line-height: 22px;  
    color: ${props => props.theme.colors.accents.base.yellow};
    font-family: ${props => props.theme.fonts.mon_bold};
    position: relative;
    top: 2px;
    text-transform: capitalize;
`;

export const ArrowCustom = styled.div`
    display: flex;
    cursor: pointer;
    position: absolute;
    top: 25px;
    margin: auto 0;
    z-index: 5;
    height: 50px;
    align-items: center;
    span {
        transition: all .3s ease-in-out;
        color: ${props => props.theme.colors.principal.greenNeon};
        font-size: 16px;
        font-weight: bold;
        border-radius: 50%;
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(29, 24, 40, .8);
    }
    &.prev {
        left: 2%;
    }
    &.next {
        right: 2%;
        transform: rotate(180deg);
    }
    ${breakpoint('lg')`
        &.prev {
            left: 3%;
        }
        &.next {
            right: 3%;
        }
    `}
    ${breakpoint('xxl')`
        &.prev {
            left: 15px;
        }
        &.next {
            right: 15px;
        }
    `}
`;

export const NotResults = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 1;
    font-family: ${props => props.theme.fonts.mon_bold};
    font-size: 20px;
`;
