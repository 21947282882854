import React, { useState } from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import es from 'date-fns/locale/es';
registerLocale('es', es)

import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { ContainerDatePicker } from './styled';
import calendar from './calendar.svg';

export const Calendar = (props: any) => {
    const { handleChangeByDate, searchByDate } = props;

    const isWeekday = (date: any) => {
        const day = date.getDay(date);
        return day !== 0 && day !== 1 && day !== 2 && day !== 4 && day !== 5;
    };
    
    let date = new Date();
    date.setMonth(date.getMonth() - 12);
    
    return (
        <ContainerDatePicker>
            <DatePicker
                className='calendar'
                dateFormat="dd/MM/yyyy"
                placeholderText="DD/MM/AA"
                onChange={handleChangeByDate}
                selected={searchByDate}
                filterDate={isWeekday}
                minDate={date}
                maxDate={new Date()}
                fixedHeight
                calendarStartDay={0}
                formatWeekDay={nameOfDay => nameOfDay.substr(0,1)}
                locale='es'
            />
            <span className='icon-calendar'></span>
        </ContainerDatePicker>
    );
};
