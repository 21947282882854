import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { graphql, useStaticQuery } from 'gatsby';
import {
    BoxLottery,
    BoxLotteryImg,
    BoxLotteryTitle,
    ButtonDelete,
    LotteryTimerAct,
    ContentFavoritesNumber,
    BoxHover,
    BoxHoverTimeClose,
    BoxHoverFavorite
} from './styled';
import { ButtonIcon, Timer } from '@componentsShared';
import { ILottery } from 'src/shared/context/superchance';
import moment from 'moment';
import { TooltipCustom } from '../../atoms/inputCustom/styled';

import Viewport from 'src/shared/hooks/viewport';

interface IPropsLottery {
    dataLottery?: ILottery | any;
    onClick?: () => void;
    handlerEliminate?: (item: ILottery) => void;
    lotteryActive?: boolean
    deleteCard?: boolean;
    showDetails?: boolean;
    className?: string;
    handlerSelected?: (lottery: ILottery) => void;
    date?: string;
}

interface ILogo {
    abreviatura: string;
    nombre: string;
    imagen: {
        file: {
            url: string;
        };
    };
}

export const LotteryBox = (props: IPropsLottery) => {
    const [timers, setTimers] = useState({
        hours: '0',
        minutes: '0',
        seconds: '0'
    });
    const [seconds, setSeconds] = useState(0);
    const [flagTime, setFlagTime] = useState(false);

    const viewport = Viewport()

    const {
        dataLottery: { desLoteria, horaCierre, abreviatura, numeroFavorito },
        onClick,
        lotteryActive,
        deleteCard,
        handlerEliminate,
        showDetails,
        className,
        handlerSelected,
        dataLottery,
        date
    } = props;

    const logoLotteries = useStaticQuery(graphql`
        query LogosLotteries {
            allContentfulLoterias {
                nodes {
                    abreviatura
                    nombre
                    imagen {
                        file {
                            url
                            fileName
                        }
                    }
                }
            }
        }
    `);

    const arrayImagesCms: ILogo[] = logoLotteries.allContentfulLoterias.nodes;

    const now = moment();
    now.format('HHmm');
    const hourCurrent = now.hour();
    const minuteCurrent = now.minute();

    const handlerSetFlagTime = (valueFlag: boolean) => {
        setFlagTime(valueFlag);
    };

    const eliminateCard = () => {
        if (handlerEliminate !== undefined) {
            handlerEliminate(props.dataLottery);
        }
    };

    useEffect(() => {
        if (timers.minutes === '00' && timers.seconds === '00') {
            eliminateCard()
            setTimers({
                hours: '0',
                minutes: '0',
                seconds: '0'
            })
            setFlagTime(false)
        }
    }, [timers])

    const timeDifferences = (hourClose: number, minuteClose: number) => {
        const hourDif = hourClose - hourCurrent;
        if (hourDif < 1) {
            handlerSetFlagTime(true);
            const minuteDif = minuteClose - minuteCurrent;
            let segundos = minuteDif * 60;
            setSeconds(segundos);
            if (minuteDif <= 0) {
                handlerSetFlagTime(false);
                segundos = 0;
            }
        }

        if (date) {
            let today = moment(new Date());
            let dateButt = moment(date, 'DD-MM-YYYY');
            dateButt > today && handlerSetFlagTime(false);
        }
    };

    const arrayHor = horaCierre.split(':');
    const hor = parseInt(arrayHor[0]);
    const min = parseInt(arrayHor[1]);

    useEffect(() => {
        timeDifferences(hor, min);
    }, []);
    const lotteryActiveClass = lotteryActive ? 'lotteryActive' : '';
    const lotteryTimerClass = flagTime ? 'activeTimer' : '';
    const deleteCardClass = deleteCard ? 'deleteCard' : '';
    const showDetailsClass = showDetails ? ' open ' : ' close ';

    const filterImageLottery = (arr: ILogo[], param: string) => {
        let logoResult = arr.filter((lot) => lot.abreviatura === param);
        return logoResult[0];
    };

    const logo: ILogo = filterImageLottery(arrayImagesCms, abreviatura);

    return (
        <>
            <BoxLottery
                data-tip
                data-iscapture
                data-for={'value' + dataLottery.ideLoteria}
                className={
                    lotteryActiveClass +
                    ' ' +
                    lotteryTimerClass +
                    ' ' +
                    deleteCardClass +
                    ' ' +
                    className
                }
                onClick={() => {
                    if (onClick !== undefined) {
                        onClick();
                    }
                    if (handlerSelected !== undefined) {
                        handlerSelected(dataLottery);
                    }
                }}
            >
                {deleteCard && (
                    <ButtonDelete>
                        <ButtonIcon
                            title="Eliminar"
                            icon="cross-small"
                            onClick={eliminateCard}
                        ></ButtonIcon>
                    </ButtonDelete>
                )}
                <BoxLotteryImg className={lotteryTimerClass}>
                    {logo !== undefined ? (
                        <img src={logo.imagen.file.url} alt={logo.nombre} />
                    ) : (
                        <img
                            src="https://c8.alamy.com/compes/kd3wf9/icono-de-rueda-de-loteria-kd3wf9.jpg"
                            alt="img lotería genérica"
                        />
                    )}

                    {lotteryActive && !flagTime && (
                        <ContentFavoritesNumber>
                            <h2>Números favoritos</h2>
                            <h3>{numeroFavorito}</h3>
                        </ContentFavoritesNumber>
                    )}

                    {flagTime && (
                        <LotteryTimerAct>
                            <p>Cierra en</p>
                            <span>
                                {timers.minutes}:{timers.seconds}
                            </span>
                            <Timer timeSeconds={seconds} setTimers={setTimers} />
                        </LotteryTimerAct>
                    )}
                </BoxLotteryImg>
                <BoxLotteryTitle>{desLoteria?.toLowerCase()}</BoxLotteryTitle>

                <BoxHover className={showDetailsClass}>
                    <h4>{desLoteria?.toLowerCase()}</h4>
                    <BoxHoverTimeClose>
                        <p>Cierra a las</p>
                        <span>{horaCierre}</span>
                    </BoxHoverTimeClose>
                    <BoxHoverFavorite>
                        <p>Favoritos</p>
                        <span>{numeroFavorito}</span>
                    </BoxHoverFavorite>
                </BoxHover>
            </BoxLottery>

            {
                className === 'disabled' &&
                    <TooltipCustom
                        multiline={true}
                        id={'value' + dataLottery.ideLoteria}
                        type="light"
                        place="bottom"
                        effect="solid"
                        event='mouseenter click'
                        eventOff='mouseleave scroll mousewheel blur'
                    >
                        Esta lotería <span>no está disponible </span>para el número que elegiste.
                    </TooltipCustom>
            }
        </>
    );
};
