import React, { useEffect, useState } from 'react';
import { Redirect } from '@componentsShared';
import { Button } from '@componentsShared';
import Viewport from 'src/shared/hooks/viewport';
import {
    BackBox,
    CardGame,
    ContainerGames,
    ContentTextValue,
    GroupText,
    Points,
    TitleGames,
    WrapperGames
} from './styled';
import { IGames, IPropsGames } from './types';


export const GamesHome = (props: IPropsGames) => {
    const viewport = Viewport();
    const { games, filter } = props;
    const [dataGame, setDataGame] = useState<IGames[]>(games)


    useEffect(() => {
        if(filter){
            const newData = games.filter((game) => game === filter);
            setDataGame(newData)
        }else{
            setDataGame(games)
        }
    }, [filter])
    return (

        <WrapperGames>
            <TitleGames>
                ¡Diviértete jugando mientras <span>ganas millones en premios!</span>
            </TitleGames>
            {viewport.desktop && <BackBox></BackBox>}
            <ContainerGames>
                    {dataGame.map((game: any) => (
                        <CardGame key={game.id} className={game.state ? 'Enabled' : 'Disabled'}>
                            <img src={game.image.fluid.src} alt={game.image.description} />
                            <GroupText className={game.state ? '' : 'Disabled'}>
                                <Points>
                                    {game.state && (
                                        <p>
                                           <span>{game.points}</span>
                                        </p>
                                    )}
                                </Points>
                                <b>{game.recomendationText}</b>
                            </GroupText>
                            <ContentTextValue className={game.state ? '' : 'shortly'}>
                                <p>{game.betFrom}</p>
                                {game.state ? <span>{game.win}</span> : <strong>¡Próximamente!</strong>}
                            </ContentTextValue>
                            <Redirect url={game.url || ''}>
                                <Button
                                    text="Jugar"
                                    disabled={!game.state}
                                    typeButton="btnDisableBlack"
                                ></Button>
                            </Redirect>
                        </CardGame>
                    ))}
                <Redirect url='juegos'>
                </Redirect>
            </ContainerGames>
        </WrapperGames>
    );
};
