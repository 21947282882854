import { ErrorSpan } from '@componentsShared';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Container,
    Icon,
    InputCustomStyle,
    InputCustomContainer,
    InputCustomLabel,
    TooltipCustom,
    SecondaryIcon
} from './styled';
import { IProps } from './types';

export const InputCustom = (props: IProps) => {
    const {
        type,
        placeholder,
        name,
        label,
        required,
        disabled,
        icon,
        maxLength,
        minLength,
        pattern,
        className,
        tooltip,
        condition,
        message,
        secondaryIcon,
        color
    } = props;

    const { register, formState } = useFormContext();

    let regExt: RegExp = /[a-zA-Z 0-9]/;
    let patternValue: RegExp = pattern !== undefined ? pattern.value : regExt;
    let patternMessage = pattern !== undefined ? pattern.message : '';

    return (
        <Container>
            {label && (
                <InputCustomLabel htmlFor={'#' + name}>
                    {label}{' '}
                    {tooltip?.isShow && (
                        <span
                            className="icon-info"
                            data-tip
                            data-event="click focus"
                            data-for={name}
                            data-iscapture
                        />
                    )}
                </InputCustomLabel>
            )}
            {tooltip?.isShow && (
                <TooltipCustom multiline={true} id={name} type="light" place="right" effect="solid">
                    {tooltip.content}
                </TooltipCustom>
            )}
            <InputCustomContainer>
                <InputCustomStyle
                    type={type}
                    placeholder={placeholder}
                    {...register(name, {
                        required: required,
                        maxLength: maxLength,
                        minLength: minLength,
                        pattern: {
                            value: patternValue,
                            message: patternMessage
                        },
                        validate: (value) => value === condition || message
                    })}
                    className={(formState.errors[name]?.message && 'error') + ' ' + className}
                    id={'#' + name}
                    typeError={formState.errors[name]?.type === 'pattern' ? 'alert' : undefined}
                    name={name}
                    disabled={disabled}
                    position={icon?.position}
                    autoComplete="off"
                    onWheel={(e) => e.currentTarget.blur()}
                    onKeyDown={(e) => {
                        if(e.keyCode === 38 || e.keyCode === 40) {
                            e.preventDefault()
                        }
                    }}
                />
                {icon && (
                    <Icon
                        className={'icon-' + icon?.icon}
                        disabled={disabled}
                        position={icon?.position}
                        onClick={icon?.onClick}
                        type="button"
                    ></Icon>
                )}

                {secondaryIcon && (
                    <SecondaryIcon
                        className={'icon-' + secondaryIcon?.icon}
                        position={icon?.position}
                        color={color}
                    ></SecondaryIcon>
                )}
            </InputCustomContainer>
            {formState.errors[name]?.message && (
                <ErrorSpan
                    text={formState.errors[name]?.message}
                    type={formState.errors[name]?.type === 'pattern' ? 'alert' : undefined}
                />
            )}
        </Container>
    );
};
