import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';
import { TContainer } from './types';

export const ContentBanner = styled.div<TContainer>`
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    border-radius: 16px;
    margin-bottom: 2rem;

    ${({ image }) => (image ? `background: url(${image});` : ` background: #3e3a47;`)}

    background-repeat: no-repeat;
    background-size: cover;

    // ${breakpoint('sm')`
    //     background-size: auto;
    // `}

    ${breakpoint('md')`
        min-height: 100px;
    `}

    ${breakpoint('lg')`
        min-height: 140px;
    `}

    // Closed Action
    &.closed {
        transition: 0.1s ease-out;
        height: 28px;
        min-height: 28px;
        justify-content: flex-end;
        background: #3e3a47;
        width: 100%;

        p {
            position: absolute;
            margin: 0;
            font-size: 14px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 135px;

            ${breakpoint('lg')`
                font-size: 16px;
                width: 500px;
            `}
        }

        img,
        a {
            display: none;
            margin: 0;
        }

        .buttonContainer {
            top: 0;
        }

        .containerImage {
            margin: 0;
            display: none;

            ${breakpoint('md')`
                display: none;
            `}
        }

        .spacer {
            margin: 0;
            display: none;
            ${breakpoint('md')`
                display: none;
                
            `}
        }

        .containerInfo {
            display: flex;
            justify-content: center;
            align-items: center;

            ${breakpoint('lg')`
                text-align: center;
            `}
        }

        ${breakpoint('md')`
            height: 34px;
            justify-content: space-between;
        `}

        ${breakpoint('lg')`
            height: 40px;
            justify-content: end;
        `}
    }
`;

export const ContainerBannerImage = styled.div`
    margin-left: 0.5rem;
    position: relative;
    border-radius: 16px;
    top: -62px;

    ${breakpoint('md')`
        top: -130px 
    `}

    img {
        position: relative;
        width: 137px;
        height: 183px;
        top: 7px;

        ${breakpoint('md')`
            top: 24px;
        `}

        ${breakpoint('lg')`
            width: 203px;
            height: 271px;
            top: 10px;
        `}
    }
`;

export const BannerInfo = styled.div`
    width: 90%;
    margin: 1rem 1rem 1rem 1rem;
    font-size: 14px;
    padding-left: 12px;

    ${breakpoint('sm')`
        width: 100%;
        margin: 0 1rem;
    `}

    ${breakpoint('md')`
        margin: auto;
    `}

    ${breakpoint('lg')`
        margin: 1rem 0.5rem 1rem 1rem;
    `}

    

    p {
        font-family: ${(props) => props.theme.fonts.mon_bold};
        line-height: 22.4px;

        ${breakpoint('sm')`
            font-size: 14px;
            line-height: 20px;
            margin: 1rem 0rem;
        `}

        ${breakpoint('md')`
            margin: 0 1rem;
        `}

        ${breakpoint('lg')`
            font-size: 26px;
            line-height: 31.2px;
            margin: 1rem 2rem;
        `}

        ${breakpoint('xl')`
            margin: 1rem 2.5rem 1rem 3rem;
        `}
    }

    b {
        color: #ffe000;
    }
`;

export const CrossButton = styled.div<TContainer>`
    position: relative;
    top: 0;
    font-size: 13px;

    button {
        height: fit-content;
        ${(props: TContainer) => props.isShowingBanner ? `
            position: absolute;
            top: -24px;
            right: 10px;
            margin: 0;
        ` : `
            position: absolute;
            top: 7px;
            right: 10px;
            margin: 0;
        `}
    }

    ${breakpoint('xs')`
        top: 0rem;

        button {
            right: 15px;
            margin: 0;
        }
    `}

    ${breakpoint('md')`
        top: 0.2rem;

        span{
            font-size: 12px;
        }

        button {
            position: relative;
            top: 10px;
            right: 15px;
            margin: 0;
        }
    
    `}

    ${breakpoint('lg')`
        top: 0rem;
         button {
            top: 14px;
        }

    `}


    button span {
        font-size: 13px;
    }
`;

export const BannerLink = styled.a`
    position: relative;
    text-decoration: none;
    color: #0df96c;
    background: none;
    font-family: ${(props) => props.theme.fonts.lf_light};
    font-size: 10px;
    border: none;
    margin-top: 7px;
    padding-bottom: 5px;
    border-bottom: 1px solid #0df96c;
    cursor: pointer;
    display: inline-block;

    ${breakpoint('sm')`
        position: relative;
        margin-top: 0.5rem;
        display: inline-block;
    `}

    ${breakpoint('md')`
        position: absolute;
        font-size: 10px;
        // top: 4rem;
        bottom: 0.5rem;
        right: 1rem;
    `}

    ${breakpoint('lg')`
        position: absolute;
        font-size: 14px;
        // top: 6rem;
        bottom: 0.8rem;
        right: 1rem;
    `}
`;

export const BannerSpacer = styled.div`
    width: 137px;
    position: relative;
    margin: 2.5rem;

    ${breakpoint('md')`
        margin: 1rem 3rem;
    `}
`;
