import React, { useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Carousel, Timer } from '@componentsShared';
import Viewport from 'src/shared/hooks/viewport';
import logoVisa from 'src/assets/images/logo-visa.png';
import logoMastercard from 'src/assets/images/logo-mastercard.png';
import logoPse from 'src/assets/images/logo-pse.png';
import logoOki from 'src/assets/images/logo-oki.png';
import { IArrow, IMetodoDePago, IPromotion, IPropsPromotion } from './types';
import { graphql, useStaticQuery } from 'gatsby';

import {
    WrapperPromotion,
    PromotionTitle,
    ContentSliderPromotions,
    BoxPromotion,
    BoxPromotionIcon,
    ArrowCustom,
    BoxPromotionDesc,
    BoxClockPromotion,
    BoxPromotionGame,
    ImageGame,
    LinkGame,
    ExpectToPlayOnlyMobile,
    YourPayments,
    ContentYourPayments,
    LogosYourPayments
} from './styled';
import siteUrl from '@utils/siteUrl';

const SampleArrowCustom = (props: IArrow) => {
    const { onClick, side } = props;
    return (
        <ArrowCustom onClick={onClick} className={side}>
            <svg width="45" height="45" viewBox="0 0 45 45" fill="none">
                <circle r="22.5" transform="matrix(-1 0 0 1 22.5 22.5)" fill="#272231" />
                <path
                    d="M18.25 19.8505L23.9862 14.118C24.2242 13.9028 24.5359 13.7873 24.8566 13.7956C25.1774 13.8038 25.4827 13.9351 25.7094 14.1622C25.936 14.3894 26.0667 14.6949 26.0742 15.0157C26.0818 15.3365 25.9657 15.6479 25.75 15.8855L20.0175 21.618C19.7831 21.8524 19.6515 22.1703 19.6515 22.5017C19.6515 22.8332 19.7831 23.1511 20.0175 23.3855L25.75 29.118C25.9844 29.3525 26.116 29.6706 26.1158 30.0022C26.1157 30.3338 25.9839 30.6517 25.7493 30.8861C25.5148 31.1205 25.1967 31.2521 24.8651 31.252C24.5336 31.2519 24.2156 31.12 23.9812 30.8855L18.25 25.153C17.547 24.4497 17.152 23.4961 17.152 22.5017C17.152 21.5074 17.547 20.5537 18.25 19.8505Z"
                    fill="#3E3A47"
                />
            </svg>
        </ArrowCustom>
    );
};

export const DayPromotionHome = (props: IPropsPromotion) => {
    const viewport = Viewport();
    const { promotionsBanner } = props;




    const dataMetodosPago = useStaticQuery(graphql`
        query metodosPago {
            allContentfulHome {
                edges {
                    node {
                        bannerMetodosDePago {
                            descripcion {
                                raw
                            }
                            imagenesMetodosDePago {
                                description
                                file {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }

    `);

    const {
        allContentfulHome: {
            edges: [
                {
                    node: { bannerMetodosDePago }
                }
            ]
        }
    } = dataMetodosPago;


    console.log(bannerMetodosDePago);






    const [timersPromotion, setTimersPromotion] = useState({
        hours: '0',
        minutes: '0',
        seconds: '0'
    });

    const customSettings = {
        centerMode: viewport.mobile ? true : false,
        centerPadding: '30px',
        dots: viewport.mobile ? false : true,
        infinite: viewport.mobile ? false : true,
        autoplay: viewport.mobile ? false : true,
        speed: 1000,
        autoplaySpeed: 10000,
        slidesToShow: viewport.mobile ? 1 : viewport.tablet ? 2 : 1,
        slidesToScroll: 1,
        nextArrow: viewport.mobile ? false : <SampleArrowCustom side="next" />,
        prevArrow: viewport.mobile ? false : <SampleArrowCustom side="prev" />
    };

    return (
        <WrapperPromotion>
            {/* <PromotionTitle>
                Promociones <span> del día</span>
            </PromotionTitle> */}

            {/* <ContentSliderPromotions> */}
            {/* <Carousel customSettings={customSettings}> */}
            {/* {promotionsBanner.map((ban: IPromotion, index) => {
                        const { icon, title, promotionText, secundaryText, gameImage } = ban;
                        return (
                            <BoxPromotion key={index}>
                                {viewport.desktop && (
                                    <BoxPromotionIcon>
                                        <img src={icon.file.url} alt="icon" />
                                    </BoxPromotionIcon>
                                )} */}

            {/* <BoxPromotionDesc> */}
            {/* {viewport.desktop && <h2>{title}</h2>} */}
            {/* 
                                    {documentToReactComponents(JSON.parse(promotionText.raw))}
                                    <h3>{secundaryText}</h3> */}

            {/* TODO: Configurar el clock con el campo que viene desde el contentful */}
            {/* <BoxClockPromotion>
                                        <h4>
                                            <span>{timersPromotion.hours}</span>:
                                            <span>{timersPromotion.minutes}</span>:
                                            <span>{timersPromotion.seconds}</span>
                                        </h4>
                                        <h5>
                                            <span>Horas</span>
                                            <span>Minutos</span>
                                            <span>Segundos</span>
                                        </h5>
                                        {!viewport.mobile && (
                                            <p>*Solo aplica para compras en Súper Chance</p>
                                        )}
                                    </BoxClockPromotion> */}

            {/* {viewport.mobile && (
                                        <ExpectToPlayOnlyMobile>
                                            <h3>¿Qué esperas para jugar?</h3>
                                            <LinkGame href="/">Jugar</LinkGame>
                                        </ExpectToPlayOnlyMobile>
                                    )} */}
            {/* </BoxPromotionDesc> */}

            {/* <BoxPromotionGame>
                                    {viewport.desktop && <h3>¿Qué esperas para ganar?</h3>}
                                    <ImageGame src={gameImage.fluid.src} alt="imagen del juego" />
                                    {viewport.desktop && (
                                        <LinkGame href={siteUrl + '/juegos/super-chance'}>
                                            Jugar
                                        </LinkGame>
                                    )}
                                </BoxPromotionGame> */}
            {/* </BoxPromotion>
                        );
                    })} */}
            {/* </Carousel> */}
            {/* </ContentSliderPromotions> */}

            <YourPayments>
                <ContentYourPayments>
                    {documentToReactComponents(JSON.parse(bannerMetodosDePago.descripcion.raw))}


                    <LogosYourPayments>
                        {bannerMetodosDePago.imagenesMetodosDePago.map((item: IMetodoDePago, index: number) => {
                            return(
                                <img key={`image-'${index}'`} src={item.file.url} alt={item.description} />

                            )

                        })}

                    </LogosYourPayments>
                </ContentYourPayments>
            </YourPayments>

            {/* <Timer timeSeconds={1500} setTimers={setTimersPromotion} /> */}
        </WrapperPromotion>
    );
};
