import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

interface IProps {
    type: string;
    data? : {
        file : {
            url: string;
            fileName: string;
        }
    }
}

export const LogoGana = (props: IProps) => {
    const dataLogo = useStaticQuery(graphql`
        query LogoGana {
            allContentfulGeneral {
                nodes {
                    logoGanaPrimary {
                        description
                        file {
                            url
                            fileName
                        }
                    }
                    terciaryLogoGana {
                        file {
                            url
                            fileName
                        }
                    }
                }
            }
        }
    `);
    const {
        allContentfulGeneral: {
            nodes: [logo]
        }
    } = dataLogo;
    const { logoGanaPrimary, terciaryLogoGana } = logo;
    let logoData = {file: { url: "", fileName: "" }};
    if(props.type === 'Primary' ){
        logoData = logoGanaPrimary
    } else if (props.type === 'Object' && props.data !== undefined){
        logoData = props.data;
    }else {logoData = terciaryLogoGana};
    
    return <img src={logoData.file.url} alt={logoData.file.fileName} title="Logo Gana" />;
};
