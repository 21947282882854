import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Slider from 'react-slick';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Breadcrumbs, ButtonGoBack, ButtonIcon, Layout, SEO } from '@componentsShared';
import { colorText } from '@utils/colorText';
import Viewport from 'src/shared/hooks/viewport';
import {
    Arrow,
    ContainerTermsConditions,
    ContentInfo,
    Header,
    TabButton,
    Title,
    Icon,
    BoxContent
} from './styled';
import siteUrl from '@utils/siteUrl';
 
type IPropsSampleArrow = {
   onClick?: () => void;
   arrow?: string;
};
 
const SampleArrow = (props: IPropsSampleArrow) => {
   const { onClick, arrow } = props;
 
   return (
       <Arrow onClick={onClick} className={arrow}>
           <ButtonIcon
               icon={`angle-${arrow}`}
               colorDefault={'#0DF96C'}
               title={arrow === 'left' ? 'Anterior' : 'Siguiente'}
               size="small"
               disabled={false}
           />
       </Arrow>
   );
};
 
const TermsConditions = () => {
   const viewport = Viewport();
 
   const data = useStaticQuery(graphql`
       query dataTermsConditions {
           allContentfulPaginaInternaFooter(filter: { page: { eq: "TerminosCondiciones" } }) {
               nodes {
                   title
                   pageIcon {
                       title
                       file {
                           url
                       }
                   }
                   secondarySection {
                       ... on ContentfulPreguntasFrecuentes {
                           question
                           answer {
                               raw
                           }
                       }
                   }
                   metaData {
                        title
                        descripcion
                        keyWords
                    }
               }
           }
       }
   `);
 
   const { title, pageIcon, secondarySection, metaData } = data.allContentfulPaginaInternaFooter.nodes[0];
 
   const settings = {
       className: 'slider-style',
       infinite: true,
       slidesToShow: 1,
       swipeToSlide: true,
       nextArrow: <SampleArrow arrow="right" />,
       prevArrow: <SampleArrow arrow="left" />,
       afterChange: (index: number) => {
           setStep(index + 1);
       }
   };
 
   const [step, setStep] = useState(1);
 
   let crumbs = [
       {
           crumbLabel: 'Inicio',
           pathname: '/'
       },
       {
           crumbLabel: 'Términos y condiciones',
           pathname: 'terminos-y-condiciones/'
       }
   ];
 
   return (
       <Layout>
           <SEO
               title={metaData.title}
               description={metaData.descripcion}
               keyWords={metaData.keyWords}
               slug={siteUrl + '/terminos-y-condiciones'}
           />
           <ContainerTermsConditions>
               {!viewport.mobile && <Breadcrumbs crumbs={crumbs} />}
               {viewport.mobile && <ButtonGoBack />}
               {!viewport.mobile && <Icon src={pageIcon.file.url} alt={pageIcon.title} />}
               <Title dangerouslySetInnerHTML={{ __html: colorText(title) }} />
               <BoxContent>
                   <hr />
                   <Header>
                       {viewport.mobile && (
                           <Slider {...settings}>
                               <TabButton>
                                   <h2
                                       dangerouslySetInnerHTML={{
                                           __html: colorText(secondarySection[0].question)
                                       }}
                                   />
                               </TabButton>
                               <TabButton>
                                   <h2
                                       dangerouslySetInnerHTML={{
                                           __html: colorText(secondarySection[1].question)
                                       }}
                                   />
                               </TabButton>
                               <TabButton>
                                   <h2
                                       dangerouslySetInnerHTML={{
                                           __html: colorText(secondarySection[2].question)
                                       }}
                                   />
                               </TabButton>
                           </Slider>
                       )}
                       {!viewport.mobile && (
                           <>
                               <TabButton
                                   active={step === 1}
                                   onClick={() => {
                                       setStep(1);
                                   }}
                               >
                                   <h2
                                       dangerouslySetInnerHTML={{
                                           __html: colorText(secondarySection[0].question)
                                       }}
                                   />
                               </TabButton>
                               <TabButton
                                   active={step === 2}
                                   onClick={() => {
                                       setStep(2);
                                   }}
                               >
                                   <h2
                                       dangerouslySetInnerHTML={{
                                           __html: colorText(secondarySection[1].question)
                                       }}
                                   />
                               </TabButton>
                               <TabButton
                                   active={step === 3}
                                   onClick={() => {
                                       setStep(3);
                                   }}
                               >
                                   <h2
                                       dangerouslySetInnerHTML={{
                                           __html: colorText(secondarySection[2].question)
                                       }}
                                   />
                               </TabButton>
                           </>
                       )}
                   </Header>
                   {step === 1 ? (
                       <ContentInfo>
                           {documentToReactComponents(JSON.parse(secondarySection[0].answer.raw))}
                       </ContentInfo>
                   ) : step === 2 ? (
                       <ContentInfo>
                           {documentToReactComponents(JSON.parse(secondarySection[1].answer.raw))}
                       </ContentInfo>
                   ) : (
                       <ContentInfo>
                           {documentToReactComponents(JSON.parse(secondarySection[2].answer.raw))}
                       </ContentInfo>
                   )}
               </BoxContent>
           </ContainerTermsConditions>
       </Layout>
   );
};
 
export default TermsConditions;
 
