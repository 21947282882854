import React, { useState } from 'react';
import { Button, ButtonGoBack, ValidateTypeDocument } from '@componentsShared';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
    BodyBoxRecoverPassword,
    BoxRecoverPassword,
    ContainerError,
    ContentBoxRecoverPassword,
    ContentForm,
    HeaderBoxRecoverPassword
} from './styled';
import { loginServices } from '@services/login';
import CryptoJS from 'crypto-js';
import { ErrorLogin, ModalNewPassword } from '@components';
import Viewport from 'src/shared/hooks/viewport';
import { encryptText } from '@utils/encrypt';

interface Inputs {
    user: string;
    type: string;
}

export const ContainerRecoverPassword = () => {
    const methods = useForm({ mode: 'onChange' });
    const [error, setError] = useState(false);
    const [isShowModalRecover, setIsShowModalRecover] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const displayModalNewPassword = () => {
        setIsShowModalRecover(!isShowModalRecover);
    };

    const viewport = Viewport();

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        setError(false)
        let user = encryptText(data.user)
        await loginServices
            .recoverPassword(data.type, user)
            .then((result) => {
                const { data: { recoverPassNew } } = result;
                setError(!recoverPassNew.status);
                if (recoverPassNew.status) {
                    setIsShowModalRecover(true);
                    setErrorMessage('');
                } else {
                    setErrorMessage(recoverPassNew.message);
                }
            })
            .catch((err) => {
                let msg =  err.message || '';
                if (msg.toLowerCase().includes('usuario o clave inv')) {
                    msg = '';
                }
                setErrorMessage(msg);
                setError(true);
            });
    };

    return (
        <BoxRecoverPassword>
            <ContentBoxRecoverPassword>
                <HeaderBoxRecoverPassword>
                    <h1>
                        <span>¿Olvidaste tu</span> contraseña?
                    </h1>
                </HeaderBoxRecoverPassword>
                <BodyBoxRecoverPassword>
                    <p>
                        Sabemos lo difícil que es recordar la contraseña, ¡Te echaremos una mano!.
                        Pon tus datos...
                    </p>
                    <FormProvider {...methods}>
                        <ContentForm onSubmit={methods.handleSubmit(onSubmit)}>
                            <ValidateTypeDocument />
                            {error && (
                                <ContainerError>
                                    <ErrorLogin message={errorMessage} />
                                </ContainerError>
                            )}
                            <Button
                                text="Recuperar contraseña"
                                disabled={!methods.formState.isValid}
                                submit={true}
                            ></Button>
                        </ContentForm>
                    </FormProvider>
                </BodyBoxRecoverPassword>
            </ContentBoxRecoverPassword>

            <ModalNewPassword
                user={isShowModalRecover ? encryptText(methods.getValues('user')): ''}
                type={methods.getValues('type')}
                modalState={isShowModalRecover}
                setModalState={displayModalNewPassword}
            />
        </BoxRecoverPassword>
    );
};
