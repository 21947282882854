import { ModalGana } from '@componentsShared';
import { setDataStorage } from '@utils/transferData';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

interface ModalNotProps {
    modalState: boolean;
    setModalState: () => void;
}

export const ModalNotLocation = (props: ModalNotProps) => {
    const { modalState, setModalState } = props;
    const dataModalNotLocation = useStaticQuery(graphql`
        query QueryNotlocation {
            allContentfulModal(filter: { name: { eq: "No estas en antioquia" } }) {
                edges {
                    node {
                        icon {
                            file {
                                url
                            }
                        }
                        title
                        text
                        AditionalText
                    }
                }
            }
        }
    `);

    const {
        allContentfulModal: { edges }
    } = dataModalNotLocation;

    const { node } = edges[0];

    const optionsModal = {
        logo: 'terciary',
        icon: node.icon.file.url,
        // title: '',
        text: node.text,
        // boldText: node.AditionalText,
        boldText: node.title,
        showHide: node.AditionalText,
        primaryButtonText: 'Cerrar',
        primaryButton: () => {
            setDataStorage(true, 'isNotLocated');
            setModalState();
        },
        dimensions: { height: { mobile: 'auto', desktop: 'auto' }, width: { desktop: '408px' } },
        sizeButton: '159px'
    };

    return (
        <ModalGana visibility={modalState} setVisibility={setModalState} options={optionsModal} />
    );
};
