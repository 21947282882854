import React from 'react';
import Slider from 'react-slick';
import { ContentSlider } from './styled';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface IProps {
    children: React.ReactNode;
    customSettings?: object;
}

export const Carousel = (props: IProps) => {
    const { children, customSettings } = props;
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    };
    return (
        <ContentSlider>
            <Slider {...(customSettings ? customSettings : settings)}>{children}</Slider>
        </ContentSlider>
    );
};
