import { breakpoint } from 'src/shared/styles/variables';
import styled, { keyframes } from 'styled-components';

export const ContainerBanner = styled.div`
    width: 100%;
    background: linear-gradient(180.26deg, #0df96c -31.53%, #004e2d 99.84%);
    height: 186px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${breakpoint('lg')`
        height: 140px;
        border-radius: 16px;
        flex-direction: row;
        background: linear-gradient(274.3deg, #004E2D 42.64%, #00D455 100.44%);
    `}
`;

export const ContainerGif = styled.div`
    background-color: #ffffff;
    height: 81px;
    width: 224px;
    box-shadow: inset 0px -5px 8px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 32px 32px;
    margin-bottom: 35px;
    display: flex;
    justify-content: center;

    img {
        height: 70px;
        width: 190px;
        border-radius: 0px 0px 32px 32px;
    }

    ${breakpoint('lg')`
        align-items: center;
        margin-bottom: 0px;
        width: 328px;
        height: 100%;
        border-radius: 16px 100px 100px 16px;
        box-shadow: inset -4px 0px 9px rgba(0, 0, 0, 0.4);
        margin-right: 70px;

        img {
            border-radius: 0px 100px 100px 0px;
            height: unset;
            width: 300px
        }
    `}
`;

export const TextBanner = styled.div`
    margin-right: 50px;
    max-width: 449px;
    p {
        font-family: ${(props) => props.theme.fonts.mon_bold};
        color: #ffffff;
        font-size: 30px;

        b {
            color: #ffe000;
        }
    }
`;

const Illumination = keyframes`
    from {
    }

    to {
        box-shadow: rgba(13,249,108,0.5) 0px 0px 18px 3px;
    }

`;

export const LinkGame = styled.a`
    background: ${(props) => props.theme.colors.principal.greenBase};
    border-radius: 16px;
    text-align: center;
    padding: 13px 24px;
    font-size: 18px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.text.gray};
    font-family: ${(props) => props.theme.fonts.mon_bold};
    width: 205px;
    text-decoration: none;
    transition: all 0.4s ease-in-out;
    animation: ${Illumination} 1s infinite linear;

    &:hover {
        transform: scale(1.05);
        animation-play-state: paused;
    }

    &:active {
        background: ${(props) => props.theme.colors.principal.darkGreen};
    }

    ${breakpoint('lg')`
        border-radius: 12px;
        padding: 8px 32px;
        font-size: 14px;
        line-height: 16px;
        min-width: 177px;
        margin-right: 30px;
    `}
`;
