import styled from 'styled-components';



export const ContentTooltip = styled.div`
    width: 100%;
    margin: 13px 45px 0 16px;

    & > span {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        color: #1d1828;
    }

    ul {
        margin-left: 19px;
    }
`;

export const ItemList = styled.li`
    display: flex;

    p {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #1d1828;
    }

    &::before {
        content: '\\2022';
        margin-right: 10px;
        margin-left: -10px;
        font-size: large;
        color: ${(props) => props.theme.colors.principal.greenBase};
    }

    span {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        color: #1d1828;
    }
`;

