import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';

export const LoginOptionContainer = styled.div`
    max-width: 1024px;
    margin: 0px auto 60px auto;
    background-color: #272231;
    border-radius: 16px;
    border: 0.5px solid #c5c3c9;

    ${breakpoint('md')`
       
        border: 1px solid ${(props) => props.theme.colors.principal.greenNeon};
        background: #1D1828;
        margin: 0px auto 32px auto;
       
    `}
`;

export const LoginOptionalText = styled.div`
    font-family: ${(props) => props.theme.fonts.lf_bold};
    font-size: 14px;
    line-height: 18px;

    padding: 32px 60px 22px 60px;
    text-align: center;

    ${breakpoint('md')`
          font-size: 14px;
          line-height: 18px;
          padding: 27px 60px 22px 60px;
    `}
`;

export const ButtonLogin = styled.a`
    background-color: transparent;
    border: none;
    border: 1px solid ${(props) => props.theme.colors.principal.greenNeon};
    border-radius: 12px;
    width: 197px;
    height: 48px;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-size: 18px;
    line-height: 21.6px;
    margin: 0 auto 30px auto;
    text-decoration: none;

    display: flex;
    justify-content: center;
    align-items: center;

    ${breakpoint('md')`
            font-size: 14px;
            line-height: 16px;
            margin: 0 auto 26px auto;
            width: 161px;
            height: 32px;
    `}
`;
