import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const BannerContainer = styled.div`
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 143px;
    background: linear-gradient(to right, #0df96c, #009f5c);

    ${breakpoint('md')`
        display: flex;
        height: 158px;
        background: rgb(13,249,108);
        background: radial-gradient(circle, rgba(13,249,108,1) 0%, rgba(0,159,92,1) 40%);

    `}
`;

export const ContainerImage = styled.div`
    position: relative;
    top: -52px;
    margin: auto;

    img {
        &.mobile {
            &.results {
                position: relative;
                top: 11px;
            }
        }

        &.desktop {
            position: relative;
            height: 220px;
            top: -10px;
            left: 100px;

            &.results {
                left: 120px;
                top: -9px;
            }
        }
    }

    ${breakpoint('md')`
        // margin: auto;
       
    `}
`;

export const ContainerDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 20px;

    ${breakpoint('md')`
        
    `}
`;

export const IconImage = styled.img`
    margin: 0 auto;
    height: 50px;
    padding-top: 15px;

    &.results {
        display: none;
    }

    ${breakpoint('md')`
        position: absolute;
        margin: 0;
        padding-top: 0;
        align-self: center;
        left: 90px;


     `}

    ${breakpoint('lg')`
        left: 236px;
    `}
`;

export const BannerText = styled.div`
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 14px;
    line-height: 16.8px;
    padding-top: 16px;

    &.games {
        p {
            text-align: center;
        }
    }

    ${breakpoint('md')`
        padding-bottom: 20px;
        align-self: center;
        position: relative;
        width: 222px;
        right: 100px;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        
        p{
            right: 50px;
            font-size: 20px;
            font-family: ${(props) => props.theme.fonts.lf_regular};
            line-height: 24px;
            text-align:left;
            width: 275px;
        }
     `}

    ${breakpoint('lg')`
        right: 220px;
    `}
`;

export const SectionTitle = styled.h2`
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-size: 20px;
    line-height: 24px;

    &.results {
        display: none;
    }
    ${breakpoint('md')`
        padding-top: 0;
        align-self: center;
        position: absolute;
        font-size: 32px;
        line-height: 38.4px;
        left: 160px;

        `}

    ${breakpoint('lg')`
        left: 312px;
    `}
`;
