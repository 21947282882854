import { carServices } from '@services/car';
import { isBrowser } from 'react-device-detect';
import axios, { Axios, AxiosError } from 'axios';
import { removeSessionStorage } from './transferData';

export interface UserLocation {
    latitude?: number;
    longitude?: number;
    accuracy?: number;
    errors?: string;
}

const URL_API_COORDS_FREE_IP_2_GEO =
    process.env.GEO_URL_API_COORDS_FREE_IP_2_GEO || 'https://freeip2geo.net/api/';
const ENABLE_HIGH_ACCURACY = process.env.GEO_ENABLE_HIGH_ACCURACY === undefined || '1' === process.env.GEO_ENABLE_HIGH_ACCURACY;
const ENABLE_GPS_DESKTOP = process.env.GEO_ENABLE_GPS_DESKTOP === undefined || '1' === process.env.GEO_ENABLE_GPS_DESKTOP;
const TIMEOUT = parseInt(process.env.GEO_TIMEOUT || '20000', 10) || 20000;
const MAXIMUM_AGE = parseInt(process.env.GEO_MAXIMUM_AGE || '0', 10) || 0;
const MSG_DENIED_LOCATION = 'denied';
let errorMessage: string = '';


export type UpdateLocation = (location: UserLocation) => void;

const hasWindow = () => typeof window !== 'undefined';
const hasNavigator = () => typeof navigator !== 'undefined';

const getError = (fnName: string, e: unknown) => {
    if (e instanceof GeolocationPositionError) {
        return `\n${fnName} : c: ${e.code} -> m: ${e.message}`;
    } else if (e instanceof Error) {
        return `\n${fnName} : n: ${e.name} m: ${e.message}`;
    }
};

const parseErrorAxios = (fnName: string, e: AxiosError) => {
    let errorAxios = `\n${fnName} : c: ${e.code} -> n: ${e.name} -> m: ${e.message} : u: ${e.config?.url}`;
    if (e.response) {
        errorAxios += ` -> s: ${e.response.status} -> sT: ${e.response.statusText}`;
    }
    if (e.request) {
        errorAxios += ` -> r: ${e.request.responseText}`;
    }
    return errorAxios;
};

const getPosition = () => {
    return new Promise<GeolocationPosition>(async (resolve, reject) => {
        if (!navigator.geolocation) {
            reject(new Error('Geolocalización no soportada'));
        } else {
            navigator.geolocation.getCurrentPosition(
                (location) => {
                    if (location.coords && hasLocation(location.coords)) {
                        resolve(location);
                    } else {
                        reject(new Error('Coordenadas no disponibles'));
                    }
                },
                (error) => {
                    console.log(error);
                    reject(error);
                },
                {
                    timeout: TIMEOUT,
                    maximumAge: MAXIMUM_AGE,
                    enableHighAccuracy: ENABLE_HIGH_ACCURACY
                }
            );
        }
    });
};

export const getCurrentPosition = (numIntents = 2, mejorLocation?: GeolocationPosition) => {
    return new Promise<UserLocation>(async (resolve, reject) => {
        if (hasWindow() && hasNavigator() && navigator.geolocation && numIntents > 0) {
            try {
                const location = await getPosition();
                resolve(location.coords);
                if (!mejorLocation || mejorLocation.coords.accuracy < location.coords.accuracy) {
                    mejorLocation = location;
                }
            } catch (e: any) {
                errorMessage += getError('getCurrentPosition', e);
                if (e && e.message && e.message.toLowerCase().includes(MSG_DENIED_LOCATION)) {
                    reject(new Error(MSG_DENIED_LOCATION));
                    return;
                } else if (numIntents > 0) {
                    resolve(await getCurrentPosition(numIntents - 1, mejorLocation));
                    return;
                } else if (numIntents == 0 && !mejorLocation) {
                    reject(new Error(MSG_DENIED_LOCATION));
                }
            }
        }
        if (mejorLocation) {
            resolve(mejorLocation.coords);
        }
    });
};

export const getLocationByGps = async (user: string, cartId: number) => {
    try {
        const position = await getCurrentPosition();
        const currentPosition = await locationMedellin(user, cartId, position);
        return currentPosition;
    } catch (error) {
        errorMessage += getError('getLocationByGps', error);
        throw error;
    }
};

export const getLocationByIp = async (user: string, cartId: number): Promise<UserLocation> => {
    try {
        await getCurrentPosition();
        const location = await getCurrentLatLngPublic(getCoordsFreeIp2geo);
        let currentLocation = await locationMedellin(user, cartId, location);
        if (ENABLE_GPS_DESKTOP && !hasLocation(currentLocation)) {
            currentLocation = await getLocationByGps(user, cartId);
        }
        return currentLocation;
    } catch (error) {
        errorMessage += getError('getLocationByIp', error);
        throw error;
    }
};

const getCurrentLatLngPublic = async (fnGetApi: () => Promise<UserLocation>) => {
    try {
        const coordenadas = await fnGetApi();
        return coordenadas;
    } catch (e) {
        console.error('getCurrentLatLngPublic: ', e);
    }
    const position = await getCurrentPosition();
    return position;
};

const getCoordsFreeIp2geo = async () => {
    try {
        const {
            data: { latitude, longitude }
        } = await axios.get(URL_API_COORDS_FREE_IP_2_GEO);
        const coordenadas = { latitude, longitude, accuracy: -1 } as UserLocation;
        return coordenadas;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            errorMessage += parseErrorAxios('getCoordsFreeIp2geo', error);
        } else {
            errorMessage += getError('getCoordsFreeIp2geo', error);
        }
        throw error;
    }
};

export const hasLocation = (loc: UserLocation | null) => {
    if (loc) {
        return (
            loc.latitude !== null &&
            loc.longitude !== null &&
            loc.latitude !== undefined &&
            loc.longitude !== undefined
        );
    }
    return false;
};

export const getCurrentLocation = async (
    user: string,
    cardId: number,
    updateLocation?: UpdateLocation
): Promise<UserLocation> => {
    try {
        errorMessage = '';
        let currentPosition = null;
        if (isBrowser) {
            currentPosition = await getLocationByIp(user, cardId);
        } else {
            currentPosition = await getLocationByGps(user, cardId);
        }
        if (hasLocation(currentPosition) && updateLocation) {
            updateLocation(currentPosition);
        }
        return currentPosition;
    } catch (error) {
        try {
            await locationMedellin(user, cardId, {
                accuracy: -1,
                latitude: -1,
                longitude: -1
            });
        } catch (error) {
            console.log(error);
        }
        throw error;
    }
};

export const isInMedellinOld = async (location: UserLocation): Promise<boolean | null> => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.latitude},${location.longitude}&key=${process.env.KEY_GEOLOCATION}`;
    try {
        const res = await fetch(url);
        const data = await res.json();
        const compound_code = data?.plus_code?.compound_code;
        return !compound_code ? null : compound_code.toLowerCase().includes('antioquia');
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const locationMedellin = async (
    user: string,
    cartId: number,
    location?: UserLocation
): Promise<UserLocation> => {
    try {
        const data = await carServices.getCurrentLocation(
            user,
            cartId,
            location?.latitude,
            location?.longitude,
            errorMessage,
            location?.accuracy
        );
        const response = {
            ...data.data.getCurrentLocation,
            accuracy: location?.accuracy
        };
        return response;
    } catch (error) {
        removeSessionStorage('isLocated');
        removeSessionStorage('isNotLocated');
        removeSessionStorage('closeModalLocation');
        console.log(error);
    }
    return {
        latitude: -1,
        longitude: -1,
        accuracy: -1
    };
};
