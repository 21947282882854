import React from 'react';
import { ContentLoading, Spinner } from './styled';

export const Loading = () => {
    return (
        <ContentLoading className="loading">
            <Spinner></Spinner>
        </ContentLoading>
    );
};
