import { motion } from 'framer-motion';
import { breakpoint } from 'src/shared/styles/variables';
import { hexToRGB } from 'src/shared/utils/hexToRgba';
import styled from 'styled-components';

interface IPropsModal {
    state?: boolean;
}

export const Container = styled.div<IPropsModal>``;

export const ContainerModal = styled.div<IPropsModal>`
    width: 100%;
    margin: 35px;
`;

export const ModalBox = styled(motion.div)`
    width: 290px;
    height: auto;
    border-radius: 16px;
    background: ${(props) => props.theme.colors.text.white};
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1000;

    ${breakpoint('md')`
        width: 521px;
    `}
`;

export const ModalContainer = styled(motion.div)<IPropsModal>`
    position: fixed;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1001;
    background: ${(props) => hexToRGB(props.theme.colors.backgrounds.gray450, 0.7)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${(props) =>
        props.state &&
        `
            visibility:visible;
            opacity:1;
        `}
`;

export const ButtonClose = styled(motion.div)`
    position: absolute;
    top: 16px;
    right: 17px;
    margin-right: -8px;
    z-index: 5;
    button span {
        color: ${(props) => props.theme.colors.backgrounds.gray400};
        font-size: 24px;
    }
`;

export const LogoContainer = styled.div`
    position: relative;
    width: 100%;
`;

interface IPropsIcon {
    colorIcon?: string;
}

export const IconContainer = styled.div<IPropsIcon>`
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;

    ${breakpoint('md')`
        margin-top: 10px;
        margin-bottom: 15px;
    `}
    span {
        color: ${(props) =>
            props.colorIcon ? props.colorIcon : props.theme.colors.accents.neon.orange};
        font-size: 42px;
    }
`;

export const ContentLogo = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 22px;
    img {
        max-width: 168px;
        margin: 0 auto;
    }
`;

export const TitleStepContainer = styled.div`
    width: 80%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;

    h2 {
        font-family: ${(props) => props.theme.fonts.mon_extrabold};
        font-style: normal;
        font-weight: 800;
        font-size: 21px;
        line-height: 120%;
        text-align: center;
        color: ${(props) => props.theme.colors.backgrounds.gray500};
    }
    &.deleteNumber {
        h2 {
            font-family: ${(props) => props.theme.fonts.mon_bold};
            font-size: 28px;
        }
    }
`;

export const TextModal = styled.div`
    width: 80%;
    display: flex;
    justify-content: center;

    p {
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        color: ${(props) => props.theme.colors.backgrounds.gray500};
        text-align: center;

        ${breakpoint('md')`
            font-size: 16px;
        `}
    }
`;

export const ActionsButtons = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0px 35px;
    justify-content: center;
    flex-wrap: wrap-reverse;
    margin-bottom: 30px;
    margin-top: 32px;

    ${breakpoint('md')`
        margin-top: 40px;
    `}
    button {
        font-size: 16px;
        padding: 13px 24px;

        ${breakpoint('md')`
            font-size: 14px;
            padding: 8px 32px;
        `}
    }
    &.deleteNumber {
        button {
            :nth-child(2) {
                max-height: 32px;
                min-width: 126px;
            }
        }
    }
`;

export const ButtonSecondaryContainer = styled.div`
    margin-top: 12px;

    button {
        font-size: 16px;
        padding: 13px 24px;

        ${breakpoint('md')`
                font-size: 14px;
                padding: 0 32px;
                margin-top: 0px;
            `}
    }
`;

export const ContentCustom = styled.div`
    width: 83%;
    display: flex;
    justify-content: center;

    ${breakpoint('md')`
        padding-bottom: 16px;
    `}
`;
