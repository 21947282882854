import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const TreeNumber = styled.div`
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    /* margin-bottom: 25px; */

    & > input:disabled {
        background: ${(props) => props.theme.colors.backgrounds.gray500};
        border: none;
    }

    &.alert {
        input:nth-child(-n + 2) {
            border: 1px solid #ff7a00;
            box-shadow: 0px 0px 9px #ff7a00;
        }
    }

    &.danger {
        input:nth-child(-n + 3) {
            border: 1px solid #ff2048;
            box-shadow: 0px 0px 9px #ff2048;
        }
    }
`;

export const ButtonRandom = styled.button`
    width: 45px;
    height: 45px;
    background: none;
    border-radius: 50%;
    outline: none;
    border: none;
    margin: 2px;
    text-align: center;
    font-size: 28px;
    font-family: ${(props) => props.theme.fonts.icons};
    border: 2px solid ${(props) => props.theme.colors.principal.darkGreen};
    cursor: pointer;

    span {
        color: ${(props) => props.theme.colors.principal.darkGreen};
    }

    &:disabled {
        border-color: ${(props) => props.theme.colors.backgrounds.gray500};
        span {
            color: ${(props) => props.theme.colors.backgrounds.gray500};
        }

        &:hover {
            border-color: ${(props) => props.theme.colors.backgrounds.gray500};
            span {
                color: ${(props) => props.theme.colors.backgrounds.gray500};
            }
        }
    }

    &:hover {
        border-color: ${(props) => props.theme.colors.principal.greenNeon};
        span {
            color: ${(props) => props.theme.colors.principal.greenNeon};
        }
    }

    &:active {
        border-color: ${(props) => props.theme.colors.principal.greenBase};

        span {
            color: ${(props) => props.theme.colors.principal.greenBase};
        }
    }

    ${breakpoint('md')`
        margin: 4px;
    `}
`;
