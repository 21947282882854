import { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import { IPropsTimer } from './types';

export const Timer = (props: IPropsTimer) => {
    const { timeSeconds, setTimers } = props; // timer in seconds and set

    const expiryTimestamp = new Date();
    expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + timeSeconds); // minutes timer

    const { seconds, minutes, hours, days } = useTimer({
        expiryTimestamp
    });

    const dayTime = days < 10 ? `0${days}` : `${days}`;
    const hourTime = hours < 10 ? `0${hours}` : `${hours}`;
    const secondTime = seconds < 10 ? `0${seconds}` : `${seconds}`;
    const minuteTime = minutes < 10 ? `0${minutes}` : `${minutes}`;

    useEffect(() => {
        setTimers({
            days: dayTime,
            hours: hourTime,
            minutes: minuteTime,
            seconds: secondTime
        });
    }, [seconds]);

    return null;
};
