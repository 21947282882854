import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const StepperContainer = styled.div`
    width: 100%;
    max-width: 1115px;
    margin: 32px auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StepperList = styled.ul`
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    li {
        width: 100%;
        text-align: center;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .circle {
            width: 32px;
            height: 32px;
            background: #c5c3c9;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            border-radius: 50%;
            text-decoration: none;
            z-index: 1;
            font-family: ${(props) => props.theme.fonts.mon_bold};
            cursor: default;
        }

        span {
            font-family: ${(props) => props.theme.fonts.lf_regular};
            font-size: 14px;
            font-weight: 16px;
            color: #c5c3c9;
            padding-top: 6px;
        }

        :after {
            content: '';
            width: 100%;
            height: 2px;
            background-color: #c5c3c9;
            position: absolute;
            top: 15px;
            right: -50%;
            z-index: 0;
        }

        :last-child:after {
            content: none;
        }
    }

    .active {
        .circle {
            background: #00a3ff;
            box-shadow: 0px 0px 18px #00a3ff;
        }
        span {
            color: #00a3ff;

            :after {
                color: #00a3ff;
            }
        }
    }
    .ready {
        .circle {
            background: #00a3ff;
            font-size:0;
            ::before {
                font-family: ${(props) => props.theme.fonts.icons};
                content: '\\e943';
                color: #fff;
                text-align: center;
                font-size: 16px;
            }
        }

        span {
            color: #00a3ff;

            :after {
                color: #00a3ff;
            }
        }

        &:after {
            background: #00a3ff;
        }
    }
    &:after {
        background: #00a3ff;
    }

    ${breakpoint('md')`
        padding: 0 100px;
    `}
`;
