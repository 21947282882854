import React from 'react';
import { MenuBurger, StyledBurger } from './styled';
import menu from 'src/shared/assets/images/menu.svg';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const Burger = ( {open,setOpen}:Props ) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <MenuBurger open={open} src={menu} alt="icon-menu-burger" title="Menu"/>
      <span className="icon-cross"></span>

    </StyledBurger>
  )
}
