import { gql } from '@apollo/client';
import client from '../../config/apollo-client-gana';

export const queriesLogin = {
    getToken: gql`
        mutation login($input: loginInput) {
            login(input: $input) {
                token
                __typename
            }
        }
    `,
    getUserData: gql`
        query consultar($usuario: UserInfoInput) {
            consultarUsuario(input: $usuario) {
                token
                tipoDocumento
                nombres
                primerApellido
                email
                usuario
                saldo
                ideUsuario
                puntos
                fechaNacimiento
                updUserData
                descargaweb
                __typename
            }
        }
    `,
    recoverPassword: gql`
        mutation recoverPassNew($input: UserInfoInputBasic) {
            recoverPassNew(input: $input){
                message
                status
            }
        }
    `,
    logout: gql`
        mutation login($token: String!) {
            logout(token: $token)
        }
    `,

    loginSc: gql`
        mutation getSessionInfo($objInfo: objInfo!) {
            getSessionInfo(objInfo: $objInfo)
        }
    `,
    updatePassword: gql`
        mutation validarActualizarClave($input: UpdatePassInputNew) {
            validarActualizarClave(input: $input) {
                status
                message
            }
        }
    `
};

export const loginServices = {
    getToken: (user: string, password: string, type: string, token: string) => {
        return client.mutate({
            mutation: queriesLogin.getToken,
            variables: {
                input: {
                    tipoDocumento: type,
                    usuario: user,
                    clave: password,
                    idComercializador: false,
                    token
                }
            }
        });
    },

    getUserData: (user: string, token: string, type: string) => {
        return client.mutate({
            mutation: queriesLogin.getUserData,
            variables: {
                usuario: {
                    tipoDocumento: type,
                    usuario: user,
                    token: token
                }
            }
        });
    },

    recoverPassword: (type: string, user: string) => {
        return client.mutate({
            mutation: queriesLogin.recoverPassword,
            variables: {
                input: {
                    tipoDocumento: type,
                    usuario: user
                }
            }
        });
    },

    logout: (token: string) => {
        return client.mutate({
            mutation: queriesLogin.logout,
            variables: {
                token: token
            }
        });
    },

    loginSc: (idUser: string) => {
        return client.mutate({
            mutation: queriesLogin.loginSc,
            variables: {
                objInfo: {
                    ideUsuario: idUser,
                    ideAplicacion: 6
                }
            }
        });
    },

    updatePassword: (token: string, password: string) => {
        return client.mutate({
            mutation: queriesLogin.updatePassword,
            variables: {
                input: {
                    token: token,
                    claveNueva: password
                }
            }
        });
    }
};
