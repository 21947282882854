import styled from 'styled-components';

export const ContainerBreadCrumbs = styled.ul`
    list-style: none;
    position: absolute;
    padding: 0;

    & > li:after {
        content: '/';
        padding: 0 4px;
    }
`;

export const Crumb = styled.li`
    display: inline-block;
    cursor: pointer;

    &:last-of-type:after {
        content: '';
        padding: 0;
    }

    a {
        text-decoration: none;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 12px;
        line-height: 14px;
    }
    &:last-child {
        a {
            color: ${(props) => props.theme.colors.principal.greenNeon};
            font-family: ${(props) => props.theme.fonts.lf_bold};
        }
    }
`;
