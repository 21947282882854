import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {
    BottomRegister,
    Button,
    ButtonGoBack,
    InputCustom,
    Redirect,
    TitleStep,
    ValidatePassword,
    Stepper,
    PredictError,
    Toast
} from '@componentsShared';
import { ModalMessage } from '@components';
import Viewport from 'src/shared/hooks/viewport';
import { setDataStorage, getDataStorage } from 'src/shared/utils/transferData';
import {
    pushEskEvent,
    pushFbqEvent,
    pushGtagEvent
} from 'src/shared/utils/metricManagementUtil';

import {
    AuxText,
    ContainerPassword,
    ContainerRegisterTop,
    FormButtons,
    FormRegister,
    InputContainer,
    MainText,
    RegisterBody,
    RegisterForm,
    RegisterTitle,
    TitleContainer,
    RegisterBox,
    ContainerReCaptcha
} from './styled';
import { IAllData, TSubmitProps } from './types';
import { registerServices } from '@services/register';
import { encryptText } from '@utils/encrypt';

export const RegisterStepThree = () => {
    const viewport = Viewport();
    const methods = useForm({ mode: 'onChange' });

    const [modalState, setModalState] = useState(false);
    const [newState, setNewState] = useState({});
    const [tokenRecaptcha, setTokenRecaptcha] = useState('')

    const displayModal = () => {
        setModalState(!modalState);
    };

    const [toastState, setToastState] = useState({
        state: false,
        text: ''
    });

    const showToast = (text: string) => {
        setToastState({ state: !toastState.state, text: text });
        setTimeout(() => {
            setToastState({ state: false, text: '' });
        }, 10000);
    };

    const fetchDataUser = async (data: IAllData) => {
        let user = encryptText(data.user);
        let password = encryptText(data.password);

        const bornDate = `${data.day}-${data.month}-${data.year}`;
        const documentDate = `${data.eDay}-${data.eMonth}-${data.eYear}`;
        const { firstName, lastName, type, email, genre, city, country, phone, mayoriaEdad,terminosYCondiciones, tratamientoDeDatos  } = data;

        await registerServices
            .register(
                firstName,
                lastName,
                type,
                user,
                bornDate,
                documentDate,
                email,
                genre,
                city,
                country,
                phone,
                password,
                mayoriaEdad,
                tokenRecaptcha,
                terminosYCondiciones,
                tratamientoDeDatos
            )
            .then((resp) => {
                pushGtagEvent('generate_lead', {
                    currency: 'COP',
                    value: 5000
                });
                pushFbqEvent('Lead', {
                    currency: 'COP',
                    value: 5000,
                })
                pushEskEvent();

                displayModal();
            })
            .catch((err) => {
                console.log(err.message);
                showToast(err.message);
            });
    };

    const handleUserValidation = () => {
        window.grecaptcha.execute(process.env.SITEKEY).then(async (token: string) => {
            setTokenRecaptcha(token)
        });
    };

    const onSubmit: SubmitHandler<TSubmitProps> = (data) => {
        const allData: any = Object.assign(newState, data);
        fetchDataUser(allData);
        setDataStorage(allData, 'registerData');
    };

    const getRegisterData = () => {
        let userRegisterData = getDataStorage('registerData');
        setNewState(userRegisterData);
    };

    useEffect(() => {
        if (methods.formState.isValid) {
            handleUserValidation();
            return
        }
        setTokenRecaptcha('')
    }, [methods.formState.isValid])

    useEffect(() => {
        getRegisterData();
    }, []);

    return (
        <RegisterBox>
            {viewport.mobile && <ButtonGoBack />}
            {viewport.mobile && (
                <ContainerRegisterTop>
                    <RegisterTitle>Registro</RegisterTitle>
                    <Stepper step1="ok" step2="ok" step3="3" />
                </ContainerRegisterTop>
            )}
            <RegisterForm>
                {!viewport.mobile && (
                    <ContainerRegisterTop>
                        <RegisterTitle>Registro</RegisterTitle>
                        <Stepper step1="ok" step2="ok" step3="3" />
                    </ContainerRegisterTop>
                )}
                <RegisterBody>
                    <TitleContainer>
                        {viewport.mobile && (
                            <TitleStep number="3" text="Datos de Contacto"></TitleStep>
                        )}

                        {!viewport.mobile && <AuxText>Datos de contacto</AuxText>}
                    </TitleContainer>
                    <MainText>Estos serán nuestros canales de comunicación.</MainText>
                    <FormProvider {...methods}>
                        <FormRegister onSubmit={methods.handleSubmit(onSubmit)}>
                            <GoogleReCaptchaProvider reCaptchaKey={process.env.SITEKEY}>
                                <InputContainer>
                                    <InputCustom
                                        label="Correo electrónico"
                                        name="email"
                                        type="email"
                                        placeholder="Escribe tu correo, p. ej. info@correo.com"
                                        required="El correo es requerido"
                                        maxLength={{
                                            value: 80,
                                            message: 'Agrega maximo 80 caracteres'
                                        }}
                                        pattern={{
                                            value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})+$/,
                                            message: 'Correo no válido'
                                        }}
                                    />
                                    <InputCustom
                                        name="phone"
                                        label="Celular"
                                        type="number"
                                        placeholder="Escribe tu celular"
                                        required="Este campo es obligatorio, agrega tu celular"
                                        minLength={{
                                            value: 10,
                                            message: 'Agrega solo 10 números'
                                        }}
                                        maxLength={{
                                            value: 10,
                                            message: 'Agrega solo 10 números'
                                        }}
                                        pattern={{
                                            value: /^3[0-9]*$/,
                                            message: 'Número no válido, debes comenzar por el número 3'
                                        }}
                                    />
                                </InputContainer>

                            <ContainerPassword>
                                <ValidatePassword
                                    condition={methods.getValues('password')}
                                    valid={methods.formState}
                                    value={methods.watch('password')}
                                />
                            </ContainerPassword>

                            <FormButtons>
                                <Button
                                    text="Registrarme"
                                    onClick={() => {}}
                                    disabled={(methods.formState.isValid && tokenRecaptcha) ? false : true}
                                    submit={true}
                                ></Button>

                                    <Redirect
                                        className="redirect"
                                        url="registro/verificacion"
                                        text="Regresar"
                                    />
                                </FormButtons>
                            </GoogleReCaptchaProvider>
                        </FormRegister>
                    </FormProvider>
                </RegisterBody>
            </RegisterForm>
            <ModalMessage modalState={modalState} setModalState={displayModal} />
            <BottomRegister text="¡Ya tengo una cuenta! Yo soy cliente Gana" url="/iniciar-sesion" />
            {toastState.state === true && (
                <Toast text={toastState.text} icon="cross" type="danger" />
            )}
        </RegisterBox>
    );
};
