import { decryptNumber, encrypt } from "@utils/security";

const IS_GANA_ENCRYPTION_V2 = process.env.GANA_ENCRYPTION_V2 === '1';

export const getGameInfoResponse = (gameInfo: any) => {
    if (!gameInfo) {
        return gameInfo;
    }
    let arrEncryptPlanPremios = [
        'valorApuesta',
        'premioNeto',
        'premioBruto',
        'iva',
        'puntosAGanar'
    ];
    try {
        if (!gameInfo.planPremios) {
            gameInfo.planPremios = [];
        }
        for (let i = 0; i < gameInfo.planPremios.length; i++) {
            const planPremio = gameInfo.planPremios[i];
            arrEncryptPlanPremios.forEach(key => {
                if (Object.prototype.hasOwnProperty.call(planPremio, key)) {
                    planPremio[key] = decryptNumber(planPremio[key]);
                }
            });
        }
        return gameInfo;
    } catch (error) {
        console.error(error);
        throw new Error('Error decrypting response gameInfo data');
    }
}


export const getGameInfoRequest = (request: any) => {
    if (!request || !IS_GANA_ENCRYPTION_V2) {
        return request;
    }
    const arrEncryptBets = [
        'QuantityLoterires'
    ];
    const arrEncryptColillas = [
        'QuantityDigitByColilla',
        'derecho',
        'combinado',
        'cuna',
        'cifra'
    ];
    try {
        if (!request.bets) {
            request.bets = [];
        }

        for (let i = 0; i < request.bets.length; i++) {
            const bet = request.bets[i];
            arrEncryptBets.forEach(key => {
                if (Object.prototype.hasOwnProperty.call(bet, key)) {
                    bet[key] = encrypt(`${bet[key]}`);
                }

                if (!bet.Colillas) {
                    bet.Colillas = [];
                }
                for (let i = 0; i < bet.Colillas.length; i++) {
                    const colilla = bet.Colillas[i];
                    arrEncryptColillas.forEach(key => {
                        if (Object.prototype.hasOwnProperty.call(colilla, key)) {
                            colilla[key] = encrypt(`${colilla[key]}`);
                        }
                    });
                }
            });
        }
        return request;
    } catch (error) {
        console.error(error);
        throw new Error('Error encrypting request gameInfo data');
    }
}