import styled from "styled-components";
import { breakpoint } from 'src/shared/styles/variables';

interface Props {
    show: boolean;
}

export const BoxLabelCookies = styled.div<Props>`
    align-items: center;
    background-color: ${(props) => props.theme.colors.accents.neon.blue};
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: ${({show}) => show ? 'flex' : 'none'};
    height: 108px;
    width: 100vw;
    position: fixed;
    bottom: 0;

    z-index: 10;

    button {
        margin-right: 27px;
        margin-left: auto;
        width: 110px;

        ${breakpoint('md')`
            transition: all 0.2s ease-in-out;
        `}

        ${breakpoint('lg')`
            font-size: 14px;
            padding: 9px 32px 9px 35px;
            margin-left: 30px;
            margin-right: 44px;

            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #E2E2E2;
                box-shadow: none;
                color: #0098EE;
                cursor: pointer;
            }
        `}
    }

    ${breakpoint('md')`
        height: 80px;
        width: 428px;
        border-radius: 12px;
        bottom: 3%;
        justify-content: space-around;
        left: calc(50% - 214px);
    `}

    ${breakpoint('lg')`
        height: 90px;
        width: 628px;
        border-radius: 12px;
        bottom: 3%;
        justify-content: space-around;
        left: calc(50% - 314px);
    `}
`;

export const ImgCookie = styled.img`
    margin-left: 35px;
    width: 62px;

    ${breakpoint('md')`
        margin-left: 25px;
        width: 52px;
    `}
    
    ${breakpoint('lg')`
        margin-left: 43px;
        width: 62px;
    `}
`;

export const BoxText = styled.div`
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 14px;
    line-height: 18px;
    padding: 19px 0px 18px 24px;

    p {
        color: ${(props) => props.theme.colors.text.gray};
    }

    a {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        text-decoration: underline;
        line-height: 20px;
    }

    ${breakpoint('md')`
        width: 200px;
    `}
    
    ${breakpoint('lg')`
        font-size: 16px;
        margin-left: 30px;
        padding: 19px 0px 18px 10px;
        width: 300px;

        p br {
            display: none;
        }

        p a {
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            
            &:hover {
                color: #DADADA;
            }
        }
    `}
`;
