import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

export const NumberContainer = styled.input`
    width: 45px;
    height: 45px;
    background: ${(props) => props.theme.colors.text.white};
    color: ${(props) => props.theme.colors.backgrounds.gray500};
    border-radius: 50%;
    outline: none;
    border: none;
    margin: 4px 2px;
    text-align: center;
    font-size: 28px;
    font-family: ${(props) => props.theme.fonts.lf_bold};
    border: 1px solid ${props => props.theme.colors.disabled.gray};
    padding: 0;

    ${breakpoint('md')`
        margin: 4px;
    `}

    &::placeholder {
        font-family: ${(props) => props.theme.fonts.icons}, ${(props) => props.theme.fonts.lf_bold};
        color: ${(props) => props.theme.colors.disabled.gray};
    }

    &:disabled {
        background: ${(props) => props.theme.colors.disabled.gray};
        &::placeholder {
            color: ${(props) => props.theme.colors.backgrounds.gray400};
        }
    }
`;