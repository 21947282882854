import React, { useEffect, useState } from 'react';
import { ContainerBanner } from './styles';
import { IIncentiveMessage } from './types';
import { incentivosSevices } from '@services/IncentivosServices';
import { IncentiveBanner } from '@componentsShared';


export const IncentiveMessage = (props: IIncentiveMessage) => {

    const { sigla, apuesta, valorApuesta, fechaApuesta, className, small } = props;
    const [message, setMessage] = useState<string>('');

    const filterIncentivos = (incentivo: any) => {
        if (!incentivo.parMensaje) {
            return false;
        }
        
        let isValid = false;

        apuesta.betNumbers.forEach(bet => {
            const number = bet.numbers.join('').length;
            if (incentivo.juegos.includes(number)) {
                isValid = true;
            }
        })

        return isValid;
    }

    const getValorApuesta = () => {
        let valorApuesta = apuesta.total;

        if (apuesta.sigla == 'CHA' || apuesta.sigla == 'SEM') {
            valorApuesta = apuesta.total - apuesta.iva;
        }

        return valorApuesta;
    }

    const isSemanario = () => {
        if (sigla !== 'CHA' || !fechaApuesta) {
            return false;
        }
        const now = new Date();


        var partesFercha = fechaApuesta.split('-');
        var dia = parseInt(partesFercha[2]);
        var mes = parseInt(partesFercha[1]) - 1;
        var anio = parseInt(partesFercha[0]); 
        const date = new Date(anio, mes, dia);

        const nowStr = `${now.getFullYear()}${now.getMonth()}${now.getDate()}`;
        const dateStr = `${date.getFullYear()}${date.getMonth()}${date.getDate()}`;
        return !nowStr.includes(dateStr);
    }

    const getSigla = () => {
        if (isSemanario()) {
            return 'SEM';
        }
        return sigla;
    }
    
    const getIncentives = () => {
        const valorApuesta = getValorApuesta();
        incentivosSevices.getIncentivosWebGana(getSigla(), valorApuesta)
            .then(response => {
                const { data: { getIncentivosWebGana: { data: incentivos } } } = response;

                const incentivosValidos = incentivos.filter(filterIncentivos);

                if (incentivosValidos.length > 0) {
                    setMessage(incentivosValidos[0].parMensaje);
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    useEffect(() => {
        getIncentives();
    }, [valorApuesta]);

    return (
        <>
            {
                message &&
                <ContainerBanner className={className || ''}>
                    <IncentiveBanner
                        message={message}
                        sigla={sigla}
                        small={small}
                    />
                </ContainerBanner>
            }
        </>
    );
};
