import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import { decode } from 'base64-arraybuffer';
import {
    Circle,
    CircleFill,
    Container,
    ContentDateState,
    InfoGame,
    ContainerGame,
    MessageTitle,
    NameState,
    OrderDetail,
    Text,
    TextEmail,
    TitleDetails,
    TransactionContainer,
    TransactionDetail,
    TransactionInformation,
    TransactionNumber,
    ContentTitle,
    ContainerImage,
    TotalValue,
    NameBuyer,
    BoxSpinner,
    TitleNotDetail
} from './styled';
import check from '../../../assets/images/check.svg';
import x from '../../../assets/images/x.svg';
import logo from '../../../assets/images/logo-game.svg';
import { Button, Spinner } from '@componentsShared';
import { TooltipCustom } from 'src/shared/components/atoms/inputCustom/styled';
import { carServices } from '@services/car';
import { AppContextUser } from 'src/shared/context/user';
import { getData } from '@utils/transferData';
import { IOrderInfo, ITransaction } from './types';
import { converterNumber } from 'src/shared/utils/decimalNumberConverter';
import siteUrl from '@utils/siteUrl';

type IProps = {
    idPayment: string
}

export const ContainerTransaction = ({idPayment}: IProps) => {
    const { dataUser, setDataUser } = useContext(AppContextUser);
    moment.locale('es');
    let transactionEmpty: ITransaction = {
        createdAt: ' ',
        reference: ' ',
        responseJson: ' ',
        state: false,
        total: 0,
        __typename: ' '
    };
    let orderInfoEmpty: IOrderInfo = [
        {
            __typename: ' ',
            colilla: 0,
            desGame: ' ',
            numTransaccion: ' ',
            serie: ' '
        }
    ];
    const [dataTransaction, setdataTransaction] = useState<ITransaction>(transactionEmpty);
    const { state, createdAt: date, total, reference } = dataTransaction;
    const [dateCreate, setDateCreate] = useState('');
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState<boolean>();
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
    const [dataOrder, setdataOrder] = useState<[] | IOrderInfo>();

    const downloadColilla = async (
        token: string,
        usuario: string,
        colilla: number,
        fecPedido: string,
        serie: string
    ) => {
        await carServices
            .downloadColilla(token, usuario, serie, colilla, fecPedido)
            .then((data) => {
                setLoadingDownload(data.loading);
                const pdfDecode = decode(data.data.downloadColillaPdf);
                const pdf = new Blob([pdfDecode], { type: 'application/pdf' });
                const datacolilla = window.URL.createObjectURL(pdf);
                const link = document.createElement('a');
                document.body.appendChild(link);
                link.href = datacolilla;
                link.download = 'Colilla-' + colilla + '-' + serie;
                link.click();
                window.URL.revokeObjectURL(datacolilla);
                link.remove();
            })
            .catch((err) => {
                console.log(err);
            });
    };
    
    const orderInfo = async (
        token: string,
        numTransaccion: string,
        fecPedido: string,
        state: boolean,
        ideUsuario: string
    ) => {
        await carServices
            .orderInfo(token, numTransaccion, fecPedido)
            .then((data) => {
                state && setdataOrder(data.data.getOrderInfo);
                setLoading(data.data.loading);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getTransactionState = async (
        token: string,
        tipoDocumento: string,
        ideUsuario: string
    ) => {
        await carServices
            .transactionState(token, tipoDocumento, ideUsuario, idPayment)
            .then((data) => {
                const dataInitial = data.data.getPayment;
                const responseJson = JSON.parse(dataInitial.responseJson);
                let numTransaction: string = ''
                const createdAt: string = dataInitial.createdAt
                if (responseJson.hasOwnProperty('DATA')) {
                    numTransaction = responseJson.DATA.CODE.toString()
                } else {
                    numTransaction = ''
                }
                const state = dataInitial.state === 'APPROVED' ? true : false;
                setdataTransaction(dataInitial);
                setdataOrder(state ? [] : orderInfoEmpty);
                setLoading(state ? true : false);
                setdataTransaction((previousData) => {
                    return {
                        ...previousData,
                        state: state ? true : false,
                        createdAt: moment(createdAt).format('dddd,  D  MMMM  YYYY,  h:MM A')
                    };
                });
                setDateCreate(dataInitial.createdAt);
                orderInfo(token, numTransaction, dataInitial.createdAt, state, ideUsuario);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        let dataLocalStorage = getData('userData');
        if (dataLocalStorage) {
            setDataUser(dataLocalStorage);
            let token = CryptoJS.AES.decrypt(
                dataLocalStorage.token,
                process.env.ENCRYPTION_KEY || ''
            );
            setToken(token.toString(CryptoJS.enc.Utf8));
            getTransactionState(
                token.toString(CryptoJS.enc.Utf8),
                dataLocalStorage.type,
                dataLocalStorage.user
            );
        }
    }, []);

    return (
        <Container>
            <MessageTitle>
                <Circle state={state}>
                    <CircleFill state={state}>
                        {state ? <img src={check} alt='Icono check' /> : <img src={x} alt='Icono de cerrar' />}
                    </CircleFill>
                </Circle>
                {state ? (
                    <>
                        <NameState state={state}>
                            ¡{dataUser.nombres}, tu transacción fue <span>exitosa!</span>
                        </NameState>
                        <TextEmail>
                            <p>Hemos enviado tu apuesta a tu correo.</p>
                            ¡Ojos de sapo, patas de rana, que tengas suerte toda la semana!
                        </TextEmail>{' '}
                    </>
                ) : (
                    <NameState state={state}>
                        Lo sentimos, tu transacción ha sido <span>rechazada </span>:(
                    </NameState>
                )}
                <Button text="Seguir jugando" onClick={() => window.location.href = siteUrl + '/juegos'}></Button>
            </MessageTitle>
            <TransactionContainer>
                <TransactionDetail state={state}>
                    <TitleDetails state={state}>Datos de transacción</TitleDetails>
                    <ContentDateState state={state}>
                        <p>{date} </p>
                        {state ? <span>Aprobada</span> : <span>Rechazada</span>}
                    </ContentDateState>
                    <TransactionInformation>
                        <Text>
                            <label>Referencia</label>
                            <h3>{reference}</h3>
                        </Text>
                        <Text>
                            <label>Nombre del comprador:</label>
                            <NameBuyer>{dataUser.nombres}</NameBuyer>
                        </Text>
                        <Text>
                            <label>Correo electrónico:</label>
                            <h3>{dataUser.email}</h3>
                        </Text>
                        <Text>
                            <TotalValue state={state}>
                                <label>Total pagado</label>
                                <h2>$ {converterNumber(total)}</h2>
                            </TotalValue>
                        </Text>
                    </TransactionInformation>
                </TransactionDetail>
                <OrderDetail>
                    <ContentTitle>
                        <TitleDetails state={state}>Detalles del pedido</TitleDetails>
                    </ContentTitle>
                    {loading && (
                        <BoxSpinner state={state}>
                            <Spinner />
                        </BoxSpinner>
                    )}
                    {
                        (!loading && dataOrder?.length === 0) &&
                            <TitleNotDetail>No hay detalles del pedido para mostrar</TitleNotDetail>
                    }
                    {!loading &&
                        dataOrder?.map((order: any, index: number) => (
                            <ContainerGame state={state} key={index}>
                                <InfoGame>
                                    <ContainerImage>
                                        <img src={logo} alt='Logo juego' />
                                    </ContainerImage>
                                    <label>
                                        No. de transacción
                                        {state && (
                                            <span
                                                className="icon-info"
                                                data-tip
                                                data-iscapture
                                                data-event="click focus"
                                                data-for="transaction"
                                            />
                                        )}
                                    </label>
                                    {state && (
                                        <TooltipCustom
                                            multiline={true}
                                            id="transaction"
                                            type="light"
                                            place="bottom"
                                            offset={{ left: 95 }}
                                            effect="solid"
                                        >
                                            La clave para abrir tu apuesta es tu número de
                                            identificación
                                        </TooltipCustom>
                                    )}
                                    <TransactionNumber>
                                        {state ? <span>{order.numTransaccion}</span> : <span />}
                                    </TransactionNumber>
                                </InfoGame>
                                <Button
                                    text="Descargar apuesta"
                                    disabled={!state}
                                    onClick={() => {
                                        downloadColilla(
                                            token,
                                            dataUser.usuario,
                                            order.colilla,
                                            dateCreate,
                                            order.serie
                                        );
                                        setLoadingDownload(true);
                                    }}
                                />
                            </ContainerGame>
                        ))}
                </OrderDetail>
            </TransactionContainer>
        </Container>
    );
};
