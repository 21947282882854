import { InputCustom, Select } from '@componentsShared';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { getDocumentsServices } from '@services/getDocumentData';
import { TDocumentsType, TDocumentType } from './types';


const options = [
    {
        value: 'CC',
        text: 'Cédula de ciudadanía'
    },
    {
        value: 'CE',
        text: 'Cédula de extranjería'
    },
    {
        value: 'PA',
        text: 'Pasaporte'
    }
];


export const ValidateTypeDocument = () => {
    const [documentType, setDocumentType] = useState<TDocumentType[]>(options);
    const [validationDocument, setValidationDocument] = useState({
        validation: {
            value: / /,
            message: ''
        },
        type: 'number'
    });


    const fetchDocumentType = async () => {
        let arrayDocuments: TDocumentType[] = [];

        await getDocumentsServices
            .getDocumentos()
            .then((data: TDocumentsType) => {
                const {
                    data: { getDocumentos }
                } = data;
                getDocumentos.forEach((document) => {
                    let documentType = {
                        value: document.abreviatura,
                        text: document.nombre
                    }
                    arrayDocuments.push(documentType);
                });
                setDocumentType(arrayDocuments)
            })
            .catch((error) => {
                console.log(error);
            });
    };


    const methods = useFormContext();

    useEffect(() => {
        if (methods.getValues('type') === 'CC') {
            setValidationDocument({
                validation: {
                    value: /^[1-9]([0-9]){4,10}$/,
                    message: 'Evita iniciar por cero(0) o poner puntos. Agrega mínimo 5 y máximo 10 números'
                },
                type: 'number'
            });
        } else if (methods.getValues('type') === 'CE') {
            setValidationDocument({
                validation: {
                    value: /^([0-9]){5,7}$/,
                    message: 'Evita poner punto. Agrega mínimo 5 y máximo 7 números'
                },
                type: 'number'
            });
        } else {
            setValidationDocument({
                validation: {
                    value: /^([a-zA-Z0-9]){4,16}$/,
                    message: 'Evita poner puntos. Agrega mínimo 5 y máximo 16 números'
                },
                type: 'text'
            });
        }
    }, [methods.getValues('type')]);

    useEffect(() => {
        fetchDocumentType();
    }, []);

    return (
        <>
            <Select
                label="Tipo de documento"
                name="type"
                options={documentType}
                defaultSelection={documentType[0]}
                required="Debe seleccionar un tipo de documento"
                // placeholder="Selecciona tipo de documento"
            />
            <InputCustom
                name="user"
                label="Número de documento"
                type={validationDocument.type}
                pattern={validationDocument.validation}
                placeholder="Escribe tu documento"
                required="Este campo es obligatorio, agrega tu documento"
                className={validationDocument.type === 'text' ? 'uppercase' : ''}
            />
        </>
    );
};
