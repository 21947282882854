import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const WrapperBanner = styled.section`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 413px;
    position: relative;
    /* padding-top: 56px; */
    .slick-slider {
        .slick-list {
            padding: 0;
            .slick-track {
                width: 100%;
                height: 100%;
                & > div {
                    height: 100%;
                    & > div {
                        height: 100%;
                    }
                }
            }
        }
        .slick-dots {
            text-align: center;
            width: auto;
            bottom: 25px;
            left: 0;
            right: 0;
            margin: 0 auto;
            li {
                width: 15px;
                height: 15px;
                &.slick-active {
                    button {
                        &:before {
                            font-size: 12px;
                            line-height: 14px;
                            width: 11px;
                            height: 11px;
                            color: transparent;
                            box-shadow: 0px 0px 6px 1.5px
                                ${(props) => props.theme.colors.alerts.yellow};
                            border: 2px solid ${(props) => props.theme.colors.alerts.yellow};
                            top: -2px;
                        }
                    }
                }
                button {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:before {
                        font-size: 10px;
                        line-height: 12px;
                        color: transparent;
                        border: 2px solid ${(props) => props.theme.colors.disabled.gray};
                        border-radius: 50%;
                        width: 7px;
                        height: 7px;
                        opacity: 1;
                    }
                }
            }
        }
    }
    ${breakpoint('md')`
        height: 630px;
        padding-top: 0;
        .slick-slider {
            .slick-dots {
                display: block;
                bottom: 64px;
                right: 70px;
                left: initial;
                margin: 0;
                text-align: initial;
            }
        }
    `}
    ${breakpoint('xxl')`
        height: 500px;
    `}
`;

export const ContentBanner = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
`;

export const BgBanner = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
    }
`;

export const InfoBanner = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    position: absolute;
    top: 0px;
    bottom: 0;
    margin: auto;
    left: 40px;
    width: 300px;
    height: 413px;
    p:nth-child(3) {
        margin-bottom: 0;
        b {
            font-size: 24px;
            line-height: 28px;
            color: ${(props) => props.theme.colors.accents.base.yellow};
            font-family: ${(props) => props.theme.fonts.mon_bold};
            width: 100%;
        }
    }
    p {
        width: 100%;
        font-size: 16px;
        line-height: 20px;
        text-shadow: 1px 1px 4px black;
        color: #f8f8f8;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        margin-bottom: 12px;
        b {
            font-size: 18px;
            line-height: 42px;
            color: ${(props) => props.theme.colors.accents.base.yellow};
            font-family: ${(props) => props.theme.fonts.lf_bold};
            margin-top: 8px;
            margin-bottom: 0;
        }
        i {
            font-size: 12px;
        }
        code {
            margin-top: 13px;
            margin-bottom: 0;
        }
    }
    ${breakpoint('md')`
        justify-content: flex-start;
        left: 190px;
        right: initial;
        width: 650px;
        height: 358px;
        margin-top:100px;
        p:nth-child(3) {
            b {
                font-size: 32px;
                line-height: 40px;
            }
        }
        p {
            font-size: 20px;
            line-height: 32px;
            text-shadow: 1px 1px 4px black;
            b {
                font-size: 27px;
                line-height: 42px;
            }
            i {
                font-size: 14px;
            }
        }
    `}
`;

export const TimerBanner = styled.div`
    display: flex;
    justify-content: space-between;
    width: 230px;
    margin-top: 22px;
    gap: 5px;
    ${breakpoint('md')`
        width: 295px;
    `}
`;

export const BoxTime = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-width: 60px;
    align-content: center;
    min-height: 60px;
    background: ${(props) => props.theme.colors.backgrounds.gray500};
    border: 1px solid ${(props) => props.theme.colors.principal.greenBase};
    box-sizing: border-box;
    box-shadow: 0px 0px 9px rgba(0, 212, 85, 0.25);
    border-radius: 12px;
    h3 {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 24px;
        line-height: 26px;
        color: ${(props) => props.theme.colors.accents.base.yellow};
        text-shadow: 0px 0px 12px rgba(255, 224, 0, 0.25);
        letter-spacing: 1px;
        font-family: ${(props) => props.theme.fonts.mon_bold};
        width: 100%;
    }
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: ${(props) => props.theme.colors.text.gray};
        font-size: 12px;

        font-family: ${(props) => props.theme.fonts.lf_regular};
        width: 100%;
    }
    ${breakpoint('md')`
        min-width: 84px;
        max-width: 84px;
        min-height: 84px;
        max-height: 84px;
        padding: 14px 2px 16px;
        h3 {
            font-size: 32px;
            line-height: 38.4px;
        }
        span {
            font-size: 14px;
            line-height: 18px;
        }
    `}
`;

export const TwoPoints = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: ${(props) => props.theme.colors.accents.base.yellow};
    text-shadow: 0px 0px 12px rgba(255, 224, 0, 0.25);
    font-size: 24px;
    line-height: 48px;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    ${breakpoint('lg')`
        font-size: 40px;
    `}
`;

export const LinkGame = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    color: #f8f8f8;
    background: linear-gradient(90deg, rgba(0, 159, 92, 1) 0%, rgba(13, 249, 108, 1) 100%);
    width: 209px;
    height: 46px;
    text-decoration: none;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    padding: 13px 20px;
    margin-top: 25px;
    transition: all 0.4s ease-in-out;
    box-shadow: 2px 2px 8px rgba(0,0,0,0.5);

    &.Centro {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 65px;
        justify-content: center;
        align-items: center;

        ${breakpoint('md')`
            left: -34px;
            bottom: 85px;
        `}
    }

    &.Derecha {
        position: absolute;
        left: 0px;
        right: 0px;
        margin: 0 auto;
        bottom: 50px;
        justify-content: center;
        align-items: center;

        ${breakpoint('sm')`
            left: 800px;
            bottom: 85px;
        `}
    }

    &:hover {
        transform: scale(1.1);
    }

    ${breakpoint('md')`
        height: 36px;
        font-size: 14px;
        line-height: 16px;
        border-radius: 12px;
        padding: 8px 32px;
    `}
`;

export const ArrowCustom = styled.div`
    display: flex;
    cursor: pointer;
    position: absolute;
    top: 50%;
    bottom: 0;
    align-items: center;
    z-index: 4;
    height: 60px;
    svg {
        path {
            transition: all 0.3s ease-in-out;
        }
    }
    &:hover {
        svg {
            path {
                fill: ${(props) => props.theme.colors.principal.greenBase};
            }
        }
    }
    &.prev {
        left: 0;
    }
    &.next {
        right: 0;
        transform: rotate(180deg);
    }
`;
