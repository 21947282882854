import React, { useEffect } from 'react';
import { IElement } from 'src/shared/context/suertudoContext';
import Viewport from 'src/shared/hooks/viewport';
import { BoxElement, BoxElementImg, BoxElementTitle } from './styled';
import { graphql, useStaticQuery } from 'gatsby';

interface IPropsElement {
    dataElement: IElement;
    onClick?: () => void;
    elementActive?: boolean;
    handlerSelected?: (element: IElement) => void;
    evento?: string;
}

export const ElementBox = (props: IPropsElement) => {
    const {
        dataElement,
        onClick,
        handlerSelected,
        elementActive,
        evento
    } = props;

    const elementActiveClass = elementActive ? 'elementActive' : '';

    const imagenes = useStaticQuery(graphql`
        query imgElements {
            allContentfulCardPortafolio(filter: { tipo: { eq: "Evento" } }) {
                edges {
                    node {
                        title
                        imagenes {
                            title
                            description
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const {
        allContentfulCardPortafolio: {
            edges
        }
    } = imagenes;

    const filterImage = (elemento: string) => {
        const arrEvento = edges.find((item: any) => item.node.title === evento);
        const imagen = arrEvento?.node.imagenes.find((item: any) => item.title === elemento);
        return imagen?.file?.url
    };

    return (
        <>
            <BoxElement
                className={elementActiveClass}
                onClick={() => {
                    if (onClick !== undefined) {
                        onClick();
                    }
                    if (handlerSelected !== undefined) {
                        handlerSelected(dataElement);
                    }
                }}
            >
                <BoxElementImg>
                    {dataElement.abreviatura && filterImage(dataElement.abreviatura) && (
                        <img src={filterImage(dataElement.abreviatura) || ''} />
                    )}
                </BoxElementImg>
                {!filterImage(dataElement.abreviatura) && (
                    <BoxElementTitle>{dataElement.desElemento}</BoxElementTitle>
                )}
            </BoxElement>
        </>
    );
};
