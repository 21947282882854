import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';
import { Props, UData } from './types';

interface IProps {
    loginUser?: boolean;
}
export const ContainerLoginData = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 6px;

    ${breakpoint('md')`
        position: relative;
        top: 2rem;
        left: -10px;
        `}

    &.desklog {
        ${breakpoint('md')`
        position: relative;
        right: 10px;
    `}
    }

    &.slog {
        button {
            flex-direction: column;
        }
    }
    &.login {
        ${breakpoint('xxl')`
           height: 45px;
        `}
    }
`;

export const UserName = styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    & > button {
        margin-right: 0px;
        margin-left: 13px;
    }
`;

export const TitleWelcome = styled.h2<UData>`
    font-family: ${(props) => props.theme.fonts.lf_bold};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;

    width: 125px;
    overflow: hidden;
    white-space: nowrap;
    /* text-overflow: ellipsis; */

    ${breakpoint('md')`
        &.desklog{
            display: none;
        }
    `}
`;

export const ContainerLoading = styled.div`
    width: 40px;
    height: 40px;
    position: relative;

    ${breakpoint('md')`
        top: -5px;
        position: absolute;
    `}
`;

export const ContainerUser = styled.div<IProps>`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 12px;
    padding: ${(props: IProps) => (props.loginUser ? '0px' : '0px 0px 0px 17px')};

    ${breakpoint('md')`
        &.desklog{
            display: none;
        }

        width: 232px;
        position: relative;
        top: 20px;
        padding: 10px 0;
        margin-right:21px;
    `}

    &.hide {
        top: 1.4rem;
    }

    &.show {
        background: #1d1828;
        border-top: 1px solid #0df96c;
        border-left: 1px solid #0df96c;
        border-right: 1px solid #0df96c;
        border-radius: 6px 6px 0 0;
        top: 1.4rem;
    }
`;

export const IconUser = styled.div<IProps>`
    width: ${(props: IProps) => (props.loginUser ? '36px' : '25px')};
    height: ${(props: IProps) => (props.loginUser ? '36px' : '25px')};
    padding: ${(props: IProps) => (props.loginUser ? '0px' : '14px')};
    border: 1px solid ${(props: IProps) => (props.loginUser ? '#00A3FF' : '#C5C3C9')};
    border-radius: 70%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        color: ${(props: IProps) => (props.loginUser ? '#00A3FF' : '#C5C3C9')};
        font-size: ${(props: IProps) => (props.loginUser ? '16px' : '14px')};
    }
`;

export const ContainerDataUser = styled.div<IProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: ${(props: IProps) => (props.loginUser ? '15px' : '10px')};
`;

export const TextWelcome = styled.p<IProps>`
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.disabled.gray};

    & > b {
        color: ${(props: IProps) => (props.loginUser ? '#ffe000' : '#f8f8f8')};
        margin-left: 5px;
        font-family: ${(props) => props.theme.fonts.lf_bold};
        font-size: 12px;
        line-height: 16px;
    }

    & > span {
        color: ${(props) => props.theme.colors.text.gray};
        margin-left: 5px;
        font-family: ${(props) => props.theme.fonts.lf_regular};
        font-size: 12px;
        line-height: 16px;
        margin-left: 2px;
    }

    ${breakpoint('md')`

        &.desklog{
            display: none;
        }

    `}
`;

export const TextBold = styled.p`
    font-family: ${(props) => props.theme.fonts.lf_bold};
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.disabled.gray};
`;

export const ButtonList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 10px;
    width: 206px;
    justify-content: space-between;
    border-bottom: 0.5px solid #5e5e5e;
    padding: 0 6px;
    padding-bottom: 20px;
    margin-right: 21px;

    &.hide {
        transform: translateY(-100%);
        opacity: 0;
        padding: 10px 16px;
        flex-direction: column;
        width: 232px;
        background: #1d1828;
        visibility: hidden;

        border-bottom: 1px solid #0df96c;
        border-left: 1px solid #0df96c;
        border-right: 1px solid #0df96c;
        border-radius: 0 0 6px 6px;

        p {
            font-size: 14px;
            line-height: 16.8px;
            padding: 16px;
            border-top: 1px solid #3e3a47bf;
        }

        button {
            display: flex;
            width: 200px;
            padding: 2px 16px;
            margin: 0;

            &:last-child {
                border-top: 1px solid #3e3a47bf;
                padding-top: 10px;
            }
        }
    }

    &.show {
        transform: translateY(0%);
        opacity: 1;
        padding: 10px 16px;
        flex-direction: column;
        background: #1d1828;
        width: 232px;

        border-bottom: 1px solid #0df96c;
        border-left: 1px solid #0df96c;
        border-right: 1px solid #0df96c;
        border-radius: 0 0 6px 6px;

        p {
            border-top: 1px solid #3e3a47bf;
            font-size: 14px;
            line-height: 16.8px;
            padding: 16px;
        }

        button {
            display: flex;
            width: 200px;
            padding: 2px 16px;
            margin: 0;

            &:last-child {
                border-top: 1px solid #3e3a47bf;
                padding-top: 10px;
            }
        }
    }

    &.desklog {
        position: relative;
        top: -1.5rem;
            ${breakpoint('xxl')`
            top: 0
           
`}

    ${breakpoint('md')`
        border: none;

        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: row;
        width: 250px;

        flex-wrap: wrap;
        width: 150px;
    `}

    ${breakpoint('xl')`
            flex-wrap: nowrap;
            width: 300px;
        `}
`;

export const ButtonHeader = styled.button<IProps>`
    background-color: transparent;
    border: none;
    color: ${(props: IProps) => (props.loginUser ? '#C5C3C9' : '#0DF96C')};
    ${breakpoint('md')`
         background: none;
         border: none;
         width: 140px;
         height: 26px;
         font-size: 14px;
         font-weight: 22px;
         font-family: ${(props) => props.theme.fonts.lf_bold};
         cursor: pointer;
         box-sizing: border-box;
     
         -webkit-flex-wrap: wrap;
         -ms-flex-wrap: wrap;
         flex-wrap: wrap;
        
        margin: 5px;

        `}

    &.margin{
      margin-bottom: 10px;
    }
    
    &.newAcount {
        width: 140px;
        height: 26px;
        font-size: 14px;
        border: 1px solid ${(props) => props.theme.colors.principal.greenNeon};
        border-radius: 12px;
        color: #fff;
    }

    a {
        background-color: transparent;
        border: none;
        color: ${(props: IProps) => (props.loginUser ? '#C5C3C9' : '#0DF96C')};
        text-decoration: none;

        ${breakpoint('md')`
         background: none;
         border: none;
         width: 140px;
         height: 26px;
         font-size: 14px;
         font-weight: 22px;
         font-family: ${(props) => props.theme.fonts.lf_regular};
         cursor: pointer;
         box-sizing: border-box;
     
         -webkit-flex-wrap: wrap;
         -ms-flex-wrap: wrap;
         flex-wrap: wrap;
        
        margin: 5px;
         `}

        &:hover {
            color: ${(props) => props.theme.colors.principal.greenNeon};
        }

        &.newAcount {
            width: 140px;
            height: 26px;
            font-size: 14px;
            border: 1px solid ${(props) => props.theme.colors.principal.greenNeon};
            border-radius: 12px;
            color: #fff;
        }
    }
`;
