import { ModalGana } from '@componentsShared';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface IProps {
    modalState: boolean;
    setModalState: () => void;
}

export const ModalCase = (props: IProps) => {
    const { modalState, setModalState } = props;

    const dataModalNoWin = useStaticQuery(graphql`
        query NotWinQuery {
            allContentfulModal(filter: { title: { eq: "Se ha creado tu caso con éxito" } }) {
                edges {
                    node {
                        icon {
                            file {
                                url
                            }
                        }
                        title
                        text
                        AditionalText
                        richText {
                            raw
                        }
                    }
                }
            }
        }
    `);
    const {
        allContentfulModal: { edges }
    } = dataModalNoWin;
    const { node } = edges[0];

    const optionsModal = {
        logo: 'terciary',
        icon: node.icon.file.url,
        title: node.title,
        primaryButtonText: 'Entendido',
        primaryButton: () => {
            setModalState();
        },
        dimensions: { height: { mobile: 'auto', desktop: '330px' }, width: { desktop: '388px' } },
        sizeButton: '136px'
    };

    return (
        <ModalGana visibility={modalState} setVisibility={setModalState} options={optionsModal} />
    );
};
