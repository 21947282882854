const printParams = (params, type) => {
    if (process.env.ENVIRONMENT !== 'production') {
        console.log(`=== ${type} EVENT ===`);
        for (const option in params) {
            console.log(option,': ', params[option]);
        }
        console.log(`=== ${type} EVENT ===`);
    }
};

export const pushDataLayerEvent = (params) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(params);

    printParams(params, 'DataLayer');
};

export const pushGtagEvent = ( event, params ) => {
    window.gtag = window.gtag || function () {};
    window.gtag('event', event, params);

    printParams(params, 'GA');
};

export const pushFbqEvent = ( event, params ) => {
    window.fbq = window.fbq || function () {};
    window.fbq('track', event, params);

    printParams(params, 'Meta Ads');
};
export const pushEskEvent = () => {
    window.esk = window.esk || function () {};
    window.esk('track', 'Conversion');

    if (process.env.ENVIRONMENT !== 'PDN') {
        console.log('=== Eskimy Ads EVENT ===');
        console.log('Track Conversion');
        console.log('=== Eskimy Ads EVENT ===');
    }
};
