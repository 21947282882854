import styled from 'styled-components';
import { breakpoint } from 'src/shared/styles/variables';

export const ContainerTestimonial = styled.div`
    padding: 0px 30px 0px 10px;
    ${breakpoint('md')`
        padding: 0px 40px 0px 20px;
    `}
`;

export const ContainerTestimonialText = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 6px 17px;
    white-space: nowrap;
    overflow: hidden;
    align-items: center;

    ${breakpoint('md')`
        padding: 12px 17px;
    `}
`;

type TCharacterNumber = {
    propCharactersNumber: number;
};

export const ParagraphTestimonial = styled.p<TCharacterNumber>`
    font-size: 14px;
    padding: 4px 0px 7px;
    padding-left: 100%;
    margin-right: 5px;
    animation: marquee ${(props) => `${props.propCharactersNumber}s linear infinite`};
    color: ${(props) => props.theme.colors.accents.base.yellow};
    font-family: ${(props) => props.theme.fonts.lf_regular};
    @keyframes marquee {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(-100%, 0);
        }
    }
`;

export const CloseSpan = styled.span`
    position: absolute;
    right: -7px;
    color: ${(props) => props.theme.colors.principal.greenNeon};
    margin-right: 10px;
    margin-bottom: 4px;
    font-size: 24px;
    cursor: pointer;

    ${breakpoint('md')`
        right: -5px;
        margin-right: 17px;
    `}
`;
