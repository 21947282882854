import { ModalSuccessRegister } from '@components';
import { Layout, SEO } from '@componentsShared';
import siteUrl from '@utils/siteUrl';
import React, { useState } from 'react';

export const ContainerRegisterConfirmation = () => {
    const [success, setSuccess] = useState(true);
    const displayModalSuccess = () => {
        setSuccess(!success);
    };

    return (
        <Layout>
            <SEO
                slug={siteUrl + '/registro/confirmado/'}
                title="Cuenta validada"
                description="Registro completo."
            />
            <ModalSuccessRegister
                modalState={success}
                setModalState={displayModalSuccess}
            />
        </Layout>
    );
};
